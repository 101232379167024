import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'react-bootstrap'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { DispatchContext } from '../actions'

import * as S from './styles'
import checkCircleIcon from '../../../../assets/images/check-circle.svg'
import { LoadingIndicator } from '../../../../components/atoms/LoadingIndicator'
import { CreateUnitContext } from '..'

export default function Step5({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { state, dispatch } = React.useContext(DispatchContext)
  const { data, setData } = React.useContext(CreateUnitContext)

  const registerUnits = data?.unitsName
    ? data?.unitsName?.map((name) => name).join(', ')
    : ''

  return (
    <>
      {state.loading && state.loading ? (
        <div
          style={{ minHeight: '18rem' }}
          className="d-flex justify-content-center align-items-center mt-5"
        >
          <LoadingIndicator />
        </div>
      ) : (
        <FormWizardStyles.TabContent>
          <S.Wrapper>
            <img src={checkCircleIcon} />
            <p>
              <strong>
                Dados das Unidades {registerUnits} cadastrados com sucesso!
              </strong>
            </p>
            <p>
              Você já pode criar Contratos de Compra e Venda para uma Unidade.
              Deseja fazer isso agora?
            </p>
            <Link to="/contracts/create">
              <Button variant="primary">
                Criar Contrato de Compra e Venda
              </Button>
            </Link>

            <Link to="/units">
              <a>Ver Unidade</a>
            </Link>
          </S.Wrapper>
        </FormWizardStyles.TabContent>
      )}
    </>
  )
}
