import * as React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import * as FormWizardStyles from '../../../../../components/organisms/FormWizard/styles'

import * as S from './styles'
import checkCircleIcon from '../../../../../assets/images/check-circle.svg'

interface IStep4 {
  closeModal: (e: boolean) => void
}

export default function Step4({ closeModal }: IStep4) {
  return (
    <FormWizardStyles.TabContent>
      <S.Wrapper>
        <img src={checkCircleIcon} />
        <p>
          <strong>
            Cadastro/Atualização de Promissário efetuado com sucesso!
          </strong>
        </p>
        <Button onClick={() => closeModal(false)}>
          <span>Voltar à criação de Contrato de Compra e Venda</span>
        </Button>
      </S.Wrapper>
    </FormWizardStyles.TabContent>
  )
}
