import { Button, Col, Row } from 'react-bootstrap'
import { TextWithHint } from '../../../components/atoms/TextWithHint'
import { EntityDetails } from '../../../components/organisms/EntityDetails'
import { DetailsHeader } from '../../../components/templates/DetailsHeader'

import { DetailButtonsRow } from '../../../components/molecules/DetailButtonsRow'
import { IconButton } from '../../../components/atoms/IconButton'
import { DetailsSection } from '../../../components/templates/DetailsSection'
import { UnitContractsTableCard } from '../../../components/organisms/UnitContractsTableCard'
import { CustomModal } from '../../../components/molecules/CustomModal'
import { deleteUnit, getUnitDetails } from '../../../domain/usecases/Unit'
import React from 'react'
import { UnitType } from '../../../domain/models/UnitModel'
import { useToasts } from 'react-toast-notifications'
import { useHistory, Link } from 'react-router-dom'
import { DeleteModal } from '../../../components/molecules/DeleteModal'
import NumberFormat from 'react-number-format'
import { ParkingSlotModel } from '../../../domain/models/ParkingSlotModel'

type UnitDetailsProps = {
  remoteGetUnits: getUnitDetails
  remoteDeleteUnit: deleteUnit
  id: string
}

const UnitDetails = ({
  remoteDeleteUnit,
  remoteGetUnits,
  id
}: UnitDetailsProps) => {
  const { addToast } = useToasts()
  const history = useHistory()
  const [, setLoading] = React.useState(false)
  const [unit, setUnit] = React.useState<UnitType>()
  const [show, setShow] = React.useState(true)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)

  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const handleOpenDeleteModal = () => setShowDeleteModal(true)

  const loadData = React.useCallback(async () => {
    setLoading(true)
    try {
      const response = await remoteGetUnits.getById({ id })
      setUnit(response)
    } catch (error) {
      history.push('/units')
      addToast('Não foi possível carregar a Unidade.', {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }, [id])

  React.useEffect(() => {
    loadData()
  }, [])

  const getOrderedGroupSlotsOrSlots = (slots: ParkingSlotModel[]) => {
    const slots_names = createGroupSlotsOrSlots(slots)
    const ordered_slots = orderSlots(slots_names)
    return ordered_slots.join(', ')
  }

  const createGroupSlotsOrSlots = (slots: ParkingSlotModel[]) =>
    slots.map((slot) => (slot.group ? createGroupSlot(slot) : slot.name))

  const createGroupSlot = (slot: ParkingSlotModel) =>
    `${slot.group?.name} - ${slot.name}`

  const orderSlots = (slots_names: string[]) => slots_names.sort(byName)

  const byName = (a: string, b: string) => {
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }

  const handleDeleteUnit = async (id: string) => {
    try {
      await remoteDeleteUnit.deleteById({ id })

      addToast('A Unidade foi excluída com sucesso!', {
        appearance: 'success',
        autoDismiss: true
      })

      history.push('/units')
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      handleCloseDeleteModal()
    }
  }

  return (
    <>
      {' '}
      {unit && (
        <>
          <DeleteModal
            show={showDeleteModal}
            handleClose={handleCloseDeleteModal}
            handleOk={() => handleDeleteUnit(unit.id)}
          />
          {!unit.is_complete && (
            <CustomModal
              show={show}
              title="Completar Cadastro da Unidade"
              body="Você já iniciou o cadastro desta Unidade, deseja completar o cadastro?"
              closeButtonText="Não"
              okButtonText="Sim, completar"
              handleClose={() => setShow(false)}
              handleOk={() =>
                history.push('/units/create', {
                  complete_unit: unit,
                  complete_multiple: true
                })
              }
            />
          )}
          <EntityDetails
            title="Unidade"
            sections={
              <>
                <DetailsHeader
                  image={unit.photo || ''}
                  fields={
                    <>
                      <Col md={6}>
                        <TextWithHint
                          text={unit.building.project.name}
                          hint="Empreendimento"
                        />
                      </Col>
                      <Col md={6}>
                        <TextWithHint
                          text={unit.building.project.ri}
                          hint="Registro da Incorporação"
                        />
                        {/*<IconWithText*/}
                        {/*  icon="icon-file mb-5"*/}
                        {/*  text={*/}
                        {/*    <TextWithHint*/}
                        {/*      text="98765478-09"*/}
                        {/*      hint="Alvará da Construção"*/}
                        {/*    />*/}
                        {/*  }*/}
                        {/*/>*/}
                      </Col>
                    </>
                  }
                  rightButtons={
                    <>
                      <Row>
                        <Col md={2}></Col>
                        <Col>
                          <Link to="/contracts/create">
                            <Button className="py-3 mb-2" variant="primary">
                              Criar Contrato de Compra e Venda
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </>
                  }
                />

                <DetailsSection
                  title="Endereço"
                  content={
                    <Row>
                      <Col md={4}>
                        <TextWithHint
                          text={unit.building.project.address.zip_code}
                          hint="CEP"
                        />
                        <TextWithHint
                          text={unit.building.project.address.complement}
                          hint="Complemento"
                        />
                        <TextWithHint
                          text={unit.building.project.address.state}
                          hint="Estado"
                        />
                      </Col>
                      <Col md={4}>
                        <TextWithHint
                          text={unit.building.project.address.address}
                          hint="Logradouro"
                        />
                        <TextWithHint
                          text={unit.building.project.address.district}
                          hint="Bairro"
                        />
                      </Col>
                      <Col md={4}>
                        <TextWithHint
                          text={unit.building.project.address.number}
                          hint="N.º"
                        />

                        <TextWithHint
                          text={unit.building.project.address.city}
                          hint="Cidade"
                        />
                      </Col>
                    </Row>
                  }
                />
                <DetailsSection
                  title="Características da Unidade"
                  content={
                    <>
                      <Row>
                        <Col md={3}>
                          <TextWithHint
                            text={unit.building.name}
                            hint="Bloco"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={unit.name}
                            hint="Unidade Autônoma"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={unit.description}
                            hint="Descrição"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={unit.total_bedrooms}
                            hint="N.º de Dormitórios"
                          />
                        </Col>

                        <Col md={3}>
                          <TextWithHint
                            text={
                              <a
                                href={unit.plan_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Abrir
                              </a>
                            }
                            hint="Planta"
                          />
                        </Col>

                        <Col md={3}>
                          <TextWithHint
                            text={unit.characteristics?.split(',').join(', ')}
                            hint="Características"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={
                              unit.usage_rights
                                ? unit.usage_rights_quantity
                                : getOrderedGroupSlotsOrSlots(
                                    unit.parking_slots
                                  )
                            }
                            hint="Vagas"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={
                              unit.real_private_area
                                ? `${unit.real_private_area}m2`
                                : ''
                            }
                            hint="Área Real Privativa"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={
                              unit.real_private_area_accessory
                                ? `${unit.real_private_area_accessory}m²`
                                : ''
                            }
                            hint="•Área Real Privativa (acessória) (m²)"
                          />
                        </Col>

                        <Col md={3}>
                          <TextWithHint
                            text={
                              unit.common_real_area
                                ? `${unit.common_real_area}m²`
                                : ''
                            }
                            hint="Área Real Comum (m²)"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={
                              unit.real_total_area
                                ? `${unit.real_total_area}m²`
                                : ''
                            }
                            hint="Área Real Total (m²)"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={unit.ideal_fraction?.toString()}
                            hint="Fração Ideal"
                          />
                        </Col>

                        <Col md={3}>
                          <TextWithHint
                            text={
                              <NumberFormat
                                value={unit.value?.toString().replace('.', ',')}
                                displayType={'text'}
                                prefix={'R$ '}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                renderText={(value) => <div>{value}</div>}
                              />
                            }
                            hint="Valor da Unidade (R$)"
                          />
                        </Col>
                      </Row>
                    </>
                  }
                />

                <DetailButtonsRow
                  buttons={
                    <>
                      <IconButton
                        icon="icon-trash"
                        onClick={handleOpenDeleteModal}
                        color="#E62355"
                      />
                      <IconButton
                        onClick={() =>
                          history.push('/units/create', { unitParams: unit })
                        }
                        icon="icon-edit"
                        color="#5E6EA7"
                      />
                    </>
                  }
                />
              </>
            }
            content={<UnitContractsTableCard />}
          />
        </>
      )}
    </>
  )
}

export default UnitDetails
