import { IToken } from 'domain/usecases/Tokens'
import { TokenModel } from 'domain/models/TokenModel'
import { validateHttpStatus } from 'validation/validateHttpResponse'
import { HttpClient } from 'data/protocols/http/httpClient'

export class Tokens implements IToken {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async getAllByUserId(): Promise<TokenModel[]> {
    return await this.httpClient.get?.({
      url: `${this.url}/tokens/my`
    })
  }
}
