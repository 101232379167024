import { EntityCardProps } from '../../components/organisms/EntityCard'
import { UnitType } from '../models/UnitModel'
import { Badge } from 'react-bootstrap'
import React from 'react'

export const convertUnitToEntity = (
  units: UnitType[] = [],
  deleteFn: (paramId: string) => void,
  openFn: (id: string) => void,
  addPhoto: (id: string) => void
): EntityCardProps[] => {
  const unitsCards = units.map((unit) => {
    return {
      rightSideContent: !unit.is_complete && (
        <Badge variant="danger">Cadastro Incompleto</Badge>
      ),
      title: `${unit.building.name} - ${unit.name}`.toUpperCase(),
      line1: `${unit.building.project.name}`,
      line2: `${unit.building.project.spe.company.business_name}`,
      image: unit.photo,
      logoOrPhoto: 'photo',
      leftBtnAction: () => deleteFn(unit.id),
      rightBtnAction: () => openFn(unit.id),
      onAddPhoto: () => addPhoto(unit.id),
      icons: [
        {
          icon: 'icon-home',
          text: unit.real_total_area ? `${unit.real_total_area} m²` : '0 m²'
        },
        { icon: '', text: `${unit.parking_slots.length} vagas` }
      ]
    }
  })

  const orderedUnits = unitsCards.sort((a: any, b: any) => {
    const unit1 = a.line1.toUpperCase()
    const unit2 = b.line1.toUpperCase()
    let comparison = 0
    if (unit1 > unit2) {
      comparison = -1
    } else if (unit1 < unit2) {
      comparison = 1
    }
    return comparison
  })
  return orderedUnits as EntityCardProps[]
}
