import styled from 'styled-components'

export const Root = styled.div`
  display: flex;

  justify-content: end;
  align-items: center;
  gap: 10px;

  span {
    color: white;
    font-weight: 600;
  }
`

export const Img = styled.img`
  margin-top: 25px;
  width: 130px;
  float: right;
`
