import styled, { keyframes } from 'styled-components'

export const appearFromDown = keyframes`
  from {
    opacity: 0;
    transition:0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transition: 1s;
    transform: translateX(0);
  }
`

export const Container = styled.div`
  animation: ${appearFromDown} 1s;
`
