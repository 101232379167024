import { Button } from 'react-bootstrap'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { Link } from 'react-router-dom'

import * as S from './styles'
import checkCircleIcon from '../../../../assets/images/check-circle.svg'

export default function Step5() {
  return (
    <FormWizardStyles.TabContent>
      <S.Wrapper>
        <img src={checkCircleIcon} />
        <p>
          <strong>Dados da SPE cadastrados com sucesso!</strong>
        </p>
        <p>
          Você já pode cadastrar um Empreendimento associado a essa SPE. Deseja
          fazer isso agora?
        </p>
        <Link to="/projects/create">
          <Button variant="primary">Cadastrar Empreendimento</Button>
        </Link>

        <Link to="/spes">
          <a>Ver SPE</a>
        </Link>
      </S.Wrapper>
    </FormWizardStyles.TabContent>
  )
}
