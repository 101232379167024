import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 10px 30px;

  p {
    color: #868e96;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }

  span {
    margin: 0;
    color: #868e96;
    font-size: 13px;
  }
`
