import * as React from 'react'
import { Card, Table } from 'react-bootstrap'
import { TableTitle } from '../../../components/atoms/TableTitle'
import { ProxiesTableRow } from '../../../components/organisms/ProxiesTableRow'
import { Proxy } from '../../../domain/models/ProxyModel'

type TableProps = {
  proxies: Proxy[]
}

export const ProxiesTableCard = ({ proxies }: TableProps) => {
  return (
    <>
      <Card className="table-card">
        <Card.Header>
          <TableTitle>
            <strong>Procurador(es) ({proxies.length})</strong>
          </TableTitle>
        </Card.Header>
        <Card.Body className="pb-0">
          <Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>NOME</th>
                <th>CPF</th>
                <th>E-MAIL</th>
              </tr>
            </thead>
            <tbody>
              {!!proxies.length &&
                proxies.map((proxy) => (
                  <ProxiesTableRow
                    key={proxy.id}
                    name={proxy.name}
                    cpf={proxy.cpf}
                    email={proxy.email}
                  />
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}
