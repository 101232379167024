export const formatValueToNumberInput = (
  value?: string,
  type?: 'reverse'
): string => {
  if (!value) return ''

  // eslint-disable-next-line no-useless-escape
  if (type === 'reverse') return value.replace(/\,/g, '.')

  return value.replace(/\./g, ',')
}
