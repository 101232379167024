import { Centered } from '../../../../components/templates/Centered'
import { BankBilletPending } from './bank-billet-pending'
import { CreditCardPending } from './credit-card-pending'
import { Card } from 'react-bootstrap'
import * as React from 'react'

export const SuccessCard = ({
  payment_type,
  bankBilletUrl
}: {
  payment_type: string
  bankBilletUrl: string
}) => {
  return (
    <Card.Body>
      <Centered>
        {payment_type === 'bank_billet' && (
          <BankBilletPending bankBilletUrl={bankBilletUrl} />
        )}
        {payment_type === 'credit_card' && <CreditCardPending />}
      </Centered>
    </Card.Body>
  )
}
