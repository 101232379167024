import * as S from './styles'
import { useLoadingSelector } from '../../../store/reducers/reducer'

export const LoadingOverlay = () => {
  const loading = useLoadingSelector((state) => state.loading)

  return loading ? (
    <S.Wrapper>
      <div className="overlay__inner">
        <div className="overlay__content">
          <span className="spinner"></span>
        </div>
      </div>
    </S.Wrapper>
  ) : (
    <></>
  )
}
