export const IconTendencyUp = () => {
  return (
    <svg
      width="52"
      height="28"
      viewBox="0 0 52 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="28" rx="14" fill="#A5FFA9" />
      <path
        d="M35.0625 8.375V13.375C35.0625 13.6236 34.9637 13.8621 34.7879 14.0379C34.6121 14.2137 34.3736 14.3125 34.125 14.3125C33.8764 14.3125 33.6379 14.2137 33.4621 14.0379C33.2863 13.8621 33.1875 13.6236 33.1875 13.375V10.6406L27.2883 16.5406C27.2012 16.628 27.0977 16.6974 26.9837 16.7447C26.8698 16.792 26.7476 16.8164 26.6242 16.8164C26.5008 16.8164 26.3787 16.792 26.2647 16.7447C26.1507 16.6974 26.0473 16.628 25.9602 16.5406L23.5 14.0781L18.5383 19.0383C18.3622 19.2144 18.1233 19.3133 17.8742 19.3133C17.6251 19.3133 17.3863 19.2144 17.2102 19.0383C17.034 18.8622 16.9351 18.6233 16.9351 18.3742C16.9351 18.1251 17.034 17.8863 17.2102 17.7102L22.8352 12.0852C22.9223 11.9978 23.0257 11.9284 23.1397 11.8811C23.2537 11.8338 23.3758 11.8094 23.4992 11.8094C23.6226 11.8094 23.7448 11.8338 23.8587 11.8811C23.9727 11.9284 24.0762 11.9978 24.1633 12.0852L26.625 14.5469L31.8594 9.3125H29.125C28.8764 9.3125 28.6379 9.21373 28.4621 9.03791C28.2863 8.8621 28.1875 8.62364 28.1875 8.375C28.1875 8.12636 28.2863 7.8879 28.4621 7.71209C28.6379 7.53627 28.8764 7.4375 29.125 7.4375H34.125C34.3736 7.4375 34.6121 7.53627 34.7879 7.71209C34.9637 7.8879 35.0625 8.12636 35.0625 8.375Z"
        fill="#00AC07"
      />
    </svg>
  )
}
