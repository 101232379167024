import { createProject, Project } from '../../../domain/usecases/Project'
import { ProjectType } from '../../../domain/models/ProjectModel'
import { HttpClient } from '../../protocols/http/httpClient'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

type buildingObjectParams = {
  name: string
  units: Record<string, string>[]
}
/*eslint-disable */
export class RemoteCreateProject implements createProject {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async createProject(params: Project.CreateParams): Promise<ProjectType> {
    const {
      id,
      units = [],
      building_permit,
      ri_document,
      grouped_parking_slots,
      parking_slots = [],
      parking_slots_groups = [],
      buildings,
      conclusion_date,
      ...properties
    } = params

    let buildingsCompleted: Array<buildingObjectParams> | undefined = []
    const objectArray = Object.entries(units)
    objectArray.forEach(([key, value]) =>
      buildingsCompleted?.push({ name: key, units: value })
    )
    const formData = new FormData()
    formData.append(`building_permit`, building_permit!)
    formData.append('ri_document', ri_document!)
    formData.append(
      'grouped_parking_slots',
      grouped_parking_slots ? 'yes' : 'no'
    )
    formData.append(`conclusion_date`, conclusion_date.toDateString())  
    for (let i = 0; i < parking_slots.length; i++) {
      formData.append(`parking_slots[${i}][name]`, parking_slots[i].name)
      formData.append(`parking_slots[${i}][id]`, parking_slots[i].id || '')
    }
    const props: Record<string, any> = properties
    for (const key in props) {
      if (typeof props[key] === 'object') {
        for (const subKey in props[key]) {
          formData.append(`${key}[${subKey}]`, props[key][subKey])
        }
      } else {
        formData.append(key, props[key])
      }
    }

    buildingsCompleted.forEach((building, index) => {
      formData.append(`buildings[${index}][name]`, building.name)
      building.units.forEach((unit, index2) => {
        formData.append(
          `buildings[${index}][units][${index2}][name]`,
          unit.name
        )
      })
    })
    parking_slots_groups.forEach((parking, index) => {
      formData.append(`parking_slots_groups[${index}][name]`, parking.name)
      formData.append(`parking_slots_groups[${index}][id]`, parking.id || '')
      parking.parking_slots.forEach((parkingSlots, index2) => {
        formData.append(
          `parking_slots_groups[${index}][parking_slots][${index2}][name]`,
          parkingSlots.name
        )
        formData.append(
          `parking_slots_groups[${index}][parking_slots][${index2}][id]`,
          parkingSlots.id || ''
        )
      })
    })

    const httpMethod = id ? 'patch' : 'post'
    return await this.httpClient[httpMethod]?.({
      url: `${this.url}/projects/${id || ''}`,
      data: formData,
      headers: {
        Accept: '*/*'
      }
    })
  }

  async uploadProjectPhoto(params: Project.UploadProjectPhoto): Promise<any> {
    const httpResponse = await this.httpClient.post?.({
      url: `${this.url}/projects/${params.id}/upload-photo`,
      data: { base64: params.base64 }
    })

    if (httpResponse?.statusCode && httpResponse?.statusCode !== 201)
      throw new Error('error on upload project photo')
    return httpResponse
  }
}
