import { RouteObject } from './guest.routes'

//Factories
import { CreateUnitFactory } from '../main/factories/pages/Unit/CreateUnitFactory'
import { ListCompaniesFactory } from '../main/factories/pages/Company/ListCompaniesFactory'
import { CompanyDetailsFactory } from '../main/factories/pages/Company/CompanyDetailsFactory'
import { CompanyCreateFactory } from '../main/factories/pages/Company/CreateCompanyFactory'
import { CreateProjectFactory } from '../main/factories/pages/Project/CreateProjectFactory'
import { CreateSpeFactory } from '../main/factories/pages/SPE/CreateSpeFactory'
import { DetailsSpeFactory } from '../main/factories/pages/SPE/DetailsSpeFactory'
import { UnitDetailsFactory } from '../main/factories/pages/Unit/UnitDetailsFactory'
import { ListSpesFactory } from '../main/factories/pages/SPE/ListSpeFactory'
import { ListUnitsFactory } from '../main/factories/pages/Unit/ListUnitsFactory'
import { ListProjectsFactory } from '../main/factories/pages/Project/ListProjectsFactory'
import { ProjectDetailsFactory } from '../main/factories/pages/Project/ProjectDetailsFactory'
import { CreateContractFactory } from '../main/factories/pages/Contracts/CreateContractFactory'
import { ListContractsFactory } from '../main/factories/pages/Contract/ListContractsFactory'
import { DashboardFactory } from '../main/factories/pages/Dashboard/DashboardFactory'
import { ContractDetailsFactory } from '../main/factories/pages/Contract/DetailsContractFactory'
import { ListPaymentsFactory } from '../main/factories/pages/Payments/ListPaymentsFactory'
import { UserType } from '../domain/models/UserModel'
import { User } from '../app'
import { DashboardInvestorFactory } from '../main/factories/pages/DashboardInvestor/DashboardFactory'
import { ListBlockchainFactory } from '../main/factories/pages/Blockchain/ListBlockchainFactory'
import { TokenListFactory } from 'main/factories/pages/Tokens/TokensList'
import { BillingDetailsFactory } from 'main/factories/pages/Billings/DetailsBillingFactory'

export class RoutesFactory {
  static createRoutes(user: User) {
    switch (user.type) {
      case UserType.COMMON:
        return loggedRoutes
      case UserType.INVESTOR:
        return loggedAsInvestorRoutes
      default:
        return []
    }
  }
}

const loggedRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: DashboardFactory
  },
  {
    path: '/companies/create',
    exact: true,
    name: 'CreateCompany',
    component: CompanyCreateFactory
  },

  {
    path: '/companies',
    exact: true,
    name: 'ListCompanies',
    component: ListCompaniesFactory
  },

  {
    path: '/companies/:id',
    exact: true,
    name: 'CompanyDetails',
    component: CompanyDetailsFactory
  },

  {
    path: '/spes/',
    exact: true,
    name: 'SPEList',
    component: ListSpesFactory
  },

  {
    path: '/spes/create',
    exact: true,
    name: 'CreateSpe',
    component: CreateSpeFactory
  },

  {
    path: '/spes/:id',
    exact: true,
    name: 'SPEDetails',
    component: DetailsSpeFactory
  },

  {
    path: '/projects/',
    exact: true,
    name: 'ListProjects',
    component: ListProjectsFactory
  },

  {
    path: '/projects/create',
    exact: true,
    name: 'CreateProject',
    component: CreateProjectFactory
  },

  {
    path: '/projects/:id',
    exact: true,
    name: 'ProjectDetails',
    component: ProjectDetailsFactory
  },

  {
    path: '/units/',
    exact: true,
    name: 'ListUnits',
    component: ListUnitsFactory
  },

  {
    path: '/units/create',
    exact: true,
    name: 'CreateUnits',
    component: CreateUnitFactory
  },

  {
    path: '/units/:id',
    exact: true,
    name: 'UnitDetails',
    component: UnitDetailsFactory
  },

  {
    path: '/contracts/',
    exact: true,
    name: 'ListContracts',
    component: ListContractsFactory
  },

  {
    path: '/contracts/create',
    exact: true,
    name: 'ContractCreate',
    component: CreateContractFactory
  },
  {
    path: '/contracts/:id',
    exact: true,
    name: 'ContractDetails',
    component: ContractDetailsFactory
  },
  {
    path: '/billings/:id',
    exact: true,
    name: 'BillingDetails',
    component: BillingDetailsFactory
  },
  {
    path: '/payments',
    exact: true,
    name: 'ListPayments',
    component: ListPaymentsFactory
  },
  {
    path: '/blockchain',
    exact: true,
    name: 'Blockchain',
    component: ListBlockchainFactory
  },
  {
    path: '/tokens',
    exact: true,
    name: 'TokenList',
    component: TokenListFactory
  }
]

export const loggedAsInvestorRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: DashboardInvestorFactory
  }
]
export default loggedRoutes
