import * as React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Alert, Card } from 'react-bootstrap'

import logo from '../../assets/images/logo.svg'
import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import { useEffect, useState } from 'react'
import { confirmEmail } from '../../services/auth'

interface MatchParams extends RouteComponentProps {
  params: { token: string }
}
const ConfirmEmail = ({ match }: { match: MatchParams }) => {
  document.title = 'Recebível Chipado - Confirmação de E-mail'
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const token = match.params.token

  useEffect(() => {
    confirmEmail(token)
      .then(() => {
        setLoading(false)
        setSuccess(true)
        setError(false)
      })
      .catch(() => {
        setLoading(false)
        setSuccess(false)
        setError(true)
      })
  }, [token])

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Card>
          <div className="row align-items-center">
            <div className="col-md-12">
              <Card.Body>
                <img src={logo} alt="" className="logo" />
                {loading && (
                  <h5 className="f-w-700">
                    Aguarde enquanto ativamos a sua Conta.
                  </h5>
                )}
                {success && (
                  <>
                    <Alert variant="success">
                      Sua Conta foi criada e nós já podemos trabalhar juntos.
                      <br />
                      Obrigado pela oportunidade! 😉
                    </Alert>
                    <div className="d-flex flex-row justify-content-around mt-4">
                      <Link to="/login">
                        <a>Acessar Recebível Chipado</a>
                      </Link>

                      <a
                        href="#"
                        onClick={() => {
                          window.close()
                        }}
                      >
                        Sair
                      </a>
                    </div>
                  </>
                )}
                {error && (
                  <Alert variant="danger">
                    Link de confirmação inválido ou expirado.
                  </Alert>
                )}
              </Card.Body>
            </div>
          </div>
        </Card>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default ConfirmEmail
