import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
