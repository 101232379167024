import styled from 'styled-components'

export const DateInput = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      background: #ecf0f5;
      display: flex;
      padding: 10px;
      border-radius: 0.3rem;
      input {
        height: 30px;
        width: 100%;
        background: #ecf0f5;
        border: none;
        font-size: 1.1rem;
        border-radius: 0.3rem;
      }
    }
  }
`
export const DateList = styled.div<{ showAll: boolean }>`
  background-color: #ecf0f5;
  padding: 7px;
  border-radius: 0.3rem;
  min-height: 50px;
  .tags {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-bottom: 0 !important;
  }
  .tag {
    background: #00acc1;
    border-radius: 3px 0 0 3px;
    color: whiteSmoke;
    font-weight: 500;
    font-size: 1.1rem;
    border-radius: 0.5rem;
    padding: 0px 5px 0px;
    margin: 5px;

    &:last-of-type {
      transition: all 0.3s ease-in-out;
      background: #8b9ed1;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`

export const TotalDates = styled.span`
  cursor: pointer;
`
