import { useHistory } from 'react-router'
import { lazy, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../app'
import { useToasts } from 'react-toast-notifications'
import * as React from 'react'

const AdminLayout = lazy(() => import('../theme-originals/layout/AdminLayout'))

export const LoggedRoutesContainer = () => {
  const [checking, setChecking] = useState(true)
  const history = useHistory()
  const { user } = useContext(AppContext)
  const { addToast } = useToasts()

  useEffect(() => {
    if (!user) {
      history.push('/login')
      addToast('Você precisa estar logado para acessar esta página.', {
        appearance: 'error',
        autoDismiss: true
      })
    } else {
      setChecking(false)
    }
  }, [user])

  return checking ? <div></div> : <AdminLayout />
}
