import { Alert, Badge, Card, Table } from 'react-bootstrap'
import { TableTitle } from '../../../../components/atoms/TableTitle'

import * as S from './styles'

export const BillingEventsTable = () => {
  return (
    <S.Wrapper>
      <Card className="table-card">
        <Card.Header>
          <TableTitle>Recebível Chipado</TableTitle>
        </Card.Header>
        <Card.Body className="pb-0">
          <S.CustomTable responsive hover className="mb-0">
            <thead>
              <tr>
                <th>EVENTO</th>
                <th>DATA / HORA</th>
                <th>HASH DO EVENTO</th>
                <th>STATUS DO EVENTO</th>
                <th>BOLETOS</th>
              </tr>
            </thead>
            <tbody></tbody>
          </S.CustomTable>
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
