import { Card, Col, Row } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import FormWizard, { Step } from '../../../components/organisms/FormWizard'
import Step1, { ICreateSpeFormStep1 } from './Step1/step1'
import Step2, { ICreateSpeFormStep2 } from './Step2/step2'
import Step3, { ICreateSpeFormStep3 } from './Step3/step3'
import Step4, { ICreateSpeFormStep4 } from './Step4'
import { Title } from '../../../components/atoms/Title'
import React, { useState } from 'react'
import { SetableContext } from '../../../interfaces/setable-context'
import Step5 from './Step5'
import { SpeType } from '../../../domain/models/SpeModel'
import Utils from './utils'

import { ICreateSpe, IUpdateSpe } from '../../../domain/usecases/SPE'
import { getCompany } from '../../../domain/usecases/Company'

export type ICreateSpeForm = Partial<
  ICreateSpeFormStep1 &
    ICreateSpeFormStep2 &
    ICreateSpeFormStep3 &
    ICreateSpeFormStep4 & { id?: string }
>
export const CreateSpeContext = React.createContext<
  SetableContext<ICreateSpeForm>
>({})

type Props = {
  handleCreate: ICreateSpe
  getCompaniesList: getCompany
  hasSpe?: SpeType
  handleUpdate: IUpdateSpe
  hasCompanyId: string
}

export default function CreateSpe({
  handleCreate,
  getCompaniesList,
  hasSpe,
  hasCompanyId,
  handleUpdate
}: Props) {
  const { addToast } = useToasts()
  const history = useHistory()
  const [data, setData] = useState<ICreateSpeForm>({
    proxies: [],
    companyId: hasCompanyId
  })

  const { convertSpeObjToExpectedFormatContext } = Utils()

  React.useEffect(() => {
    hasSpe && setData(convertSpeObjToExpectedFormatContext(hasSpe))
  }, [])

  const steps: Step[] = [
    {
      id: 1,
      label: 'Dados da SPE',
      component: (props) => Step1({ getCompaniesList, props })
    },
    {
      id: 2,
      label: 'NIRE',
      component: Step2
    },
    {
      id: 3,
      label: 'Procurador(es)',
      component: Step3
    },
    {
      id: 4,
      label: 'Meios de Pagamento',
      component: Step4
    },
    {
      id: 5,
      label: 'Final',
      component: Step5,
      disableContinueLater: true
    }
  ]
  /*eslint-disable */
  const updateSpe = () => {
    if (
      hasSpe?.commercial_board_document_url ===
      data?.commercial_board_document_url?.name
    ) {
      delete data.commercial_board_document_url
    }
    handleUpdate.updateOne(data).catch((error) => {
      history.push('/spes')
      addToast('Não foi possível editar a SPE.', {
        appearance: 'error',
        autoDismiss: true
      })
    })
  }

  const createSpe = () => {
    handleCreate.createOne(data as Required<ICreateSpeForm>)
  }

  const onFinish = () => {
    hasSpe ? updateSpe() : createSpe()
  }

  const handleSave = () => {
    sessionStorage.setItem('CreateSpeForm', JSON.stringify(data))
  }

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Title>{hasSpe ? 'Editar' : 'Cadastrar'} SPE</Title>
          </Card.Header>
          <Card.Body>
            <CreateSpeContext.Provider value={{ data, setData }}>
              <FormWizard
                onSaveDraft={handleSave}
                steps={steps}
                finishOnStep={5}
                onFinish={onFinish}
                canClickOnSteps={!!hasSpe}
              />
            </CreateSpeContext.Provider>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
