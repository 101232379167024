import { HttpClient } from '../../protocols/http/httpClient'
import { getUnitDetails, getUnits, Unit } from '../../../domain/usecases/Unit'
import { UnitType } from '../../../domain/models/UnitModel'

export class RemoteGetUnits implements getUnitDetails, getUnits {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpClient
  ) {}

  async getUnits(): Promise<UnitType[]> {
    const httpResponse = await this.httpGetClient.get?.({
      url: `${this.url}/units`
    })

    if (httpResponse.error) throw new Error('Error on get units')
    return httpResponse
  }

  async getById(params: Unit.GetParams): Promise<any> {
    const httpResponse = await this.httpGetClient.get?.({
      url: `${this.url}/units/${params.id}`
    })

    if (httpResponse.statusCode && httpResponse.statusCode != 200)
      throw new Error('Error on get unit')
    return httpResponse
  }
}
