import * as yup from 'yup'
// @ts-ignore
import isValidCep from '@brazilian-utils/is-valid-cep'

export default yup.object().shape({
  card_number: yup
    .string()
    .max(16, 'Máximo de 16 números.')
    .required('O campo Número do Cartão é requerido.'),
  cvv: yup
    .string()
    .min(3, 'Mínimo de 3 números.')
    .max(4, 'Máximo de 4 números.')
    .required('O campo Código de Segurança é requerido.'),
  expiration_month: yup
    .string()
    .required('O campo Mês de Expiração é requerido.'),
  expiration_year: yup
    .string()
    .required('O campo Ano de Expiração é requerido.'),
  zipcode: yup
    .string()
    .test('is-cep', 'Digite um CEP válido', (value) => isValidCep(value)),
  street: yup.string().required('O campo Endereço é requerido.'),
  number: yup.string().required('O campo Número é requerido.'),
  neighborhood: yup.string().required('O campo Bairro é requerido.'),
  city: yup.string().required('O campo Cidade é requerido.'),
  state: yup.string().required('O campo Estado é requerido.'),
  birth: yup.string().required('O campo Data de Nascimento é requerido.')
})
