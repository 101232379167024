export const COMERCIAL_BOARD_NAME = [
  'Distrito Federal',
  'Estado do Acre',
  'Estado de Alagoas',
  'Estado do Amazonas',
  'Estado do Amapá',
  'Estado da Bahia',
  'Estado do Ceará',
  'Estado do Espírito Santo',
  'Estado de Goiás',
  'Estado do Maranhão',
  'Estado do Mato Grosso',
  'Estado do Mato Grosso do Sul',
  'Estado de Minas Gerais',
  'Estado do Pará',
  'Estado da Paraíba',
  'Estado do Paraná',
  'Estado de Pernambuco',
  'Estado do Piauí',
  'Estado do Rio de Janeiro',
  'Estado do Rio Grande do Norte',
  'Estado do Rio Grande do Sul',
  'Estado de Rondônia',
  'Estado de Roraima',
  'Estado de Santa Catarina',
  'Estado de São Paulo',
  'Estado de Sergipe',
  'Estado do Tocantins'
]
