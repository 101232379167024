import { Alert, Badge, Card, Table } from 'react-bootstrap'
import { IconButton } from '../../../../components/atoms/IconButton'
import { TableTitle } from '../../../../components/atoms/TableTitle'

import * as S from './styles'
export type ValuesPersonTable = {
  id: string
  name: string
  cpfOrCnpj: string
  mail: string
  status: string
  signed_at: string
}

export type PersonsTableProps = {
  value: ValuesPersonTable[]
}

export const formatContractSignatureStatusVariant = {
  Assinado: 'success',
  Pendente: 'warning'
}

export const PersonsTable = ({ value }: PersonsTableProps) => {
  return (
    <S.Wrapper>
      <Card className="table-card">
        <Card.Header>
          <TableTitle>Assinantes</TableTitle>
        </Card.Header>
        <Card.Body className="pb-0">
          <S.CustomTable responsive hover className="mb-0">
            <thead>
              <tr>
                <th>NOME</th>
                <th>CPF / CNPJ</th>
                <th>E-MAIL</th>
                <th>STATUS DA ASSINATURA</th>
                <th>DATA DA ASSINATURA</th>
              </tr>
            </thead>
            <tbody>
              {value.map((person) => (
                <tr key={person.id}>
                  <td>{person.name}</td>
                  <td>{person.cpfOrCnpj}</td>
                  <td>{person.mail}</td>
                  <td>
                    <S.CustomBadge
                      variant={
                        formatContractSignatureStatusVariant[
                          person.status as keyof typeof formatContractSignatureStatusVariant
                        ]
                      }
                    >
                      {person.status}
                    </S.CustomBadge>
                  </td>
                  <td>{person.signed_at}</td>
                </tr>
              ))}
            </tbody>
          </S.CustomTable>
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
