import { Form, FormControlProps } from 'react-bootstrap'
import { forwardRef, ForwardRefRenderFunction, useState } from 'react'

interface ISwitch
  extends Omit<FormControlProps, 'onChange' | 'value' | 'type'> {
  value?: boolean
  onChange?: (value: boolean) => void
  label?: string
  name?: string
}

type ISwitchRef = HTMLInputElement

const Switch: ForwardRefRenderFunction<ISwitchRef, ISwitch> = (
  { value, onChange, name, label, ...rest },
  ref
) => {
  const idValueOrDefault = rest.id ?? 'checked-default'

  return (
    <Form.Group>
      <div className="switch d-inline m-r-10">
        <Form.Control
          name={name}
          type="checkbox"
          id={idValueOrDefault}
          defaultChecked={value}
          onChange={(event: any) => {
            const { checked } = event.target
            if (onChange) {
              onChange(checked)
              return
            }
          }}
          ref={ref}
          {...rest}
        />
        <Form.Label htmlFor={idValueOrDefault} className="cr" />
      </div>
      {label && <Form.Label>{label}</Form.Label>}
    </Form.Group>
  )
}

export default forwardRef(Switch)
