import React, { useState } from 'react'
import { Button, ProgressBar } from 'react-bootstrap'
import Loki from 'react-loki'
import FormsModal from '../../molecules/FormsModal'
import * as S from './styles'

export interface FormWizardHandlers {
  nextHandler: (data?: any) => void
  backHandler: () => void
}

export interface Step {
  id: number
  label?: string
  component: (props: FormWizardHandlers) => JSX.Element
  disableContinueLater?: boolean
}

interface FormWizardProps {
  //eslint-disable-next-line
  onSaveDraft: () => void
  steps: Step[]
  onFinish: () => void
  finishOnStep?: number
  canClickOnSteps?: boolean
  getCurrentStep?(props: number): void
}

export default function FormWizard({
  steps: customSteps,
  onSaveDraft,
  onFinish,
  finishOnStep,
  getCurrentStep,
  canClickOnSteps = false
}: FormWizardProps) {
  const handleShow = () => {
    setShowModal(true)
  }

  const handleHide = () => {
    setShowModal(false)
  }
  const [showModal, setShowModal] = useState(false)
  const onAccept = () => {
    onSaveDraft()
    handleHide()
  }

  const customRenderer = ({ currentStep, nextHandler, backHandler }: any) => {
    if (getCurrentStep) getCurrentStep(currentStep)

    const goToStep = (step: number) => {
      if (step === stepsData.length) {
        return
      }
      const diff = Math.abs(step - currentStep)

      for (let i = 0; i < diff; i++) {
        if (step > currentStep) {
          setTimeout(() => {
            nextHandler()
          }, 1)
        } else {
          setTimeout(() => {
            backHandler()
          }, 1)
        }
      }
    }

    const stepsData = customSteps.map((step, index) => {
      return (
        <li key={index}>
          <S.StepIndicator isActive={step.id === currentStep}>
            {step.id != customSteps.length && canClickOnSteps ? (
              <S.RawButton onClick={() => goToStep(step.id)}>
                {step.label}
              </S.RawButton>
            ) : (
              <span>{step.label}</span>
            )}
          </S.StepIndicator>
        </li>
      )
    })
    return (
      <ul
        className="nav nav-tabs step-anchor d-inline-flex justify-content-around"
        style={{ width: '100%' }}
      >
        {stepsData}
      </ul>
    )
  }

  const customComponents = ({
    currentStep,
    nextHandler,
    backHandler
  }: never) => {
    return customSteps.map((step, index) => {
      if (currentStep === index + 1) {
        return (
          <React.Fragment key={index}>
            <FormsModal
              title="Cadastro em Andamento"
              onHide={handleHide}
              onAccept={onAccept}
              show={showModal}
              body={
                <p>
                  Os dados registrados até agora serão salvos e você poderá
                  continuar o cadastro depois através do menu “meus cadastros”.
                </p>
              }
            />
            <S.Wrapper key={index}>
              <ProgressBar
                variant="success"
                style={{ borderRadius: 0 }}
                now={(currentStep / customSteps.length) * 100}
              />
              <step.component
                nextHandler={() => (nextHandler as any)(currentStep)}
                backHandler={backHandler}
              />
              {/*{!step.disableContinueLater && (*/}
              {/*  <div className="buttons">*/}
              {/*    <Button onClick={handleShow}>*/}
              {/*      Salvar e Continuar Depois*/}
              {/*    </Button>*/}
              {/*  </div>*/}
              {/*)}*/}
            </S.Wrapper>
          </React.Fragment>
        )
      }
      return false
    })
  }

  return (
    <Loki
      steps={customSteps}
      currentStep={1}
      renderComponents={customComponents}
      renderSteps={customRenderer}
      renderActions={() => <div></div>}
      onFinish={() => console.log('Loki onFinish')}
      onNext={(currentStep: number) => {
        if (finishOnStep === currentStep + 1) {
          onFinish()
        }
      }}
    />
  )
}
