import SignIn from '../pages/SignIn/signIn'
import SignUp from '../pages/SignUp/index'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import ConfirmEmail from '../pages/ConfirmEmail'
import { CreateSimplifiedSmartContract } from '../pages/SimplifiedSmartContracts/CreateSimplifiedSmartContract'
import { VerifySimplifiedSmartContract } from '../pages/SimplifiedSmartContracts/VerifySimplifiedSmartContract'
import { HistorySimplifiedSmartContract } from '../pages/SimplifiedSmartContracts/HistorySimplifiedSmartContract'
import { DetailSimplifiedSmartContract } from '../pages/SimplifiedSmartContracts/DetailSimplifiedSmartContract'
import SignatureFlow from '../pages/SignatureFlow'
import { PayBillingFactory } from '../main/factories/pages/Payments/PayBillingFactory'
import SignUpInvestor from '../pages/SignUpInvestor'
import Explorer from '../pages/Explorer/Explorer'
import ExplorerDetail from '../pages/Explorer/ExplorerDetail'
import ProcessContract from '../pages/ProcessContract/process-contract'

export interface RouteObject {
  path: string
  exact?: boolean
  name?: string
  component: any
}

const guestRoutes: RouteObject[] = [
  { path: '/', exact: true, name: 'landing', component: SignIn },

  {
    path: '/verify-smart-contract',
    exact: true,
    name: 'VerifySimplifiedSmartContract',
    component: VerifySimplifiedSmartContract
  },

  {
    path: '/history-smart-contract',
    exact: true,
    name: 'HistorySimplifiedSmartContract',
    component: HistorySimplifiedSmartContract
  },

  {
    path: '/smart-contract/:id',
    exact: false,
    name: 'DetailSimplifiedSmartContract',
    component: DetailSimplifiedSmartContract
  },

  {
    path: '/smart-contract',
    exact: true,
    name: 'CreateSimplifiedSmartContract',
    component: CreateSimplifiedSmartContract
  },

  { path: '/login', exact: true, name: 'login', component: SignIn },

  { path: '/abra-sua-conta', exact: true, name: 'register', component: SignUp },

  {
    path: '/abra-sua-conta-investidor',
    exact: true,
    name: 'register-investor',
    component: SignUpInvestor
  },

  {
    path: '/esqueci-minha-senha',
    exact: true,
    name: 'recover-password',
    component: ForgotPassword
  },

  {
    path: '/redefinir-senha/:hash',
    exact: true,
    name: 'reset-password',
    component: ResetPassword
  },

  {
    path: '/confirmar-email/:token',
    exact: true,
    name: 'confirm-email',
    component: ConfirmEmail
  },

  {
    path: '/assinar-contrato/:id',
    exact: true,
    name: 'assinar-contrato',
    component: SignatureFlow
  },

  {
    path: '/efetuar-pagamento/:id',
    exact: true,
    name: 'efetuar-pagamento',
    component: PayBillingFactory
  },

  {
    path: '/explorer',
    exact: true,
    name: 'explorer',
    component: Explorer
  },

  {
    path: '/explorer/:id',
    exact: true,
    name: 'explorer-detail',
    component: ExplorerDetail
  },

  {
    path: '/liberar-processamento/:id',
    exact: true,
    name: 'process-contract',
    component: ProcessContract
  }
]
export default guestRoutes
