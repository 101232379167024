import { useLocation } from 'react-router'
import { URL_API } from '../../../../services/api_url'
import CreateSpe from '../../../../pages/Spes/CreateSpe'
import { SpeType } from '../../../../domain/models/SpeModel'

import { RemoteCreateSPE } from '../../../../data/usecases/SPE/RemoteCreateSPE'
import { RemoteGetCompanies } from '../../../../data/usecases/Company/RemoteGetCompanies'
import { RemoteUpdateSpe } from '../../../../data/usecases/SPE/RemoteUpdateSpe'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'

type ParamsState = {
  speParams: SpeType
  companyId: string
}
export const CreateSpeFactory = () => {
  const URL = URL_API || 'without-url'
  const axiosHttpClient = useAxiosHttpClient()
  const remoteCreateSpe = new RemoteCreateSPE(URL, axiosHttpClient)
  const remoteGetCompanies = new RemoteGetCompanies(URL, axiosHttpClient)
  const remoteUpdateSpe = new RemoteUpdateSpe(URL, axiosHttpClient)

  const { state } = useLocation<ParamsState>()

  return (
    <CreateSpe
      hasSpe={state?.speParams}
      hasCompanyId={state?.companyId}
      handleCreate={remoteCreateSpe}
      getCompaniesList={remoteGetCompanies}
      handleUpdate={remoteUpdateSpe}
    />
  )
}
