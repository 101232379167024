import styled from 'styled-components'

export const Wrapper = styled.div`
  h5 {
    color: #e62355 !important;
  }

  h6 {
    color: #868e96 !important;
    font-weight: 600;
  }

  p {
    color: #868e96;
  }

  i {
    font-size: 18px !important;
    color: #e62355 !important;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
