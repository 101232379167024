import * as React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { Link } from 'react-router-dom'

import * as S from './styles'
import checkCircleIcon from '../../../../assets/images/check-circle.svg'
import { useContext } from 'react'
import { CreateContractsContext } from '../index'

export default function Step8({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data } = useContext(CreateContractsContext)

  return (
    <FormWizardStyles.TabContent>
      <S.Wrapper>
        <img src={checkCircleIcon} />
        <p>
          <strong>Contrato de Compra e Venda criado com sucesso!</strong>
        </p>
        <p>
          Um E-mail contendo a minuta e instruções para assinatura foi enviado
          para todas as partes.
        </p>
        {data?.created_id && (
          <Link to={`/contracts/${data.created_id}`}>
            <Button variant="primary">Acompanhar Situação </Button>
          </Link>
        )}

        <Link to="/dashboard">
          <a>Voltar ao Painel</a>
        </Link>
      </S.Wrapper>
    </FormWizardStyles.TabContent>
  )
}
