import { Card, Table } from 'react-bootstrap'
import { TableTitle } from '../../atoms/TableTitle'
import * as S from './styles'
import Information from '../../atoms/Information'

export const UnitContractsTableCard = () => {
  return (
    <S.Wrapper>
      <Card className="table-card">
        <Card.Header>
          <TableTitle>
            <strong>Contrato de Compra e Venda</strong>
          </TableTitle>
        </Card.Header>
        <Card.Body className="pb-0">
          <Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>DATA</th>
                <th>PROMISSÓRIO</th>
                <th>CPF/CNPJ</th>
                <th>VALOR DO CONTRATO (R$)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5}>
                  <Information>Sem registros</Information>
                </td>
              </tr>
              {/*<tr>*/}
              {/*  <td>20/04/2021</td>*/}
              {/*  <td>Luiza Gonçalves Pereira</td>*/}
              {/*  <td>087.198.876-21</td>*/}
              {/*  <td>R$ 000.000,00</td>*/}
              {/*  <td>*/}
              {/*    <S.IconsWrapper>*/}
              {/*      <IconButton icon="icon-trash" color="#E62355" disabled />*/}

              {/*      <IconButton icon="icon-edit" color="#5e6ea7" />*/}
              {/*    </S.IconsWrapper>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td>Bloco A</td>*/}
              {/*  <td>102</td>*/}
              {/*  <td>Planta A</td>*/}
              {/*  <td>01</td>*/}
              {/*  <td>*/}
              {/*    <S.IconsWrapper>*/}
              {/*      <IconButton icon="icon-trash" color="#E62355" />*/}

              {/*      <IconButton icon="icon-edit" color="#5e6ea7" />*/}
              {/*    </S.IconsWrapper>*/}
              {/*  </td>*/}
              {/*</tr>*/}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
