import { Card } from 'react-bootstrap'
import { TableTitle } from '../../../../components/atoms/TableTitle'

import * as S from './styles'
import { BillingDetail } from '../../../../domain/models/BillingModel'
import { convertNumberToCurrencyFormat } from '../../../../utils/convertNumberToCurrencyFormat'
import moment from 'moment'

interface BillingCompositionTableProps {
  values: BillingDetail
}

export const BillingCompositionTable = ({
  values
}: BillingCompositionTableProps) => {
  return (
    <S.Wrapper>
      <Card className="table-card">
        <Card.Header>
          <div className="d-flex">
            <TableTitle>Composição do Recebível (UR)</TableTitle>
            <S.CustomLink to="/payments">Ver tudo</S.CustomLink>
          </div>
        </Card.Header>
        <Card.Body className="pb-0">
          <S.CustomTable responsive hover className="mb-0">
            <thead>
              <tr>
                <th>ETAPA</th>
                <th>VALOR TOTAL</th>
                <th>EMISSÃO DO BOLETO</th>
                <th>DATA VENCIMENTO</th>
                <th>DATA PAGAMENTO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Parcela</td>
                <td>
                  {convertNumberToCurrencyFormat(
                    Number(values?.billing?.value)
                  )}
                </td>
                <td>
                  EMITIDO -{' '}
                  <a
                    target={'_blank'}
                    href={values?.bank_billet_transaction.info.url}
                    rel="noreferrer"
                  >
                    abrir
                  </a>
                </td>
                <td>{moment(values?.billing.due_date).format('DD/MM/YYYY')}</td>
                <td>
                  {values?.bank_billet_transaction.paid_at
                    ? moment(values?.bank_billet_transaction.paid_at).format(
                        'DD/MM/YYYY'
                      )
                    : 'NÃO PAGO'}
                </td>
              </tr>
              <tr>
                <td>Juros</td>
                <td>0% a.m (R$ 0,00)</td>
                <td>NÃO EMITIDO</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Multa</td>
                <td>0% a.m (R$ 0,00)</td>
                <td>NÃO EMITIDO</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Correção monetária</td>
                <td>0% a.m (R$ 0,00)</td>
                <td>NÃO EMITIDO</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </S.CustomTable>
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
