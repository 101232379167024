import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import { EntityCardProps } from '../../../components/organisms/EntityCard'
import { FilterItem } from '../../../components/organisms/EntityFilter'
import { EntityList } from '../../../components/organisms/EntityList'

import { IGetSpes, IDeleteSpe, ICreateSpe } from '../../../domain/usecases/SPE'
import { filtersFactory } from '../../../main/factories/filters.factory'
import { getEntitiesCount } from '../../../domain/usecases/Reports'
import { convertSpeToEntity } from '../../../domain/converters/SPE'
import { FileInput } from '../../../components/atoms/FileInput'
import { DeleteModal } from '../../../components/molecules/DeleteModal'
import { ConvertFileToBase64 } from '../../../utils/ConvertFileToBase64'
import { useDispatch } from 'react-redux'

type ListSpesProps = {
  remoteGetSpe: IGetSpes
  remoteDeleteSpe: IDeleteSpe
  remoteGetEntitiesCount: getEntitiesCount
  remoteCreateSpe: ICreateSpe
}

const ListSpes = ({
  remoteCreateSpe,
  remoteGetSpe,
  remoteDeleteSpe,
  remoteGetEntitiesCount
}: ListSpesProps) => {
  const { addToast } = useToasts()
  const history = useHistory()
  const dispatch = useDispatch()
  const [spes, setSpes] = React.useState<EntityCardProps[]>()
  const [filters, setFilters] = useState<FilterItem[]>([])

  const [selectedId, setSelectedId] = useState('')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<any>(null)

  const RedirectToSpeDetails = (id: string) => {
    history.push(`/spes/${id}`)
  }

  const deleteSpeFunction = (idSpe: string) => {
    handleShow()
    setSelectedId(idSpe)
  }

  const handleDelete = () => {
    remoteDeleteSpe
      .deleteById({ id: selectedId })
      .then((res) => {
        loadData()
        handleClose()
      })
      .catch((error) => {
        handleClose()
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true
        })
      })
  }

  const addSpePhoto = async (id: string) => {
    try {
      setSelectedId(id)
      inputFileRef.current?.click()
    } catch (err) {
      return err
    }
  }

  React.useEffect(() => {
    async function handleChange() {
      dispatch({ type: 'SET_LOADING', loading: true })
      const fileBase64 = await ConvertFileToBase64(file)
      if (fileBase64) {
        await remoteCreateSpe.uploadSpePhoto({
          id: selectedId,
          base64: fileBase64
        })
      }
      loadData().then(() => {
        dispatch({ type: 'SET_LOADING', loading: false })
        setFile(null)
      })
    }
    if (file) {
      handleChange()
    }
  }, [file])

  const loadData = React.useCallback(async () => {
    /*eslint-disable */
    try {
      const SpeResponse = await remoteGetSpe.getAll()
      setSpes(
        convertSpeToEntity(
          SpeResponse,
          deleteSpeFunction,
          RedirectToSpeDetails,
          addSpePhoto
        )
      )
    } catch (error) {
      addToast('Não foi possivel carregar as SPEs.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
    /*eslint-disable */
  }, [])

  React.useEffect(() => {
    loadData()
    remoteGetEntitiesCount
      .getEntitiesCount()
      .then((entitiesCount) =>
        setFilters(filtersFactory(entitiesCount, history))
      )
  }, [])

  return (
    <>
      <FileInput inputFileRef={inputFileRef} handleChange={setFile} />
      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleOk={handleDelete}
      />
      <EntityList
        title="SPEs"
        activeFilter={1}
        buttons={
          <Button
            onClick={() => history.push(`/spes/create`)}
            variant="primary"
          >
            Adicionar SPE
          </Button>
        }
        filters={filters}
        entityCards={spes}
      />
    </>
  )
}

export default ListSpes
