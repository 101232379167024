import creditCardType from 'credit-card-type'

export default (card_number: string) => {
  const [creditCard] = creditCardType(card_number)

  if (creditCard.type === 'diners-club') {
    return 'diners'
  } else if (creditCard.type === 'american-express') {
    return 'amex'
  } else {
    return creditCard.type
  }
}
