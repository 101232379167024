import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { RemoteGetDashboard } from '../../../../data/usecases/Dashboard/RemoteGetDashboard'
import Dashboard from '../../../../pages/Dashboard/dashboard'
import { RemoteGetCompanies } from '../../../../data/usecases/Company/RemoteGetCompanies'
import { RemoteGetSPE } from '../../../../data/usecases/SPE/RemoteGetSPE'
import { RemoteGetProject } from '../../../../data/usecases/Project/RemoteGetProject'
import { RemoteGetUnits } from '../../../../data/usecases/Unit/RemoteGetUnits'

export const DashboardFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetCompanies = new RemoteGetCompanies(URL, axiosHttpClient)
  const remoteGetDashboard = new RemoteGetDashboard(URL, axiosHttpClient)
  const remoteGetSpes = new RemoteGetSPE(URL, axiosHttpClient)
  const remoteGetProjects = new RemoteGetProject(URL, axiosHttpClient)
  const remoteGetUnits = new RemoteGetUnits(URL, axiosHttpClient)

  return (
    <Dashboard
      remoteGetDashboard={remoteGetDashboard}
      remoteGetCompanies={remoteGetCompanies}
      remoteGetSpes={remoteGetSpes}
      remoteGetProjects={remoteGetProjects}
      remoteGetUnits={remoteGetUnits}
    />
  )
}
