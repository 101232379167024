import { Button } from 'react-bootstrap'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../../app'

export const Menu = () => {
  const { clearUser } = useContext(AppContext)

  const logout = () => {
    clearUser?.()
    history.push('/login')
  }
  const history = useHistory()

  const active = location.pathname

  return (
    <div className="mt-3">
      <Button
        onClick={() => history.push('/smart-contract')}
        size="sm"
        className="mr-1 mb-1"
        variant={active == '/smart-contract' ? 'dark' : 'outline-dark'}
      >
        Registrar Contrato 📃
      </Button>
      <Button
        onClick={() => history.push('/verify-smart-contract')}
        size="sm"
        className="mr-1 mb-1"
        variant={active == '/verify-smart-contract' ? 'dark' : 'outline-dark'}
      >
        Consultar Contrato 🔎
      </Button>
      <Button
        onClick={() => history.push('/history-smart-contract')}
        size="sm"
        className="mr-1 mb-1"
        variant={active == '/history-smart-contract' ? 'dark' : 'outline-dark'}
      >
        Histórico de Registros 📁
      </Button>
      <Button
        onClick={logout}
        size="sm"
        className="mr-1 mb-1"
        variant="outline-danger"
      >
        Sair
      </Button>
    </div>
  )
}
