import { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ErrorText from '../ErrorText'
import * as S from './styles'

type Props = {
  onChange: (date: Date) => void
  label: string
  errorMessage?: string
  defaultValue?: Date
}

export const DateInput = ({
  onChange,
  label,
  errorMessage,
  defaultValue,
  ...other
}: Props) => {
  const [dateSelected, setDateSelected] = useState<Date>(
    defaultValue ? defaultValue : new Date()
  )
  registerLocale('ptBR', ptBR)

  useEffect(() => {
    onChange(dateSelected)
  }, [dateSelected])

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          <strong>{label}</strong>
        </Form.Label>
      )}
      <S.DateInput>
        <ReactDatePicker
          locale="ptBR"
          dateFormat="dd/MM/yyyy"
          selected={dateSelected}
          onChange={(date: Date) => {
            setDateSelected(date)
          }}
        />
      </S.DateInput>
      {errorMessage && <ErrorText text={errorMessage} />}
    </Form.Group>
  )
}
