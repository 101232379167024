import { Form } from 'react-bootstrap'
import * as React from 'react'
import { InputHTMLAttributes } from 'react'

import ErrorText from '../ErrorText'
import NumberFormat from 'react-number-format'

type TextFieldTypes = InputHTMLAttributes<HTMLInputElement>

export type NumberInputProps = {
  label?: string
  value?: string | number
  as?: HTMLElement
  errorMessage?: string
  returnNumber?: (value: number) => void
} & Omit<TextFieldTypes, 'size' | 'value'>

export const NumberInput = ({
  label,
  placeholder,
  errorMessage,
  returnNumber,
  ...other
}: NumberInputProps) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          <strong>{label}</strong>
        </Form.Label>
      )}
      <NumberFormat
        className="form-control"
        thousandSeparator="."
        decimalSeparator=","
        value={other.value}
        onChange={returnNumber ? undefined : other.onChange}
        onValueChange={({ floatValue }) => {
          if (returnNumber) returnNumber(floatValue as number)
        }}
        placeholder={placeholder && placeholder}
        disabled={other.disabled}
        allowNegative={false}
      />
      {errorMessage && <ErrorText text={errorMessage} />}
    </Form.Group>
  )
}
