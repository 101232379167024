import { HttpClient } from '../../protocols/http/httpClient'
import {
  checkoutBilling,
  getBillings,
  ICreditCardCheckout,
  IGetBillings
} from '../../../domain/usecases/Billing'
import {
  BillingDetail,
  CheckoutDetails
} from '../../../domain/models/BillingModel'
import { BillingTransaction } from '../../../domain/models/BillingTransactionModel'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'
import { Billing } from '../../../domain/usecases/Billing'

export class RemoteBillings implements IGetBillings, ICreditCardCheckout {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async getCheckoutDetails(
    params: getBillings.getBillingByIdParams
  ): Promise<CheckoutDetails> {
    return this.httpClient.get?.({
      url: `${this.url}/billings/${params.id}/checkout-details`
    })
  }

  @validateHttpStatus
  async creditCardCheckout(
    params: checkoutBilling.creditCardCheckoutParams
  ): Promise<BillingTransaction> {
    return this.httpClient.post?.({
      url: `${this.url}/billings/${params.id}/credit-card-checkout`,
      data: params.data
    })
  }

  @validateHttpStatus
  async getOne({ id }: Billing.GetOne): Promise<BillingDetail> {
    const httpResponse = await this.httpClient.get?.({
      url: `${this.url}/billings/${id}`
    })

    return httpResponse
  }
}
