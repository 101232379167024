import styled from 'styled-components'

export const WrapperProxyBox = styled.td`
  display: flex;
  justify-content: center;

  & > div {
    display: flex;
    justify-content: center;

    width: 100%;
    max-width: 6.5rem;
  }
`

export const ProxyBox = styled.div`
  min-width: 2.5rem;
  min-height: 2.5rem;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(1) {
    background: #5e6ea7;
    z-index: 3;
  }
  &:nth-child(2) {
    background: #95c567;
    z-index: 2;

    margin-left: -0.5rem;
  }
  &:nth-child(3) {
    background: #ecf0f5;
    margin-left: -0.5rem;
    strong {
      color: #504f4a;
    }
  }

  strong {
    color: #ffff;

    font-size: 20px;
  }
`
