/* eslint-disable prettier/prettier */
import moment from 'moment'
import { EntityCardProps } from '../../components/organisms/EntityCard'
import { ValuePaymentsTable } from '../../pages/Contracts/ContractDetails/PaymentsTable'
import { ValuesPersonTable } from '../../pages/Contracts/ContractDetails/PersonsTable'
import { convertCPForCNPJStringToNumbers } from '../../utils/convertCPFStringToNumbers'
import { convertNumberToCurrencyFormat } from '../../utils/convertNumberToCurrencyFormat'
import { formatCPFWithDots } from '../../utils/formatCPFWithDots'
import { convertCPFStringToDots } from '../../utils/convertCPFStringToDots'
import formatStringToCNPJ from '../../utils/formatStringToCNPJ'
import { Contract, ContractWithPaymentPart } from '../models/ContractsModel'
import { IndividualPersonModel } from '../models/IndividualPersonModel'
import { JuridicPersonModel } from '../models/JuridicPersonModel'

const BadgeStatus = {
  signatures_pending: 'Aguardando assinatura',
  password_pending: 'Aguardando liberação',
  processing: 'Processando',
  registered: 'Sucesso',
  error: 'Erro'
}
const BadgeType = {
  password_pending: 'info',
  signatures_pending: 'info',
  processing: 'processing_info',
  registered: 'success',
  error: 'danger'
}

export const convertBillingStatus = {
  pending: 'Pendente',
  paid: 'Pago',
  late: 'Atrasado'
}

const convertValueForCurrency = (value: number | string) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(Number(value))

const convertDate = (value: Date | string) =>
  new Date(value).toLocaleDateString('pt-BR', {
    dateStyle: 'short'
  })

export const convertContractsToEntity = (
  contracts: Contract[] = [],
  openFn: (id: string) => void
): EntityCardProps[] => {
  return contracts.map((contract) => {
    const status = contract.status as keyof typeof BadgeType

    return {
      id: contract.id,
      title: `Bloco: ${contract.unit.building.name} - Unidade: ${contract.unit.name}`,
      subtitle: convertDate(contract.created_at),
      line1: contract.buyers
        .map((buyer) =>
          buyer.info_type === 'IndividualPerson'
            ? (buyer.info as IndividualPersonModel).name
            : (buyer.info as JuridicPersonModel).business_name
        )
        .slice(0, 2)
        .join(', '),
      line2: convertValueForCurrency(contract.value),
      badge: {
        status: status === 'processing' ? 'Processando' : BadgeStatus[status],
        type: status === 'processing' ? 'processing_info' : BadgeType[status]
      },
      leftBtnDisabled: true,
      icons: [{ icon: 'icon-home', text: contract.project.name }],
      rightBtnAction: () => openFn(contract.id)
    }
  })
}

export const serializedValuesForPaymentsTable = (
  values: ContractWithPaymentPart | undefined
) => {
  return values
    ? values?.payments.map((payment) => ({
        firstDueDate: moment(payment.first_due_date).format('DD/MM/YYYY'),
        frequency: payment.frequency,
        id: payment.id,
        installments: payment.installments,
        lastDueDate: moment(payment.first_due_date).format('DD/MM/YYYY'),
        status:
          convertBillingStatus[
            payment.billings.status as keyof typeof convertBillingStatus
          ],
        step: payment.name,
        totalValue: String(convertNumberToCurrencyFormat(payment.total_value))
      }))
    : []
}

export const convertStatusFromPersonTable = {
  pending: 'Pendente',
  signed: 'Assinado'
}

export const serializedValuesForPersonsTable = (
  values: ContractWithPaymentPart | undefined
) => {
  return values
    ? values.contract.signatures.map((signature) => {
        const { id, cpf, email, status, name, buyer, signed_at } = signature

        if (buyer && buyer.info_type === 'IndividualPerson') {
          const IndividualPerson = buyer.info as IndividualPersonModel

          return {
            id: IndividualPerson.id,
            name: IndividualPerson.name,
            cpfOrCnpj: formatCPFWithDots(IndividualPerson.cpf),
            mail: signature.email,
            status: convertStatusFromPersonTable[signature.status],
            signed_at: signed_at
              ? moment(signed_at).format('DD/MM/YYYY HH:mm:ss')
              : ''
          }
        } else if (buyer && buyer.info_type === 'JuridicPerson') {
          const JuridicPerson = buyer.info as JuridicPersonModel

          return {
            id: JuridicPerson.id,
            cpfOrCnpj: formatStringToCNPJ(JuridicPerson.cnpj),
            name: JuridicPerson.business_name,
            mail: signature.email,
            status: convertStatusFromPersonTable[signature.status],
            signed_at: signed_at
              ? moment(signed_at).format('DD/MM/YYYY HH:mm:ss')
              : ''
          }
        } else {
          return {
            id,
            cpfOrCnpj: formatCPFWithDots(cpf),
            name,
            mail: email,
            status: convertStatusFromPersonTable[status],
            signed_at: signed_at
              ? moment(signed_at).format('DD/MM/YYYY HH:mm:ss')
              : ''
          }
        }
      })
    : []
}
