import { Badge, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const CustomLink = styled(Link)`
  width: 64px;
`

export const CustomTable = styled(Table)`
  thead {
    th {
      font-weight: normal;
      text-align: center;
      vertical-align: middle;
    }
  }
  tbody {
    td {
      text-align: center;
      vertical-align: middle;
    }
  }

  .badge {
    border-radius: 2rem;
    min-width: 5rem;
    padding: 0.4rem;
  }

  .badge-success {
    background: #95c56730;
    color: #95c567;
  }

  .badge-info {
    background: #8b9ed133;
    color: #5e6ea7;
  }

  .badge-danger {
    color: #e62355;

    background: rgb(90%, 14%, 33%, 0.2);
  }

  .badge-warning {
    color: #ddac4b;

    background: #eed20230;
  }
`

export const CustomBadge = styled(Badge)`
  width: 100%;
`
