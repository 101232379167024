import * as S from './styles'
import { Card, Col, ListGroup, Row } from 'react-bootstrap'
import { Title } from '../../atoms/Title'

import React from 'react'

interface EntityDetailsProps {
  title?: string
  content?: React.ReactNode
  sections?: React.ReactNode
}

export const EntityDetails = ({
  title,
  content,
  sections
}: EntityDetailsProps) => {
  return (
    <S.Wrapper>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Title>{title}</Title>
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">{sections}</ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {content}
    </S.Wrapper>
  )
}
