import { Tag } from '.'

export type handleAddTagWithEnterProps = {
  value: string
  interceptorBeforeAddTagFunc: ((value: string) => string | void) | undefined
  limit: number
  tags: Tag[]
  id?: string
}

// Alter string if label is grater than 20 chars and added '...' at the end
export const serializedTagsWithSubstring = (arr: Tag[]) => {
  return arr.map((tag) => {
    if (tag.name.length > 20) {
      return {
        ...tag,
        name: `${tag.name.substring(0, 20)}...`
      }
    }
    return tag
  })
}

export const validateBeforeSaveNewTag = ({
  value = '',
  interceptorBeforeAddTagFunc,
  limit = 0,
  tags = [],
  id = ''
}: handleAddTagWithEnterProps) => {
  const interceptorBeforeAddTagValue =
    interceptorBeforeAddTagFunc && interceptorBeforeAddTagFunc(value)

  const name =
    typeof interceptorBeforeAddTagValue == 'string'
      ? interceptorBeforeAddTagValue
      : value

  if ((limit && limit <= tags.length) || !name.length) return

  const newTag = {
    id: id || `${Date.now()}`,
    name,
    disable: false
  }

  return newTag
}
