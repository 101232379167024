import { Button } from 'react-bootstrap'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { Link, useHistory } from 'react-router-dom'

import * as S from './styles'
import checkCircleIcon from '../../../../assets/images/check-circle.svg'

export default function Step4() {
  const history = useHistory()
  return (
    <FormWizardStyles.TabContent>
      <S.Wrapper>
        <img src={checkCircleIcon} />
        <p>
          <strong>
            Dados da Incorporadora e Usuários cadastrados com sucesso!
          </strong>
        </p>
        <p>
          Você já pode cadastrar uma SPE associada à esta Incorporadora. Deseja
          fazer isso agora?
        </p>
        <Link to="/spes/create">
          <Button
            variant="primary"
            onClick={() => history.push('/spes/create')}
          >
            Cadastrar SPE
          </Button>
        </Link>

        <Link to="/companies">
          <a>Ver Incorporadora</a>
        </Link>
      </S.Wrapper>
    </FormWizardStyles.TabContent>
  )
}
