import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 2rem;
  .card {
    height: 100%;
    padding-bottom: 1rem;
  }
  .card .card-header {
    border-bottom: 0;
  }
  .card .card-body {
    padding-bottom: 0;
  }
  .progress {
    margin: 0 1.2rem;
  }
  .button-create {
    padding: 12px 25px 12px 25px;
    margin: 0 1rem 1rem 1rem;
    @media (min-width: 500px) {
      width: 16rem;
    }
  }
`

export const StepPending = styled.span``

export const StepOk = styled.span`
  text-decoration: line-through;
`
