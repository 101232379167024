import { Centered } from '../../../components/templates/Centered'
import logo from '../../../assets/images/logo.svg'
import { Menu } from '../Menu'
import { Card } from 'react-bootstrap'
import * as React from 'react'

interface HeaderCardProps {
  title: string
  showMenu?: boolean
  description?: string
}

export const HeaderCard = ({
  title,
  showMenu = true,
  description
}: HeaderCardProps) => {
  return (
    <Card.Header>
      <Centered>
        <img src={logo} alt="" className="logo" />
        <div className="mb-4">{showMenu && <Menu />}</div>
        <h4 style={{ margin: 0, padding: 0, width: '100%' }}>{title}</h4>
        {description && <span>{description}</span>}
      </Centered>
    </Card.Header>
  )
}
