import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { Card } from 'react-bootstrap'
import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import PaymentStep0 from './PaymentStep0'
import PaymentStepCreditCardForm from './PaymentStep1'
import PaymentStepReview from './PaymentStep2'
import PaymentAnalysis from './PaymentAnalysis'
import PaymentError from './PaymentError'
import PaymentAlreadyPayed from './PaymentAlreadyPayed'

import { useParams } from 'react-router-dom'
import { RemoteBillings } from '../../data/usecases/Billing/RemoteBillings'
import { CheckoutDetails } from '../../domain/models/BillingModel'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import PaymentStepBillingForm from './PaymentStepBillingForm'
import { PaymentsHeader } from '../../components/molecules/PaymentsHeader'
import { useGerencianetTokenGenerator } from '../../hooks/useGerencianetTokenGenerator'
import { LoadingIndicator } from '../../components/atoms/LoadingIndicator'

export interface IParam {
  id: string
}

interface PaymentFlowProps {
  remoteBillings: RemoteBillings
}

export type paymentSteps =
  | 'initial'
  | 'credit_card_form'
  | 'billing_form'
  | 'review'
  | 'analysis'
  | 'error'
  | 'already-payed'

export interface PaymentData {
  credit_card?: {
    payment_token?: string
    number: string
    name: string
    expiry: string
    cvc: string
    birth: string
  }
  billing_address?: {
    street: string
    number: string
    neighborhood: string
    zipcode: string
    city: string
    state: string
  }
}

export interface PaymentContext {
  paymentData?: PaymentData
  setPaymentData?: React.Dispatch<React.SetStateAction<PaymentData>>
}

export const PaymentContext = React.createContext<PaymentContext>({})

const PaymentFlow = ({ remoteBillings }: PaymentFlowProps) => {
  const [step, setStep] = useState<paymentSteps>('initial')
  const [checkoutDetails, setCheckoutDetails] = useState<CheckoutDetails>()
  const [paymentData, setPaymentData] = useState<PaymentData>({})
  const history = useHistory()
  const { addToast } = useToasts()
  const { ready } = useGerencianetTokenGenerator()

  const { id } = useParams<IParam>()

  const payed_at = new Date()

  function handleStepChange(nextStep: paymentSteps) {
    setStep(nextStep)
  }

  useEffect(() => {
    remoteBillings
      .getCheckoutDetails({ id })
      .then((data) => {
        setCheckoutDetails(data)
        if (data.billing.status === 'paid') {
          handleStepChange('already-payed')
        }
      })
      .catch(() => {
        console.log('error')
        // history.push('/')
        addToast('Link expirado ou inválido.', { appearance: 'error' })
      })
  }, [])

  const onFinish = () => {
    if (
      !paymentData.credit_card ||
      !paymentData.credit_card.payment_token ||
      !paymentData.billing_address
    ) {
      throw new Error('Credit card or billing info not found')
    }

    remoteBillings
      .creditCardCheckout({
        id,
        data: {
          payment_token: paymentData.credit_card.payment_token,
          name: paymentData.credit_card.name,
          birth: paymentData.credit_card.birth,
          billing_address: {
            number: paymentData.billing_address.number,
            state: paymentData.billing_address.state,
            city: paymentData.billing_address.city,
            zipcode: paymentData.billing_address.zipcode.replace(/\D/g, ''),
            neighborhood: paymentData.billing_address.neighborhood,
            street: paymentData.billing_address.street
          }
        }
      })
      .then(() => {
        handleStepChange('analysis')
      })
      .catch((e) => {
        console.log('error', e)
        handleStepChange('error')
      })
  }

  return checkoutDetails ? (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Card style={{ padding: 0 }}>
          <S.Wrapper>
            <PaymentContext.Provider value={{ setPaymentData, paymentData }}>
              <PaymentsHeader step={step} checkoutDetails={checkoutDetails} />

              {ready ? (
                <>
                  {step === 'initial' && (
                    <PaymentStep0
                      checkoutDetails={checkoutDetails}
                      handleStepChange={handleStepChange}
                    />
                  )}

                  {step === 'credit_card_form' && (
                    <PaymentStepCreditCardForm
                      handleStepChange={handleStepChange}
                    />
                  )}

                  {step === 'billing_form' && (
                    <PaymentStepBillingForm
                      handleStepChange={handleStepChange}
                    />
                  )}

                  {step === 'review' && (
                    <PaymentStepReview
                      onFinish={onFinish}
                      checkoutDetails={checkoutDetails}
                    />
                  )}

                  {step === 'analysis' && <PaymentAnalysis />}

                  {step === 'error' && <PaymentError />}

                  {step === 'already-payed' && (
                    <PaymentAlreadyPayed payedAt={payed_at} />
                  )}
                </>
              ) : (
                <LoadingIndicator />
              )}
            </PaymentContext.Provider>
          </S.Wrapper>
        </Card>
        <DevelopedByGT />
      </div>
    </div>
  ) : (
    <div></div>
  )
}
export default PaymentFlow
