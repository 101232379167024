import * as React from 'react'
import { useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateUnitContext } from '../index'
import { DispatchContext } from '../actions'
import { useCreationConfirmModal } from '../../../../hooks/useCreationConfirmModal'
import { Unit } from 'domain/usecases/Unit'
import { MultiSelectField } from '../../../../components/atoms/MultiSelectField'
import { ParkingSlots, ParkingSlotsByUnits } from './hooks/parkingSlots'
import { parkingSlotsType } from '../../../../domain/models/ProjectModel'
import Information from '../../../../components/atoms/Information'
import SwitchButton from '../../../../components/Switch'
import { TextInput } from '../../../../components/atoms/TextInput'

export type ICreateUnitFormStep4 = Pick<
  Unit.UnitParamsToCreateMany,
  'parkingSlotByUnit' | 'usage_rights' | 'usage_rights_quantity'
>

export default function Step4({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateUnitContext)
  const { state } = useContext(DispatchContext)
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<ICreateUnitFormStep4>({
    defaultValues: {
      parkingSlotByUnit: data?.parkingSlotByUnit
    }
  })

  const { handlers, parkingSlotByUnit, preventDuplicateSuggestions } =
    ParkingSlots({
      unitNames: data?.unitsName as string[],
      parkingSlotsFromProjects: state.project.parking_slots
    })

  const { setShowConfirmationModal, Modal } = useCreationConfirmModal({
    entityText: 'da(s) Unidade(s)',
    onFinish: nextHandler
  })

  const onClickNext = (formData: ICreateUnitFormStep4) => {
    setData!({ ...data, ...formData, parkingSlotByUnit })
    setShowConfirmationModal(true)
  }

  React.useEffect(() => {
    const isUpdatePage = !!state.unit.id
    if (isUpdatePage) {
      const parkingSlotsByUnit: ParkingSlotsByUnits = {}
      parkingSlotsByUnit[state.unit.name] = state?.unit.parking_slots.map(
        (slot: parkingSlotsType) => slot.id
      )
      handlers.updateParkingSlotsByUnit(parkingSlotsByUnit)
    }
  }, [])

  const createParkingSlotsSelectOptions = () => {
    return state.unit?.parking_slots?.map((slot: parkingSlotsType) => ({
      label: slot.name,
      value: slot.id
    }))
  }

  const usage_rights = watch('usage_rights')

  return (
    <>
      <div className="m-3">
        <FormWizardStyles.TabContent>
          <Row className="m-2 mb-4">
            <Information>
              Associe as vagas às Unidades. Caso a Unidade não tenha vagas,
              deixe o(s) campo(s) em branco.
            </Information>
          </Row>
          <Controller
            control={control}
            name="usage_rights"
            render={({ field: { onChange, value } }) => (
              <SwitchButton
                label="Direito ao uso"
                value={data?.usage_rights}
                onChange={(event) => onChange(event)}
              />
            )}
          />
          {usage_rights ? (
            <>
              <Controller
                control={control}
                name={`usage_rights_quantity`}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    type="number"
                    min="1"
                    label="Quantidade de vagas com direito ao uso"
                    onChange={(event) => onChange(event)}
                    value={data?.usage_rights_quantity}
                  />
                )}
              />
            </>
          ) : (
            <>
              {data?.unitsName!.map((unit: string, index: number) => {
                return (
                  <Row key={index}>
                    <Col data-testid={`parking-${unit}`} md={6}>
                      <MultiSelectField
                        label={`Vagas da Unidade ${unit}`}
                        options={preventDuplicateSuggestions}
                        onChange={(options) => {
                          handlers.addParkingSlotsByUnit({
                            [unit]: options as string[]
                          })
                        }}
                        defaultValue={createParkingSlotsSelectOptions()}
                        emptyOptionText="Selecione as Vagas da Unidade"
                      />
                    </Col>
                  </Row>
                )
              })}
            </>
          )}

          <Row>
            <Col>
              <FormWizardStyles.ButtonsWrapperSpaced>
                <Button variant="link" onClick={backHandler}>
                  Voltar
                </Button>
                <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                  Próximo
                </Button>
              </FormWizardStyles.ButtonsWrapperSpaced>
            </Col>
          </Row>
        </FormWizardStyles.TabContent>
      </div>
      {Modal}
    </>
  )
}
