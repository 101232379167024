import { SpeType } from '../../domain/models/SpeModel'
import { EntityCardProps } from '../../components/organisms/EntityCard'
import formatStringToCNPJ from '../../utils/formatStringToCNPJ'

export const convertSpeToEntity = (
  spes: SpeType[] = [],
  deleteFn: (paramId: string) => void,
  openFn: (id: string) => void,
  addPhoto: (id: string) => void
) => {
  return spes.map((spe: SpeType) => {
    const formatted: EntityCardProps = {
      title: spe.business_name,
      line1: formatStringToCNPJ(spe.cnpj),
      image: spe.photo!,
      logoOrPhoto: spe.photo ? 'photo' : 'logo',
      leftBtnAction: () => deleteFn(spe.id),
      rightBtnAction: () => openFn(spe.id),
      onAddPhoto: () => addPhoto(spe.id),
      icons: [
        {
          icon: 'x-square',
          text: `${spe.projects.length} Empreendimentos`
        }
      ]
    }
    return formatted
  })
}
