import { Badge } from 'react-bootstrap'
import { SpaceEvenly } from '../../templates/SpaceEvenly'
import { IconButton } from '../../atoms/IconButton'

import { Variant } from 'react-bootstrap/types'

interface UsersTableRowProps {
  name: string
  email: string
  statusType: Variant
  statusText: string
  role: string
  onClickDelete?: () => void
  onClickEdit?: () => void
}

export const UsersTableRow = ({
  name,
  email,
  statusType,
  statusText,
  role,
  onClickDelete,
  onClickEdit
}: UsersTableRowProps) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{email}</td>
      <td>
        <Badge variant={statusType} pill={true}>
          {statusText}
        </Badge>
      </td>
      <td>{role}</td>
      {/*<td>*/}
      {/*  <SpaceEvenly>*/}
      {/*    <IconButton*/}
      {/*      icon="icon-trash"*/}
      {/*      color="#E62355"*/}
      {/*      onClick={onClickDelete}*/}
      {/*    />*/}
      {/*    <IconButton icon="icon-edit" color="#5E6EA7" onClick={onClickEdit} />*/}
      {/*  </SpaceEvenly>*/}
      {/*</td>*/}
    </tr>
  )
}
