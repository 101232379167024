import { useContext, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import {
  SelectField,
  SelectFieldOption
} from '../../../../components/atoms/SelectField'
import { CreateContractsContext } from '../index'
import { Button, Col, Row } from 'react-bootstrap'
import TagInput from '../../../../components/atoms/TagInput'
import { useToast } from '../../../../hooks/useToast'
import { useContextPage } from '../contextPage'
import step2_schema from './step2.schema'

import { IGetSpes } from '../../../../domain/usecases/SPE'
import { Contract } from '../../../../domain/usecases/Contracts'

export type ICreateContractsFormStep2 = Pick<Contract.CreateParams, 'spe_id'>

const Step2 = ({ nextHandler, backHandler }: FormWizardHandlers) => {
  const { showToast } = useToast()
  const { data, setData } = useContext(CreateContractsContext)
  const { getSpes } = useContextPage()
  const [spes, setSpes] = useState<SelectFieldOption[]>([])

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ICreateContractsFormStep2>({
    resolver: yupResolver(step2_schema),
    defaultValues: {
      ...data
    }
  })

  useEffect(() => {
    getSpeslist()
  }, [])

  const onClickNext = (formData: ICreateContractsFormStep2) => {
    setData?.({ ...data, ...formData })
    nextHandler()
  }

  const getSpeslist = async () => {
    try {
      const spes = await getSpes.getAll()
      setSpes(
        spes.map((item) => ({ label: item.business_name, value: item.id }))
      )
    } catch (error) {
      showToast('Não foi possível carregar as SPEs.', 'error')
    }
  }

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="spe_id"
            render={({ field: { onChange, value } }) => {
              return (
                <SelectField
                  data-testid="select-spes"
                  emptyOptionText={'Selecione'}
                  errorMessage={errors.spe_id?.message}
                  defaultValue={data?.spe_id || ''}
                  value={value}
                  onChange={onChange}
                  label="Selecione a SPE do Promitente"
                  options={spes}
                />
              )
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperSpaced>
            <Button variant="link" onClick={backHandler}>
              Voltar
            </Button>
            <Button variant="primary" onClick={handleSubmit(onClickNext)}>
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperSpaced>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
export default Step2
