import { Col, Row } from 'react-bootstrap'
import { Controller, Control, FieldErrors } from 'react-hook-form'
import { TextInputWithMask } from '../../../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../../../components/atoms/TextInput'
import * as React from 'react'
import { ICreatePromissoryBuyerFormStep1, SelectedPerson } from './step1'
import { useContextPage } from '../../contextPage'

interface JuridicalStep1 {
  control: Control<Partial<ICreatePromissoryBuyerFormStep1>>
  juridical?: Partial<SelectedPerson>
  errors: FieldErrors<Partial<ICreatePromissoryBuyerFormStep1>>
}

export default function JuridicalStep1({ control, errors }: JuridicalStep1) {
  const { state } = useContextPage()

  return (
    <Row>
      <Col md={6}>
        <Controller
          control={control}
          name="person.cnpj"
          render={({ field: { onChange, value } }) => (
            <TextInputWithMask
              mask="99.999.999/9999-99"
              label="CNPJ"
              readOnly
              placeholder="CNPJ da Incorporadora"
              errorMessage={errors.person?.cnpj?.message}
              defaultValue={state?.selectedPerson?.info?.cnpj}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Col>
      <Col md={6}>
        <Controller
          control={control}
          name="person.business_name"
          render={({ field: { onChange, value } }) => (
            <TextInput
              type="text"
              label="Razão Social"
              readOnly
              placeholder=""
              errorMessage={errors.person?.business_name?.message}
              defaultValue={state?.selectedPerson?.info?.business_name}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Col>
    </Row>
  )
}
