import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;

  @media screen and (min-width: 760px) {
    .buttons {
      position: absolute;
      bottom: 1.2rem;
      right: 20%;
      display: flex;
      justify-content: center;
    }
  }
`
export const StepIndicator = styled.div`
  ${({ isActive }: { isActive: boolean }) => css`
    color: ${isActive ? 'black' : '#5e6ea7'};
    margin: 0 0 10px 0;
    padding: 0 30px 5px 30px;
    border-bottom: 2px solid ${isActive ? '#5e6ea7' : '#E2E5E8'};
  `}
`

export const TabContent = styled.div`
  padding: 20px 0;
`

export const ButtonsWrapperSpaced = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`

export const ButtonsWrapperRight = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`

export const ButtonsWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`

export const RawButton = styled.div`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`
