import { RemoteCreateProject } from '../../../../data/usecases/Project/RemoteCreateProject'
import { RemoteGetProject } from '../../../../data/usecases/Project/RemoteGetProject'
import { RemoteDeleteProject } from '../../../../data/usecases/Project/RemoteDeleteProject'
import { RemoteGetEntitiesCount } from '../../../../data/usecases/Reports/RemoteGetEntitiesCount'

import { URL_API } from '../../../../services/api_url'
import ListProjects from '../../../../pages/Projects/ListProjects/listProjects'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'

export const ListProjectsFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetProjects = new RemoteGetProject(URL, axiosHttpClient)
  const remoteDeleteProject = new RemoteDeleteProject(URL, axiosHttpClient)
  const remoteGetEntitiesCount = new RemoteGetEntitiesCount(
    URL,
    axiosHttpClient
  )
  const remoteCreateProject = new RemoteCreateProject(URL, axiosHttpClient)

  return (
    <ListProjects
      remoteGetProjects={remoteGetProjects}
      remoteGetEntitiesCount={remoteGetEntitiesCount}
      remoteDeleteProject={remoteDeleteProject}
      remoteCreateProject={remoteCreateProject}
    />
  )
}
