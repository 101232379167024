import * as yup from 'yup'

export default yup.object().shape({
  address: yup.object().shape({
    zip_code: yup.string().required('O campo CEP é requerido.'),
    address: yup.string().required('O campo Logradouro é requerido.'),
    number: yup.string().required('O campo Número é requerido.'),
    addressComplement: yup.string().nullable(),
    district: yup.string().required('O campo Bairro é requerido.'),
    city: yup.string().required('O campo Cidade é requerido.'),
    state: yup.string().required('O campo Estado é requerido.'),
    country: yup.string().required('O campo País é requerido.')
  }),
  secondary_address: yup.object().shape({
    allowToRegisterBusinessAddress: yup.boolean(),
    zip_code_business: yup.string().when('allowToRegisterBusinessAddress', {
      is: true,
      then: yup.string().required('O campo CEP é requerido.')
    }),
    address_business: yup.string().when('allowToRegisterBusinessAddress', {
      is: true,
      then: yup.string().required('O campo Logradouro é requerido.')
    }),
    number_business: yup.string().when('allowToRegisterBusinessAddress', {
      is: true,
      then: yup.string().required('O campo Número é requerido.')
    }),
    complement_business: yup
      .string()
      .when('allowToRegisterBusinessAddress', {
        is: true,
        then: yup.string().nullable()
      })
      .nullable(),
    district_business: yup.string().when('allowToRegisterBusinessAddress', {
      is: true,
      then: yup.string().required('O campo Bairro é requerido.')
    }),
    city_business: yup.string().when('allowToRegisterBusinessAddress', {
      is: true,
      then: yup.string().required('O campo Cidade é requerido.')
    }),
    state_business: yup.string().when('allowToRegisterBusinessAddress', {
      is: true,
      then: yup.string().required('O campo Estado é requerido.')
    }),
    country_business: yup.string().when('allowToRegisterBusinessAddress', {
      is: true,
      then: yup.string().required('O campo País é requerido.')
    })
  })
})
