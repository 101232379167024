import { HttpClient } from '../../protocols/http/httpClient'
import {
  CompanyUser,
  ICreateCompanyUser
} from '../../../domain/usecases/CompanyUser'
import { CompanyUser as CompanyUserModel } from '../../../domain/models/CompanyUserModel'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export class RemoteCreateCompanyUser implements ICreateCompanyUser {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async create(params: CompanyUser.CreateParams): Promise<CompanyUserModel> {
    return await this.httpClient.post?.({
      url: `${this.url}/company-users/`,
      data: params
    })
  }
}
