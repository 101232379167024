import api from '../../services/api'

export interface CnpjDto {
  nomeEmpresarial: string
}

export default class CnpjService {
  static async get(cnpj: string): Promise<CnpjDto> {
    try {
      const res = await api.get<CnpjDto>(`/persons/cnpj/${cnpj}`)
      return res.data
    } catch {
      throw new Error('Error')
    }
  }
}
