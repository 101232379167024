import { URL_API } from '../../../../services/api_url'
import ListSpe from '../../../../pages/Spes/ListSpe'

import { RemoteGetSPE } from '../../../../data/usecases/SPE/RemoteGetSPE'
import { RemoteDeleteSpe } from '../../../../data/usecases/SPE/RemoteDeleteSPE'
import { RemoteGetEntitiesCount } from '../../../../data/usecases/Reports/RemoteGetEntitiesCount'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { RemoteCreateSPE } from '../../../../data/usecases/SPE/RemoteCreateSPE'

export const ListSpesFactory = () => {
  const URL = URL_API || 'without-url'
  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetSpe = new RemoteGetSPE(URL, axiosHttpClient)
  const remoteDeleteSpe = new RemoteDeleteSpe(URL, axiosHttpClient)
  const remoteCreateSpe = new RemoteCreateSPE(URL, axiosHttpClient)
  const remoteGetEntitiesCount = new RemoteGetEntitiesCount(
    URL,
    axiosHttpClient
  )

  return (
    <ListSpe
      remoteGetEntitiesCount={remoteGetEntitiesCount}
      remoteGetSpe={remoteGetSpe}
      remoteDeleteSpe={remoteDeleteSpe}
      remoteCreateSpe={remoteCreateSpe}
    />
  )
}
