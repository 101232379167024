import * as yup from 'yup'

export default yup.object().shape({
  interest: yup.string().required('O campo Taxa de Juros é requerido.'),
  fine: yup.string().required('O campo Multa é requerido.'),
  interest_type: yup.string().required('O campo Periodicidade é requerido.'),
  bank_account_type: yup
    .string()
    .required('O campo Tipo de Conta é requerido.'),
  bank: yup.string().required('O campo Banco é requerido.'),
  account: yup.string().required('O campo Conta e Dígito é requerido.'),
  branch: yup.string().required('O campo Agência e Dígito é requerido.'),
  account_digit: yup.string().required('O campo Dígito é requerido.'),
  branch_digit: yup.string().required('O campo Dígito é requerido.')
})
