import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

export interface ICreditCard {
  number: string
  name: string
  expiry: string
  cvc: string
  focus: 'name' | 'number' | 'expiry' | 'cvc' | undefined
}

const CreditCard = ({ number, name, expiry, cvc, focus }: ICreditCard) => {
  return (
    <Cards
      number={number}
      name={name}
      expiry={expiry}
      cvc={cvc}
      focused={focus}
      placeholders={{ name: 'SEU NOME AQUI' }}
      locale={{ valid: 'VALIDADE' }}
    />
  )
}

export default CreditCard
