export type ACTIONTYPE = { type: 'SET_LOADING'; loading: boolean }

const loadingReducer = (state = false, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'SET_LOADING':
      return action.loading
    default:
      return state
  }
}

export default loadingReducer
