import { Card, Table } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { ProjectSpe } from '../../../../domain/models/SpeModel'

import * as S from './styles'
import Information from '../../../../components/atoms/Information'

type Props = {
  projects: ProjectSpe[]
}

export const ProjectsTable = ({ projects }: Props) => {
  const history = useHistory()
  const [unitsAmount] = projects.map((project) =>
    project.buildings
      .map((item) => item.units)
      .reduce((prev: number, curr: number) => prev + curr, 0)
  )
  return (
    <S.Wrapper>
      <Card className="table-card">
        <Card.Header>
          <S.Title>Empreendimentos ({projects.length})</S.Title>
        </Card.Header>
        <Card.Body className="pb-0">
          <Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>NOME</th>
                <th>REGISTRO</th>
                <th>BLOCOS</th>
                <th>UNIDADES</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {projects.length ? (
                projects.map((project) => (
                  <tr
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`/projects/${project.id}`)}
                    key={project.id}
                  >
                    <td>{project.name}</td>
                    <td>{project.ri}</td>
                    <td>
                      {project.buildings.length}
                      <S.Plus className="far fa-plus-square"></S.Plus>
                    </td>
                    <td>
                      {unitsAmount}{' '}
                      <S.Plus className="far fa-plus-square"></S.Plus>
                    </td>
                    <td>
                      <S.IconsWrapper>
                        <S.Delete className="mx-2 text-muted feather icon-trash"></S.Delete>
                        <S.Edit className="mx-2 feather icon-edit"></S.Edit>
                      </S.IconsWrapper>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <Information>Sem registros</Information>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
