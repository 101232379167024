import { BorderedContent } from '../BorderedContent'
import React from 'react'

import * as S from './styles'

interface DetailButtonsRowProps {
  buttons: React.ReactNode
}

export const DetailButtonsRow = ({ buttons }: DetailButtonsRowProps) => {
  return (
    <BorderedContent>
      <S.ButtonsWrapper>{buttons}</S.ButtonsWrapper>
    </BorderedContent>
  )
}
