import { HttpClient } from '../../protocols/http/httpClient'
import {
  Company,
  getCompany,
  getCompanyDetails
} from '../../../domain/usecases/Company'
import { CompanyType } from '../../../domain/models/CompanyModel'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export class RemoteGetCompanies implements getCompany, getCompanyDetails {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpClient
  ) {}

  @validateHttpStatus
  async getAll(): Promise<CompanyType[]> {
    const httpResponse = await this.httpGetClient.get?.({
      url: `${this.url}/companies/my`
    })

    if (httpResponse.error) throw new Error('Error on get companies')
    return httpResponse
  }

  @validateHttpStatus
  async getById(params: Company.GetParams): Promise<any> {
    const httpResponse = await this.httpGetClient.get?.({
      url: `${this.url}/companies/${params.id}`
    })

    if (httpResponse.error) throw new Error('Error on get company')
    return httpResponse
  }
}
