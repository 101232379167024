import styled, { css } from 'styled-components'

export const Container = styled('div')<{ disabled: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      .card-footer {
        background-color: #868e96;
      }
      .card-body {
        }
        h3 {
          color: #868e96;
        }
        .info-card {
          h4 {
            color: #868e96;
          }
          i {
            color: #868e96;
          }
        }
      }
      h2 {
        color: #868e96;
      }
    }
    `}
`
