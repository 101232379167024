import * as React from 'react'
import CpfService, { CpfDto } from '../domain/usecases/Cpf'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'

export const useGetCpf = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const getCpf = React.useCallback(
    async (cpf: string): Promise<CpfDto | undefined> => {
      try {
        if (isInvalid(cpf)) return
        dispatch({ type: 'SET_LOADING', loading: true })
        const result = await CpfService.get(extractDigits(cpf))
        dispatch({ type: 'SET_LOADING', loading: false })
        return result
      } catch {
        dispatch({ type: 'SET_LOADING', loading: false })
        addToast('CPF não encontrado.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    },
    []
  )

  const isInvalid = (cpf: string) => {
    const cpfDigits = extractDigits(cpf)
    return cpfDigits.length !== 11
  }

  const extractDigits = (cpf: string) => {
    return cpf.replace(/\D/g, '')
  }

  return { getCpf }
}
