import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import * as S from '../styles'
import { LoadingIndicator } from '../../../components/atoms/LoadingIndicator'
import VerificationCodeInput from '../../../components/organisms/VerificationCodeInput'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './signature-step2.schema'
import { IContractSignature } from '../'
import { ISignatureStep2Form } from './signature-step2-form.interface'
import {
  checkCodes,
  generateCodes
} from '../../../services/contract-signatures'
import { useToasts } from 'react-toast-notifications'
import { Checkbox } from '../../../components/atoms/Checkbox'

export type SignatureStep2Props = {
  signatureData: IContractSignature
  handleStepChange: (step: string) => void
}

const SignatureStep2 = ({
  signatureData,
  handleStepChange
}: SignatureStep2Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(60)
  const [countdownEnd, setCountdownEnd] = useState<boolean>(false)
  const { addToast } = useToasts()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ISignatureStep2Form>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<ISignatureStep2Form> = async (data) => {
    try {
      setLoading(true)
      const { success } = await checkCodes(
        signatureData.id,
        Number(data.sms_code),
        Number(data.email_code)
      )
      if (success) {
        setLoading(false)
        handleStepChange('ok')
      } else {
        setLoading(false)
        addToast('Código inválido, verifique e tente novamente.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    } catch (e) {
      setLoading(false)
      handleStepChange('error')
    }
  }

  const handleResendCodes = async () => {
    await generateCodes(signatureData.id)
    addToast('Os novos códigos foram enviados.', {
      appearance: 'success',
      autoDismiss: true
    })
  }

  useEffect(() => {
    let timeleft = countdown
    const timer = setInterval(() => {
      timeleft--
      setCountdown(timeleft)
      if (timeleft <= 0) {
        clearInterval(timer)
        setCountdownEnd(true)
      }
    }, 1000)
  }, [])

  return (
    <S.ContentWrapper>
      <S.p>
        Para realizar a assinatura, por gentileza, preencha <b>AMBOS</b> os
        códigos recebidos por SMS e E-MAIL nos campos indicados abaixo.
      </S.p>

      <S.CodeWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="sms_code"
            render={({ field: { onChange } }) => (
              <S.CodeItem>
                <S.CodeLabel>
                  Código recebido por SMS - {signatureData.mobile_phone}
                </S.CodeLabel>
                <VerificationCodeInput
                  autoFocus
                  handleChange={(value) => onChange(value)}
                />
                {errors.sms_code && (
                  <S.ErrorLabel>{errors.sms_code?.message}</S.ErrorLabel>
                )}
              </S.CodeItem>
            )}
          />

          <Controller
            control={control}
            name="email_code"
            render={({ field: { onChange } }) => (
              <S.CodeItem>
                <S.CodeLabel>Código recebido por E-MAIL</S.CodeLabel>
                <VerificationCodeInput
                  handleChange={(value) => onChange(value)}
                />
                {errors.email_code && (
                  <S.ErrorLabel>{errors.email_code?.message}</S.ErrorLabel>
                )}
              </S.CodeItem>
            )}
          />
          <div className="mb-3">
            <a
              target="_blank"
              rel="noreferrer"
              href={signatureData.contract.url}
            >
              Clique aqui para ler o Contrato antes de assinar.
            </a>
          </div>

          <Col md={12}>
            <Controller
              control={control}
              name="agree"
              render={({ field: { onChange } }) => (
                <Checkbox
                  onChange={(value) => onChange(value)}
                  label={
                    <p>
                      Declaro que estou ciente de todas as obrigações, <br />{' '}
                      deveres e teor estipulado nesse Contrato.
                    </p>
                  }
                  errorMessage={errors.agree?.message}
                />
              )}
            />
          </Col>

          <button
            className="btn btn-primary pull-left mb-4 mt-4"
            style={{ width: '100%' }}
            type="submit"
            disabled={loading}
          >
            {!loading ? 'Assinar Contrato' : <LoadingIndicator />}
          </button>
          <S.Tip marginBottom={10}>
            <a href="#" onClick={() => handleStepChange('1')}>
              Voltar
            </a>
          </S.Tip>
        </form>
      </S.CodeWrapper>

      <S.Tip>
        Os códigos expiram 10 minutos após o envio.{' '}
        {!countdownEnd &&
          `Você poderá solicitar novamente em ${countdown} segundos.`}
        {countdownEnd && (
          <a href="#" onClick={handleResendCodes}>
            Reenviar códigos.
          </a>
        )}
        <br />
        Caso não tenha recebido, verifique a pasta de Spam ou Lixo Eletrônico no
        seu E-mail.
      </S.Tip>
    </S.ContentWrapper>
  )
}
export default SignatureStep2
