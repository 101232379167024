import { HttpClient } from '../../protocols/http/httpClient'
import { deleteProject, Project } from '../../../domain/usecases/Project'
import {
  MinimalProject,
  ProjectType
} from '../../../domain/models/ProjectModel'

export class RemoteDeleteProject implements deleteProject {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async deleteById(params: Project.DeleteParams): Promise<void> {
    const httpResponse = await this.httpClient.delete?.({
      url: `${this.url}/projects/${params.id}`
    })

    if (httpResponse?.statusCode === 400) {
      throw new Error(httpResponse.message)
    } else if (httpResponse?.statusCode && httpResponse?.statusCode != 200) {
      throw new Error('Não foi possível excluir o Empreendimento.')
    }
  }
}
