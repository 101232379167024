import * as yup from 'yup'

const user_schema = yup.object().shape({
  invite_name: yup.string().required('O campo Nome é requerido.'),
  invite_email: yup.string().required('O campo E-mail é requerido.'),
  role: yup.string().required('O campo Perfil é requerido.')
})

export default yup.object().shape({
  users: yup.array().of(user_schema)
})
