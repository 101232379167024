import { Card } from 'react-bootstrap'
import * as S from './styles'
import { Pending } from '../../../domain/models/DashboardModel'
import PendencyRow from './PendencyRow'
import Information from '../../atoms/Information'

interface PendenciesProps {
  pendencies: Pending
}

export const PendenciesCard = ({ pendencies }: PendenciesProps) => {
  const hasNoPendency = () =>
    Object.values(pendencies).every((p) => p.status === 'ok')

  const joinWithComma = (array: string[]) => array.join(', ')

  return (
    <S.Wrapper>
      <Card className="latest-update-card">
        <Card.Header>
          <h5>Pendências</h5>
        </Card.Header>
        <Card.Body style={{ display: 'flex' }}>
          <S.ContentWrapper>
            {hasNoPendency() ? (
              <Information style={{ margin: '10px 0 0 0' }}>
                Suas pendências serão mostradas aqui.
              </Information>
            ) : (
              <>
                <div className="latest-update-box">
                  {pendencies.company.status === 'not' && (
                    <PendencyRow
                      redirectTo="/companies/create"
                      title="Cadastrar Incorporadora"
                      message="É preciso uma incorporadora para o cadastro de ativos"
                    />
                  )}
                  {pendencies.spe.status === 'not' && (
                    <PendencyRow
                      redirectTo="/spes/create"
                      title="Cadastrar SPE"
                      message={`É preciso ao menos uma SPE cadastrada para a(s) Incorporadora(s)
                    ${joinWithComma(pendencies.spe.data)}.`}
                    />
                  )}
                  {pendencies.project.status === 'not' && (
                    <PendencyRow
                      redirectTo="/projects/create"
                      title="Cadastrar Empreendimento"
                      message={`É preciso ao menos um empreendimento cadastrado para a(s) SPE(s)
                    ${joinWithComma(pendencies.project.data)}.`}
                    />
                  )}
                  {pendencies.units.status === 'not' && (
                    <PendencyRow
                      redirectTo="/units/create"
                      title="Completar Unidades"
                      state={{ complete_multiple: true }}
                      message={`O(s) Empreendimento(s) ${joinWithComma(
                        pendencies.units.data
                      )} tem unidade(s) com o
                    cadastro incompleto.`}
                    />
                  )}
                  {pendencies.contracts.status === 'not' && (
                    <PendencyRow
                      redirectTo="/contracts/create"
                      title="Registrar Contrato de Compra e Venda"
                      message="Você ainda não registrou contratos de compra e venda."
                    />
                  )}
                </div>
                {Object.values(pendencies).length > 10 && (
                  <div className="text-center">
                    <a href="#" className="b-b-primary text-primary">
                      Ver todas as pendências
                    </a>
                  </div>
                )}
              </>
            )}
          </S.ContentWrapper>
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
