import api from './api'

interface SignInData {
  cpf: string
  password: string
}

export async function signIn(data: SignInData) {
  const response = await api.post('/auth/login', data)
  return response.data
}

interface ForgotPasswordData {
  cpf: string
}

export async function forgotPassword(data: ForgotPasswordData) {
  const response = await api.post('/auth/recover-password', data)
  return response.data
}

interface ResetPasswordData {
  hash: string
  password: string
  password_confirmation: string
}

export async function resetPassword(data: ResetPasswordData) {
  const response = await api.post('/auth/reset-password', data)
  return response.data
}

export async function confirmEmail(token: string) {
  const response = await api.post('/auth/confirm-email', {
    token
  })
  return response.data
}
