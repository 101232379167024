import * as React from 'react'
import CepService, { CepDto } from '../domain/usecases/Cep'
import { useToasts } from 'react-toast-notifications'

export const useGetCep = () => {
  const { addToast } = useToasts()

  const getCep = React.useCallback(
    async (cep: string): Promise<CepDto | undefined> => {
      try {
        if (isInvalidCep(cep)) return
        return await CepService.get(cep)
      } catch {
        addToast('CEP não encontrado.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    },
    []
  )

  const isInvalidCep = (cep: string) => {
    const cepDigits = extractCepDigits(cep)
    return cepDigits.length !== 8
  }

  const extractCepDigits = (cep: string) => {
    return cep.replace(/\D/g, '')
  }

  return { getCep }
}
