import { Card } from 'react-bootstrap'
import { TableTitle } from '../../../../components/atoms/TableTitle'

import * as S from './styles'

export type ValuePaymentsTable = {
  id: string
  step: string
  installments: string
  frequency: string
  firstDueDate: string
  lastDueDate: string
  totalValue: string
  status: string
}

export type PaymentsTableProps = {
  value: ValuePaymentsTable[]
}

export const convertBillingStatusVariant = {
  Pendente: 'info',
  Pago: 'success',
  Atrasado: 'warning'
}

const convertFrequency = {
  one: 'Única',
  monthly: 'Mensal',
  semester: 'Semestral',
  year: 'Anual'
}

export const PaymentsTable = ({ value }: PaymentsTableProps) => {
  return (
    <S.Wrapper>
      <Card className="table-card">
        <Card.Header>
          <div className="d-flex">
            <TableTitle>Recebíveis</TableTitle>
            <S.CustomLink to="/payments">Ver tudo</S.CustomLink>
          </div>
        </Card.Header>
        <Card.Body className="pb-0">
          <S.CustomTable responsive hover className="mb-0">
            <thead>
              <tr>
                <th>ETAPA</th>
                <th>Nº DE PARCELAS</th>
                <th>PERIODICIDADE</th>
                <th>VENCIMENTO 1ª PARCELA</th>
                <th>VENCIMENTO ÚLTIMA PARCELA</th>
                <th>VALOR TOTAL</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {value.map((item) => (
                <tr key={item.id}>
                  <td>{item.step}</td>
                  <td>{item.installments}</td>
                  <td>
                    {
                      convertFrequency[
                        item.frequency as keyof typeof convertFrequency
                      ]
                    }
                  </td>
                  <td>{item.firstDueDate}</td>
                  <td>{item.lastDueDate}</td>
                  <td>{item.totalValue}</td>
                  <td>
                    <S.CustomBadge
                      variant={
                        convertBillingStatusVariant[
                          item.status as keyof typeof convertBillingStatusVariant
                        ]
                      }
                    >
                      {item.status}
                    </S.CustomBadge>
                  </td>
                </tr>
              ))}
            </tbody>
          </S.CustomTable>
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
