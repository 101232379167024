import * as S from './styles'
import { Badge, Collapse } from 'react-bootstrap'
import { useState } from 'react'

export interface FilterSubItem {
  title: string
  onClick?: () => void
}

export interface FilterItem {
  title: string
  badge: boolean
  badgeValue: number
  onClick?: () => void
  subItems?: FilterSubItem[]
}

interface EntityFilterProps {
  data: FilterItem[]
  activeIndex?: number
}

export const EntityFilter = ({
  data,
  activeIndex: actIndex
}: EntityFilterProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(actIndex || 0)

  const onClickItem = (item: FilterItem) => {
    if (!item.subItems) {
      item.onClick && item.onClick()
    } else {
      const index = data.findIndex((i) => i.title === item.title)
      setActiveIndex(index)
    }
  }

  const isItemActive = (item: FilterItem) => {
    return data.findIndex((i) => i.title === item.title) === activeIndex
  }

  return (
    <S.Wrapper>
      {data.map((item) => (
        <S.Item key={item.title} onClick={() => onClickItem(item)}>
          <S.ItemHeader active={isItemActive(item)}>
            <p>{item.title}</p>
            <S.ItemButtonsWrapper>
              {item?.subItems?.length && !isItemActive(item) && (
                <i className="feather icon-chevron-down" />
              )}
              {item.badge && (
                <Badge pill variant="primary">
                  {item.badgeValue}
                </Badge>
              )}
            </S.ItemButtonsWrapper>
          </S.ItemHeader>
          {item.subItems?.length && (
            <Collapse in={isItemActive(item)}>
              <S.ItemContent>
                {item.subItems.map((subitem) => (
                  <S.SubItem key={subitem.title} onClick={subitem.onClick}>
                    <span>{subitem.title}</span>
                    <i className="feather icon-chevron-right" />
                  </S.SubItem>
                ))}
              </S.ItemContent>
            </Collapse>
          )}
        </S.Item>
      ))}
    </S.Wrapper>
  )
}
