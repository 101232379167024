import * as React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { TextInputWithMask } from '../../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../../components/atoms/TextInput'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToasts } from 'react-toast-notifications'
import step1_schema from './step1.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { getCompany } from '../../../../domain/usecases/Company'
import { useContext } from 'react'
import { CreateSpeContext } from '../index'
import { SelectField } from '../../../../components/atoms/SelectField'
import { useGetCep } from '../../../../hooks/useGetCep'
import { useGetCnpj } from '../../../../hooks/useGetCnpj'
import { CompanyType } from 'domain/models/CompanyModel'

export interface ICreateSpeFormStep1 {
  cnpj: string
  business_name: string
  zip_code: string
  address: string
  number: string
  complement: string
  district: string
  city: string
  state: string
  country: string
  companyId: string
}

type Props = {
  props: FormWizardHandlers
  getCompaniesList: getCompany
}

type CompaniesSelected = {
  label: string
  value: string
}

export default function Step1({
  getCompaniesList,
  props: { nextHandler }
}: Props) {
  const { data, setData } = useContext(CreateSpeContext)
  const { addToast } = useToasts()
  const { getCep } = useGetCep()
  const [fullCompaniesList, setFullcompaniesList] = React.useState<
    CompanyType[]
  >([])
  //TODO: create a route to request only ids from companies
  const [companies, setCompanies] = React.useState<CompaniesSelected[]>([])
  const { getCnpj } = useGetCnpj()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm<ICreateSpeFormStep1>({
    resolver: yupResolver(step1_schema),
    defaultValues: {
      address: data?.address,
      business_name: data?.business_name,
      city: data?.city,
      cnpj: data?.cnpj,
      companyId: data?.companyId,
      complement: data?.complement,
      country: data?.country,
      district: data?.district,
      number: data?.number,
      state: data?.state,
      zip_code: data?.zip_code
    }
  })
  const cnpj = watch('cnpj')
  const onClickNext = (formData: ICreateSpeFormStep1) => {
    setData?.({ ...data, ...formData })
    nextHandler()
  }

  const getCompanies = React.useCallback(async () => {
    try {
      const companiesList = await getCompaniesList.getAll()
      const companieListSelectFieldFormat = companiesList.map((company) => ({
        label: company.business_name,
        value: company.id
      }))
      setFullcompaniesList(companiesList)
      setCompanies(companieListSelectFieldFormat)
    } catch (error) {
      addToast('Não foi possível carregar as Incorporadoras.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }, [])

  React.useEffect(() => {
    getCompanies()
  }, [])

  const checkCep = React.useCallback(async (cep: string) => {
    const cepDto = await getCep(cep)
    if (!cepDto) return
    setValue('address', cepDto.logradouro)
    setValue('state', cepDto.uf)
    setValue('city', cepDto.localidade)
    setValue('district', cepDto.bairro)
    setValue('country', 'Brasil')
  }, [])

  const checkCnpj = React.useCallback(
    async (cnpj: string) => {
      const alreadyExistsCompanyWithSameCnpj = fullCompaniesList.find(
        (company) => company.cnpj === cnpj
      )
      if (alreadyExistsCompanyWithSameCnpj) {
        addToast('Este CNPJ já está cadastrado no sistema.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
      try {
        const cnpjDto = await getCnpj(cnpj)
        if (!cnpjDto) return
        setValue('business_name', cnpjDto.nomeEmpresarial)
      } catch {
        reset({ cnpj: '', business_name: '' })
      }
    },
    [cnpj]
  )

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col>
          <Controller
            control={control}
            name="companyId"
            render={({ field: { onChange, value } }) => (
              <SelectField
                data-testid="select-company"
                emptyOptionText={'Selecione '}
                onChange={onChange}
                label="Selecione a Incorporadora"
                options={companies}
                errorMessage={errors.companyId?.message}
                defaultValue={data?.companyId || ''}
                value={value}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="cnpj"
            render={({ field: { onChange, value } }) => (
              <TextInputWithMask
                disabled={!!data?.cnpj}
                data-testid="input-cnpj"
                mask="99.999.999/9999-99"
                label="CNPJ"
                placeholder=""
                errorMessage={errors.cnpj?.message}
                value={value}
                defaultValue={data?.cnpj}
                onChange={({ target: { value } }) => {
                  onChange(value)
                  checkCnpj(value)
                }}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="business_name"
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="text"
                label="Razão Social"
                placeholder=""
                errorMessage={errors.business_name?.message}
                value={value}
                defaultValue={data?.business_name}
                onChange={onChange}
                readOnly
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="zip_code"
            render={({ field: { onChange, value } }) => (
              <TextInputWithMask
                data-testid="input-zipcode"
                mask="99999-999"
                label="CEP"
                errorMessage={errors.zip_code?.message}
                onChange={({ target: { value } }) => {
                  onChange(value)
                  checkCep(value)
                }}
                value={value}
                defaultValue={data?.zip_code}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="address"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Logradouro"
                errorMessage={errors.address?.message}
                onChange={onChange}
                value={value}
                defaultValue={data?.address}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="number"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="input-number"
                label="Número"
                errorMessage={errors.number?.message}
                onChange={onChange}
                value={value}
                defaultValue={data?.number}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="complement"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Complemento"
                errorMessage={errors.complement?.message}
                value={value}
                onChange={onChange}
                defaultValue={data?.complement}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="district"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Bairro"
                value={value}
                errorMessage={errors.district?.message}
                onChange={onChange}
                defaultValue={data?.district}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Cidade"
                value={value}
                errorMessage={errors.city?.message}
                defaultValue={data?.city}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="state"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Estado"
                errorMessage={errors.state?.message}
                onChange={onChange}
                value={value}
                defaultValue={data?.state}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="País"
                errorMessage={errors.country?.message}
                onChange={onChange}
                defaultValue={data?.country}
                value={value}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperRight>
            <Button
              variant="primary"
              data-testid="button-submit"
              onClick={handleSubmit(onClickNext)}
            >
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperRight>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
