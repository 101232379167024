import { useState, useMemo, useEffect } from 'react'
import { MultiSelectFieldOption } from '../../../../../components/atoms/MultiSelectField'
import { Unit } from '../../../../../domain/usecases/Unit'
import { parkingSlotsType } from '../../../../../domain/models/ProjectModel'

export type parkingSlotsKey = keyof Pick<
  Unit.UnitParamsToCreateMany,
  'parkingSlotByUnit'
>
export type ParkingSlotsByUnits = Exclude<
  Unit.UnitParamsToCreateMany[parkingSlotsKey],
  undefined
>

type Props = {
  unitNames: string[]
  parkingSlotsFromProjects: parkingSlotsType[]
}

/*eslint-disable */
export const ParkingSlots = ({unitNames, parkingSlotsFromProjects }: Props) => {
  const [parkingSuggestion, setParkingSuggestion] = useState<MultiSelectFieldOption[]>([])
  const [parkingSlotByUnit, setParkingSlotByUnit] = useState<ParkingSlotsByUnits>({} as ParkingSlotsByUnits)
  const [preventDuplicateSuggestions,setPreventDuplicateSuggestions] = useState<MultiSelectFieldOption[]>([])

  useEffect(() => {
    createParkingSlotByUnitObject(unitNames)
    createParkingSuggestion(parkingSlotsFromProjects)

   return () => {
      setParkingSlotByUnit({})
      setParkingSuggestion([])
      setPreventDuplicateSuggestions([])
    }
  },[])

  useEffect(() => avoidDuplicateSuggestions() ,[parkingSlotByUnit])


  const createParkingSlotByUnitObject = (unitsNames: string[]) => {
    const parkingSlots: Unit.UnitParamsToCreateMany[parkingSlotsKey] = {}
    unitsNames!.forEach((unit: string) => (parkingSlots[unit] = []))
    setParkingSlotByUnit(parkingSlots)
  }

  const createParkingSuggestion = (parkingSlotsFromProjects: parkingSlotsType[]) => {
    const createLabelWithSlotGroup = (item: parkingSlotsType) => `${item.group?.name} - ${item.name}`
    const belongsToSlotGroup = (item: parkingSlotsType) => !!item.group
    const convertToSelectOption = (item: parkingSlotsType) => ({
      value: item.id,
      label: belongsToSlotGroup(item) ? createLabelWithSlotGroup(item) : item.name
    })
    const parkingSlotsSelectOptions: MultiSelectFieldOption[] = parkingSlotsAvailable(parkingSlotsFromProjects).map(convertToSelectOption)
    const orderSelectOptions = (selectOptions: MultiSelectFieldOption[]) => selectOptions.sort((current, next) => {
      if (current.label > next.label) return 1
      if (current.label < next.label) return -1
      return 0
    })
    const orderedSelectOptions = orderSelectOptions(parkingSlotsSelectOptions)
    setParkingSuggestion(orderedSelectOptions)
    setPreventDuplicateSuggestions(orderedSelectOptions)
  }

  const parkingSlotsAvailable = (parkingSlotsFromProjects: parkingSlotsType[]) =>
    parkingSlotsFromProjects.filter(slot => slot.unit_id === null)

  const avoidDuplicateSuggestions = () => {
    const optionsSelected = Object.values(parkingSlotByUnit).flat()   
    const result = parkingSuggestion.filter((suggestion) => !optionsSelected.includes(suggestion.value))
    setPreventDuplicateSuggestions(result)
  }

  const handlers = useMemo(() => ({
    addParkingSlotsByUnit: (parkingSlotsByUnits: ParkingSlotsByUnits  ) => {
      setParkingSlotByUnit({...parkingSlotByUnit, ...parkingSlotsByUnits} )
    },
    updateParkingSlotsByUnit: (parkingSlotsByUnits: ParkingSlotsByUnits) => {
      setParkingSlotByUnit(parkingSlotsByUnits)
    }
  }),[parkingSlotByUnit])

  return {
    handlers,
    preventDuplicateSuggestions,
    parkingSlotByUnit
  }

}
