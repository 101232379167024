import * as React from 'react'

export default function WelcomeToast() {
  return (
    <>
      <div>Obrigado por se cadastrar! 😉</div>
      <div style={{ marginBottom: '5px' }}>
        Te enviamos um E-mail para ativar sua Conta.
      </div>
      <div>
        Para usar nossos serviços, por favor, entre em contato através deste{' '}
        <a
          href="https://proptoken.com.br/#gform_1"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          link
        </a>
        .
      </div>
    </>
  )
}
