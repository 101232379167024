import { getBlockchainTransaction } from '../../../domain/usecases/Blockchain_Transaction'
import { Blockchain_Transaction } from '../../../domain/models/Blockchain_Transaction'
import { HttpClient } from '../../protocols/http/httpClient'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export default class RemoteGetBlockchainTransactions
  implements getBlockchainTransaction
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpClient
  ) {}

  @validateHttpStatus
  async getAll(): Promise<Blockchain_Transaction[]> {
    const httpResponse = await this.httpGetClient.get?.({
      url: `${this.url}/blockchain-transactions`
    })
    if (httpResponse.error)
      throw new Error('Error on get blockchain transactions.')
    return httpResponse
  }
}
