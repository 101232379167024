import * as React from 'react'
import { useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm, Controller, set } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import step3_schema from './step3.schema'
import * as FormWizardStyles from '../../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../../components/organisms/FormWizard'
import { CreatePromissoryBuyerContext } from '../index'
import { TextInputWithMask } from '../../../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../../../components/atoms/TextInput'
import SwitchButton from '../../../../../components/molecules/SwitchButton'
import { ContactPerson } from '../../../../../domain/models/PersonsModel'

export interface ICreatePromissoryBuyerFormStep3 {
  contact_info: Partial<ContactPerson>
  secondary_contact_info: Partial<ContactPerson>
}

export default function Step3({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreatePromissoryBuyerContext)
  const hasSecondaryContactInfo = !!data?.secondary_contact_info?.email_second
  const [secondaryContact, setSecondaryContact] = React.useState(
    hasSecondaryContactInfo
  )
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ICreatePromissoryBuyerFormStep3>({
    resolver: yupResolver(step3_schema),
    defaultValues: { ...data }
  })

  const onClickNext = (formData: ICreatePromissoryBuyerFormStep3) => {
    setData!({ ...data, ...formData })
    nextHandler()
  }

  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={4}>
            <Controller
              control={control}
              name="contact_info.email"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="email"
                  type="text"
                  label="E-mail"
                  errorMessage={errors.contact_info?.email?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="contact_info.home_phone"
              render={({ field: { onChange, value } }) => (
                <TextInputWithMask
                  data-testid="home_phone"
                  mask="(99)9999-9999"
                  label="(DDD) Telefone Residencial"
                  errorMessage={errors.contact_info?.home_phone?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="contact_info.mobile_phone"
              render={({ field: { onChange, value } }) => (
                <TextInputWithMask
                  data-testid="mobile_phone"
                  mask="(99)99999-9999"
                  label="(DDD) Celular"
                  errorMessage={errors.contact_info?.mobile_phone?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Controller
              control={control}
              name="secondary_contact_info.allowToRegisterSecondaryContact"
              render={({ field: { onChange } }) => (
                <SwitchButton
                  defaultSwitch={secondaryContact}
                  label="Adicionar outros dados de contato"
                  onChange={() => {
                    setSecondaryContact(!secondaryContact)
                    return onChange(!secondaryContact)
                  }}
                />
              )}
            />
          </Col>
        </Row>
        {secondaryContact && (
          <Row>
            <Col md={4}>
              <Controller
                control={control}
                name="secondary_contact_info.email_second"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    type="text"
                    label="E-mail Secundário"
                    errorMessage={
                      errors.secondary_contact_info?.email_second?.message
                    }
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Col>
            <Col md={4}>
              <Controller
                control={control}
                name="secondary_contact_info.homePhone_second"
                render={({ field: { onChange, value } }) => (
                  <TextInputWithMask
                    mask="(99)9999-9999"
                    label=" (DDD) Telefone Comercial"
                    errorMessage={
                      errors.secondary_contact_info?.homePhone_second?.message
                    }
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Col>
            <Col md={4}>
              <Controller
                control={control}
                name="secondary_contact_info.fone_second"
                render={({ field: { onChange, value } }) => (
                  <TextInputWithMask
                    mask="(99)99999-9999"
                    label="(DDD) Celular Secundário"
                    errorMessage={
                      errors.secondary_contact_info?.fone_second?.message
                    }
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button
                variant="primary"
                data-testid="button_next"
                onClick={handleSubmit(onClickNext)}
              >
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
