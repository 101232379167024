import { HttpClient } from '../../protocols/http/httpClient'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'
import { IGetPersons, Persons } from '../../../domain/usecases/Persons'
import { Person } from '../../../domain/models/PersonsModel'
import { convertCPForCNPJStringToNumbers } from '../../../utils/convertCPFStringToNumbers'

export class RemoteGetPersons implements IGetPersons {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}
  @validateHttpStatus
  async getAll(params: Persons.GetParams): Promise<Person[]> {
    const persons: Person[] = []

    for (const cpfOrCpnj of params.cpfOrCnpj) {
      if (this.isCnpj(cpfOrCpnj)) {
        persons.push(await this.getJuridical({ cnpj: cpfOrCpnj }))
      } else {
        persons.push(await this.getIndividual({ cpf: cpfOrCpnj }))
      }
    }

    return persons
  }

  @validateHttpStatus
  async getJuridical(params: Persons.GetJuridical): Promise<any> {
    const cnpj = convertCPForCNPJStringToNumbers(params.cnpj)

    return this.httpClient.get?.({
      url: `${this.url}/persons/get-juridic/${cnpj}`
    })
  }

  @validateHttpStatus
  async getIndividual(params: Persons.GetIndividual): Promise<any> {
    const cpf = convertCPForCNPJStringToNumbers(params.cpf)

    return this.httpClient.get?.({
      url: `${this.url}/persons/get-individual/${cpf}`
    })
  }

  private isCnpj(value: string) {
    return value.replace(/\D/g, '').length === 14
  }
}
