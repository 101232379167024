import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0;

  .btn {
    padding: 10px 1rem;
    width: 100%;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FirstRow = styled.div`
  padding-bottom: 20px;
`
