import styled from 'styled-components'

export const Wrapper = styled.div``

export const Title = styled.h5`
  color: #5e6ea7 !important;
`
export const Plus = styled.i`
  margin-left: 1rem;
  font-size: 1rem;
`
export const Delete = styled.i`
  color: #e62355;
  font-size: 1.3rem;
`

export const Edit = styled.i`
  color: #5e6ea7;
  font-size: 1.3rem;
`
export const IconsWrapper = styled.span`
  display: grid;
  grid-template-columns: repeat(2, 50%);
`
