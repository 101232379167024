import * as React from 'react'

import * as S from './styles'

import image from '../../../assets/images/logo-gt.png'

export const DevelopedByGT = () => {
  return (
    <S.Root>
      <span>Powered by</span>
      <S.Img src={image} />
    </S.Root>
  )
}
