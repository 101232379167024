/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import FormWizard, { Step } from '../../../components/organisms/FormWizard'
import Step0, { ICreateCompanyFormStep0 } from './Step0/step0'
import Step1, { ICreateContractsFormStep1 } from './Step1/step1'
import Step2, { ICreateContractsFormStep2 } from './Step2/index'
import Step3, { ICreateContractsFormStep3 } from './Step3/index'
import Step4, { ICreateContractsFormStep4 } from './Step4/step4'
import Step5, {
  ICreateContractBillingPersonId,
  ICreateContractsFormStep5
} from './Step5/step5'
import { ICreateContractsFormStep6, Step6 } from './Step6/step6'
import Step7, { ICreateContractsFormStep7 } from './Step7/step7'
import Step8 from './Step8/step8'
import { Title } from '../../../components/atoms/Title'
import { SetableContext } from '../../../interfaces/setable-context'
import PromissoryBuyerTable from './Step1/PromissoryBuyerTable'
import UnitsTable from './Step4/UnitsTable'
import { AppearFromDown } from '../../../components/templates/AppearFromDown'
import UnitsList from './Step3/UnitsList'
import { useContextPage } from './contextPage'
import { useToast } from '../../../hooks/useToast'
import Step7b, { ICreateContractsFormStep7b } from './Step7b/step7b'
export type ICreateContractsForm = Partial<
  ICreateCompanyFormStep0 &
    ICreateContractsFormStep1 &
    ICreateContractsFormStep2 &
    ICreateContractsFormStep3 &
    ICreateContractsFormStep4 &
    ICreateContractsFormStep5 &
    // ICreateContractsFormStep6 &
    ICreateContractsFormStep7 &
    ICreateContractsFormStep7b &
    ICreateContractBillingPersonId & { created_id?: string }
>

export const CreateContractsContext = React.createContext<
  SetableContext<ICreateContractsForm>
>({})

export default function CreateContracts() {
  const history = useHistory()
  const { showToast } = useToast()
  const [data, setData] = useState<ICreateContractsForm>({})
  const [currentStep, setCurrentStep] = useState(0)
  const { createContract } = useContextPage()

  const steps: Step[] = [
    {
      id: 1,
      label: 'Incorporadora',
      component: Step0,
      disableContinueLater: true
    },
    {
      id: 2,
      label: 'Promissário(s)',
      component: Step1,
      disableContinueLater: true
    },
    {
      id: 3,
      label: 'Promitente',
      component: Step2,
      disableContinueLater: true
    },
    {
      id: 4,
      label: 'Objeto da Promessa',
      component: Step3,
      disableContinueLater: true
    },
    {
      id: 5,
      label: 'Valor do Contrato (R$)',
      component: Step4
    },
    {
      id: 6,
      label: 'Pagamento',
      component: Step5,
      disableContinueLater: true
    },
    // {
    //   id: 7,
    //   label: 'Tokenização',
    //   component: Step6,
    //   disableContinueLater: true
    // },
    {
      id: 7,
      label: 'Assinaturas',
      component: Step7,
      disableContinueLater: true
    },
    {
      id: 8,
      label: 'Minuta',
      component: Step7b,
      disableContinueLater: true
    },
    {
      id: 9,
      label: 'Final',
      component: Step8,
      disableContinueLater: true
    }
  ]

  const onFinish = async () => {
    try {
      const { id } = await createContract.createOne(
        data as Required<ICreateContractsForm>
      )
      setData({ ...data, created_id: id })
    } catch (error) {
      history.push('/contracts?layout=grid')
      showToast('Não foi possível criar o Contrato de Compra e Venda.', 'error')
    }
  }

  const handleSave = () => {
    sessionStorage.setItem('ContractForm', JSON.stringify(data))
  }

  return (
    <>
      <CreateContractsContext.Provider value={{ data, setData }}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Title>Cadastrar Contrato de Compra e Venda</Title>
              </Card.Header>
              <Card.Body>
                <FormWizard
                  steps={steps}
                  finishOnStep={9}
                  onFinish={onFinish}
                  onSaveDraft={handleSave}
                  getCurrentStep={setCurrentStep}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <AppearFromDown key={currentStep}>
              {
                {
                  2: <PromissoryBuyerTable />,
                  4: <UnitsList />,
                  5: <UnitsTable />
                }[currentStep]
              }
            </AppearFromDown>
          </Col>
        </Row>
      </CreateContractsContext.Provider>
    </>
  )
}
