import styled, { css } from 'styled-components'

export const Container = styled('div')<{ disabled: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      .button {
        background-color: #868e96;
        opacity: 100;
      }
    `}
`
