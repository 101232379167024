import { Badge, Card, Col, Row, Table } from 'react-bootstrap'
import * as React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { useIsLogged } from '../../../hooks/useIsLogged'
import { AppContext } from '../../../app'
import { IHistoryPush } from '../../../interfaces/router'
import { HeaderCard } from '../HeaderCard'
import { getMyContractHistory } from '../../../services/simplified-smart-contracts'
import Information from '../../../components/atoms/Information'

const sortByDate = (a: SimplifiedContract, b: SimplifiedContract) => {
  if (a.created_at < b.created_at) {
    return 1
  }
  if (a.created_at > b.created_at) {
    return -1
  }
  return 0
}

export interface SimplifiedContract {
  id: string
  object: string
  value: string
  url: string
  documentHash: string
  transaction_hash: string
  name: string
  document_type: string
  document: string
  created_at: Date
  status: string
  paid_at: Date
}

export const getStatusBadge = (status: string) => {
  return (
    <>
      {status === 'paid' && <Badge variant="success">pago</Badge>}
      {status === 'waiting_payment' && (
        <Badge variant="warning">aguardando pagamento</Badge>
      )}
    </>
  )
}

export const HistorySimplifiedSmartContract = ({
  history
}: {
  history: IHistoryPush
}) => {
  const [contracts, setContracts] = useState<SimplifiedContract[]>([])
  document.title = 'Recebível Chipado'

  const { user } = useContext(AppContext)

  useEffect(() => {
    getMyContractHistory(user!.token).then((results) => setContracts(results))
  }, [])

  useIsLogged(history)

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col md={12}>
            <Card>
              <HeaderCard title="Histório de registro na rede Ethereum" />
              <Card.Body style={{ padding: '10px' }}>
                {!contracts.length && (
                  <Information>Nenhum registro encontrado.</Information>
                )}
                <Table responsive bordered striped>
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td>Data</td>
                      <td>Tipo</td>
                      <td>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {contracts.sort(sortByDate).map((contract) => (
                      <tr key={contract.id}>
                        <td>
                          <Link to={`/smart-contract/${contract.id}`}>
                            {contract.id}
                          </Link>
                        </td>
                        <td>
                          {moment(contract.created_at).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </td>
                        <td>{contract.object}</td>
                        <td>{getStatusBadge(contract.status)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
