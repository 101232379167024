import * as yup from 'yup'

export default yup.object().shape({
  cnpj: yup.string().required('O campo CNPJ é requerido.'),
  companyId: yup.string().required('O campo Incorporadora é requerido.'),
  business_name: yup.string().required('O campo Razão Social é requerido.'),
  zip_code: yup.string().required('O campo CEP é requerido.'),
  address: yup.string().required('O campo Logradouro é requerido.'),
  number: yup.string().required('O campo Número é requerido.'),
  district: yup.string().required('O campo Bairro é requerido.'),
  city: yup.string().required('O campo Cidade é requerido.'),
  state: yup.string().required('O campo Estado é requerido.'),
  country: yup.string().required('O campo País é requerido.')
})
