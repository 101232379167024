import api from './api'

interface SignUpData {
  name: string
  email: string
  phone: string
  cpf: string
  password: string
  password_confirmation: string
  type: string
}

export async function signUp(data: SignUpData) {
  const response = await api.post('/users', data)
  return response.data
}
