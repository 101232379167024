import React from 'react'

export interface FileInputProps {
  inputFileRef: React.RefObject<HTMLInputElement>
  handleChange(file: any): void
}

export const FileInput = ({ inputFileRef, handleChange }: FileInputProps) => {
  return (
    <input
      type="file"
      id="file"
      accept="image/*"
      ref={inputFileRef}
      hidden={true}
      onChange={(e) => handleChange(e.target.files)}
    />
  )
}
