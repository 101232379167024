import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-right: 3rem;
  }

  .btn {
    width: 100%;
  }
`
