import { HttpClient } from '../../protocols/http/httpClient'
import { IEditUnit, Unit } from '../../../domain/usecases/Unit'
import { UnitType } from '../../../domain/models/UnitModel'

export class RemoteEditUnit implements IEditUnit {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async editById(params: Unit.UnitParams): Promise<UnitType> {
    const { file, parking_slots = [], ...others } = params
    const formData = new FormData()
    for (let i = 0; i < parking_slots.length; i++) {
      formData.append(`parking_slots[${i}]`, parking_slots[i])
    }
    Object.entries(others).map(([key, value]) => {
      if (value) {
        formData.append(key, value as any)
      }
    })
    formData.append('plan', file!)
    const httpResponse = await this.httpClient.patch?.({
      url: `${this.url}/units/${params.id}`,
      data: formData,
      headers: {
        Accept: '*/*'
      }
    })
    if (httpResponse?.statusCode && httpResponse?.statusCode !== 200)
      throw new Error('error on editing unit')
    return httpResponse
  }
}
