import React, { useState, useContext } from 'react'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './payment-step-billing-form.schema'
import * as S from '../styles'
import { LoadingIndicator } from '../../../components/atoms/LoadingIndicator'
import { TextInputWithMask } from '../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../components/atoms/TextInput'
import { Col, Row } from 'react-bootstrap'
import { SelectField } from '../../../components/atoms/SelectField'
import { PaymentContext, paymentSteps } from '../index'

export type SignatureStep1Props = {
  paymentData?: any
  handleStepChange: (step: paymentSteps) => void
}

interface IPaymentStepBillingForm {
  birth: string
  zipcode: string
  street: string
  number: string
  neighborhood: string
  city: string
  state: string
}

const PaymentStepBillingForm = ({ handleStepChange }: SignatureStep1Props) => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState<boolean>(false)

  const { setPaymentData, paymentData } = useContext(PaymentContext)

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IPaymentStepBillingForm>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<IPaymentStepBillingForm> = async (data) => {
    try {
      setPaymentData!({
        ...paymentData,
        billing_address: data
      })
      handleStepChange('review')
    } catch (e) {
      setLoading(false)
      addToast('Algo deu errado, tente novamente.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <S.ContentWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>Endereço do cartão de crédito</h4>
        <Row>
          <Col md={4}>
            <Controller
              name="zipcode"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInputWithMask
                  mask="99999-999"
                  disabled={loading}
                  label="CEP"
                  errorMessage={errors?.zipcode?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={6}>
            <Controller
              name="street"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  disabled={loading}
                  label="Endereço"
                  errorMessage={errors?.street?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={2}>
            <Controller
              name="number"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  disabled={loading}
                  label="Número"
                  errorMessage={errors?.number?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Controller
              name="neighborhood"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  disabled={loading}
                  label="Bairro"
                  errorMessage={errors?.neighborhood?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              name="city"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label="Cidade"
                  disabled={loading}
                  errorMessage={errors?.city?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              name="state"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  label="Estado"
                  disabled={loading}
                  errorMessage={errors?.state?.message}
                  onChange={onChange}
                  value={value}
                  emptyOptionText={'-- Selecione --'}
                  options={[
                    { label: 'AC', value: 'AC' },
                    { label: 'AL', value: 'AL' },
                    { label: 'AP', value: 'AP' },
                    { label: 'AM', value: 'AM' },
                    { label: 'BA', value: 'BA' },
                    { label: 'CE', value: 'CE' },
                    { label: 'DF', value: 'DF' },
                    { label: 'ES', value: 'ES' },
                    { label: 'GO', value: 'GO' },
                    { label: 'MA', value: 'MA' },
                    { label: 'MT', value: 'MT' },
                    { label: 'MS', value: 'MS' },
                    { label: 'MG', value: 'MG' },
                    { label: 'PA', value: 'PA' },
                    { label: 'PB', value: 'PB' },
                    { label: 'PR', value: 'PR' },
                    { label: 'PE', value: 'PE' },
                    { label: 'PI', value: 'PI' },
                    { label: 'RJ', value: 'RJ' },
                    { label: 'RN', value: 'RN' },
                    { label: 'RS', value: 'RS' },
                    { label: 'RO', value: 'RO' },
                    { label: 'RR', value: 'RR' },
                    { label: 'SC', value: 'SC' },
                    { label: 'SP', value: 'SP' },
                    { label: 'SE', value: 'SE' },
                    { label: 'TO', value: 'TO' }
                  ]}
                />
              )}
            />
          </Col>
        </Row>

        <button
          className="btn btn-primary pull-left mt-4 mb-4"
          style={{ width: '100%' }}
          type="submit"
          disabled={loading}
        >
          {!loading ? 'Continuar' : <LoadingIndicator />}
        </button>
      </form>
      <S.Tip>
        Você poderá revisar as informações antes de finalizar o pagamento.
      </S.Tip>
    </S.ContentWrapper>
  )
}
export default PaymentStepBillingForm
