import * as S from './styles'

interface CPFTagInputLabel {
  hintText?: string
}

export default function CPFTagInputLabel({ hintText }: CPFTagInputLabel) {
  return (
    <>
      <strong>CPF(s) do(s) Procurador(es)</strong>
      <S.WarningInformation>
        {hintText} Aperte a tecla &ldquo;<strong>Enter</strong>&rdquo; após
        informar cada CPF.
      </S.WarningInformation>
    </>
  )
}
