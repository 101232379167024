import * as yup from 'yup'

export default yup.object().shape({
  custom_contract: yup.boolean(),
  file_size: yup
    .number()
    .max(
      10000000,
      'O arquivo é muito grande. Por favor, selecione um arquivo de até 10 MB.'
    ),
  file_type_custom_contract: yup.string()
})
