import { Card, Col, Row } from 'react-bootstrap'

import * as React from 'react'

import * as S from './styles'

interface InforCardProps {
  title: string | React.ReactNode
  subtitle: string
  tendency?: 'up' | 'down'
  textColor?: string
  iconColor?: string
  footerBgColor?: string
  footerContent?: React.ReactElement
}

export const InfoCard = ({
  title,
  subtitle,
  textColor,
  footerBgColor,
  iconColor,
  footerContent
}: InforCardProps) => {
  return (
    <Card className="card-smaller-radius events-card">
      <Card.Body>
        <Row className="info-card align-items-center">
          <Col sm={8}>
            <S.Title color={textColor}>{title}</S.Title>
          </Col>
          <Col sm={4} className="text-right">
            <S.Icon
              className="feather icon-bar-chart-2 f-28"
              color={iconColor}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <S.Subtitle className="text-muted m-b-0">{subtitle}</S.Subtitle>
          </Col>
        </Row>
      </Card.Body>
      {/*<Card.Footer style={{ background: footerBgColor }}>*/}
      {/*  {footerContent}*/}
      {/*</Card.Footer>*/}
    </Card>
  )
}
