import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useGetCpf } from '../../../hooks/useGetCpf'
import { TextInput } from '../../atoms/TextInput'
import { CPFTagInput } from '../../molecules/CPFTagInput'
import { Proxy } from 'domain/models/ProxyModel'
import { Tag } from 'react-tag-autocomplete'

export interface ICreateCompanyFormStep3 {
  proxies: Proxy[]
}
interface ProxiesSelectorProps {
  hintText?: string
  defaultProxies?: Proxy[]
  errors: any
  onChange: (proxies: Proxy[]) => void
}

export default function ProxiesSelector({
  errors,
  defaultProxies = [],
  onChange,
  hintText
}: ProxiesSelectorProps) {
  const [proxies, setProxies] = useState<Proxy[]>(defaultProxies || [])
  const { getCpf } = useGetCpf()

  useEffect(() => {
    onChange(proxies)
  }, [proxies])

  // TODO remove procuradores
  const updatedCPFs = async (
    cpfs: string[],
    updateProxies: any
  ): Promise<Tag[]> => {
    const procuradoresCpfs = [...getCurrentCpfs(cpfs), ...getNewCpfs(cpfs)]
    const procuradores = await getProcuradoresNameByCpf(procuradoresCpfs)
    updateProxies([...procuradores])
    return procuradores.map((procurador) => ({
      id: '',
      name: procurador.cpf
    }))
  }

  const getCurrentCpfs = (cpfs: string[]) => {
    return proxies?.filter((proxy) => cpfs.includes(proxy?.cpf)) || []
  }

  const getNewCpfs = (cpfs: string[]) => {
    return cpfs
      ?.filter((cpf) => !proxies.find((proxy) => proxy?.cpf === cpf))
      ?.map((cpf) => ({ cpf }))
  }

  const getProcuradoresNameByCpf = async (
    procuradoresCpfs: { cpf: string }[]
  ) => {
    const procuradores = []
    for (const procuradorCpf of procuradoresCpfs) {
      const procurador = await createProcurador(procuradorCpf.cpf)
      if (!procurador.name) continue
      procuradores.push(procurador)
    }
    return procuradores
  }

  const createProcurador = async (cpf: string) => {
    const cpfDto = await getCpf(cpf)
    const email = proxies.find((p) => p.cpf === cpf)?.email
    const id = proxies.find((p) => p.cpf === cpf)?.id
    const company_id = proxies.find((p) => p.cpf === cpf)?.company_id

    return {
      ...(id && { id, company_id }),
      cpf,
      name: cpfDto?.nome,
      email
    }
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <CPFTagInput
            defaultValue={proxies?.map((item) => ({
              id: '',
              name: item.cpf
            }))}
            onChange={(cpfs) => updatedCPFs(cpfs, setProxies)}
            cpfHintText={hintText}
          />
          {
            <span style={{ color: 'red' }}>
              {errors.proxies && (errors.proxies as any)?.message}
            </span>
          }
        </Col>
      </Row>
      {proxies?.map((proxy, index) => (
        <Row key={`proxy-${index}`}>
          <Col md={4}>
            <TextInput label="CPF do Procurador" value={proxy?.cpf} readOnly />
          </Col>
          <Col md={4}>
            <TextInput
              type="text"
              label="Nome do Procurador"
              value={proxy?.name}
              readOnly
            />
          </Col>
          <Col md={4}>
            <TextInput
              type="text"
              label="E-mail do Procurador"
              defaultValue={proxy?.email}
              onChange={(ev) => {
                const newProxies = proxies.map((prx) =>
                  prx.cpf == proxy.cpf
                    ? { ...prx, email: ev.target.value }
                    : prx
                )
                setProxies(newProxies)
              }}
            />
          </Col>
        </Row>
      ))}
    </>
  )
}
