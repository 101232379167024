import React, { ReactNode } from 'react'
import { Button, Modal } from 'react-bootstrap'
interface IModalProps {
  show: boolean
  title: string
  body: ReactNode
  onAccept: () => void
  onHide: () => void
}
const FormsModal = ({ show, title, body, onAccept, onHide }: IModalProps) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>{title}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="light" onClick={onHide}>
          Cancelar
        </Button>
        <Button onClick={onAccept}>Salvar e Continuar Depois</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FormsModal
