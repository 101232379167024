/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Button, Col, Row } from 'react-bootstrap'

import { TextWithHint } from '../../../components/atoms/TextWithHint'
import { EntityDetails } from '../../../components/organisms/EntityDetails'
import { DetailsHeader } from '../../../components/templates/DetailsHeader'
import { DetailsSection } from '../../../components/templates/DetailsSection'
import { PaymentsTable, ValuePaymentsTable } from './PaymentsTable'
import { PersonsTable, ValuesPersonTable } from './PersonsTable'
import { getContract } from '../../../domain/usecases/Contracts'
import { ContractWithPaymentPart } from '../../../domain/models/ContractsModel'
import propImage from '../../../assets/images/rkm_capa.png'
import { convertNumberToCurrencyFormat } from '../../../utils/convertNumberToCurrencyFormat'
import { IndividualPersonModel } from '../../../domain/models/IndividualPersonModel'
import { JuridicPersonModel } from '../../../domain/models/JuridicPersonModel'
import formatStringToCNPJ from '../../../utils/formatStringToCNPJ'
import { convertCPFStringToDots } from '../../../utils/convertCPFStringToDots'
import {
  serializedValuesForPaymentsTable,
  serializedValuesForPersonsTable
} from '../../../domain/converters/Contracts'

export type IParams = {
  id: string
}

export type StatusBadge = 'success' | 'info' | 'warning' | 'error'

export type ContractDetailsProps = {
  remoteGetContract: getContract
}

export const ContractDetails = ({
  remoteGetContract
}: ContractDetailsProps) => {
  const [values, setValues] = useState<ContractWithPaymentPart>()

  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { id } = params as IParams

  useEffect(() => {
    const getOneContract = async () => {
      try {
        dispatch({ type: 'SET_LOADING', loading: true })

        const contract = await remoteGetContract.getOne({ id })
        setValues(contract)
      } catch (error) {
        addToast('Contrato não encontrado.', {
          appearance: 'error',
          autoDismiss: true
        })
        history.push('/contracts?layout=grid')
      } finally {
        dispatch({ type: 'SET_LOADING', loading: false })
      }
    }

    getOneContract()
  }, [])

  return (
    <EntityDetails
      title="Contrato de Compra e Venda"
      sections={
        <>
          <DetailsSection
            title=""
            content={
              <div>
                <Row>
                  <Col>
                    <TextWithHint
                      text={values?.contract?.project?.name}
                      hint="Razão Social"
                      isTitle
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextWithHint
                      text={
                        <a
                          href={values?.contract.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Abrir
                        </a>
                      }
                      hint="Documento do Contrato"
                    />

                    <TextWithHint
                      text={values?.contract?.project?.ri}
                      hint="Registro da Incorporação"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <TextWithHint
                      text={values?.contract?.project.address.address}
                      hint="Logradouro"
                    />
                    <TextWithHint
                      text={values?.contract?.project.address.zip_code}
                      hint="CEP"
                    />
                  </Col>
                  <Col>
                    <TextWithHint
                      text={values?.contract?.project.address.number}
                      hint="Número"
                    />
                    <TextWithHint
                      text={values?.contract?.project.address.district}
                      hint="Bairro"
                    />
                  </Col>
                  <Col>
                    <TextWithHint
                      text={values?.contract?.project?.address?.city}
                      hint="Cidade"
                    />
                    <TextWithHint
                      text={values?.contract?.project?.address?.state}
                      hint="Estado"
                    />
                  </Col>
                  <Col xs={3}>
                    <TextWithHint
                      text={values?.contract?.unit.name}
                      hint="Unidade Autônoma"
                    />
                  </Col>
                  <Col>
                    <TextWithHint
                      text={values?.contract?.unit.building.name}
                      hint="Bloco"
                    />
                  </Col>
                </Row>
              </div>
            }
          />
          <DetailsSection
            title="Dados do Pagamento"
            content={
              <Row className="pt-2">
                <Col xs={2}>
                  <TextWithHint
                    text={convertNumberToCurrencyFormat(
                      Number(values?.contract.unit.value)
                    )}
                    hint="Valor do Imóvel"
                  />
                </Col>
                <Col xs={2}>
                  <TextWithHint
                    text={convertNumberToCurrencyFormat(
                      Number(values?.contract.value)
                    )}
                    hint="Valor Negociado"
                  />
                </Col>
              </Row>
            }
          />
          <DetailsSection
            title="Dados da SPE"
            content={
              <Row className="pt-2">
                <Col xs={2}>
                  <TextWithHint text={values?.contract?.spe.cnpj} hint="CNPJ" />
                </Col>
                <Col>
                  <TextWithHint
                    text={values?.contract?.spe.business_name}
                    hint="Razão Social"
                  />
                </Col>
              </Row>
            }
          />
          <DetailsSection
            title="Dados da Incorporadora"
            content={
              <Row className="pt-2">
                <Col xs={2}>
                  <TextWithHint
                    text={values?.contract?.company.cnpj}
                    hint="CNPJ"
                  />
                </Col>
                <Col>
                  <TextWithHint
                    text={values?.contract?.company.business_name}
                    hint="Razão Social"
                  />
                </Col>
              </Row>
            }
          />
        </>
      }
      content={
        <>
          <PaymentsTable value={serializedValuesForPaymentsTable(values)} />
          <PersonsTable value={serializedValuesForPersonsTable(values)} />
        </>
      }
    />
  )
}
