import * as S from './styles'
import { convertNumberToCurrencyFormat } from '../../utils/convertNumberToCurrencyFormat'
import moment from 'moment'
import React from 'react'
import { CheckoutDetails } from '../../domain/models/BillingModel'

export const PaymentMainInfo = ({
  checkoutDetails
}: {
  checkoutDetails: CheckoutDetails
}) => {
  return (
    <S.MainInfo>
      <S.p>
        <b>Valor do Pagamento</b>
        <br />
        {convertNumberToCurrencyFormat(checkoutDetails.billing.value)}
      </S.p>

      <S.p>
        <b>Número da Parcela</b>
        <br />
        {checkoutDetails.billing.installment_number} de{' '}
        {checkoutDetails.billing.contract_payment_part.installments}
      </S.p>

      <S.p>
        <b>Vencimento</b>
        <br />
        {moment(checkoutDetails.billing.due_date).format('DD/MM/YYYY')}
      </S.p>
    </S.MainInfo>
  )
}
