import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { isValid as isValidCPF } from 'cpf'
import { validate as isValidCNPJ } from 'cnpj'
import { useToasts } from 'react-toast-notifications'
import Information from '../../atoms/Information'
import TagInput from '../TagInput'
import { Tag } from '../InputTag'
import { convertCPForCNPJStringToNumbers } from 'utils/convertCPFStringToNumbers'
import { convertCPFStringToDots } from 'utils/convertCPFStringToDots'
import { convertNumbersToCnpj } from 'utils/convertNumbersToCNPJ'

export interface TagInputProps {
  label: string
  hint?: string
  onChange: (cpfs: string[]) => void
  defaultValue?: Tag[]
  errorMessage?: string
}

export const CPFAndCNPJTagInput = ({
  label,
  hint,
  onChange,
  defaultValue = [],
  errorMessage
}: TagInputProps) => {
  const [data, setData] = useState<Tag[]>(defaultValue)
  const { addToast } = useToasts()

  useEffect(() => {
    onChange(data.map((tag) => tag.name))
  }, [data])

  const handleAddition = (tags: Tag[]) => {
    const cpfAndCnpjNumbers = tags.map((tag) =>
      convertCPForCNPJStringToNumbers(tag.name)
    )
    const formattedCpfAndCnpj = cpfAndCnpjNumbers.map((item) =>
      formatCpfOrCnpj(item)
    )
    const cpfAndCnpjTags = formattedCpfAndCnpj.map((tag) => ({
      id: Date.now(),
      name: tag
    }))
    setData(cpfAndCnpjTags)
  }

  const formatCpfOrCnpj = (value: string) => {
    if (isValidCPF(value)) return convertCPFStringToDots(value)
    return convertNumbersToCnpj(value)
  }

  const toastCpfOrCnpjError = () => {
    addToast('CPF/CNPJ inválido.', {
      appearance: 'error',
      autoDismiss: true
    })
  }

  const notDuplicated = (tag: Pick<Tag, 'name'>) => {
    return !data.find(
      (item) =>
        convertCPForCNPJStringToNumbers(item.name) ===
        convertCPForCNPJStringToNumbers(tag.name)
    )
  }

  const isValidCpfOrCnpj = (value: string) =>
    isValidCPF(value) || isValidCNPJ(value)

  return (
    <Form.Group>
      <Form.Label>
        <strong>{label}</strong>
        <Information>
          Aperte a tecla &ldquo;<strong>Enter</strong>&rdquo; após informar cada
          CPF ou CNPJ.
        </Information>
      </Form.Label>
      <TagInput
        info={<span>{hint}</span>}
        placeholder="Digite e aperte ENTER"
        defaultTags={data}
        onChange={(tags, type) => {
          type === 'remove' ? setData(tags) : handleAddition(tags)
        }}
        interceptorBeforeAddTag={(value) => formatCpfOrCnpj(value)}
        onValidate={(tag) => {
          if (isValidCpfOrCnpj(tag.name) && notDuplicated(tag)) return true
          toastCpfOrCnpjError()
          return false
        }}
        errorMessage={errorMessage}
      />
    </Form.Group>
  )
}
