import React, { useState, useRef } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import { EntityList } from '../../../components/organisms/EntityList'
import { EntityCardProps } from '../../../components/organisms/EntityCard'
import { convertUnitToEntity } from '../../../domain/converters/Unit'
import { getEntitiesCount } from '../../../domain/usecases/Reports'

import { deleteUnit, getUnits, Unit } from '../../../domain/usecases/Unit'

import { filtersFactory } from '../../../main/factories/filters.factory'
import { FilterItem } from '../../../components/organisms/EntityFilter'

import { DeleteModal } from '../../../components/molecules/DeleteModal'
import { FileInput } from '../../../components/atoms/FileInput'
import { ConvertFileToBase64 } from '../../../utils/ConvertFileToBase64'
import { SeparateBetween } from '../../../components/templates/SeparateBetween'

type ListUnitsProps = {
  remoteGetUnits: getUnits
  remoteDeleteUnit: deleteUnit
  remoteGetEntitiesCount: getEntitiesCount
  remoteCreateUnit: Unit
}

const ListUnits = ({
  remoteGetUnits,
  remoteDeleteUnit,
  remoteGetEntitiesCount,
  remoteCreateUnit
}: ListUnitsProps) => {
  const history = useHistory()
  const { addToast } = useToasts()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [units, setUnits] = useState<EntityCardProps[]>([])
  const [selectedId, setSelectedId] = useState('')

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<any>(null)

  const [filters, setFilters] = useState<FilterItem[]>([])

  React.useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }, [errorMessage])

  const requestUnits = async () => {
    setLoading(true)
    try {
      const UnitsResponse = await remoteGetUnits.getUnits()
      setUnits(
        convertUnitToEntity(
          UnitsResponse,
          deleteUnitFunction,
          RedirectToUnitDetails,
          addUnitPhoto
        )
      )
      setLoading(false)
    } catch (error) {
      setErrorMessage('Não foi possível carregar as Unidades.')
    }
  }

  React.useEffect(() => {
    requestUnits()
    remoteGetEntitiesCount
      .getEntitiesCount()
      .then((entitiesCount) =>
        setFilters(filtersFactory(entitiesCount, history))
      )
    setLoading(true)
  }, [])

  const handleDelete = () => {
    try {
      remoteDeleteUnit
        .deleteById({ id: selectedId })
        .then((res) => {
          requestUnits()
          handleClose()
        })
        .catch((error) => {
          handleClose()
          setErrorMessage('Não foi possível excluir a Unidade.')
        })
    } catch (err) {
      setErrorMessage('Não foi possível excluir a Unidade.')
      return err
    }
  }

  const deleteUnitFunction = (idUnit: string) => {
    handleShow()
    setSelectedId(idUnit)
  }

  const RedirectToUnitDetails = (id: string) => {
    history.push(`/units/${id}`, { id: id })
  }

  const addUnitPhoto = async (id: string) => {
    try {
      setSelectedId(id)
      inputFileRef.current?.click()
    } catch (err) {
      return err
    }
  }

  React.useEffect(() => {
    async function handleChange() {
      setLoading(true)
      const fileBase64 = await ConvertFileToBase64(file)
      if (fileBase64) {
        await remoteCreateUnit.uploadUnitPhoto({
          id: selectedId,
          base64: fileBase64
        })
      }
      requestUnits().then(() => {
        setLoading(false)
        setFile(null)
      })
    }
    if (file) {
      handleChange()
    }
  }, [file])

  return (
    <>
      <FileInput inputFileRef={inputFileRef} handleChange={setFile} />
      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleOk={handleDelete}
      />
      <EntityList
        title="Unidades"
        filters={filters}
        activeFilter={3}
        entityCards={units}
        buttons={
          <SeparateBetween>
            <Button
              onClick={() =>
                history.push('/units/create', {
                  complete_multiple: true
                })
              }
              variant="info"
            >
              <strong>Completar Múltiplas Unidades</strong>
            </Button>
            <Button
              onClick={() => history.push('/units/create')}
              variant="primary"
            >
              Adicionar Unidade
            </Button>
          </SeparateBetween>
        }
      />
    </>
  )
}

export default ListUnits
