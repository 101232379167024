import { cloneElement } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const HooverableTooltip = ({
  children,
  text
}: {
  children: React.ReactElement
  text: string
}) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="button-tooltip-2">{text}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) =>
        cloneElement(children, { ...triggerHandler, ref: ref })
      }
    </OverlayTrigger>
  )
}
