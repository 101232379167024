import * as yup from 'yup'

export default yup.object().shape({
  sms_code: yup.string().required('O campo Código SMS é requerido.'),
  email_code: yup.string().required('O campo Código E-mail é requerido.'),
  agree: yup
    .boolean()
    .oneOf([true], 'Leia o Contrato antes de assinar.')
    .required('Campo requerido.')
})
