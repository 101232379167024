import * as React from 'react'

import * as S from './styles'

interface ErrorTextProps {
  text: string
}

export default function ErrorText({ text }: ErrorTextProps) {
  return <S.Error>{text}</S.Error>
}
