import React, { useContext, useState } from 'react'

import { NavLink, useLocation, useParams } from 'react-router-dom'
import { Alert, Card } from 'react-bootstrap'

import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import { PasswordField } from '../../components/atoms/PasswordField'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './process-contract.schema'
import { IProcessContractForm } from './process-contract.interface'
import { useToasts } from 'react-toast-notifications'
import { LoadingIndicator } from '../../components/atoms/LoadingIndicator'
import { IHistoryPush } from '../../interfaces/router'
import { useIsGuest } from '../../hooks/useIsGuest'
import Logo from '../../components/atoms/Logo'
import { processContract } from '../../services/contracts'
import { IParam } from '../SignatureFlow'

const ProcessContract = () => {
  document.title = 'Recebível Chipado - Liberação de processamento'
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [conflict, setConflict] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IProcessContractForm>({
    resolver: yupResolver(schema)
  })

  const { id: contract_id } = useParams<IParam>()

  const { search } = useLocation()
  const user_id = new URLSearchParams(search).get('user_id') || ''

  const [accessDenied, setAccessDenied] = useState<boolean>(false)

  const onSend: SubmitHandler<IProcessContractForm> = async (data) => {
    setAccessDenied(false)
    try {
      setLoading(true)
      const response = await processContract(contract_id, {
        password: data.password,
        user_id
      })
      setLoading(false)
      setAccessDenied(false)
      setFinished(true)
    } catch (e) {
      console.error(e)
      setLoading(false)
      if (e.response.status === 401) {
        setAccessDenied(true)
      } else if (e.response.status === 409) {
        setConflict(true)
      } else {
        addToast('Ocorreu um erro desconhecido.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(onSend)}>
          <Card>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Card.Body>
                  <div style={{ marginBottom: '20px' }}>
                    <Logo />
                  </div>
                  {!finished && !conflict && (
                    <>
                      <h5 className="f-w-700">
                        Por favor, digite a sua senha da conta Recebível Chipado
                        para efetuar a liberação de processamento do seu
                        contato.
                      </h5>
                      <Controller
                        control={control}
                        name="password"
                        render={({ field: { onChange, value } }) => (
                          <PasswordField
                            disabled={loading || finished}
                            onChange={onChange}
                            value={value}
                            errorMessage={errors.password?.message}
                          />
                        )}
                      />
                      {accessDenied && (
                        <Alert variant="danger">
                          Usuário e/ou Senha incorretos.
                        </Alert>
                      )}
                      <div className="d-flex mb-4 mt-4">
                        <button
                          className="btn btn-primary pull-left"
                          type="submit"
                          disabled={loading}
                        >
                          {!loading ? (
                            'Liberar processamento'
                          ) : (
                            <LoadingIndicator />
                          )}
                        </button>
                      </div>
                      <div className="d-block mb-4">
                        {!loading && (
                          <p className="mb-2 text-muted pull-left">
                            <NavLink
                              to="/esqueci-minha-senha"
                              className="f-w-400"
                            >
                              Esqueci minha Senha
                            </NavLink>
                          </p>
                        )}
                      </div>
                    </>
                  )}
                  {finished && (
                    <>
                      <h5 className="f-w-700">
                        Liberação efetuada com sucesso! Você receberá um email
                        assim que o processamento estiver finalizado.
                      </h5>
                    </>
                  )}
                  {conflict && (
                    <>
                      <h5 className="f-w-700">
                        Erro. Este contrato já foi liberado para processamento
                        anteriormente.
                      </h5>
                    </>
                  )}
                </Card.Body>
              </div>
            </div>
          </Card>
        </form>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default ProcessContract
