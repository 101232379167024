import React, { useState, useRef } from 'react'

import { EntityCardProps } from '../../../components/organisms/EntityCard'
import { FilterItem } from '../../../components/organisms/EntityFilter'
import { EntityList } from '../../../components/organisms/EntityList'
import {
  deleteProject,
  getProject,
  createProject
} from '../../../domain/usecases/Project'
import { getEntitiesCount } from '../../../domain/usecases/Reports'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { filtersFactory } from '../../../main/factories/filters.factory'
import { convertProjectToEntity } from '../../../domain/converters/Project'
import { Button } from 'react-bootstrap'

import { DeleteModal } from '../../../components/molecules/DeleteModal'
import { FileInput } from '../../../components/atoms/FileInput'
import { ConvertFileToBase64 } from '../../../utils/ConvertFileToBase64'

type ListProjectsProps = {
  remoteGetProjects: getProject
  remoteGetEntitiesCount: getEntitiesCount
  remoteDeleteProject: deleteProject
  remoteCreateProject: createProject
}

const ListProjects = ({
  remoteGetProjects,
  remoteGetEntitiesCount,
  remoteDeleteProject,
  remoteCreateProject
}: ListProjectsProps) => {
  const history = useHistory()
  const { addToast } = useToasts()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [projects, setProjects] = useState<EntityCardProps[]>([])
  const [selectedId, setSelectedId] = useState('')

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<any>(null)

  const [filters, setFilters] = useState<FilterItem[]>([])

  React.useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }, [errorMessage])

  const requestProjects = async () => {
    setLoading(true)
    try {
      const ProjectsResponse = await remoteGetProjects.getProjects()
      setProjects(
        convertProjectToEntity(
          ProjectsResponse,
          deleteProjectFunction,
          RedirectToProjectDetails,
          addProjectPhoto
        )
      )
      setLoading(false)
    } catch (error) {
      setErrorMessage('Não foi possível carregar os Empreendimentos.')
    }
  }

  React.useEffect(() => {
    requestProjects()
    remoteGetEntitiesCount
      .getEntitiesCount()
      .then((entitiesCount) =>
        setFilters(filtersFactory(entitiesCount, history))
      )
  }, [])

  const handleDelete = () => {
    try {
      remoteDeleteProject
        .deleteById({ id: selectedId })
        .then((res) => {
          requestProjects()
          handleClose()
        })
        .catch((error) => {
          handleClose()
          setErrorMessage('Não foi possível excluir o Empreendimento.')
        })
    } catch (err) {
      setErrorMessage('Não foi possível excluir o Empreendimento.')
      return err
    }
  }

  const deleteProjectFunction = (idProject: string) => {
    handleShow()
    setSelectedId(idProject)
  }

  const RedirectToProjectDetails = (id: string) => {
    history.push(`/projects/${id}`, { id: id })
  }

  const addProjectPhoto = async (id: string) => {
    try {
      setSelectedId(id)
      inputFileRef.current?.click()
    } catch (err) {
      return err
    }
  }

  React.useEffect(() => {
    async function handleChange() {
      setLoading(true)
      const fileBase64 = await ConvertFileToBase64(file)
      if (fileBase64) {
        await remoteCreateProject.uploadProjectPhoto({
          id: selectedId,
          base64: fileBase64
        })
      }
      requestProjects().then(() => {
        setLoading(false)
        setFile(null)
      })
    }
    if (file) {
      handleChange()
    }
  }, [file])

  return (
    <>
      <FileInput inputFileRef={inputFileRef} handleChange={setFile} />
      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleOk={handleDelete}
      />
      <EntityList
        activeFilter={2}
        title="Empreendimentos"
        filters={filters}
        buttons={
          <Button
            onClick={() => history.push('/projects/create')}
            variant="primary"
          >
            Adicionar Empreendimento
          </Button>
        }
        entityCards={projects}
      />
    </>
  )
}

export default ListProjects
