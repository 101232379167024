import { useContext } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateProjectContext } from '../index'
import { Controller, useForm } from 'react-hook-form'
import { Button, Col, Row } from 'react-bootstrap'

import TagInput from '../../../../components/molecules/TagInput'
import schema from './step3.schema'
import { Tag } from 'react-tag-autocomplete'
import * as React from 'react'
import UpdateProjectStep3 from './updateProjectStep3'
import Information from '../../../../components/atoms/Information'

export interface ICreateProjectFormStep3 {
  buildings: Tag[]
}

export default function Step3({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateProjectContext)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ICreateProjectFormStep3>({
    resolver: yupResolver(schema),
    defaultValues: {
      buildings: data?.buildings
    }
  })

  const onClickNext = (formData: ICreateProjectFormStep3) => {
    setData?.({ ...data, ...formData })
    nextHandler()
  }

  const allowToAddIfNotDuplicated = (tag: Pick<Tag, 'name'>, value: Tag[]) => {
    return !value.find((building) => building.name === tag.name)
  }

  const isUpdatePage = () => data?.id

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col md={12}>
          <Controller
            control={control}
            name="buildings"
            render={({ field: { onChange, value = [] } }) => {
              if (isUpdatePage())
                return (
                  <UpdateProjectStep3 onChange={onChange} setValue={setValue} />
                )
              return (
                <TagInput
                  label="Blocos"
                  info={
                    <Information>
                      Digite o nome dos Blocos e aperte a tecla{' '}
                      <strong>ENTER</strong> após informar cada nome.
                    </Information>
                  }
                  onChange={onChange}
                  defaultTags={data?.buildings}
                  suggestions={data?.buildings?.map((building) => ({
                    id: '',
                    name: building.name
                  }))}
                  onValidate={(tag) => allowToAddIfNotDuplicated(tag, value)}
                  errorMessage={(errors.buildings as any)?.message}
                  placeholder="Digite o nome dos Blocos"
                />
              )
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperSpaced>
            <Button variant="link" onClick={backHandler}>
              Voltar
            </Button>
            <Button variant="primary" onClick={handleSubmit(onClickNext)}>
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperSpaced>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
