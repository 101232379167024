import api from '../../services/api'

export type CepDto = {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  status: number
  erro: boolean
}

export default class CepService {
  static async get(cep: string): Promise<CepDto> {
    const { data } = await api.get<CepDto>(`/address/cep/${cep}`)
    return data
  }
}
