export const unitCharacteristics = [
  { label: 'Ar-condicionado', value: 'Ar-condicionado' },
  { label: 'Área de serviço', value: 'Área de serviço' },

  { label: 'Churrasqueira', value: 'Churrasqueira' },
  { label: 'Conexão à internet', value: 'Conexão à internet' },
  { label: 'Cozinha', value: 'Cozinha' },

  { label: 'Depósito', value: 'Depósito' },

  { label: 'Escritório', value: 'Escritório' },

  { label: 'Interfone', value: 'Interfone' },

  { label: 'Mobiliado', value: 'Mobiliado' },

  { label: 'Piscina', value: 'Piscina' },

  { label: 'TV a cabo', value: 'TV a cabo' },

  { label: 'Varanda', value: 'Varanda' },
  { label: 'Varanda gourmet', value: 'Varanda gourmet' }
]
