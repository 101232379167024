import React from 'react'
import { EntityFilter, FilterItem } from '../EntityFilter'
import { EntityCard, EntityCardProps } from '../EntityCard'
import { Card, Col, Row } from 'react-bootstrap'
import { SeparateBetween } from '../../templates/SeparateBetween'
import { Title } from '../../atoms/Title'
import { ViewOptions, viewOptionsTypes } from '../../molecules/ViewOptions'
import { TitleWithButtons } from '../../molecules/TitleWithButtons'
import { useLocation } from 'react-router-dom'

interface EntityListProps {
  mainTitle?: string
  title: string
  buttons?: React.ReactNode
  filters?: FilterItem[]
  filterWithSearch?: React.ReactNode
  activeFilter?: number
  entityCards?: EntityCardProps[]
  entityTable?: React.ReactNode
  onClickList?: () => void
  onClickGrid?: () => void
  fullWidth?: boolean
}

export const EntityList = (props: EntityListProps) => {
  const { search } = useLocation()
  const typeQueryParam = new URLSearchParams(search).get(
    'type'
  ) as viewOptionsTypes | null

  return (
    <Row>
      <Col md={props.fullWidth ? 12 : 9}>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                <SeparateBetween>
                  <Title>
                    {props.mainTitle ? props.mainTitle : 'Meus Cadastros'}
                  </Title>
                </SeparateBetween>
              </Card.Header>
              <Card.Body>
                <TitleWithButtons title={props.title} buttons={props.buttons} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>{props.entityTable}</Col>
        </Row>
        <Row>
          {props?.entityCards?.map((entityCard, index) => (
            <Col key={`entity-card-${index}`} xl={4} sm={6}>
              <EntityCard {...entityCard} />
            </Col>
          ))}
        </Row>
      </Col>
      <Col md={3}>
        <Card>
          {props.filters && (
            <Card.Header>
              <h6>Atalhos</h6>
              <EntityFilter
                data={props.filters}
                activeIndex={props.activeFilter}
              />
            </Card.Header>
          )}
          {props.filterWithSearch && props.filterWithSearch}
        </Card>
      </Col>
    </Row>
  )
}
