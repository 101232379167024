import { ContractPaymentPart } from 'domain/models/PaymentPartsModel'
import { FREQUENCY_PAYMENTS } from 'constants/frequency_payments'

export type installmentDatesProps = {
  date: Date
  amountOfDates: number
  frequency: number
}

export default function (defaultValue?: ContractPaymentPart) {
  const multiplyValueByInstallment = (
    installments: number,
    installmentsValue: number
  ) => {
    return installments * installmentsValue
  }

  const installmentDates = ({
    date,
    amountOfDates,
    frequency = 0
  }: installmentDatesProps): string[] => {
    const dates: string[] = []
    for (let i = 2; i <= amountOfDates; i++) {
      const result = date?.setMonth(date?.getMonth() + frequency)

      dates.push(new Date(result).toLocaleDateString('pt-BR'))
    }
    return dates
  }

  const createInitialStateInstallments = () => {
    return defaultValue?.installments || 0
  }
  const createInitialStateInstallmentsValue = () => {
    return defaultValue?.installment_value || 0
  }

  const createInitialStateDate = () => {
    return (
      (defaultValue?.first_due_date &&
        new Date(defaultValue?.first_due_date)) ||
      new Date()
    )
  }

  const createInitialStateFrequency = () => {
    return (
      (defaultValue?.frequency &&
        Number(FREQUENCY_PAYMENTS[defaultValue?.frequency])) ||
      0
    )
  }
  const createInitialStateTotalValue = () => {
    return defaultValue?.total_value || 0
  }
  return {
    installmentDates,
    multiplyValueByInstallment,
    createInitialStateInstallments,
    createInitialStateInstallmentsValue,
    createInitialStateDate,
    createInitialStateFrequency,
    createInitialStateTotalValue
  }
}
