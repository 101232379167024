import { useState, useEffect, useCallback } from 'react'
import { DashboardInfoResume } from './DashboardInfoResume'
import { Col, Row } from 'react-bootstrap'
import { TableCard } from '../../components/organisms/TableCard'
import WelcomeCardToCreateCompany from '../../components/organisms/WelcomeCardToCreateCompany'
import * as S from './styles'
import { PendenciesCard } from '../../components/organisms/PendenciesCard'
import { getDashboard } from '../../domain/usecases/Dashboard'
import { getCompany } from '../../domain/usecases/Company'
import { IGetSpes as getSpes } from '../../domain/usecases/SPE'
import { getProject } from '../../domain/usecases/Project'
import { getUnits } from '../../domain/usecases/Unit'
import {
  Contract,
  ContractStatuses,
  LastTenContracts
} from '../../domain/models/ContractsModel'
import { IndividualPersonModel } from '../../domain/models/IndividualPersonModel'
import { JuridicPersonModel } from '../../domain/models/JuridicPersonModel'
import { Pending } from '../../domain/models/DashboardModel'
import Information from '../../components/atoms/Information'
import { convertNumberToCurrencyFormat } from '../../utils/convertNumberToCurrencyFormat'

interface DashboardProps {
  remoteGetDashboard: getDashboard
  remoteGetCompanies: getCompany
  remoteGetSpes: getSpes
  remoteGetProjects: getProject
  remoteGetUnits: getUnits
}

export interface IDashboardFirstSteps {
  companies: number
  spes: number
  projects: number
  units: number
}

const Dashboard = ({
  remoteGetDashboard,
  remoteGetCompanies,
  remoteGetSpes,
  remoteGetProjects,
  remoteGetUnits
}: DashboardProps) => {
  const disabled = true

  const [dashboardFirstSteps, setDashboardFirstSteps] =
    useState<IDashboardFirstSteps>({} as IDashboardFirstSteps)
  const [firstStepsCompleted, setFirstStepsCompleted] = useState(false)
  const [lastTenContracts, setLastTenContracts] = useState<LastTenContracts[]>(
    []
  )
  const [pendencies, setPendencies] = useState<Pending | null>(null)

  const getData = useCallback(async () => {
    const companies = await remoteGetCompanies.getAll()
    const spes = await remoteGetSpes.getAll()
    const projects = await remoteGetProjects.getProjects()
    const units = await remoteGetUnits.getUnits()
    setDashboardFirstSteps({
      companies: companies.length ? 1 : 0,
      spes: spes.length ? 1 : 0,
      projects: projects.length ? 1 : 0,
      units: units.length ? 1 : 0
    })
    const dashboard = await remoteGetDashboard.getIndex()
    setLastTenContracts(dashboard.last_ten_contracts)
    setPendencies(dashboard.pending)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    setFirstStepsCompleted(
      Object.values(dashboardFirstSteps).every((value) => value > 0)
    )
  }, [dashboardFirstSteps])

  const translateContractStatus = (status: ContractStatuses) => {
    switch (status) {
      case ContractStatuses.REGISTERED:
        return 'Registrado'
      case ContractStatuses.ERROR:
        return 'Inválido'
      case ContractStatuses.PASSWORD_PENDING:
        return 'Aguardando liberação de processamento'
      case ContractStatuses.PROCESSING:
        return 'Processando'
      case ContractStatuses.SIGNATURES_PENDING:
        return 'Assinatura(s) Pendente(s)'
    }
  }

  return (
    <S.Container disabled={disabled}>
      <Row>
        <Col>
          {!firstStepsCompleted && (
            <WelcomeCardToCreateCompany data={dashboardFirstSteps} />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <DashboardInfoResume
            disabled={disabled}
            remoteGetDashboard={remoteGetDashboard}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TableCard
            data={{
              titleHeader: 'Últimos negócios',
              thead: (
                <thead>
                  <tr>
                    <th>DATA</th>
                    <th>ID</th>
                    <th>TIPO</th>
                    <th>PARTES</th>
                    <th>VALOR (R$)</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
              ),
              infoMsg: 'Seus últimos Contratos serão exibidos aqui.',
              tbody: (
                <tbody>
                  {!lastTenContracts.length ? (
                    <tr>
                      <td colSpan={6}>
                        <Information style={{ margin: '0' }} />
                      </td>
                    </tr>
                  ) : (
                    lastTenContracts.map((contract) => (
                      <tr key={contract.id}>
                        <td>{contract.created_at}</td>
                        <td>{contract.id}</td>
                        <td>Compra e Venda</td>
                        <td>{contract.buyers_name}</td>
                        <td>
                          {convertNumberToCurrencyFormat(
                            Number(contract.value)
                          )}
                        </td>
                        <td>{translateContractStatus(contract.status)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              )
            }}
          />
          {pendencies && <PendenciesCard pendencies={pendencies} />}
        </Col>
      </Row>
    </S.Container>
  )
}
export default Dashboard
