import { useState, useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Button, Col } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import { EntityDetails } from '../../../components/organisms/EntityDetails'
import { DetailsHeader } from '../../../components/templates/DetailsHeader'
import { TextWithHint } from '../../../components/atoms/TextWithHint'
import { SeparateBetween } from '../../../components/templates/SeparateBetween'
import { IconButton } from '../../../components/atoms/IconButton'
import { DetailButtonsRow } from '../../../components/molecules/DetailButtonsRow'
import { UsersTableCard } from './UsersTableCard'
import { ProxiesTableCard } from './ProxiesTableCard'
import { SpesTableCard } from './SpesTableCard'
import {
  getCompanyDetails,
  deleteCompany
} from '../../../domain/usecases/Company'
import { deleteCompanyUser } from '../../../domain/usecases/CompanyUser'
import { CompanyType } from '../../../domain/models/CompanyModel'
import { CompanyUser } from '../../../domain/models/CompanyUserModel'
import { Proxy } from '../../../domain/models/ProxyModel'
import { IDeleteSpe } from '../../../domain/usecases/SPE'
import { SpeType } from '../../../domain/models/SpeModel'

import formatStringToCNPJ from '../../../utils/formatStringToCNPJ'
import { DeleteModal } from '../../../components/molecules/DeleteModal'

type stateType = {
  id: string
}

type CompanyDetailsProps = {
  remoteGetCompanies: getCompanyDetails
  remoteDeleteCompany: deleteCompany
  remoteDeleteCompanyUser: deleteCompanyUser
  handleDeleteSpe: IDeleteSpe
}

export const CompanyDetails = ({
  remoteGetCompanies,
  remoteDeleteCompany,
  remoteDeleteCompanyUser,
  handleDeleteSpe
}: CompanyDetailsProps) => {
  const { addToast } = useToasts()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState<CompanyType>()
  const [companyUser, setCompanyUser] = useState<CompanyUser[]>([])
  const [proxies, setProxies] = useState<Proxy[]>([])
  const [spe, setSpe] = useState<SpeType[]>([])
  const { state } = useLocation<stateType>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    loadCompany()
  }, [])

  const loadCompany = useCallback(async () => {
    try {
      setLoading(true)
      const companyResponse = await remoteGetCompanies.getById({ id: state.id })
      setCompany(companyResponse)
      setCompanyUser(companyResponse.company_users)
      setProxies(companyResponse.proxies)
      setSpe(companyResponse.spes)
      setLoading(false)
    } catch (error) {
      addToast('Não foi possível carregar o Empreendimento.', {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      setLoading(false)
    }
  }, [state])

  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const handleOpenDeleteModal = () => setShowDeleteModal(true)

  const deleteCompanyFunction = async (idCompany: string) => {
    try {
      await remoteDeleteCompany.deleteById({ id: idCompany })

      history.push('/companies')
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      handleCloseDeleteModal()
    }
  }

  return (
    <>
      {company && (
        <EntityDetails
          title="Incorporadora"
          sections={
            <>
              <DeleteModal
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleOk={() => deleteCompanyFunction(company.id)}
              />
              <DetailsHeader
                image={
                  company.photo ||
                  'https://service.proptoken.com.br/image-thumb.png'
                }
                fields={
                  <>
                    <Col md={6}>
                      <TextWithHint
                        text={company.business_name}
                        hint="Razão Social"
                      />
                    </Col>
                    <Col md={6}>
                      <TextWithHint
                        text={formatStringToCNPJ(company.cnpj)}
                        hint="CNPJ"
                      />
                    </Col>
                  </>
                }
                rightButtons={
                  <>
                    <SeparateBetween>
                      <Button
                        onClick={() =>
                          history.push('/spes/create', {
                            companyId: company.id
                          })
                        }
                        variant="primary"
                      >
                        Adicionar SPE
                      </Button>
                    </SeparateBetween>
                  </>
                }
                buttonsRow={
                  <DetailButtonsRow
                    buttons={
                      <>
                        <IconButton
                          onClick={handleOpenDeleteModal}
                          icon="icon-trash"
                          color="#E62355"
                        />
                        <IconButton
                          onClick={() =>
                            history.push('/companies/create', { company })
                          }
                          icon="icon-edit"
                          color="#5E6EA7"
                        />
                      </>
                    }
                  />
                }
              />
            </>
          }
          content={
            <>
              <UsersTableCard users={companyUser} />
              <ProxiesTableCard proxies={proxies} />
              <SpesTableCard handleDeleteSpe={handleDeleteSpe} spes={spe} />
            </>
          }
        />
      )}
    </>
  )
}
