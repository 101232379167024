import * as React from 'react'
import { useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateContractsContext } from '../index'
import PaymentDetailsForm from './paymentDetailsForm'
import { convertNumberToCurrencyFormat } from '../../../../utils/convertNumberToCurrencyFormat'
import { useContextPage } from '../contextPage'
import { SelectField } from 'components/atoms/SelectField'
import { Contract } from '../../../../domain/usecases/Contracts'
import { AppearFromRight } from '../../../../components/templates/ApperFromLeft'
import { MultiSelectField } from 'components/atoms/MultiSelectField'
import formatStringToCNPJ from '../../../../utils/formatStringToCNPJ'
import { Person } from '../../../../domain/models/PersonsModel'
import { yupResolver } from '@hookform/resolvers/yup'
import step5_schema from './step5.schema'
import { TextInput } from 'components/atoms/TextInput'
import { ContractPaymentPart } from 'domain/models/PaymentPartsModel'
import { useToast } from '../../../../hooks/useToast'

export type ICreateContractsFormStep5 = Pick<
  Contract.CreateParams,
  'payment_parts' | 'discount_rate'
>
export type ICreateContractBillingPersonId = Pick<
  Contract.CreateParams,
  'billing_person_id'
>

export type errorType = Record<string, boolean>

type SelectOptions = { label: string; value: string }[]

export default function Step5({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { state, setState } = useContextPage()
  const { showToast } = useToast()
  const [paymentMethods, setPaymentMethods] = React.useState<string[]>(
    state.paymentMethods || []
  )
  const [error, setError] = React.useState<errorType>({})
  const [billingPersons, setBillingPersons] = React.useState<SelectOptions>([])
  const { data, setData } = useContext(CreateContractsContext)

  const valueTraded = convertNumberToCurrencyFormat(data?.value as number)
  const unitValue = convertNumberToCurrencyFormat(
    Number(state.unitSelected?.value)
  )

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ICreateContractsFormStep5 & ICreateContractBillingPersonId>({
    resolver: yupResolver(step5_schema),
    defaultValues: {
      ...data
    }
  })

  const validatePaymentsParts = (paymentPart: ICreateContractsFormStep5) => {
    const hasError: errorType = {}
    const chosenPayments = paymentPart.payment_parts.filter((payment) =>
      paymentMethods.includes(payment.name)
    )

    const validate = (
      chosenPayments: ICreateContractsFormStep5[keyof Omit<
        ICreateContractsFormStep5,
        'discount_rate'
      >],
      hasError: errorType
    ) => {
      chosenPayments.forEach((paymentPartItem) => {
        const unfilledFields = Object.values(paymentPartItem).filter(
          (value) => !!value === false
        )
        !!unfilledFields.length && (hasError[paymentPartItem.name] = true)
      })
    }

    validate(chosenPayments, hasError)
    return hasError
  }

  const formatCpf = (cpf: string) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  React.useEffect(() => {
    setBillingPersons(state.persons?.map(convertPersonToSelectOptions) || [])
  }, [state])

  const convertPersonToSelectOptions = (person: Person) => ({
    label: createPersonLabel(person),
    value: person.id
  })

  const createPersonLabel = (person: Person): string =>
    isIndividual(person) ? individualLabel(person) : juridicalLabel(person)

  const isIndividual = (person: Person) => person.info.cpf

  const individualLabel = (person: Person): string =>
    `${person.info.name} - ${formatCpf(person.info.cpf)}`

  const juridicalLabel = (person: Person): string =>
    `${person.info.business_name} - ${formatStringToCNPJ(person.info.cnpj)}`

  const onClickNext = (formData: ICreateContractsFormStep5) => {
    if (paymentPartsTotalNotEqualToNegotiatedValue(formData.payment_parts))
      return showToast(
        'O Valor Total de todos os componentes do Pagamento deve ser igual ao Valor Negociado (A taxa de enxoval não esta inclusa no cálculo)',
        'error'
      )
    const errors = validatePaymentsParts(formData)
    if (Object.keys(errors).length) return setError(errors)
    setError({})
    setData!({
      ...data,
      ...formData,
      discount_rate: Number(formData.discount_rate) || 0
    })
    setState({ ...state, paymentMethods })
    nextHandler()
  }

  const paymentPartsTotalNotEqualToNegotiatedValue = (
    payment_parts: ContractPaymentPart[]
  ) =>
    payment_parts.reduce(
      (acc, part) =>
        part.name != 'Taxa de Enxoval' ? acc + part.total_value : acc, // Taxa de Enxolval não entra no preço
      0
    ) !== Number(data?.value)

  const getDefaultValueToPaymentParts = (paymentName: string) => {
    let defaultValuePaymentPart: ContractPaymentPart | undefined

    if (data?.payment_parts) {
      defaultValuePaymentPart = data?.payment_parts.find(
        (payment) => payment.name === paymentName
      )
    }
    return defaultValuePaymentPart
  }

  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        <Row className="mt-4">
          <Col md={4}>
            <Controller
              control={control}
              name="billing_person_id"
              render={({ field: { onChange, value } }) => (
                <SelectField
                  data-testid="billing_person_id"
                  type="text"
                  emptyOptionText={'Selecione'}
                  label="Responsável Financeiro"
                  onChange={onChange}
                  value={value}
                  options={billingPersons}
                  defaultValue={data?.billing_person_id || ''}
                  errorMessage={errors.billing_person_id?.message}
                />
              )}
            />
          </Col>
          <Col md={5}>
            <Controller
              control={control}
              name="discount_rate"
              render={({ field: { onChange, value } }) => {
                return (
                  <TextInput
                    type="number"
                    min={0}
                    step={0.1}
                    label="Taxa de desconto para antecipação de valores (%)"
                    onChange={(event) => onChange(event.target.value)}
                    errorMessage={errors.discount_rate?.message}
                    value={value}
                    defaultValue={data?.discount_rate}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <div className="d-flex flex-column">
              <span>Valor do Imóvel (R$)</span>
              <strong>{unitValue}</strong>
            </div>
          </Col>
          <Col md={6} className="mt-2">
            <div className="d-flex flex-column">
              <span>Valor Negociado (R$)</span>
              <strong>{valueTraded}</strong>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <MultiSelectField
              options={[
                { label: 'Sinal', value: 'Sinal' },
                { label: 'Entrada', value: 'Entrada' },
                {
                  label: 'Parcelas',
                  value: 'Parcelas'
                },
                {
                  label: 'Financiamento Bancário',
                  value: 'Financiamento Bancário'
                },
                { label: 'Parcelas Mensais', value: 'Parcelas Mensais' },
                {
                  label: 'Parcelas Intermediárias',
                  value: 'Parcelas Intermediárias'
                },
                {
                  label: 'Parcelas Trimestrais',
                  value: 'Parcelas Trimestrais'
                },
                {
                  label: 'Parcelas Quinquemestrais',
                  value: 'Parcelas Quinquemestrais'
                },
                {
                  label: 'Parcelas Semestrais',
                  value: 'Parcelas Semestrais'
                },
                {
                  label: 'Parcelas Anuais',
                  value: 'Parcelas Anuais'
                },
                { label: 'Pré-Chaves', value: 'Pré-Chaves' },
                { label: 'Entrega das Chaves', value: 'Entrega das Chaves' },
                { label: 'Taxa de Enxoval', value: 'Taxa de Enxoval' }
              ]}
              onChange={(options) => setPaymentMethods(options as string[])}
              defaultValue={paymentMethods.map((payment) => ({
                label: payment,
                value: payment
              }))}
              emptyOptionText="Selecione os componentes de pagamento do seu Contrato"
            />
          </Col>
        </Row>
        {paymentMethods.map((paymentMethod: string, index) => {
          const fieldName = `payment_parts[${index}]`
          return (
            <>
              <AppearFromRight key={index}>
                <PaymentDetailsForm
                  defaultValue={getDefaultValueToPaymentParts(paymentMethod)}
                  setValue={setValue}
                  fieldName={fieldName}
                  key={index}
                  index={index}
                  control={control}
                  errors={error}
                  paymentMethod={paymentMethod}
                />
              </AppearFromRight>
            </>
          )
        })}
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button
                variant="primary"
                disabled={!paymentMethods.length}
                onClick={handleSubmit(onClickNext)}
              >
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
