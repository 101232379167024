import VerificationInput from 'react-verification-input'

export type IVerificationCodeInput = {
  autoFocus?: boolean
  handleChange: (value: string) => void
}

const VerificationCodeInput = ({
  autoFocus,
  handleChange
}: IVerificationCodeInput) => {
  return (
    <>
      <VerificationInput
        autoFocus={autoFocus}
        removeDefaultStyles
        classNames={{
          container: 'code-container',
          character: 'code-character',
          characterInactive: 'code-character--inactive',
          characterSelected: 'code-character--selected'
        }}
        onChange={(value) => handleChange(value)}
        validChars="0-9"
        length={4}
        placeholder=""
      />
    </>
  )
}
export default VerificationCodeInput
