import { useToasts, AppearanceTypes } from 'react-toast-notifications'

export const useToast = () => {
  const { addToast } = useToasts()

  const showToast = (MsgToast: string, status: AppearanceTypes) =>
    addToast(MsgToast, {
      appearance: status,
      autoDismiss: true
    })

  return { showToast }
}
