import api from '../../services/api'
import { ICreatePromissoryBuyerForm } from '../../pages/Contracts/CreateContracts/ModalFormToCreatePromissoryUser'
import { AppContext } from '../../app'
import { useContext } from 'react'
import { Proxy } from 'domain/models/ProxyModel'
interface CreateIndividualPersonDto {
  name: string
  cpf: string
  nationality: string // Nacionalidade
  birth_country: string // Naturalidade
  birth_date: string // Data de Nascimento
  occupation: string // Profissão
  marital_status: string // Estado Civil
  goods_regime?: string // Comunhão de bens
  identity: string // Identidade
  identity_issuer: string // Emissor
}

interface CreateJuridicPersonDto {
  business_name: string
  cnpj: string
}

interface CreateAddressDto {
  zip_code: string
  address: string
  number: string
  complement?: string
  district: string
  city: string
  state: string
  country: string
}

interface CreateContactDto {
  email: string
  home_phone?: string
  mobile_phone?: string
}

interface PersonDto {
  type: 'PF' | 'PJ'
  company_id: string
  person: CreateIndividualPersonDto | CreateJuridicPersonDto
  address: CreateAddressDto
  secondary_address?: CreateAddressDto
  contact_info: CreateContactDto
  secondary_contact_info?: CreateContactDto
  proxies: Proxy[]
}

export default class PersonService {
  static async create(
    personDto: ICreatePromissoryBuyerForm,
    token?: string
  ): Promise<void> {
    try {
      return await api.post('/persons', personDto, {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  static async update(personDto: ICreatePromissoryBuyerForm, token?: string) {
    try {
      return await api.patch(`/persons/${personDto.person?.id}`, personDto, {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
}
