import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { Card } from 'react-bootstrap'
import logo from '../../assets/images/logo.svg'
import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import SignatureStep1 from './SignatureStep1'
import SignatureStep2 from './SignatureStep2'
import SignatureOk from './SignatureOk'
import SignatureError from './SignatureError'
import SignatureAlreadySigned from './SignatureAlreadySigned'
import {
  getSignature,
  shouldGenerateCodes,
  isSigned
} from '../../services/contract-signatures'
import { useParams } from 'react-router-dom'

export interface IParam {
  id: string
}

export interface IContract {
  id: string
  url: string
  company_id: string
  company: any
  spe_id: string
  spe: any
  project_id: string
  project: any
  unit_id: string
  unit: any
  buyers: any
  value: number
  payment_parts: any
  creator_id: string
  type: string
  is_tokenized: boolean
  status: string
  creator: any
  created_at: Date
}

export interface IContractSignature {
  id: string
  contract_id: string
  contract: IContract
  status: string
  side: string
  name: string
  cpf: string
  email: string
  mobile_phone: string
  sms_code: number
  email_code: number
  codes_expiration: Date
  signed_at: Date
  ip_address: string
  signature_location: string
  created_at: Date
}

const SignatureFlow = () => {
  const [step, setStep] = useState<string>('1')
  const [headerTitle, setHeaderTitle] = useState<string>('')

  const [contractSignature, setContractSignature] = useState<
    IContractSignature | undefined
  >()
  const { id } = useParams<IParam>()

  document.title = `Recebível Chipado - ${headerTitle}`

  function handleStepChange(nextStep: string) {
    setStep(nextStep)
  }

  useEffect(() => {
    const checkHeaderTitle = () => {
      switch (step) {
        case '1':
          setHeaderTitle('Assinar Contrato')
          break

        case '2':
          setHeaderTitle('Assinar Contrato')
          break

        case 'ok':
          setHeaderTitle('Contrato Assinado')
          break

        case 'error':
          setHeaderTitle('Contrato Não Assinado')
          break

        case 'already-signed':
          setHeaderTitle('Contrato Assinado')
          break

        default:
          setHeaderTitle('Contrato')
      }
    }
    checkHeaderTitle()
  }, [step])

  useEffect(() => {
    getSignature(id).then((results) => {
      setContractSignature(results)
    })
    isSigned(id).then(({ is_signed }) => {
      if (is_signed) return setStep('already-signed')
    })
    shouldGenerateCodes(id).then(({ should_generate }) => {
      if (!should_generate) return setStep('2')
    })
  }, [])

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Card style={{ padding: 0 }}>
          <S.Wrapper>
            <S.HeaderWrapper>
              <div>
                <S.h4>{headerTitle}</S.h4>
                {contractSignature && (
                  <S.h5>
                    {contractSignature.contract?.project.name.toUpperCase()}
                    <br />
                    UNIDADE {contractSignature.contract?.unit.name}{' '}
                    {contractSignature.contract?.unit.building.name.toUpperCase()}
                    <br />
                    {contractSignature.contract?.id}
                  </S.h5>
                )}
              </div>

              <div>
                <S.Img src={logo} alt="Recebível Chipado" className="logo" />
              </div>
            </S.HeaderWrapper>
            {step === '1' && contractSignature && (
              <SignatureStep1
                signatureData={contractSignature}
                handleStepChange={handleStepChange}
                updateSignatureData={setContractSignature}
              />
            )}
            {step === '2' && contractSignature && (
              <SignatureStep2
                signatureData={contractSignature}
                handleStepChange={handleStepChange}
              />
            )}
            {step === 'ok' && (
              <SignatureOk contractUrl={contractSignature?.contract.url} />
            )}
            {step === 'error' && <SignatureError />}
            {step === 'already-signed' && (
              <SignatureAlreadySigned signedAt={contractSignature?.signed_at} />
            )}
          </S.Wrapper>
        </Card>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default SignatureFlow
