import { HttpClient } from '../../protocols/http/httpClient'
import { Company, deleteCompany } from '../../../domain/usecases/Company'

export class RemoteDeleteCompany implements deleteCompany {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async deleteById(params: Company.DeleteParams): Promise<void> {
    const httpResponse = await this.httpClient.delete?.({
      url: `${this.url}/companies/${params.id}`
    })

    if (httpResponse?.statusCode === 400) {
      throw new Error(httpResponse.message)
    } else if (httpResponse?.statusCode && httpResponse?.statusCode != 200) {
      throw new Error('Não foi possível excluir a Incorporadora.')
    }
  }
}
