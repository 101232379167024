import { CustomModal } from '../CustomModal'

export interface DeleteModalProps {
  show: boolean
  handleClose?: () => void
  handleOk?: () => void
}

export const DeleteModal = ({
  show,
  handleClose,
  handleOk
}: DeleteModalProps) => {
  return (
    <>
      <CustomModal
        show={show}
        title="Excluir registro"
        body="Deseja realmente excluir?"
        closeButtonText="Não"
        handleClose={handleClose}
        okButtonText="Sim, excluir"
        handleOk={handleOk}
      />
    </>
  )
}
