import { useContext } from 'react'
import { AxiosHttpClient } from '../../../../infra/http/axiosHttpClient'
import { URL_API } from '../../../../services/api_url'
import CreateProject from '../../../../pages/Projects/CreateProject'
import { AppContext } from '../../../../app'
import CreateProjectActionsContext from '../../../../pages/Projects/CreateProject/actions'
import { RemoteCreateProject } from '../../../../data/usecases/Project/RemoteCreateProject'
import { RemoteGetSPE } from '../../../../data/usecases/SPE/RemoteGetSPE'
import { ProjectType } from '../../../../domain/models/ProjectModel'
import { useLocation } from 'react-router'

type ParamsState = {
  projectParams: ProjectType
  speId: string
}

export const CreateProjectFactory = () => {
  const { user } = useContext(AppContext)
  const URL = URL_API || 'without-url'
  const TOKEN_USER = user?.token || 'whithout-token'
  const axiosHttpClient = new AxiosHttpClient(TOKEN_USER)
  const remoteProject = new RemoteCreateProject(URL, axiosHttpClient)
  const remoteGetSpes = new RemoteGetSPE(URL, axiosHttpClient)
  const { state } = useLocation<ParamsState>()

  return (
    <CreateProjectActionsContext
      remoteProject={remoteProject}
      remoteGetSpes={remoteGetSpes}
    >
      <CreateProject
        hasProject={state?.projectParams}
        hasSpeId={state?.speId}
      />
    </CreateProjectActionsContext>
  )
}
