import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateSpeContext } from '../index'
import { useForm } from 'react-hook-form'
import { Button, Col, Row } from 'react-bootstrap'
import step3_schema from './step3.schema'
import ProxiesSelector from '../../../../components/organisms/ProxiesSelector'
import { Proxy } from 'domain/models/ProxyModel'
import { validateEmail } from 'utils/validateEmail'
import { useToasts } from 'react-toast-notifications'

export interface ICreateSpeFormStep3 {
  proxies: Proxy[]
}

export default function Step3({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateSpeContext)
  const { addToast } = useToasts()
  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ICreateSpeFormStep3>({
    resolver: yupResolver(step3_schema),
    defaultValues: {
      proxies: data?.proxies
    }
  })
  const location = useLocation()

  const onClickNext = (formData: ICreateSpeFormStep3) => {
    setData?.({ ...data, ...formData })
    nextHandler()
  }

  const proxies = watch('proxies')

  const onFinish = () => {
    const hasErrors = !!proxies?.filter(
      (proxy) => !proxy?.cpf || !proxy?.name || !validateEmail(proxy?.email)
    ).length
    if (!hasErrors) {
      handleSubmit(onClickNext)()
    } else {
      return addToast(
        'Por favor, verifique se todos os campos foram preenchidos corretamente.',
        { appearance: 'error', autoDismiss: true }
      )
    }
  }

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col md={12}>
          <ProxiesSelector
            errors={errors}
            onChange={(new_proxies) => setValue('proxies', new_proxies)}
            defaultProxies={proxies}
            hintText="Digite os CPFs de todos os Procuradores da SPE."
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperSpaced>
            <Button variant="link" onClick={backHandler}>
              Voltar
            </Button>
            <Button variant="primary" onClick={onFinish}>
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperSpaced>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
