import { Card, Button, Col, Row, ProgressBar } from 'react-bootstrap'

import * as S from './styles'
import { Title } from '../../../components/atoms/Title'
import { useHistory } from 'react-router-dom'

export default function WelcomeCardInvestor() {
  const history = useHistory()
  return (
    <S.Container>
      <Row>
        <Col>
          <Card style={{ height: 'auto', marginBottom: '0' }}>
            <Card.Body>
              <Title>Olá! Que bom que você está com a gente! 😉</Title>
              <br />
              <p>
                Aqui você encontra uma maneira inteligente de gerir seus
                Negócios, efetuar saques e depósitos, além de acompanhar todas
                as suas movimentações de Compra e Venda de Tokens.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </S.Container>
  )
}
