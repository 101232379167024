import { HttpClient } from '../../protocols/http/httpClient'
import { ICreateSpe, SPE } from '../../../domain/usecases/SPE'

export class RemoteCreateSPE implements ICreateSpe {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async createOne(params: SPE.CreateParams): Promise<any> {
    const { proxies, commercial_board_document, ...properties } =
      this.formatDataToBackend(params)

    const formData = new FormData()

    for (let i = 0; i < proxies.length; i++) {
      formData.append(`proxies[${i}][name]`, proxies[i].name)
      formData.append(`proxies[${i}][email]`, proxies[i].email)
      formData.append(`proxies[${i}][cpf]`, proxies[i].cpf)
    }

    const props: Record<string, any> = properties
    for (const key in props) {
      if (typeof props[key] === 'object') {
        for (const subKey in props[key]) {
          if (typeof props[key][subKey] === 'undefined') {
            formData.append(`${key}[${subKey}]`, '')
          } else {
            formData.append(
              `${key}[${subKey}]`,
              subKey === 'fine' || subKey === 'interest'
                ? props[key][subKey].replace(/,/g, '.')
                : props[key][subKey]
            )
          }
        }
      } else {
        formData.append(key, props[key])
      }
    }
    formData.append('commercial_board_document', commercial_board_document)
    const response = await this.httpClient.post?.({
      url: `${this.url}/spes`,
      data: formData,
      headers: {
        Accept: '*/*'
      }
    })
    return response
  }

  async uploadSpePhoto(params: SPE.UploadSpePhoto): Promise<any> {
    const httpResponse = await this.httpClient.post?.({
      url: `${this.url}/spes/${params.id}/upload-photo`,
      data: { base64: params.base64 }
    })

    if (httpResponse?.statusCode && httpResponse?.statusCode !== 201)
      throw new Error('error on upload SPE photo')
    return httpResponse
  }

  private formatDataToBackend(params: SPE.CreateParams) {
    return {
      cnpj: params.cnpj,
      company_id: params.companyId,
      business_name: params.business_name,
      address: {
        zip_code: params.zip_code,
        address: params.address,
        number: params.number,
        complement: params.complement,
        district: params.district,
        city: params.city,
        state: params.state,
        country: params.country
      },
      nire: params.nire,
      commercial_board_name: params.commercial_board_name,
      commercial_board_identifier: params.commercial_board_identifier,
      commercial_board_document: params.commercial_board_document_url,
      proxies: params.proxies,
      bank_account: {
        type: params.bank_account_type,
        bank: params.bank,
        branch_number: params.branch,
        branch_digit: params.branch_digit,
        account_number: params.account,
        account_digit: params.account_digit
      },
      bank_billet_preference: {
        fine: params.fine,
        interest: params.interest,
        interest_type: params.interest_type
      }
    }
  }
}
