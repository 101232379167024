import { URL_API } from '../../../../services/api_url'
import UnitDetails from '../../../../pages/Units/UnitDetails'
import { RemoteGetUnits } from '../../../../data/usecases/Unit/RemoteGetUnits'
import { RemoteDeleteUnit } from '../../../../data/usecases/Unit/RemoteDeleteUnit'
import { useParams } from 'react-router'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'

type ParamsState = {
  id: string
}

export const UnitDetailsFactory = () => {
  const URL = URL_API || 'without-url'

  const { id } = useParams<ParamsState>()

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetUnits = new RemoteGetUnits(URL, axiosHttpClient)
  const remoteDeleteUnit = new RemoteDeleteUnit(URL, axiosHttpClient)

  return (
    <UnitDetails
      remoteDeleteUnit={remoteDeleteUnit}
      remoteGetUnits={remoteGetUnits}
      id={id}
    />
  )
}
