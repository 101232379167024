import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ isTitle }: { isTitle: boolean }) => css`
    margin-bottom: 30px;
    p {
      font-size: ${isTitle ? '21px' : '14px'};
      font-weight: 400;
      line-height: ${isTitle ? '26px' : '19px'};
      color: ${isTitle ? '#504F4A' : '#373a3c'};
      margin: 0;
      padding: 0;
    }

    span {
      margin: 0;
      padding: 0;
      color: #868e96;
      font-size: 9px;
      font-weight: 400;
    }
  `}
`
