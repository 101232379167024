import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { RemoteGetContracts } from '../../../../data/usecases/Contracts/RemoteGetContracts'
import { useLocation } from 'react-router-dom'
import { RemoteGetContract } from '../../../../data/usecases/Contracts/RemoteGetContract'
import { ContractDetails } from '../../../../pages/Contracts/ContractDetails'

export const ContractDetailsFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetContract = new RemoteGetContract(URL, axiosHttpClient)

  document.title = 'Recebível Chipado - Detalhes do Contrato'

  return <ContractDetails remoteGetContract={remoteGetContract} />
}
