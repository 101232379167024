import styled from 'styled-components'

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  h5 {
    font-weight: 600;
    font-size: 0.875rem;
    color: #37474f;
  }

  span {
    color: #5286a4;
    font-weight: bold;
    font-size: 22px;
  }
`
