import { Table } from 'react-bootstrap'
import * as React from 'react'

export const BankBilletPending = ({
  bankBilletUrl
}: {
  bankBilletUrl: string
}) => {
  return (
    <>
      <h5>Aguardando Pagamento</h5>
      <br />
      <p>
        Só falta um passo para você registrar o seu Contrato na Rede Ethereum.
        <br />
        <br />
        Após o pagamento do boleto ser compensado o seu Contrato será registrado
        automaticamente e você receberá um E-mail de confirmação com os dados da
        transação.
      </p>
      <Table responsive borderless>
        <thead>
          <tr>
            <th>
              <a href={bankBilletUrl} target="_blank" rel="noreferrer">
                Abrir boleto
              </a>
            </th>
          </tr>
        </thead>
      </Table>
    </>
  )
}
