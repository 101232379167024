import * as React from 'react'

import { Card } from 'react-bootstrap'

import logo from '../../assets/images/logo.svg'
import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './resetPassword.schema'
import { resetPassword } from '../../services/auth'
import { useToasts } from 'react-toast-notifications'
import * as S from '../SignUp/styles'
import { IResetPasswordForm } from './resetPassword-form.interface'
import { PasswordField } from '../../components/atoms/PasswordField'
import { useState } from 'react'
import { LoadingIndicator } from '../../components/atoms/LoadingIndicator'
import { RouteComponentProps } from 'react-router'
import { IHistoryPush } from '../../interfaces/router'

interface IMatchParams extends RouteComponentProps {
  params: {
    hash: string
  }
}

const ResetPassword = ({
  history,
  match
}: {
  history: IHistoryPush
  match: IMatchParams
}) => {
  document.title = 'Recebível Chipado - Redefinir Senha'

  const [loading, setLoading] = useState(false)

  const { addToast } = useToasts()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IResetPasswordForm>({
    resolver: yupResolver(schema)
  })

  const hash = match.params.hash

  const onSend: SubmitHandler<IResetPasswordForm> = async (data) => {
    try {
      setLoading(true)
      await resetPassword({
        ...data,
        hash
      })
      history.push('/login')
      setLoading(false)
      addToast(
        'Sua Senha foi redefinida com sucesso! Agora você já pode acessar o Recebível Chipado.',
        {
          appearance: 'success',
          autoDismiss: true
        }
      )
    } catch (e) {
      setLoading(false)
      if (e.response.status === 400) {
        addToast('Link de Recuperação de Senha inválido ou expirado.', {
          appearance: 'error',
          autoDismiss: true
        })
      } else {
        addToast('Ocorreu um erro desconhecido.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(onSend)}>
          <Card>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Card.Body>
                  <img src={logo} alt="" className="logo" />
                  <h5 className="f-w-700">
                    Preencha os campos com a Senha que deseja definir para sua
                    Conta.
                  </h5>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PasswordField
                        disabled={loading}
                        onChange={onChange}
                        value={value}
                        errorMessage={errors.password?.message}
                      />
                    )}
                  />

                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <PasswordField
                        disabled={loading}
                        onChange={onChange}
                        value={value}
                        label="Confirmação de Senha"
                        errorMessage={errors.password_confirmation?.message}
                        placeholder="Repita a Senha"
                      />
                    )}
                    name="password_confirmation"
                    control={control}
                  />

                  <S.ButtonsWrapper>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {!loading ? 'Continuar' : <LoadingIndicator />}
                      </button>
                    </div>
                  </S.ButtonsWrapper>
                </Card.Body>
              </div>
            </div>
          </Card>
        </form>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default ResetPassword
