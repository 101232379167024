import api from './api'

interface ProcessContractData {
  password: string
  user_id: string
}

export async function processContract(
  contract_id: string,
  data: ProcessContractData
) {
  const response = await api.post(`/contracts/${contract_id}/process`, data)
  return response.data
}
