import styled from 'styled-components'

export const LabelUnits = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 15px;
    font-weight: 500;
  }
  .label {
    font-size: 10px;
  }
`
