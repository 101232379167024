import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ disabled }: { disabled?: boolean }) => css`
    display: flex;

    align-items: center;
    i {
      font-size: 24px;
      color: ${disabled ? '#AFB5BD' : '#8b9ed1'};
      margin-right: 15px;
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }
  `}
`
