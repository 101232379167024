import { Card, Col, Row } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import FormWizard, { Step } from '../../../components/organisms/FormWizard'
import Step1, { ICreateCompanyFormStep1 } from './Step1/step1'
import React, { useState } from 'react'
import Step2, { CompanyUser, ICreateCompanyFormStep2 } from './Step2/step2'
import Step4 from './Step4/step4'
import { Title } from '../../../components/atoms/Title'
import { SetableContext } from '../../../interfaces/setable-context'
import {
  CompanyReturnedFromCreation,
  CompanyType
} from '../../../domain/models/CompanyModel'
import { createCompany, editCompany } from '../../../domain/usecases/Company'
import {
  ICreateCompanyUser,
  IUpdatedCompanyUser,
  deleteCompanyUser
} from '../../../domain/usecases/CompanyUser'
import Step3, { ICreateCompanyFormStep3 } from './Step3/step3'

export type ICreateCompanyForm = Partial<
  ICreateCompanyFormStep1 & ICreateCompanyFormStep2 & ICreateCompanyFormStep3
>

export const CreateCompanyContext = React.createContext<
  SetableContext<ICreateCompanyForm>
>({})

type stateRouteType = {
  company: CompanyType
}

type Props = {
  handleCreateCompany: createCompany
  handleUpdateCompany: editCompany
  handleCreateCompanyUser: ICreateCompanyUser
  handleUpdateCompanyUser: IUpdatedCompanyUser
  handleDeleteCompanyUser: deleteCompanyUser
}

export default function CreateCompany({
  handleCreateCompany,
  handleUpdateCompany,
  handleCreateCompanyUser,
  handleUpdateCompanyUser,
  handleDeleteCompanyUser
}: Props) {
  const { addToast } = useToasts()
  const history = useHistory()
  const { state } = useLocation<stateRouteType>()
  const [errorMessage, setErrorMessage] = React.useState('')
  const company = state?.company
    ? {
        id: state.company.id,
        cnpj: state.company.cnpj,
        business_name: state.company.business_name,
        users: state.company.company_users.map((user) => ({
          id: user.id,
          invite_name: user.invite_name,
          invite_email: user.invite_email,
          role: user.role
        })),
        proxies: state.company.proxies
      }
    : {}

  const [data, setData] = useState<ICreateCompanyForm>(company)

  const steps: Step[] = [
    {
      id: 1,
      label: 'Dados da Incorporadora',
      component: Step1
    },
    {
      id: 2,
      label: 'Adicionar Usuários',
      component: (formWizardHandlers) =>
        Step2({ formWizardHandlers, handleDeleteCompanyUser })
    },
    {
      id: 3,
      label: 'Procuradores',
      component: Step3
    },
    {
      id: 4,
      label: 'Final',
      component: Step4,
      disableContinueLater: true
    }
  ]
  const handleSave = () => {
    sessionStorage.setItem('CreateCompanyForm', JSON.stringify(data))
  }

  const isUpdatePage = Object.keys(company).length

  const onFinish = React.useCallback(async () => {
    try {
      const company = await createOrUpdateCompany()
      return await createOrUpdateCompanyUsers(company)
    } catch {
      history.push('/companies')
      setErrorMessage('Não foi possível cadastrar/editar a Incorporadora.')
    }
  }, [data])

  const createOrUpdateCompany = async () => {
    return isUpdatePage ? updateCompany() : createCompany()
  }

  const updateCompany = () => {
    return handleUpdateCompany.editById(
      data as Required<ICreateCompanyForm & { id: string }>
    )
  }

  const createCompany = () => {
    return handleCreateCompany.createOne(data as Required<ICreateCompanyForm>)
  }

  const createOrUpdateCompanyUsers = async (
    company: CompanyReturnedFromCreation
  ) => {
    for (const user of data.users || []) {
      if (!user.id) {
        createCompanyUser(company, user)
        break
      }
      updateCompanyUser(company, user)
    }
  }

  const createCompanyUser = async (
    company: CompanyReturnedFromCreation,
    user: CompanyUser
  ) => {
    return handleCreateCompanyUser.create({
      invite_email: user.invite_email,
      invite_name: user.invite_name,
      company_id: company.id,
      role: user.role
    })
  }

  const updateCompanyUser = async (
    company: CompanyReturnedFromCreation,
    user: CompanyUser
  ) => {
    return handleUpdateCompanyUser.update({
      id: user.id,
      invite_email: user.invite_email,
      invite_name: user.invite_name,
      company_id: company.id,
      role: user.role
    })
  }

  React.useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }, [errorMessage])

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Title>{isUpdatePage ? 'Editar' : 'Cadastrar'} Incorporadora</Title>
          </Card.Header>
          <Card.Body>
            <CreateCompanyContext.Provider value={{ data, setData }}>
              <FormWizard
                canClickOnSteps={!!isUpdatePage}
                onSaveDraft={handleSave}
                steps={steps}
                finishOnStep={4}
                onFinish={onFinish}
              />
            </CreateCompanyContext.Provider>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
