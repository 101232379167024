import * as S from './styles'
import { ItemValidStatus } from '../../atoms/ItemValidStatus'

interface PasswordValidatorProps {
  password: string
}

export const PasswordValidator = ({ password }: PasswordValidatorProps) => {
  const eightCharsIsValid = password?.length >= 8
  const uppercaseIsValid = !!password?.match(/[A-Z]/)
  const lowerCaseIsValid = !!password?.match(/[a-z]/)
  const numberIsValid = !!password?.match(/\d/)
  const specialCharIsValid = !!password?.match(/[!@#$&*]/)

  return (
    <S.Wrapper>
      <div className="row">
        <div className="col-md-4">
          <ItemValidStatus text="8 Caracteres" isValid={eightCharsIsValid} />
        </div>
        <div className="col-md-4">
          <ItemValidStatus
            text="1 Letra Maiúscula"
            isValid={uppercaseIsValid}
          />
        </div>
        <div className="col-md-4">
          <ItemValidStatus
            text="1 Letra Minúscula"
            isValid={lowerCaseIsValid}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <ItemValidStatus text="1 Número" isValid={numberIsValid} />
        </div>
        <div className="col-md-4">
          <ItemValidStatus
            text="1 Caracter Especial"
            isValid={specialCharIsValid}
          />
        </div>
      </div>
    </S.Wrapper>
  )
}
