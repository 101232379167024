import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { EntityCardProps } from '../../components/organisms/EntityCard'
import { convertContractsToEntity } from '../../domain/converters/Contracts'
import { getContracts } from '../../domain/usecases/Contracts'
import { filtersFactory } from '../../main/factories/filters.factory'
import { FilterItem } from '../../components/organisms/EntityFilter'
import { getEntitiesCount } from '../../domain/usecases/Reports'

export type ContractListContextProviderProps = {
  children: ReactNode
  remoteGetContracts: getContracts
  remoteGetEntitiesCount: getEntitiesCount
}
export type ContractListContextProviderValues = {
  contracts: EntityCardProps[]
  errorMessage: string
  loading: boolean
  filters: FilterItem[]
}

export const ContractListContext = createContext(
  {} as ContractListContextProviderValues
)

export const ContractListContextProvider = ({
  children,
  remoteGetContracts,
  remoteGetEntitiesCount
}: ContractListContextProviderProps) => {
  const [loading, setIsLoading] = useState(false)
  const [contracts, setContracts] = useState<EntityCardProps[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [filters, setFilters] = useState<FilterItem[]>([])

  const history = useHistory()

  const { addToast } = useToasts()

  useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }, [errorMessage])

  useEffect(() => {
    requestContracts()
  }, [])

  const requestContracts = async () => {
    setIsLoading(true)
    try {
      const contracts = await remoteGetContracts.getAll()
      setContracts(
        convertContractsToEntity(contracts, handleRedirectToContractDetails)
      )
    } catch (error) {
      setErrorMessage('Não foi possível carregar os Contratos.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleRedirectToContractDetails = (id: string) => {
    history.push(`/contracts/${id}`, { id })
  }

  React.useEffect(() => {
    requestContracts()
    remoteGetEntitiesCount
      .getEntitiesCount()
      .then((entitiesCount) =>
        setFilters(filtersFactory(entitiesCount, history))
      )
  }, [])

  return (
    <ContractListContext.Provider
      value={{ contracts, errorMessage, loading, filters }}
    >
      {children}
    </ContractListContext.Provider>
  )
}

export const useContractList = () => {
  return useContext(ContractListContext)
}
