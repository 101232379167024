import { Card, Col, Row } from 'react-bootstrap'
import * as React from 'react'
import { CurrencyInput } from '../../atoms/CurrencyInput'

export const WithdrawCard = () => {
  return (
    <Card>
      <Card.Header>
        <h5>Sacar (R$)</h5>
        <small className="d-block">
          Utilize essa area para efetuar saques em reais.
        </small>
      </Card.Header>
      <Card.Body>
        <h5 className="text-primary">Valor do saque</h5>
        <Row>
          <Col md={6}>
            <CurrencyInput />
          </Col>
          <Col md={6}>
            <button className="btn btn-primary disabled">Sacar agora</button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
