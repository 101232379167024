interface ProxiesTableRowProps {
  name: string
  cpf: string
  email: string
}

export const ProxiesTableRow = ({ name, cpf, email }: ProxiesTableRowProps) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{cpf}</td>
      <td>{email}</td>
    </tr>
  )
}
