import * as React from 'react'
import { useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import step1_schema from './step1.schema'
import step1_juridical_schema from './step1_juridical.schema'
import * as FormWizardStyles from '../../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../../components/organisms/FormWizard'
import { CreatePromissoryBuyerContext } from '../index'
import JuridicalStep1 from './juridicalStep1'
import IndividualStep1 from './individualStep1'
import { useContextPage } from '../../contextPage'
import {
  ContactPerson,
  InfoPerson,
  AddressPerson
} from '../../../../../domain/models/PersonsModel'

export interface SelectedPerson {
  cpf: string
  cnpj: string
  name: string
  business_name: string
}

type PersonPromissory = InfoPerson & { selected: Partial<SelectedPerson> }

export interface ICreatePromissoryBuyerFormStep1 {
  person: Partial<PersonPromissory>
  address: Partial<AddressPerson>
  contact_info: Partial<ContactPerson>
  secondary_contact_info: Partial<ContactPerson>
  secondary_address: Partial<AddressPerson>
  type: 'PJ' | 'PF'
  selectedCompanyId: string
}

export default function Step1({ nextHandler }: FormWizardHandlers) {
  const { state } = useContextPage()
  const isJuridical = () => {
    return state?.selectedPerson?.info?.cnpj
  }
  const { data, setData } = useContext(CreatePromissoryBuyerContext)
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<Partial<ICreatePromissoryBuyerFormStep1>>({
    resolver: yupResolver(
      isJuridical() ? step1_juridical_schema : step1_schema
    ),
    defaultValues: {
      person: {
        nationality: 'Brasil',
        occupation: 'Administrador'
      }
    }
  })

  const onClickNext = (formData: ICreatePromissoryBuyerFormStep1) => {
    // const occupation: formData.person.occupation !== 'Brasil' ? ''
    setData!({ ...data, ...formData })
    nextHandler()
  }

  React.useEffect(() => {
    if (!state.selectedPerson?.info) return
    setSelectedPersonStates()
  }, [state])

  const setSelectedPersonStates = () => {
    setPersonStates()
    setAddressStates()
    setSecondaryAddressStates()
    setContactInfoStates()
    setSecondaryContactInfoStates()
    setTypeState()
  }

  const setPersonStates = () => {
    setValue('person', {
      ...state?.selectedPerson?.info,
      birth_date: convertToLocaleDateString(
        state.selectedPerson!.info!.birth_date
      ),
      identity_issuer_date: convertToLocaleDateString(
        state.selectedPerson!.info!.identity_issuer_date
      ),
      nationality: state.selectedPerson!.info!.nationality || 'Brasil',
      occupation: state.selectedPerson!.info!.occupation || 'Administrador'
    })
  }

  const convertToLocaleDateString = (date: string) => {
    console.log('date', date)
    return date ? new Date(date).toLocaleDateString('pt-BR') : ''
  }

  const setAddressStates = () => {
    setValue('address', {
      ...state?.selectedPerson?.address,
      address: state?.selectedPerson?.address?.address,
      allowToRegisterBusinessAddress: !!state?.selectedPerson?.secondary_address
    })
  }

  const setSecondaryAddressStates = () => {
    setValue('secondary_address', {
      zip_code_business: state?.selectedPerson?.secondary_address?.zip_code,
      address_business: state?.selectedPerson?.secondary_address?.address,
      number_business: state?.selectedPerson?.secondary_address?.number,
      complement_business: state?.selectedPerson?.secondary_address?.complement,
      district_business: state?.selectedPerson?.secondary_address?.district,
      city_business: state?.selectedPerson?.secondary_address?.city,
      state_business: state?.selectedPerson?.secondary_address?.state,
      country_business: state?.selectedPerson?.secondary_address?.country
    })
  }

  const setContactInfoStates = () => {
    setValue('contact_info', {
      ...state?.selectedPerson?.contact_info
    })
  }

  const setSecondaryContactInfoStates = () => {
    setValue('secondary_contact_info', {
      email_second: state?.selectedPerson?.secondary_contact_info?.email,
      homePhone_second:
        state?.selectedPerson?.secondary_contact_info?.home_phone,
      fone_second: state?.selectedPerson?.secondary_contact_info?.mobile_phone
    })
  }
  const setTypeState = () => {
    setValue('type', isJuridical() ? 'PJ' : 'PF')
  }

  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        {isJuridical() ? (
          <JuridicalStep1 control={control} errors={errors} />
        ) : (
          <IndividualStep1
            control={control}
            errors={errors}
            setValue={setValue}
          />
        )}
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperRight>
              <Button
                variant="primary"
                data-testid="button_next"
                onClick={handleSubmit(onClickNext as any)}
              >
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperRight>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
