import * as S from '../styles'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

const PaymentAnalysis = () => {
  return (
    <S.IconWrapper>
      <WarningAmberIcon
        style={{ color: '#EED202', fontSize: 96, marginBottom: 10 }}
      />
      <S.IconText>
        <b>Estamos analisando o pagamento.</b> <br />
        Você receberá a atualização de status por E-mail.
      </S.IconText>
    </S.IconWrapper>
  )
}
export default PaymentAnalysis
