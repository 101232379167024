import { Card, Col, Row } from 'react-bootstrap'
import * as React from 'react'
import { CurrencyInput } from '../../atoms/CurrencyInput'

export const DepositCard = () => {
  return (
    <Card>
      <Card.Header>
        <h5>Depositar (R$)</h5>
        <small className="d-block">
          Utilize essa area para efetuar depósitos para a compra de tokens.
        </small>
      </Card.Header>
      <Card.Body>
        <h5 className="text-primary">Valor do depósito</h5>
        <Row>
          <Col md={6}>
            <CurrencyInput />
          </Col>
          <Col md={6}>
            <button className="btn btn-primary disabled">
              Depositar agora
            </button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
