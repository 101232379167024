import * as S from './styles'
import { Title } from '../../atoms/Title'
import React from 'react'

interface TitleWithButtonsProps {
  title: string
  buttons: React.ReactNode
}

export const TitleWithButtons = ({ title, buttons }: TitleWithButtonsProps) => {
  return (
    <S.Wrapper>
      <Title>{title}</Title>
      <div>{buttons}</div>
    </S.Wrapper>
  )
}
