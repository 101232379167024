import * as S from './style'

interface ItemValidStatusProps {
  text: string
  isValid: boolean
}

export const ItemValidStatus = ({ text, isValid }: ItemValidStatusProps) => {
  return (
    <S.Wrapper isValid={isValid}>
      <i className="feather icon-check" />
      <span>{text}</span>
    </S.Wrapper>
  )
}
