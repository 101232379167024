import React, { useContext, useState } from 'react'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import * as S from '../styles'
import { LoadingIndicator } from '../../../components/atoms/LoadingIndicator'
import { IPaymentStep2Form } from './payment-step2-form.interface'
import schema from './payment-step2.schema'
import { Checkbox } from '../../../components/atoms/Checkbox'
import { PaymentContext } from '../index'
import { CheckoutDetails } from '../../../domain/models/BillingModel'
import { PaymentMainInfo } from '../payment-main-info'

export type PaymentStep2Props = {
  checkoutDetails: CheckoutDetails
  onFinish: () => void
}

const PaymentStepReview = ({
  onFinish,
  checkoutDetails
}: PaymentStep2Props) => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState<boolean>(false)

  const { paymentData } = useContext(PaymentContext)

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IPaymentStep2Form>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<IPaymentStep2Form> = async (data) => {
    try {
      setLoading(true)
      onFinish()
    } catch (e) {
      setLoading(false)
      addToast('Algo deu errado, tente novamente.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <S.ContentWrapper>
      <PaymentMainInfo checkoutDetails={checkoutDetails} />
      <S.MainInfo>
        <S.p>
          <b>Forma de Pagamento</b>
          <br />
          Cartão de Crédito com final{' '}
          {paymentData?.credit_card?.number.substr(
            paymentData?.credit_card?.number.length - 4
          )}
        </S.p>
      </S.MainInfo>

      {/* <S.MainInfo>
        <S.p>
          <b>Informação Adicional</b>
          <br />
          Urna tempor nulla cursus nibh. Eu diam in donec leo adipiscing nisi,
          integer integer. Diam non consectetur turpis morbi dictum faucibus mi,
          egestas mauris. Posuere morbi.
        </S.p>
      </S.MainInfo>

      <S.MainInfo>
        <S.p>
          <b>Informação Adicional</b>
          <br />
          Tempus faucibus egestas. Nec nisl lectus eget suspendisse.
        </S.p>
      </S.MainInfo> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="agree"
          render={({ field: { onChange } }) => (
            <Checkbox
              onChange={(value) => onChange(value)}
              label={<p>Declaro que as informações acima estão corretas.</p>}
              errorMessage={errors.agree?.message}
            />
          )}
        />
        <button
          className="btn btn-primary pull-left mt-4 mb-4"
          style={{ width: '100%' }}
          type="submit"
          disabled={loading}
        >
          {!loading ? 'Pagar' : <LoadingIndicator />}
        </button>
      </form>
      <S.Tip>
        Confira as informações acima antes de finalizar o pagamento.
      </S.Tip>
    </S.ContentWrapper>
  )
}
export default PaymentStepReview
