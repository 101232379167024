import { Button, Card, Col, Row } from 'react-bootstrap'
import creditCardType from 'credit-card-type'
import { CreditCardInfo } from '../index'
import { Controller, useForm } from 'react-hook-form'
import { TextInput } from '../../../../components/atoms/TextInput'
import * as React from 'react'
import { SelectField } from '../../../../components/atoms/SelectField'
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './credit-card.schema'
import { TextInputWithMask } from '../../../../components/atoms/TextInputWithMask'
import { LoadingIndicator } from '../../../../components/atoms/LoadingIndicator'
import getCreditCardBrandFromNumber from '../../../../utils/getCreditCardBrandFromNumber'
import { useToasts } from 'react-toast-notifications'

interface CreditCardFormProps {
  onFinish: (data: CreditCardInfo) => Promise<void>
  setMenuVisible: (visible: boolean) => void
}

interface CreditCardData {
  birth: string
  brand: string
  card_number: string
  cvv: string
  expiration_month: string
  expiration_year: string
  zipcode: string
  street: string
  number: string
  neighborhood: string
  city: string
  state: string
}

export const CreditCardForm = ({
  onFinish,
  setMenuVisible
}: CreditCardFormProps) => {
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm<CreditCardData>({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  const getPaymentTokenCallback = async (error: any, response: any) => {
    if (error) {
      setLoading(false)
      // Trata o erro ocorrido
      console.error(error)

      const { error: errorMessage } = error

      if (errorMessage == 'invalid_card_number') {
        setError('card_number', {
          type: 'manual',
          message: 'Número do cartão inválido'
        })
      }
    } else {
      const {
        data: { payment_token }
      } = response

      const data = getValues()

      setMenuVisible(true)
      setLoading(false)

      await onFinish({
        installments: 1,
        payment_token,
        birth: data.birth,
        billing_address: {
          zipcode: data.zipcode.replace(/\D/g, ''),
          city: data.city,
          street: data.street,
          neighborhood: data.neighborhood,
          state: data.state,
          number: data.number
        }
      })
    }
  }

  const onContinue = (data: CreditCardData) => {
    setMenuVisible(false)
    setLoading(true)

    try {
      const brand = getCreditCardBrandFromNumber(data.card_number)

      // @ts-ignore
      $gn.checkout.getPaymentToken(
        {
          brand, // bandeira do cartão
          number: data.card_number, // número do cartão
          cvv: data.cvv, // código de segurança
          expiration_month: data.expiration_month, // mês de vencimento
          expiration_year: data.expiration_year // ano de vencimento
        },
        getPaymentTokenCallback
      )
    } catch (e) {
      addToast(
        'Ocorreu um erro. Por favor, entre em contato com o administrador do sistema.',
        { appearance: 'error', autoDismiss: true }
      )
    }
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.innerHTML =
      "var s=document.createElement('script');s.type='text/javascript';var v=parseInt(Math.random()*1000000);s.src='https://sandbox.gerencianet.com.br/v1/cdn/70597e49ae4786b62882fef31399a7d5/'+v;s.async=false;s.id='70597e49ae4786b62882fef31399a7d5';if(!document.getElementById('70597e49ae4786b62882fef31399a7d5')){document.getElementsByTagName('head')[0].appendChild(s);};$gn={validForm:true,processed:false,done:{},ready:function(fn){$gn.done=fn;}};"
    document.body.appendChild(script)

    // @ts-ignore
    $gn.ready(function (checkout) {
      setMenuVisible(true)
      setReady(true)
    })

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <Card.Body className="card-small-padding">
        {ready ? (
          <>
            <form onSubmit={handleSubmit(onContinue)}>
              <h4>Dados do dono do cartão</h4>
              <Row>
                <Col>
                  <Controller
                    name="birth"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInputWithMask
                        mask="99/99/9999"
                        label="Data de Nascimento"
                        errorMessage={errors?.birth?.message}
                        value={value}
                        onChange={onChange}
                        disabled={loading}
                      />
                    )}
                  />
                </Col>
              </Row>
              <h4>Dados do cartão</h4>
              <Row>
                <Col md={12}>
                  <Controller
                    name="card_number"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        label="Número do Cartão"
                        errorMessage={errors?.card_number?.message}
                        value={value}
                        onChange={onChange}
                        disabled={loading}
                      />
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    name="expiration_month"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectField
                        label="Mês de Expiração"
                        errorMessage={errors?.expiration_month?.message}
                        onChange={onChange}
                        value={value}
                        emptyOptionText={'-- Selecione --'}
                        disabled={loading}
                        options={[
                          { label: '1', value: '01' },
                          { label: '2', value: '02' },
                          { label: '3', value: '03' },
                          { label: '4', value: '04' },
                          { label: '5', value: '05' },
                          { label: '6', value: '06' },
                          { label: '7', value: '07' },
                          { label: '8', value: '08' },
                          { label: '9', value: '09' },
                          { label: '10', value: '10' },
                          { label: '11', value: '11' },
                          { label: '12', value: '12' }
                        ]}
                      />
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    name="expiration_year"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectField
                        label="Ano de Expiração"
                        onChange={onChange}
                        value={value}
                        emptyOptionText={'-- Selecione --'}
                        disabled={loading}
                        errorMessage={errors?.expiration_year?.message}
                        options={[
                          { label: '2021', value: '2021' },
                          { label: '2022', value: '2022' },
                          { label: '2023', value: '2023' },
                          { label: '2024', value: '2024' },
                          { label: '2025', value: '2025' },
                          { label: '2026', value: '2026' },
                          { label: '2027', value: '2027' },
                          { label: '2028', value: '2028' },
                          { label: '2029', value: '2029' },
                          { label: '2030', value: '2030' }
                        ]}
                      />
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    name="cvv"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        label="Código de segurança"
                        errorMessage={errors?.cvv?.message}
                        value={value}
                        placeholder="CVV"
                        disabled={loading}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>
              <br />
              <h4>Endereço do cartão de crédito</h4>
              <Row>
                <Col md={4}>
                  <Controller
                    name="zipcode"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInputWithMask
                        mask="99999-999"
                        disabled={loading}
                        label="CEP"
                        errorMessage={errors?.zipcode?.message}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Controller
                    name="street"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        disabled={loading}
                        label="Endereço"
                        errorMessage={errors?.street?.message}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Controller
                    name="number"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        disabled={loading}
                        label="Número"
                        errorMessage={errors?.number?.message}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Controller
                    name="neighborhood"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        disabled={loading}
                        label="Bairro"
                        errorMessage={errors?.neighborhood?.message}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        label="Cidade"
                        disabled={loading}
                        errorMessage={errors?.city?.message}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectField
                        label="Estado"
                        disabled={loading}
                        errorMessage={errors?.state?.message}
                        onChange={onChange}
                        value={value}
                        emptyOptionText={'-- Selecione --'}
                        options={[
                          { label: 'AC', value: 'AC' },
                          { label: 'AL', value: 'AL' },
                          { label: 'AP', value: 'AP' },
                          { label: 'AM', value: 'AM' },
                          { label: 'BA', value: 'BA' },
                          { label: 'CE', value: 'CE' },
                          { label: 'DF', value: 'DF' },
                          { label: 'ES', value: 'ES' },
                          { label: 'GO', value: 'GO' },
                          { label: 'MA', value: 'MA' },
                          { label: 'MT', value: 'MT' },
                          { label: 'MS', value: 'MS' },
                          { label: 'MG', value: 'MG' },
                          { label: 'PA', value: 'PA' },
                          { label: 'PB', value: 'PB' },
                          { label: 'PR', value: 'PR' },
                          { label: 'PE', value: 'PE' },
                          { label: 'PI', value: 'PI' },
                          { label: 'RJ', value: 'RJ' },
                          { label: 'RN', value: 'RN' },
                          { label: 'RS', value: 'RS' },
                          { label: 'RO', value: 'RO' },
                          { label: 'RR', value: 'RR' },
                          { label: 'SC', value: 'SC' },
                          { label: 'SP', value: 'SP' },
                          { label: 'SE', value: 'SE' },
                          { label: 'TO', value: 'TO' }
                        ]}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-block"
                  >
                    {!loading ? 'Pagar' : <LoadingIndicator />}
                  </Button>
                </Col>
              </Row>
            </form>
          </>
        ) : (
          <p>
            <LoadingIndicator />
          </p>
        )}
      </Card.Body>
    </>
  )
}
