import { getProject, Project } from '../../../domain/usecases/Project'
import {
  MinimalProject,
  ProjectType
} from '../../../domain/models/ProjectModel'
import { HttpClient } from '../../protocols/http/httpClient'

export class RemoteGetProject implements getProject {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async getProjects(): Promise<ProjectType[] | []> {
    const httpResponse = await this.httpClient.get?.({
      url: `${this.url}/projects`
    })

    if (httpResponse.error) throw new Error(`Error on get projects`)
    return httpResponse
  }

  async getMinimalProjects(): Promise<MinimalProject[] | []> {
    const httpResponse = await this.httpClient.get?.({
      url: `${this.url}/projects?scope=minimal`
    })

    if (httpResponse.error) return []
    return httpResponse
  }

  async getProjectById(params: Project.GetByIdParams): Promise<ProjectType> {
    const httpResponse = await this.httpClient.get?.({
      url: `${this.url}/projects/${params.id}`
    })

    if (httpResponse.error)
      throw new Error(`Error on get project:  ${httpResponse.error}`)

    return httpResponse
  }
}
