import { useEffect, useState } from 'react'

export const useGerencianetTokenGenerator = () => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.innerHTML =
      "var s=document.createElement('script');s.type='text/javascript';var v=parseInt(Math.random()*1000000);s.src='https://sandbox.gerencianet.com.br/v1/cdn/70597e49ae4786b62882fef31399a7d5/'+v;s.async=false;s.id='70597e49ae4786b62882fef31399a7d5';if(!document.getElementById('70597e49ae4786b62882fef31399a7d5')){document.getElementsByTagName('head')[0].appendChild(s);};$gn={validForm:true,processed:false,done:{},ready:function(fn){$gn.done=fn;}};"
    document.body.appendChild(script)

    // @ts-ignore
    $gn.ready(function (checkout) {
      setReady(true)
    })

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return { ready }
}
