import React from 'react'
import { EntityList } from '../../components/organisms/EntityList'
import { BlockchainTableCard } from '../../components/organisms/BlockchainTableCard'
import { getBlockchainTransaction } from '../../domain/usecases/Blockchain_Transaction'
import { Blockchain_Transaction } from '../../domain/models/Blockchain_Transaction'

type BlockchainProps = {
  remoteGetBlockchainTransactions: getBlockchainTransaction
}

type Blockchain = Blockchain_Transaction & {
  etherscan: string
  contract_id: string
  created_at: string
}

const Blockchain = ({ remoteGetBlockchainTransactions }: BlockchainProps) => {
  const [blockchains, setBlockChains] = React.useState<Blockchain[]>([])

  const getAllBlockchainTransactions = React.useCallback(async () => {
    const blockchain_transactions =
      await remoteGetBlockchainTransactions.getAll()
    const transactions = blockchain_transactions.map((bt) => ({
      ...bt,
      etherscan: `${process.env.REACT_APP_BLOCKCHAIN_EXPLORER_URL}/tx/${bt.transaction_hash}`,
      contract_id: bt.contract.id,
      created_at: new Date(bt.created_at).toLocaleString('pt-BR')
    }))
    setBlockChains(transactions)
  }, [])

  React.useEffect(() => {
    getAllBlockchainTransactions()
  }, [getAllBlockchainTransactions])

  return (
    <EntityList
      fullWidth={true}
      mainTitle="Blockchain"
      title="Transações"
      entityTable={<BlockchainTableCard values={blockchains} />}
    />
  )
}

export default Blockchain
