import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import * as React from 'react'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useIsLogged } from '../../../hooks/useIsLogged'
import { AppContext } from '../../../app'
import { HeaderCard } from '../HeaderCard'
import { getContractById } from '../../../services/simplified-smart-contracts'
import {
  getStatusBadge,
  SimplifiedContract
} from '../HistorySimplifiedSmartContract'

export const DetailSimplifiedSmartContract = ({ history, match }: any) => {
  const [contract, setContract] = useState<SimplifiedContract>()
  document.title = 'Recebível Chipado'

  const id = match.params.id

  const { user } = useContext(AppContext)

  useEffect(() => {
    getContractById(id, user!.token).then((data) => setContract(data))
  }, [])

  useIsLogged(history)

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col md={12}>
            <Card>
              <HeaderCard title="Detalhes do Registro na Rede Ethereum" />
              <Card.Body style={{ padding: '0px' }} className="mt-2">
                {contract && (
                  <Table striped responsive>
                    <tbody>
                      <tr>
                        <th>ID:</th>
                        <td>{contract.id}</td>
                      </tr>
                      <tr>
                        <th>Data / Hora:</th>
                        <td>
                          {moment(contract.created_at).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Tipo:</th>
                        <td>{contract.object}</td>
                      </tr>
                      <tr>
                        <th>Valor (R$):</th>
                        <td>{contract.value}</td>
                      </tr>
                      <tr>
                        <th>Responsável:</th>
                        <td>
                          {contract.name.toUpperCase()}
                          {' - '}
                          {contract.document_type === '1' && 'CPF - '}
                          {contract.document_type === '2' && 'CNPJ - '}
                          {contract.document}
                        </td>
                      </tr>
                      <tr>
                        <th>Hash do Contrato:</th>
                        <td>{contract.documentHash}</td>
                      </tr>
                      <tr>
                        <th>Hash da Transação:</th>
                        <td>{contract.transaction_hash}</td>
                      </tr>
                      <tr>
                        <th>Pagamento</th>
                        <td>
                          {getStatusBadge(contract.status)}{' '}
                          {contract.paid_at &&
                            moment(contract.paid_at).format('DD/MM/YYYY HH:mm')}
                        </td>
                      </tr>
                      <tr>
                        <th>Documento:</th>
                        <td>
                          <a
                            href={contract.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Visualizar
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th>Explorer:</th>
                        <td>
                          {contract.transaction_hash && (
                            <a
                              href={`${process.env.REACT_APP_BLOCKCHAIN_EXPLORER_URL}/tx/${contract.transaction_hash}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Consultar
                            </a>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
                <Row>
                  <Col>
                    {' '}
                    <Button
                      onClick={() => history.goBack()}
                      size="sm"
                      variant="outline-danger"
                    >
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
