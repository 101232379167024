import * as React from 'react'

export const LoadingIndicator = () => {
  return (
    <>
      <span className="spinner-border spinner-border-sm mr-1" role="status" />
      Carregando...
    </>
  )
}
