import * as S from '../styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import moment from 'moment'

export type PaymentAlreadyPayedProps = {
  payedAt: Date | undefined
}

const PaymentAlreadyPayed = ({ payedAt }: PaymentAlreadyPayedProps) => {
  const newDate = moment(payedAt).format('DD/MM/YYYY HH:mm:ss')
  return (
    <S.IconWrapper>
      <CheckCircleOutlineIcon
        style={{ color: '#95C567', fontSize: 96, marginBottom: 10 }}
      />
      <S.IconText>Pagamento realizado em: {newDate}</S.IconText>
    </S.IconWrapper>
  )
}
export default PaymentAlreadyPayed
