import React, { useState } from 'react'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './signature-step1.schema'
import { ISignatureStep1Form } from './signature-step1-form.interface'
import * as S from '../styles'
import { LoadingIndicator } from '../../../components/atoms/LoadingIndicator'
import { TextInputWithMask } from '../../../components/atoms/TextInputWithMask'
import { IContractSignature } from '../'
import { addPhone, generateCodes } from '../../../services/contract-signatures'

export type SignatureStep1Props = {
  signatureData: IContractSignature
  handleStepChange: (step: string) => void
  updateSignatureData: React.Dispatch<
    React.SetStateAction<IContractSignature | undefined>
  >
}

const SignatureStep1 = ({
  signatureData,
  handleStepChange,
  updateSignatureData
}: SignatureStep1Props) => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ISignatureStep1Form>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<ISignatureStep1Form> = async (data) => {
    try {
      setLoading(true)
      await addPhone(signatureData.id, data.mobile_phone)
      await generateCodes(signatureData.id)
      updateSignatureData({ ...signatureData, mobile_phone: data.mobile_phone })
      addToast(
        `Um código foi enviado por SMS para o número ${data.mobile_phone}.`,
        {
          appearance: 'success',
          autoDismiss: true
        }
      )
      setLoading(false)
      handleStepChange('2')
    } catch (e) {
      setLoading(false)
      addToast('Algo deu errado, tente novamente.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <S.ContentWrapper>
      <S.p>
        <b>Olá, {signatureData.name}</b>!
        <br />
        <br />
        Aqui você realiza o processo de assinatura eletrônica de forma bem
        simples!
        <br />
        Basta preencher o número do seu celular e solicitar os códigos de
        confirmação.
        <br />
        <br />
        Você receberá <b>1 código por SMS e 1 código por E-MAIL.</b>
      </S.p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="mobile_phone"
          render={({ field: { onChange, value } }) => (
            <TextInputWithMask
              value={value}
              disabled={loading}
              label="Celular"
              placeholder="(99) 9 9999-9999"
              mask="(99) 9 9999-9999"
              onChange={({ target: { value } }) => {
                onChange(value)
              }}
              errorMessage={errors.mobile_phone?.message}
            />
          )}
        />
        <button
          className="btn btn-primary pull-left mt-4 mb-4"
          style={{ width: '100%' }}
          type="submit"
          disabled={loading}
        >
          {!loading ? (
            'Solicitar Códigos de Confirmação (SMS e E-mail)'
          ) : (
            <LoadingIndicator />
          )}
        </button>
      </form>
    </S.ContentWrapper>
  )
}
export default SignatureStep1
