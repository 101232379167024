import * as React from 'react'
import { Form } from 'react-bootstrap'
import Select, { GroupTypeBase, OptionTypeBase } from 'react-select'
import ErrorText from '../ErrorText'
import { InputHTMLAttributes } from 'react'

export interface MultiSelectFieldOption {
  label: string
  value: string
}

type SelectFieldTypes = InputHTMLAttributes<HTMLSelectElement>

export type SelectFieldProps = {
  label?: string
  value?: string | number
  errorMessage?: string
  emptyOptionText?: string
  options: MultiSelectFieldOption[]
  defaultValue: MultiSelectFieldOption[]
  onChange: (options: string[]) => void
}

export const MultiSelectField = ({
  label,
  errorMessage,
  options,
  emptyOptionText,
  defaultValue,
  onChange
}: SelectFieldProps) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          <strong>{label}</strong>
        </Form.Label>
      )}
      <Select
        id="multi_select"
        defaultValue={defaultValue}
        onChange={(optionsSelected) =>
          onChange(
            optionsSelected.map(
              (option) => (option as MultiSelectFieldOption).value
            )
          )
        }
        isMulti
        options={options as unknown as GroupTypeBase<never>[]}
        className="basic-multi-select"
        placeholder={emptyOptionText}
      />
      {errorMessage && <ErrorText text={errorMessage} />}
    </Form.Group>
  )
}
