import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { RemoteBillings } from '../../../../data/usecases/Billing/RemoteBillings'
import PaymentFlow from '../../../../pages/PaymentFlow'

export const PayBillingFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteBillings = new RemoteBillings(URL, axiosHttpClient)

  return <PaymentFlow remoteBillings={remoteBillings} />
}
