import styled, { css } from 'styled-components'
import { IconButtonProps } from './index'

export const Wrapper = styled.button`
  ${({ color, disabled }: Partial<IconButtonProps>) => css`
    background: none;
    border: none;

    i {
      color: ${disabled ? 'grey' : color};
    }

    font-size: 18px;

    :hover {
      opacity: ${disabled ? 1 : 0.7};
    }
  `}
`
