import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import schema from './step3.schema'
import { CreateContractsContext } from '../index'
import { Button, Col, Row } from 'react-bootstrap'
import { useToast } from '../../../../hooks/useToast'
import {
  SelectField,
  SelectFieldOption
} from '../../../../components/atoms/SelectField'
import { useContextPage } from '../contextPage'

import { Contract } from '../../../../domain/usecases/Contracts'
import { ProjectType } from '../../../../domain/models/ProjectModel'
import { DateInput } from 'components/atoms/DateInput'
import moment from 'moment'

export type ICreateContractsFormStep3 = Pick<
  Contract.CreateParams,
  'project_id' | 'unit_id' | 'deadline_allows_modify_unit'
>

export const Step3 = ({ nextHandler, backHandler }: FormWizardHandlers) => {
  const { showToast } = useToast()
  const { data, setData } = useContext(CreateContractsContext)
  const { state, setState, getProjects } = useContextPage()

  const [projectsToSelectedField, setProjectsToSelectedField] = React.useState<
    SelectFieldOption[]
  >([])
  const [projects, setProjects] = React.useState<ProjectType[]>([])

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<ICreateContractsFormStep3>({
    resolver: yupResolver(schema),
    defaultValues: {
      deadline_allows_modify_unit: moment().toDate(),
      project_id: data?.project_id
    }
  })

  React.useEffect(() => {
    getProjectsList()
  }, [])

  const onClickNext = (formData: ICreateContractsFormStep3) => {
    setData?.({
      ...data,
      ...formData,
      unit_id: state?.unitSelected?.id
    })
    nextHandler()
  }

  const handleChange = async (id: string) => {
    const projectSelected = projects.find((project) => project.id === id)
    const unitsDeep = projectSelected
      ? projectSelected.buildings.map((building) =>
          building.units.map((unit) => ({
            ...unit,
            building
          }))
        )
      : []
    const units = unitsDeep.reduce(function (a, b) {
      return a.concat(b)
    }, [])
    setState?.({ ...state, units, projectsSelected: projectSelected })
  }

  const getProjectsList = async () => {
    try {
      const projectslist = await getProjects.getProjects()
      setProjects(projectslist)
      setProjectsToSelectedField(
        projectslist.map((project) => ({
          value: project.id,
          label: project.name
        }))
      )
    } catch (error) {
      showToast('Não foi possível carregar os Empreendimentos.', 'error')
    }
  }

  return (
    <>
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={6}>
            <Controller
              control={control}
              name="project_id"
              render={({ field: { onChange, value } }) => {
                return (
                  <SelectField
                    data-testid="select-project"
                    emptyOptionText={'Selecione'}
                    errorMessage={errors.project_id?.message}
                    defaultValue={data?.project_id || ''}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value)
                      handleChange(e.target.value)
                    }}
                    label="Selecione o Empreendimento"
                    options={projectsToSelectedField}
                  />
                )
              }}
            />
          </Col>
          <Col md={6}>
            <Controller
              control={control}
              name="deadline_allows_modify_unit"
              render={({ field: { onChange, value } }) => {
                const defaultDate = data?.deadline_allows_modify_unit
                  ? new Date(String(data?.deadline_allows_modify_unit))
                  : undefined
                return (
                  <DateInput
                    defaultValue={defaultDate}
                    label="Data limite para modificações aplicáveis à Unidade"
                    errorMessage={errors?.deadline_allows_modify_unit?.message}
                    onChange={onChange}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {state?.unitSelected && (
              <span style={{ fontWeight: 500 }}>
                Unidade selecionada: {''}
                <strong>{state.unitSelected?.name ?? ''}</strong>
              </span>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button
                variant="primary"
                disabled={!state?.unitSelected}
                onClick={handleSubmit(onClickNext)}
              >
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </>
  )
}
export default Step3
