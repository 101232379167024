import { Card, Col, Row } from 'react-bootstrap'
import * as React from 'react'
import { useContext, useState } from 'react'
import { sendSimplifiedContract } from '../../../services/simplified-smart-contracts'
import { useToasts } from 'react-toast-notifications'
import { useIsLogged } from '../../../hooks/useIsLogged'
import { AppContext } from '../../../app'
import { IHistoryPush } from '../../../interfaces/router'
import { Party } from '../../../components/organisms/SimplifiedContractParties'
import { HeaderCard } from '../HeaderCard'
import { SuccessCard } from './parts/success-card'
import { LoadingCard } from './parts/loading-card'
import { Form } from './parts/form'
import { CreditCardForm } from './parts/credit-card-form'

export interface CreditCardInfo {
  birth: string
  installments: number
  payment_token: string
  billing_address: {
    street: string
    number: string
    neighborhood: string
    zipcode: string
    city: string
    state: string
  }
}

export interface CreateSimplifiedSmartContract {
  object: string
  value: string
  document: string
  document_type: string
  name: string
  file_type: string
  file_size: number
  terms_accepted: boolean
  file?: File
  parties?: Party[]
  payment_type: string
  credit_card_info?: CreditCardInfo
}

export const CreateSimplifiedSmartContract = ({
  history
}: {
  history: IHistoryPush
}) => {
  document.title = 'Recebível Chipado'
  const { addToast } = useToasts()

  type Steps = 'form' | 'loading' | 'credit_card_form' | 'success'

  const [step, setStep] = useState<Steps>('form')

  const [file, setFile] = useState<File>()
  const [paymentType, setPaymentType] = useState('bank_billet')
  const [bankBilletUrl, setBankBilletUrl] = useState('')

  const [firstStepData, setFirstStepData] =
    useState<CreateSimplifiedSmartContract>()

  useIsLogged(history)

  const { user, menuVisible, setMenuVisible } = useContext(AppContext)

  const send = async (data: CreateSimplifiedSmartContract) => {
    try {
      setMenuVisible(false)
      setStep('loading')
      //eslint-disable-next-line
      const res = await sendSimplifiedContract(user!.token, {
        ...data,
        payment_type: paymentType,
        file
      })

      if (paymentType === 'bank_billet') {
        setBankBilletUrl(res.data.bank_billet_url!)
      }

      setMenuVisible(true)
      setStep('success')
    } catch (e) {
      setMenuVisible(true)
      setStep('form')
      addToast('Ocorreu um erro desconhecido.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const onContinue = async (data: CreateSimplifiedSmartContract) => {
    setFirstStepData(data)
    setPaymentType(data.payment_type)

    if (data.payment_type === 'credit_card') {
      setMenuVisible(false)
      setStep('credit_card_form')
    } else {
      await send(data)
    }
  }

  const onFinishCreditCard = async (credit_card_info: CreditCardInfo) => {
    if (!firstStepData) {
      return addToast('Ocorreu um erro desconhecido.', {
        appearance: 'error',
        autoDismiss: true
      })
    }

    await send({
      ...firstStepData,
      credit_card_info
    })
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col md={12}>
            <Card>
              <HeaderCard
                title="Registro de Contrato na rede Ethereum"
                showMenu={menuVisible}
              />
              {step === 'credit_card_form' && (
                <CreditCardForm
                  onFinish={onFinishCreditCard}
                  setMenuVisible={setMenuVisible}
                />
              )}
              {step === 'success' && (
                <SuccessCard
                  payment_type={paymentType}
                  bankBilletUrl={bankBilletUrl}
                />
              )}
              {step === 'loading' && <LoadingCard />}
              {step === 'form' && (
                <Form onContinue={onContinue} setFile={setFile} file={file} />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
