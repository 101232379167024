const Logo = () => {
  return (
    <svg
      width="181"
      height="65"
      viewBox="0 0 223 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-[3.8rem]"
    >
      <g clipPath="url(#clip0_101_1067)" id={'myClip'}>
        <path
          d="M29.1668 17.7186L29.6567 17.2287C35.3898 11.4957 44.6804 11.4957 50.4135 17.2287L51.6441 18.4593C52.1981 19.0134 53.1021 19.0134 53.6562 18.4593L60.7889 11.3266C61.343 10.7725 61.343 9.86852 60.7889 9.31446L59.5583 8.08387C48.7746 -2.69983 31.2956 -2.69983 20.5119 8.08387L20.022 8.57377C19.4679 9.12783 19.4679 10.0318 20.022 10.5859L27.1547 17.7186C27.7088 18.2727 28.6128 18.2727 29.1668 17.7186Z"
          fill="#4759FF"
        />
        <path
          d="M62.7833 29.605C68.5163 35.338 68.5163 44.6287 62.7833 50.3617L50.3199 62.8251C44.6452 68.4998 35.4187 68.4998 29.744 62.8251L17.4614 50.5425C14.8602 47.9414 13.1631 44.5121 12.959 40.8436C12.7198 36.6445 14.2479 32.6494 17.1931 29.7042L17.7705 29.1209C18.3246 28.5669 18.3246 27.6629 17.7705 27.1088L10.6377 19.9761C10.0837 19.422 9.1797 19.422 8.62565 19.9761L8.04826 20.5535C2.76431 25.8374 -0.105127 32.9002 -0.000147741 40.3887C0.104831 47.7023 3.27754 54.6426 8.45068 59.8157L20.5933 71.9583C31.307 82.672 48.7394 82.672 59.4589 71.9583L71.9223 59.4949C82.706 48.7112 82.706 31.2322 71.9223 20.4485C71.5024 20.0286 70.8317 20.0286 70.4118 20.4485L62.7774 28.0828C62.3575 28.5027 62.3575 29.1734 62.7774 29.5934L62.7833 29.605Z"
          fill="#4759FF"
        />
        <path
          d="M48.7859 35.612C47.8935 33.8973 46.488 32.4859 44.7675 31.5936C44.2776 31.3312 43.7644 31.1212 43.2336 30.9579C42.3355 30.6721 41.3848 30.5205 40.3934 30.5205C39.4019 30.5205 38.4571 30.6721 37.5531 30.9579C37.0224 31.1212 36.5091 31.337 36.0192 31.5936C34.3046 32.4801 32.899 33.8915 32.0009 35.612C31.7384 36.1019 31.5284 36.6151 31.3651 37.1459C31.0794 38.044 30.9277 38.9947 30.9277 39.9861C30.9277 40.9776 31.0794 41.9282 31.3651 42.8264C31.5284 43.3571 31.7442 43.8704 32.0009 44.3603C32.8932 46.0749 34.2987 47.4863 36.0192 48.3786C36.5091 48.6411 37.0224 48.851 37.5531 49.0143C38.4512 49.3001 39.4019 49.4518 40.3934 49.4518C41.3848 49.4518 42.3296 49.3001 43.2336 49.0143C43.7644 48.851 44.2776 48.6353 44.7675 48.3786C46.4822 47.4921 47.8935 46.0808 48.7859 44.3603C49.0483 43.8704 49.2583 43.3571 49.4216 42.8264C49.7074 41.9282 49.859 40.9776 49.859 39.9861C49.859 38.9947 49.7074 38.044 49.4216 37.1459C49.2583 36.6151 49.0425 36.1019 48.7859 35.612Z"
          fill="#4759FF"
        />
        <path
          d="M216.538 33.2841V11.2646H220.528V33.2841H216.538Z"
          fill="black"
        />
        <path
          d="M207.097 33.5889C205.554 33.5889 204.183 33.264 202.985 32.6143C201.787 31.9443 200.833 31.0306 200.122 29.8733C199.432 28.716 199.087 27.3963 199.087 25.9141C199.087 24.4522 199.422 23.1528 200.092 22.0158C200.782 20.8584 201.716 19.9448 202.894 19.2748C204.071 18.5844 205.381 18.2393 206.823 18.2393C208.244 18.2393 209.493 18.5641 210.569 19.2138C211.665 19.8433 212.518 20.7163 213.127 21.833C213.756 22.9294 214.071 24.1781 214.071 25.5791C214.071 25.843 214.051 26.1171 214.01 26.4014C213.99 26.6653 213.939 26.9699 213.858 27.315L201.493 27.3455V24.3608L212 24.3304L210.386 25.5791C210.345 24.706 210.183 23.9751 209.899 23.3863C209.635 22.7975 209.239 22.3508 208.711 22.0462C208.203 21.7214 207.574 21.5589 206.823 21.5589C206.031 21.5589 205.34 21.7417 204.752 22.1071C204.163 22.4523 203.706 22.9497 203.381 23.5995C203.077 24.2289 202.924 24.9801 202.924 25.8532C202.924 26.7465 203.087 27.5282 203.412 28.1983C203.757 28.848 204.244 29.3556 204.873 29.721C205.503 30.0662 206.234 30.2388 207.066 30.2388C207.818 30.2388 208.498 30.117 209.107 29.8733C209.716 29.6094 210.244 29.2236 210.691 28.716L213.036 31.0611C212.325 31.8935 211.452 32.523 210.416 32.9493C209.401 33.3757 208.295 33.5889 207.097 33.5889Z"
          fill="black"
        />
        <path
          d="M189.318 33.2843L182.831 18.5742H187.217L191.876 30.6956H189.714L194.404 18.5742H198.638L192.181 33.2843H189.318Z"
          fill="black"
        />
        <path
          d="M177.012 33.2845V18.5745H181.002V33.2845H177.012ZM177.042 16.6862L175.52 14.798L179.905 10.0469L182.463 13.1534L177.042 16.6862Z"
          fill="black"
        />
        <path
          d="M167.27 33.5886C166.214 33.5886 165.259 33.3653 164.407 32.9186C163.554 32.4719 162.874 31.8628 162.366 31.0913C161.859 30.3197 161.584 29.4568 161.544 28.5025V23.2642C161.584 22.3099 161.859 21.4571 162.366 20.7059C162.894 19.9344 163.584 19.3354 164.437 18.909C165.29 18.4826 166.234 18.2694 167.27 18.2694C168.63 18.2694 169.848 18.6045 170.924 19.2745C172 19.9445 172.843 20.8582 173.452 22.0155C174.081 23.1728 174.396 24.4824 174.396 25.9443C174.396 27.3858 174.081 28.6853 173.452 29.8426C172.843 30.9999 172 31.9136 170.924 32.5836C169.848 33.2536 168.63 33.5886 167.27 33.5886ZM159.107 33.2841V11.2646H163.097V22.1982L162.427 25.7311L163.067 29.3248V33.2841H159.107ZM166.6 29.9035C167.33 29.9035 167.97 29.7411 168.518 29.4162C169.087 29.071 169.523 28.6041 169.828 28.0153C170.153 27.4061 170.315 26.7057 170.315 25.9138C170.315 25.1423 170.153 24.4621 169.828 23.8733C169.523 23.2642 169.087 22.7972 168.518 22.4723C167.95 22.1272 167.3 21.9546 166.569 21.9546C165.838 21.9546 165.188 22.1272 164.62 22.4723C164.072 22.7972 163.635 23.2642 163.31 23.8733C163.006 24.4621 162.853 25.1423 162.853 25.9138C162.853 26.7057 163.006 27.4061 163.31 28.0153C163.635 28.6041 164.082 29.071 164.65 29.4162C165.219 29.7411 165.869 29.9035 166.6 29.9035Z"
          fill="black"
        />
        <path
          d="M149.665 33.5889C148.122 33.5889 146.752 33.264 145.554 32.6143C144.356 31.9443 143.401 31.0306 142.691 29.8733C142 28.716 141.655 27.3963 141.655 25.9141C141.655 24.4522 141.99 23.1528 142.66 22.0158C143.351 20.8584 144.285 19.9448 145.462 19.2748C146.64 18.5844 147.949 18.2393 149.391 18.2393C150.812 18.2393 152.061 18.5641 153.137 19.2138C154.233 19.8433 155.086 20.7163 155.695 21.833C156.325 22.9294 156.639 24.1781 156.639 25.5791C156.639 25.843 156.619 26.1171 156.579 26.4014C156.558 26.6653 156.507 26.9699 156.426 27.315L144.061 27.3455V24.3608L154.568 24.3304L152.954 25.5791C152.914 24.706 152.751 23.9751 152.467 23.3863C152.203 22.7975 151.807 22.3508 151.279 22.0462C150.772 21.7214 150.142 21.5589 149.391 21.5589C148.599 21.5589 147.909 21.7417 147.32 22.1071C146.731 22.4523 146.274 22.9497 145.95 23.5995C145.645 24.2289 145.493 24.9801 145.493 25.8532C145.493 26.7465 145.655 27.5282 145.98 28.1983C146.325 28.848 146.812 29.3556 147.442 29.721C148.071 30.0662 148.802 30.2388 149.635 30.2388C150.386 30.2388 151.066 30.117 151.675 29.8733C152.284 29.6094 152.812 29.2236 153.259 28.716L155.604 31.0611C154.893 31.8935 154.02 32.523 152.985 32.9493C151.97 33.3757 150.863 33.5889 149.665 33.5889Z"
          fill="black"
        />
        <path
          d="M134.912 33.5889C133.409 33.5889 132.059 33.2539 130.861 32.5839C129.663 31.9138 128.719 31.0002 128.029 29.8429C127.338 28.6855 126.993 27.3861 126.993 25.9445C126.993 24.4827 127.338 23.1731 128.029 22.0158C128.719 20.8584 129.663 19.9448 130.861 19.2748C132.079 18.5844 133.429 18.2393 134.912 18.2393C136.069 18.2393 137.135 18.4524 138.109 18.8788C139.084 19.3052 139.927 19.9143 140.637 20.7062L138.079 23.2949C137.693 22.8685 137.226 22.5437 136.678 22.3203C136.15 22.097 135.561 21.9853 134.912 21.9853C134.181 21.9853 133.521 22.1579 132.932 22.503C132.364 22.8279 131.907 23.2847 131.562 23.8736C131.237 24.4421 131.074 25.1222 131.074 25.9141C131.074 26.6856 131.237 27.376 131.562 27.9851C131.907 28.5739 132.364 29.0409 132.932 29.386C133.521 29.7109 134.181 29.8733 134.912 29.8733C135.582 29.8733 136.181 29.7616 136.709 29.5383C137.257 29.2947 137.724 28.9495 138.109 28.5028L140.698 31.0915C139.947 31.9037 139.084 32.523 138.109 32.9493C137.135 33.3757 136.069 33.5889 134.912 33.5889Z"
          fill="black"
        />
        <path
          d="M118.525 33.5889C116.982 33.5889 115.612 33.264 114.414 32.6143C113.216 31.9443 112.262 31.0306 111.551 29.8733C110.861 28.716 110.516 27.3963 110.516 25.9141C110.516 24.4522 110.851 23.1528 111.521 22.0158C112.211 20.8584 113.145 19.9448 114.323 19.2748C115.5 18.5844 116.81 18.2393 118.251 18.2393C119.673 18.2393 120.921 18.5641 121.997 19.2138C123.094 19.8433 123.947 20.7163 124.556 21.833C125.185 22.9294 125.5 24.1781 125.5 25.5791C125.5 25.843 125.479 26.1171 125.439 26.4014C125.419 26.6653 125.368 26.9699 125.287 27.315L112.922 27.3455V24.3608L123.429 24.3304L121.815 25.5791C121.774 24.706 121.612 23.9751 121.327 23.3863C121.063 22.7975 120.668 22.3508 120.14 22.0462C119.632 21.7214 119.003 21.5589 118.251 21.5589C117.46 21.5589 116.769 21.7417 116.18 22.1071C115.592 22.4523 115.135 22.9497 114.81 23.5995C114.505 24.2289 114.353 24.9801 114.353 25.8532C114.353 26.7465 114.515 27.5282 114.84 28.1983C115.185 28.848 115.673 29.3556 116.302 29.721C116.932 30.0662 117.663 30.2388 118.495 30.2388C119.246 30.2388 119.926 30.117 120.536 29.8733C121.145 29.6094 121.673 29.2236 122.119 28.716L124.464 31.0611C123.754 31.8935 122.881 32.523 121.845 32.9493C120.83 33.3757 119.723 33.5889 118.525 33.5889Z"
          fill="black"
        />
        <path
          d="M96.3141 24.3304V21.1021H101.278C102.294 21.1021 103.075 20.8483 103.623 20.3407C104.192 19.8128 104.476 19.092 104.476 18.1783C104.476 17.3459 104.192 16.6556 103.623 16.1074C103.075 15.5389 102.294 15.2546 101.278 15.2546H96.3141V11.874H101.644C103.025 11.874 104.233 12.1481 105.268 12.6963C106.324 13.2242 107.136 13.9552 107.705 14.8891C108.293 15.8231 108.588 16.8992 108.588 18.1174C108.588 19.3763 108.293 20.4727 107.705 21.4066C107.136 22.3203 106.324 23.0411 105.268 23.569C104.212 24.0766 102.984 24.3304 101.583 24.3304H96.3141ZM93.2686 33.2843V11.874H97.4105V33.2843H93.2686ZM104.964 33.2843L97.8978 24.0258L101.705 22.8381L109.989 33.2843H104.964Z"
          fill="black"
        />
        <path
          d="M197.337 62.4007C195.916 62.4007 194.637 62.0657 193.5 61.3957C192.363 60.7257 191.459 59.8222 190.789 58.6852C190.119 57.5278 189.784 56.2385 189.784 54.8173C189.784 53.4163 190.119 52.1575 190.789 51.0408C191.459 49.9038 192.363 49.0003 193.5 48.3302C194.637 47.6602 195.916 47.3252 197.337 47.3252C198.738 47.3252 200.007 47.6602 201.144 48.3302C202.301 48.98 203.215 49.8733 203.885 51.0103C204.555 52.1473 204.89 53.4163 204.89 54.8173C204.89 56.2385 204.555 57.5278 203.885 58.6852C203.215 59.8222 202.301 60.7257 201.144 61.3957C200.007 62.0657 198.738 62.4007 197.337 62.4007ZM197.337 59.7511C198.251 59.7511 199.063 59.5379 199.774 59.1115C200.484 58.6851 201.043 58.1065 201.449 57.3756C201.855 56.6243 202.058 55.7716 202.058 54.8173C202.058 53.8833 201.845 53.0509 201.418 52.3199C201.012 51.589 200.454 51.0205 199.743 50.6144C199.053 50.188 198.251 49.9748 197.337 49.9748C196.424 49.9748 195.611 50.188 194.901 50.6144C194.19 51.0205 193.632 51.589 193.226 52.3199C192.82 53.0509 192.617 53.8833 192.617 54.8173C192.617 55.7716 192.82 56.6243 193.226 57.3756C193.632 58.1065 194.19 58.6851 194.901 59.1115C195.611 59.5379 196.424 59.7511 197.337 59.7511Z"
          fill="black"
        />
        <path
          d="M179.123 62.4005C177.783 62.4005 176.585 62.0756 175.529 61.4259C174.474 60.7559 173.631 59.8523 173.002 58.7153C172.392 57.5783 172.088 56.2992 172.088 54.8779C172.088 53.4567 172.392 52.1775 173.002 51.0405C173.631 49.9035 174.474 49 175.529 48.33C176.585 47.6599 177.783 47.3249 179.123 47.3249C180.199 47.3249 181.174 47.5584 182.047 48.0254C182.92 48.4721 183.62 49.1015 184.148 49.9137C184.697 50.7055 185.001 51.6293 185.062 52.6851V57.0403C185.001 58.0758 184.707 58.9996 184.179 59.8117C183.651 60.6239 182.95 61.2635 182.077 61.7304C181.204 62.1771 180.22 62.4005 179.123 62.4005ZM179.58 59.8117C180.494 59.8117 181.285 59.5986 181.956 59.1722C182.646 58.7458 183.184 58.1671 183.57 57.4362C183.955 56.685 184.148 55.8322 184.148 54.8779C184.148 53.883 183.945 53.0201 183.539 52.2892C183.153 51.5583 182.615 50.9796 181.925 50.5532C181.255 50.1268 180.463 49.9137 179.55 49.9137C178.636 49.9137 177.834 50.1268 177.144 50.5532C176.453 50.9796 175.905 51.5684 175.499 52.3197C175.113 53.0506 174.92 53.8932 174.92 54.8475C174.92 55.8221 175.113 56.685 175.499 57.4362C175.905 58.1671 176.453 58.7458 177.144 59.1722C177.854 59.5986 178.666 59.8117 179.58 59.8117ZM186.737 62.0959H183.966V58.1976L184.483 54.6647L183.966 51.1623V40.3506H186.737V62.0959Z"
          fill="black"
        />
        <path
          d="M161.428 62.4007C160.108 62.4007 158.91 62.0759 157.834 61.4262C156.758 60.7561 155.905 59.8526 155.276 58.7156C154.667 57.5786 154.362 56.2995 154.362 54.8782C154.362 53.4569 154.667 52.1778 155.276 51.0408C155.905 49.9038 156.748 49.0003 157.804 48.3302C158.88 47.6602 160.088 47.3252 161.428 47.3252C162.524 47.3252 163.499 47.5587 164.352 48.0257C165.225 48.4724 165.925 49.1018 166.453 49.9139C166.981 50.7058 167.276 51.6296 167.336 52.6854V57.0405C167.276 58.076 166.981 58.9999 166.453 59.812C165.946 60.6242 165.255 61.2637 164.382 61.7307C163.529 62.1774 162.545 62.4007 161.428 62.4007ZM161.885 59.812C163.245 59.812 164.342 59.3552 165.174 58.4415C166.007 57.5075 166.423 56.3198 166.423 54.8782C166.423 53.8833 166.23 53.0204 165.844 52.2895C165.479 51.5382 164.951 50.9596 164.26 50.5535C163.57 50.1271 162.768 49.9139 161.854 49.9139C160.941 49.9139 160.129 50.1271 159.418 50.5535C158.728 50.9799 158.179 51.5687 157.773 52.3199C157.388 53.0509 157.195 53.8935 157.195 54.8477C157.195 55.8223 157.388 56.6852 157.773 57.4365C158.179 58.1674 158.738 58.7461 159.448 59.1724C160.159 59.5988 160.971 59.812 161.885 59.812ZM166.24 62.0962V58.1979L166.758 54.665L166.24 51.1626V47.6298H169.011V62.0962H166.24Z"
          fill="black"
        />
        <path
          d="M145.164 62.4007C144.067 62.4007 143.072 62.1774 142.179 61.7307C141.306 61.2637 140.606 60.6242 140.078 59.812C139.55 58.9999 139.255 58.076 139.194 57.0405V52.6854C139.255 51.6296 139.55 50.7058 140.078 49.9139C140.626 49.1018 141.336 48.4724 142.21 48.0257C143.103 47.5587 144.088 47.3252 145.164 47.3252C146.483 47.3252 147.671 47.6602 148.727 48.3302C149.803 49.0003 150.646 49.9038 151.255 51.0408C151.864 52.1778 152.169 53.4569 152.169 54.8782C152.169 56.2995 151.864 57.5786 151.255 58.7156C150.646 59.8526 149.803 60.7561 148.727 61.4262C147.671 62.0759 146.483 62.4007 145.164 62.4007ZM137.55 68.1569V47.6298H140.291V51.4367L139.773 54.9391L140.291 58.472V68.1569H137.55ZM144.707 59.812C145.621 59.812 146.423 59.5988 147.113 59.1724C147.803 58.7461 148.351 58.1674 148.757 57.4365C149.164 56.6852 149.367 55.8223 149.367 54.8477C149.367 53.8935 149.164 53.0407 148.757 52.2895C148.351 51.5382 147.803 50.9596 147.113 50.5535C146.423 50.1271 145.631 49.9139 144.737 49.9139C143.824 49.9139 143.022 50.1271 142.331 50.5535C141.641 50.9596 141.103 51.5382 140.717 52.2895C140.331 53.0407 140.139 53.9036 140.139 54.8782C140.139 55.8325 140.321 56.6852 140.687 57.4365C141.073 58.1674 141.611 58.7461 142.301 59.1724C143.012 59.5988 143.814 59.812 144.707 59.812Z"
          fill="black"
        />
        <path
          d="M130.769 62.0955V47.6291H133.51V62.0955H130.769ZM132.139 44.7358C131.632 44.7358 131.215 44.5734 130.891 44.2485C130.566 43.9033 130.403 43.477 130.403 42.9694C130.403 42.4821 130.566 42.076 130.891 41.7511C131.215 41.406 131.632 41.2334 132.139 41.2334C132.647 41.2334 133.063 41.406 133.388 41.7511C133.713 42.076 133.875 42.4821 133.875 42.9694C133.875 43.477 133.713 43.9033 133.388 44.2485C133.063 44.5734 132.647 44.7358 132.139 44.7358Z"
          fill="black"
        />
        <path
          d="M124.287 62.0959V53.6597C124.287 52.5633 123.942 51.6598 123.251 50.9491C122.561 50.2385 121.668 49.8832 120.571 49.8832C119.84 49.8832 119.191 50.0456 118.622 50.3705C118.054 50.6954 117.607 51.142 117.282 51.7105C116.957 52.279 116.795 52.9288 116.795 53.6597L115.668 53.0201C115.668 51.9237 115.912 50.9491 116.399 50.0964C116.886 49.2436 117.566 48.5736 118.439 48.0863C119.312 47.5787 120.297 47.3249 121.394 47.3249C122.49 47.3249 123.454 47.5686 124.287 48.0559C125.14 48.5432 125.81 49.2233 126.297 50.0964C126.784 50.9695 127.028 51.9745 127.028 53.1115V62.0959H124.287ZM114.054 62.0959V40.3506H116.795V62.0959H114.054Z"
          fill="black"
        />
        <path
          d="M103.166 62.4006C101.644 62.4006 100.232 62.1265 98.933 61.5783C97.6335 61.0098 96.4965 60.2281 95.5219 59.2332C94.5677 58.2383 93.8266 57.081 93.2987 55.7613C92.7708 54.4415 92.5068 53.0304 92.5068 51.5279C92.5068 50.0052 92.7708 48.5839 93.2987 47.2641C93.8266 45.9444 94.5677 44.7972 95.5219 43.8227C96.4965 42.8278 97.6234 42.0562 98.9025 41.508C100.202 40.9395 101.613 40.6553 103.136 40.6553C104.801 40.6553 106.263 40.9395 107.521 41.508C108.78 42.0765 109.897 42.8481 110.872 43.8227L108.892 45.8023C108.222 45.051 107.4 44.4622 106.425 44.0359C105.471 43.6095 104.374 43.3963 103.136 43.3963C102.019 43.3963 100.994 43.5993 100.06 44.0054C99.1259 44.3912 98.3137 44.9495 97.6234 45.6805C96.9331 46.4114 96.395 47.2743 96.0092 48.2692C95.6438 49.2641 95.461 50.3503 95.461 51.5279C95.461 52.7056 95.6438 53.7918 96.0092 54.7867C96.395 55.7816 96.9331 56.6445 97.6234 57.3754C98.3137 58.1064 99.1259 58.6749 100.06 59.0809C100.994 59.4667 102.019 59.6596 103.136 59.6596C104.456 59.6596 105.603 59.4464 106.577 59.02C107.552 58.5733 108.374 57.9744 109.044 57.2231L111.024 59.1723C110.049 60.1875 108.902 60.9793 107.582 61.5478C106.283 62.1163 104.811 62.4006 103.166 62.4006Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_101_1067">
          <rect width="222.27" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
