/*eslint-disable */
export function validateHttpStatus(
    target: any,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<any>>
) {
    const fn = descriptor.value!;
    descriptor.value = async function DescriptorValue(...args: any[]) {
        const httpResponse = await fn.apply(this, args);
        
        if(haveAnErrorInTheStatusCode(httpResponse)) {
            throw new Error(`Heyy! we have a problem coming from: ${propertyKey}. error_message: ${httpResponse.message}`)
        }
        return httpResponse
    };
};

const haveAnErrorInTheStatusCode = (response: any) => {
    return response && !!response.statusCode && response.statusCode !== (200 || 201 || 204)
}