import { Col, Row } from 'react-bootstrap'
import { ChartCard } from '../../components/organisms/ChartCard'
import { ChartAdditionalInfo } from '../../components/atoms/ChartAdditionalInfo'
import { InfoCard } from '../../components/organisms/InfoCard'
import { TendencyViewer } from '../../components/molecules/TendencyViewer'
import { useEffect, useState } from 'react'
import { getDashboard } from '../../domain/usecases/Dashboard'
import moment from 'moment'
import {
  DashboardCharts,
  EthChartData,
  Values
} from '../../domain/models/DashboardModel'
import { getChartStructure } from '../../services/charts/chart'
import NumberFormat from 'react-number-format'
import { CallToActionCard } from '../../components/organisms/CallToActionCard'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../hooks/useToast'

interface IDashboardInfoResume {
  disabled?: boolean
  remoteGetDashboard: getDashboard
}

export const DashboardInfoResume = ({
  remoteGetDashboard
}: IDashboardInfoResume) => {
  const [ethChartData, setEthChartData] = useState<EthChartData>()
  const [dashboard, setDashboard] = useState<DashboardCharts>()
  const history = useHistory()
  const { showToast } = useToast()

  useEffect(() => {
    remoteGetDashboard.getEthData().then((data) => setEthChartData(data))
    remoteGetDashboard.getIndex().then((data) => setDashboard(data))
  }, [])

  const getFormattedValues = (type: 'keys' | 'values' = 'values') => {
    const transformDays = dashboard?.seven_days
      .map((day) => {
        return Object[type](day)[0]
      })
      .reverse()

    return transformDays ?? []
  }

  const getDays = () => {
    const days = [1, 2, 3, 4, 5, 6, 7, 8]
    return days
      .map((day) => moment().subtract(day, 'days').format('DD/MM/YYYY'))
      .reverse()
  }
  return (
    <Row>
      <Col md={12}>
        <Row>
          {dashboard && (
            <>
              <Col md={6}>
                <ChartCard
                  value={
                    <NumberFormat
                      value={dashboard.total_value}
                      displayType={'text'}
                      prefix={'R$'}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                    />
                  }
                  name="Total de créditos originados"
                  data={getChartStructure(
                    getFormattedValues(),
                    'Negócios',
                    getFormattedValues('keys')
                  )}
                  bgClass="bg-success"
                >
                  <ChartAdditionalInfo
                    info2={{
                      name: `${dashboard.contracts_quantities.month}`,
                      value: '30 dias'
                    }}
                    info={{
                      name: `${dashboard.contracts_quantities.today}`,
                      value: 'Hoje'
                    }}
                  />
                </ChartCard>
              </Col>
              <Col md={6}>
                <ChartCard
                  value={
                    <NumberFormat
                      value={0}
                      displayType={'text'}
                      prefix={'R$'}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                    />
                  }
                  name="Total de créditos vendidos"
                  data={getChartStructure(
                    [],
                    'Negócios',
                    getFormattedValues('keys')
                  )}
                  bgClass="bg-success"
                >
                  <ChartAdditionalInfo
                    info2={{
                      name: `${0}`,
                      value: '30 dias'
                    }}
                    info={{
                      name: `${0}`,
                      value: 'Hoje'
                    }}
                  />
                </ChartCard>
              </Col>
            </>
          )}
        </Row>
        {dashboard && (
          <Row>
            <Col md={3}>
              <InfoCard
                title={
                  <div className="events-box">
                    <h3>Eventos</h3>
                    <span>0 notificações</span>
                    <span>0 pagamentos</span>
                    <span>0 atrasos</span>
                  </div>
                }
                subtitle="Último mês"
                textColor="#5E6EA7"
                footerBgColor="#868e96"
                iconColor="#DDAC4B"
              />
            </Col>
            <Col md={3}>
              <CallToActionCard
                title="Novo crédito"
                description="Crie um Contrato de Compra e Venda"
                buttonText="Originar créditos"
                onClick={() => history.push('/contracts/create')}
              />
            </Col>
            <Col md={3}>
              <CallToActionCard
                title="Cessão de recebíveis"
                description="Faça a cessão dos recebíveis"
                buttonText="Vender créditos"
                onClick={() =>
                  showToast(
                    'Oie! Estamos finalizando o desenvolvimento desta funcionalidade! Em breve você poderá usá-la!😉',
                    'info'
                  )
                }
              />
            </Col>
            <Col md={3}>
              <CallToActionCard
                title="Carteira de recebíveis"
                description="Veja os recebíveis cadastrados"
                buttonText="Abrir carteira"
                onClick={() => history.push('/payments')}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}
