import * as React from 'react'
import { useContext } from 'react'
import { Button, Col, Row, Alert } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import step3_schema from './step3.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { TextInput } from '../../../../components/atoms/TextInput'
import { CreateUnitContext } from '../index'
import * as S from './styles'
import { DispatchContext } from '../actions'
import { CurrencyInput } from '../../../../components/atoms/CurrencyInput'
import { NumberInput } from '../../../../components/atoms/NumberInput'

export interface ICreateUnitFormStep3 {
  real_private_area: number
  real_private_area_accessory?: number
  common_real_area?: number
  real_total_area?: number
  ideal_fraction: number
  value: string
}

export default function Step3({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateUnitContext)
  const { state, dispatch } = useContext(DispatchContext)
  const [buildingName, setBuildingName] = React.useState('')
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ICreateUnitFormStep3>({
    resolver: yupResolver(step3_schema),
    defaultValues: {
      real_private_area: data?.real_private_area,
      real_private_area_accessory: data?.real_private_area_accessory,
      common_real_area: data?.common_real_area,
      real_total_area: data?.real_total_area,
      ideal_fraction: data?.ideal_fraction,
      value: data?.value
    }
  })

  const onClickNext = (formData: ICreateUnitFormStep3) => {
    setData!({ ...data, ...formData })

    nextHandler()
  }

  React.useEffect(() => {
    const { buildings } = state.project

    const [building] =
      buildings &&
      buildings.filter((item: any) => item.id === data?.building_id)
    setBuildingName(building.name)
  }, [data, state])

  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={3}>
            <S.LabelUnits>
              <span className="title">{buildingName}</span>
              <span className="label">Bloco</span>
            </S.LabelUnits>
          </Col>
          <Col md={4}>
            <S.LabelUnits>
              <span className="title">{data?.unitsName?.toString()}</span>
              <span className="label">Unidade(s)</span>
            </S.LabelUnits>
          </Col>
        </Row>
        <Alert className="mt-3" dismissible={false} variant="warning">
          Lembre-se de que todas estas Unidades devem ter as mesmas
          características.
        </Alert>
        <Row>
          <Col sm={12} md={4}>
            <Controller
              control={control}
              name="real_private_area"
              render={({ field: { onChange, value } }) => {
                return (
                  <NumberInput
                    data-testid="real_private_area"
                    label="Área Real Privativa (m²)"
                    returnNumber={(number) => onChange(number)}
                    value={value}
                    errorMessage={errors?.real_private_area?.message}
                  />
                )
              }}
            />
          </Col>
          <Col sm={12} md={4}>
            <Controller
              control={control}
              name="real_private_area_accessory"
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  data-testid="real_private_area_accessory"
                  label="Área Real Privativa (acessória) (m²)"
                  returnNumber={(number) => onChange(number)}
                  value={value}
                  errorMessage={errors.real_private_area_accessory?.message}
                />
              )}
            />
          </Col>
          <Col sm={12} md={4}>
            <Controller
              control={control}
              name="common_real_area"
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  data-testid="common_real_area"
                  label="Área Real Comum (m²)"
                  returnNumber={(number) => onChange(number)}
                  value={value}
                  errorMessage={errors.common_real_area?.message}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Controller
              control={control}
              name="real_total_area"
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  data-testid="real_total_area"
                  label="Área Real Total (m²)"
                  returnNumber={(number) => onChange(number)}
                  value={value}
                  errorMessage={errors.real_total_area?.message}
                />
              )}
            />
          </Col>
          <Col sm={12} md={4}>
            <Controller
              control={control}
              name="ideal_fraction"
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  data-testid="ideal_fraction"
                  label="Fração Ideal"
                  returnNumber={(number) => onChange(number)}
                  value={value}
                  errorMessage={errors.ideal_fraction?.message}
                />
              )}
            />
          </Col>
          <Col sm={12} md={4}>
            <Controller
              control={control}
              name="value"
              render={({ field: { onChange, value } }) => {
                return (
                  <CurrencyInput
                    data-testid="value"
                    min="1"
                    label="Valor da Unidade (R$)"
                    errorMessage={errors.value?.message}
                    onChange={onChange}
                    value={value}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
