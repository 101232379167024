import * as React from 'react'
import CnpjService, { CnpjDto } from '../domain/usecases/Cnpj'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'

export const useGetCnpj = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const getCnpj = React.useCallback(
    async (cnpj: string): Promise<CnpjDto | undefined> => {
      try {
        if (isInvalid(cnpj)) return
        dispatch({ type: 'SET_LOADING', loading: true })
        const result = await CnpjService.get(extractDigits(cnpj))
        dispatch({ type: 'SET_LOADING', loading: false })
        return result
      } catch {
        dispatch({ type: 'SET_LOADING', loading: false })
        addToast('CNPJ não encontrado.', {
          appearance: 'error',
          autoDismiss: true
        })
        throw new Error('CNPJ não encontrado.')
      }
    },
    []
  )

  const isInvalid = (cnpj: string) => {
    const cnpjDigits = extractDigits(cnpj)
    return cnpjDigits.length !== 14
  }

  const extractDigits = (cnpj: string) => {
    return cnpj.replace(/\D/g, '')
  }

  return { getCnpj }
}
