import { CompanyType } from '../models/CompanyModel'
import { EntityCardProps } from '../../components/organisms/EntityCard'
import formatStringToCNPJ from '../../utils/formatStringToCNPJ'

export const convertCompanyToEntity = (
  companies: CompanyType[] = [],
  deleteFn: (paramId: string) => void,
  openFn: (id: string) => void,
  addPhoto: (id: string) => void
) => {
  return companies.map((company: CompanyType) => {
    const formatted: EntityCardProps = {
      title: company.business_name,
      line1: formatStringToCNPJ(company.cnpj),
      image: company.photo!,
      logoOrPhoto: company.photo ? 'photo' : 'logo',
      leftBtnAction: () => deleteFn(company.id),
      rightBtnAction: () => openFn(company.id),
      onAddPhoto: () => addPhoto(company.id),
      icons: [
        {
          icon: 'icon-folder',
          text: `${company.spes.length} SPEs`,
          disabled: !!company.spes.length
        },
        {
          icon: 'icon-user',
          text: `${company.company_users.length} Usuários`
        }
      ]
    }
    return formatted
  })
}
