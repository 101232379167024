import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Card, Form, FormCheck } from 'react-bootstrap'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import logo from '../../assets/images/logo.svg'
import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import { PasswordField } from '../../components/atoms/PasswordField'
import { TextInput } from '../../components/atoms/TextInput'
import { TextInputWithMask } from '../../components/atoms/TextInputWithMask'
import { signUp } from '../../services/users'
import { useToasts } from 'react-toast-notifications'
import schema from './signup-investor.schema'
import { ISignUpInvestorForm } from './signup-investor-form.interface'

import * as S from './styles'
import { PasswordValidator } from '../../components/molecules/PasswordValidator'
import { useState } from 'react'
import { LoadingIndicator } from '../../components/atoms/LoadingIndicator'
import { IHistoryPush } from '../../interfaces/router'
import { useGetCpf } from '../../hooks/useGetCpf'
import WelcomeToast from '../../components/molecules/WelcomeToast'
// import { Checkbox } from '../../components/atoms/Checkbox'

interface Props {
  history: IHistoryPush
}

const SignUpInvestor = ({ history }: Props) => {
  document.title = 'Recebível Chipado - Abra sua Conta'
  const { addToast } = useToasts()
  const { getCpf } = useGetCpf()
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm<ISignUpInvestorForm>({
    resolver: yupResolver(schema)
  })

  const password = watch('password')

  const onSubmit: SubmitHandler<ISignUpInvestorForm> = async (data) => {
    try {
      setLoading(true)
      const investor: ISignUpInvestorForm = { ...data, type: 'investor' }
      await signUp(investor)
      addToast(<WelcomeToast />, { appearance: 'info', autoDismiss: false })
      setLoading(false)
      history.push('/login')
    } catch (e) {
      setLoading(false)
      if (e?.response.status === 409) {
        addToast(
          <span>
            Este CPF já está registrado. Caso tenha perdido sua Senha, você pode
            recuperá-la.
            <button
              className="btn btn-sm btn-danger"
              style={{ marginTop: '5px' }}
              onClick={() => history.push('/esqueci-minha-senha')}
            >
              Recuperar minha Senha
            </button>
            .
          </span>,
          {
            appearance: 'error',
            autoDismiss: true
          }
        )
      } else {
        addToast('Ocorreu um erro desconhecido.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    }
  }

  const checkCpf = async (cpf: string) => {
    try {
      const cpfDto = await getCpf(cpf)
      if (!cpfDto) return
      setValue('name', cpfDto.nome)
    } catch {
      reset({ cpf: '', name: '' })
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content" style={{ width: '800px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Card.Body>
                  <img src={logo} alt="" className="logo mb-4" />
                  <p>
                    Crie uma conta conosco e transforme a sua maneira de fazer
                    Negócios
                  </p>
                  <h5 className="f-w-700 mb-4">
                    Preencha os dados para criar a sua Conta.
                  </h5>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="cpf"
                            render={({ field: { onChange, value } }) => (
                              <TextInputWithMask
                                disabled={loading}
                                label="CPF"
                                placeholder="Seu CPF"
                                mask="999.999.999-99"
                                value={value}
                                errorMessage={errors.cpf?.message}
                                onChange={({ target: { value } }) => {
                                  onChange(value)
                                  checkCpf(value)
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="name"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                disabled={loading}
                                readOnly
                                label="Nome"
                                placeholder="Campo preenchido automaticamente"
                                type="text"
                                value={value}
                                onChange={onChange}
                                errorMessage={errors.name?.message}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                disabled={loading}
                                label="E-mail"
                                placeholder="Seu E-mail"
                                type="email"
                                errorMessage={errors.email?.message}
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="phone"
                            render={({ field: { onChange, value } }) => (
                              <TextInputWithMask
                                disabled={loading}
                                label="Telefone"
                                placeholder="Seu telefone"
                                mask="(99) 9 9999-9999"
                                errorMessage={errors.phone?.message}
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, value } }) => (
                              <PasswordField
                                disabled={loading}
                                onChange={onChange}
                                value={value}
                                errorMessage={errors.password?.message}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="password_confirmation"
                            render={({ field: { onChange, value } }) => (
                              <PasswordField
                                disabled={loading}
                                onChange={onChange}
                                value={value}
                                label="Confirmação de Senha"
                                errorMessage={
                                  errors.password_confirmation?.message
                                }
                                placeholder="Repita a Senha"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <PasswordValidator password={password} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" d-flex m-r-10">
                    <FormCheck type="checkbox" id="privacyPolicy" />
                    <Form.Label
                      style={{ maxWidth: 450 }}
                      htmlFor="privacyPolicy"
                    >
                      Li e concordo com os <a>Termos de Uso</a> e Política de
                      Privacidade do Recebível Chipado.
                    </Form.Label>
                  </div>
                  <S.ButtonsWrapper>
                    <div className="">
                      {!loading && (
                        <NavLink to="/login" className="f-w-400">
                          <a className="btn">Voltar</a>
                        </NavLink>
                      )}
                    </div>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {!loading ? 'Criar Conta' : <LoadingIndicator />}
                      </button>
                    </div>
                  </S.ButtonsWrapper>
                </Card.Body>
              </div>
            </div>
          </Card>
        </form>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default SignUpInvestor
