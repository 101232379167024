import { useContext } from 'react'
import { AppContext } from '../app'
import { IHistoryPush } from '../interfaces/router'

export const useIsGuest = (history: IHistoryPush) => {
  const { user } = useContext(AppContext)

  if (user) {
    history.push('/dashboard')
  }
}
