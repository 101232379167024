import { Alert, Badge, Card, Table } from 'react-bootstrap'

export const TransactionsCard = () => {
  return (
    <Card>
      <Card.Header>
        <h5>Transações</h5>
      </Card.Header>
      <Card.Body>
        <Table striped hover responsive bordered id="data-table-zero">
          <thead>
            <tr>
              <th>ID</th>
              <th>Tipo</th>
              <th>Data</th>
              <th>Moeda/Token</th>
              <th>Quantidade</th>
              <th>Valor</th>
              <th>Taxas (%)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={8}>
                <Alert variant="warning">Suas transações aparecerão aqui</Alert>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <div>#14342</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <Badge variant="success">Depósito</Badge>*/}
            {/*  </td>*/}
            {/*  <td>25/10/2020 13:15</td>*/}

            {/*  <td>Reais (R$)</td>*/}
            {/*  <td>*/}
            {/*    <div className="amount">70000</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div>R$ 70.000,00</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div>0</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div className="badge badge-success badge-pill">Completado</div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <div>#15123</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <Badge variant="info">Compra</Badge>*/}
            {/*  </td>*/}
            {/*  <td>26/10/2020 14:00</td>*/}

            {/*  <td>PRP-APT-1005</td>*/}
            {/*  <td>*/}
            {/*    <div className="amount">0.50</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div>R$ 2.500,00</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div>0</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div className="badge badge-success badge-pill">Completado</div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <div>#16523</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <Badge variant="info">Compra</Badge>*/}
            {/*  </td>*/}
            {/*  <td>26/10/2020 14:30</td>*/}

            {/*  <td>PRP-APT-1042</td>*/}
            {/*  <td>*/}
            {/*    <div className="amount">12</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div>R$ 60.000,00</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div>0</div>*/}
            {/*  </td>*/}
            {/*  <td>*/}
            {/*    <div className="badge badge-success badge-pill">Completado</div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
