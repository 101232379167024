import { TokenModel } from 'domain/models/TokenModel'
import { useHistory } from 'react-router-dom'

import { EntityList } from 'components/organisms/EntityList'
import TableCardTemplate from 'components/templates/TableCardTemplate'

type Props = {
  tokens: TokenModel[]
}

export function TokenList({ tokens }: Props) {
  const ANTECIPATION_KEY = 'antecipation'
  const history = useHistory()
  return (
    <>
      <EntityList
        fullWidth={true}
        mainTitle="Tokens"
        title="Transações"
        entityTable={
          <TableCardTemplate
            columns={[
              'NOME',
              'TIPO',
              'SÍMBOLO',
              'CONTRATO',
              'DATA DE CRIAÇÃO',
              'STATUS',
              'TOKENS VENDIDOS',
              'TOTAL DE TOKENS',
              'EXPLORER'
            ]}
            rows={tokens.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.type === ANTECIPATION_KEY ? 'Antecipação' : ''}</td>
                <td>{item.symbol}</td>
                <td>
                  {
                    <a
                      className="btn btn-outline-primary btn-sm"
                      href="#"
                      onClick={() =>
                        history.push(`/contracts/${item.contract_id}`, {
                          id: item.contract_id
                        })
                      }
                    >
                      Abrir
                    </a>
                  }
                </td>
                <td>{new Date(item.created_at).toLocaleDateString('pt-BR')}</td>
                <td>{'Em Oferta'}</td>
                <td>{'0'}</td>
                <td>{item.total_supply}</td>
                <td>
                  {
                    <a
                      className="btn btn-outline-primary btn-sm"
                      href={`${process.env.REACT_APP_BLOCKCHAIN_EXPLORER_URL}/token/${item.contract_address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Abrir
                    </a>
                  }
                </td>
              </tr>
            ))}
          />
        }
      />
    </>
  )
}
