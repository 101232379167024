import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import { EntityCardProps } from '../../../components/organisms/EntityCard'
import {
  getCompany,
  deleteCompany,
  createCompany
} from '../../../domain/usecases/Company'

import { FilterItem } from '../../../components/organisms/EntityFilter'
import { EntityList } from '../../../components/organisms/EntityList'
import { getEntitiesCount } from '../../../domain/usecases/Reports'
import { filtersFactory } from '../../../main/factories/filters.factory'
import { convertCompanyToEntity } from '../../../domain/converters/Company'
import { FileInput } from '../../../components/atoms/FileInput'
import { DeleteModal } from '../../../components/molecules/DeleteModal'
import { ConvertFileToBase64 } from '../../../utils/ConvertFileToBase64'
import { useDispatch } from 'react-redux'

type ListCompaniesProps = {
  remoteGetCompanies: getCompany
  remoteDeleteCompany: deleteCompany
  remoteCreateCompany: createCompany
  remoteGetEntitiesCount: getEntitiesCount
}

export const ListCompanies = ({
  remoteGetCompanies,
  remoteDeleteCompany,
  remoteGetEntitiesCount,
  remoteCreateCompany
}: ListCompaniesProps) => {
  const history = useHistory()

  const [companies, setCompanies] = React.useState<EntityCardProps[]>([])
  const [filters, setFilters] = useState<FilterItem[]>([])
  const dispatch = useDispatch()

  const { addToast } = useToasts()

  const [selectedId, setSelectedId] = useState('')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<any>(null)

  const deleteCompanyFunction = (idSpe: string) => {
    handleShow()
    setSelectedId(idSpe)
  }

  const handleDelete = () => {
    remoteDeleteCompany
      .deleteById({ id: selectedId })
      .then((res) => {
        requestCompanies()
        handleClose()
      })
      .catch((error) => {
        handleClose()
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true
        })
      })
  }

  const RedirectToCompanyDetails = (id: string) => {
    history.push(`/companies/${id}`, { id: id })
  }

  const addPhoto = async (id: string) => {
    try {
      setSelectedId(id)
      inputFileRef.current?.click()
    } catch (err) {
      return err
    }
  }

  React.useEffect(() => {
    async function handleChange() {
      dispatch({ type: 'SET_LOADING', loading: true })
      const fileBase64 = await ConvertFileToBase64(file)
      if (fileBase64) {
        await remoteCreateCompany.uploadCompanyPhoto({
          id: selectedId,
          base64: fileBase64
        })
      }
      requestCompanies().then(() => {
        dispatch({ type: 'SET_LOADING', loading: false })
        setFile(null)
      })
    }
    if (file) {
      handleChange()
    }
  }, [file])

  const requestCompanies = async () => {
    remoteGetCompanies
      .getAll()
      .then((companies) => {
        setCompanies(
          convertCompanyToEntity(
            companies,
            deleteCompanyFunction,
            RedirectToCompanyDetails,
            addPhoto
          )
        )
      })
      .catch((error) =>
        addToast('Não foi possível carregar as Incorporadoras.', {
          appearance: 'error',
          autoDismiss: true
        })
      )
  }

  React.useEffect(() => {
    requestCompanies()
    remoteGetEntitiesCount
      .getEntitiesCount()
      .then((entitiesCount) =>
        setFilters(filtersFactory(entitiesCount, history))
      )
  }, [])

  return (
    <>
      <FileInput inputFileRef={inputFileRef} handleChange={setFile} />
      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleOk={handleDelete}
      />
      <EntityList
        title="Incorporadora"
        activeFilter={0}
        buttons={
          <Button
            onClick={() => history.push('/companies/create')}
            variant="primary"
          >
            Adicionar Incorporadora
          </Button>
        }
        filters={filters}
        entityCards={companies}
      />
    </>
  )
}
