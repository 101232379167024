import { SpeType } from '../../../domain/models/SpeModel'
export default function Utils() {
  const convertSpeObjToExpectedFormatContext = (spe: SpeType) => {
    return {
      id: spe.id,
      proxies: spe.proxies || [],
      cnpj: spe.cnpj,
      companyId: spe.company_id,
      business_name: spe.business_name,
      zip_code: spe.address.zip_code,
      address: spe.address.address,
      number: spe.address.number,
      complement: spe.address.complement,
      district: spe.address.district,
      city: spe.address.city,
      state: spe.address.state,
      nire: spe.nire,
      branch_digit: spe.bank_account.branch_digit,
      account_digit: spe.bank_account.account_digit,
      commercial_board_name: spe.commercial_board_name,
      commercial_board_identifier: spe.commercial_board_identifier,
      bank: spe.bank_account.bank,
      branch: spe.bank_account.branch_number,
      account: spe.bank_account.account_number,
      bank_account_type: spe.bank_account.type,
      interest: spe.bank_billet_preference.interest,
      fine: spe.bank_billet_preference.fine,
      interest_type: spe.bank_billet_preference.interest_type,
      country: spe.address.country,
      commercial_board_document_url: new File(
        ['fijRKjhudDjiokDhg1524164151'],
        spe.commercial_board_document_url,
        { type: 'application/pdf' }
      ),
      file_type: 'application/pdf',
      file_size: 181435,
      bank_billet_enabled: !!Object.keys(spe.bank_billet_preference).length
    }
  }
  return {
    convertSpeObjToExpectedFormatContext
  }
}
