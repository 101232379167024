import { Button, Col, Row } from 'react-bootstrap'
import * as React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import step2_schema from './step2.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateCompanyContext } from '../index'
import UserInviter from '../../../../components/organisms/UserInviter'
import { deleteCompanyUser } from 'domain/usecases/CompanyUser'
import { useContext } from 'react'

export interface CompanyUser {
  invite_name: string
  invite_email: string
  role: string
  id: string
}

export interface ICreateCompanyFormStep2 {
  users: CompanyUser[]
}
type Props = {
  formWizardHandlers: FormWizardHandlers
  handleDeleteCompanyUser: deleteCompanyUser
}

export default function Step2({
  formWizardHandlers: { nextHandler, backHandler },
  handleDeleteCompanyUser
}: Props) {
  const { data, setData } = useContext(CreateCompanyContext)
  const { handleSubmit, control } = useForm<ICreateCompanyFormStep2>({
    resolver: yupResolver(step2_schema),
    defaultValues: {
      ...data
    }
  })

  const onClickNext = (formData: ICreateCompanyFormStep2) => {
    setData?.({ ...data, ...formData })
    nextHandler()
  }

  return (
    <FormWizardStyles.TabContent>
      <Controller
        name="users"
        control={control}
        render={({ field: { onChange, value } }) => (
          <UserInviter
            deleteUserCompany={handleDeleteCompanyUser}
            defaultValue={data?.users}
            onChange={onChange}
          />
        )}
      />

      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperSpaced>
            <Button variant="link" onClick={backHandler}>
              Voltar
            </Button>
            <Button variant="link" onClick={handleSubmit(nextHandler)}>
              Cadastrar depois
            </Button>
            <Button variant="primary" onClick={handleSubmit(onClickNext)}>
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperSpaced>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
