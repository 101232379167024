import { useState, useEffect, useCallback } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import { TextWithHint } from '../../../components/atoms/TextWithHint'
import { ProjectsTable } from './ProjectsTable'
import { EntityDetails } from '../../../components/organisms/EntityDetails'
import { DetailsHeader } from '../../../components/templates/DetailsHeader'
import { SeparateBetween } from '../../../components/templates/SeparateBetween'
import { DetailButtonsRow } from '../../../components/molecules/DetailButtonsRow'
import { IconButton } from '../../../components/atoms/IconButton'
import { DetailsSection } from '../../../components/templates/DetailsSection/index'
import { IGetSpes, IDeleteSpe } from '../../../domain/usecases/SPE'
import { SpeType } from '../../../domain/models/SpeModel'
import formatStringToCNPJ from '../../../utils/formatStringToCNPJ'
import { BANK_ACCOUNT_TYPES } from '../../../constants/bankAccountType'
import { DeleteModal } from '../../../components/molecules/DeleteModal'
import { INTEREST_TYPE } from '../../../constants/interestType'

type Props = {
  getSpes: IGetSpes
  id: string
  handleDelete: IDeleteSpe
}

export default function DetailsSpe({ getSpes, id, handleDelete }: Props) {
  const { addToast } = useToasts()
  const history = useHistory()
  const [spe, setSpe] = useState<SpeType>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = useCallback(async () => {
    try {
      const response = await getSpes.getOne({ id })
      setSpe(response)
    } catch (error) {
      history.push('/spes')
      addToast('Não foi possível carregar a SPE.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }, [id])

  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const handleOpenDeleteModal = () => setShowDeleteModal(true)

  const handleDeleteSpe = async (id: string) => {
    try {
      await handleDelete.deleteById({ id })

      addToast('A SPE foi excluída com sucesso!', {
        appearance: 'success',
        autoDismiss: true
      })

      history.push('/spes')
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      })
    } finally {
      handleCloseDeleteModal()
    }
  }

  const handleRedirectForCreateProject = (speId: string) => {
    history.push('/projects/create', { speId })
  }

  return (
    <>
      {spe && (
        <EntityDetails
          title="SPE"
          sections={
            <>
              <DeleteModal
                show={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleOk={() => handleDeleteSpe(spe.id)}
              />
              <DetailsHeader
                image={spe.photo || ''}
                fields={
                  <>
                    <Col md={5}>
                      <TextWithHint
                        text={spe.business_name}
                        hint="Razão Social"
                      />
                    </Col>
                    <Col md={7}>
                      <TextWithHint
                        text={formatStringToCNPJ(spe.cnpj)}
                        hint="CNPJ"
                      />
                    </Col>
                  </>
                }
                rightButtons={
                  <SeparateBetween>
                    <Button
                      className="py-3"
                      variant="primary"
                      onClick={() => handleRedirectForCreateProject(spe.id)}
                    >
                      Adicionar Empreendimento
                    </Button>
                  </SeparateBetween>
                }
              />

              <DetailsSection
                title="Endereço"
                content={
                  <Row>
                    <Col md={3}>
                      <TextWithHint text={spe.address.zip_code} hint="CEP" />
                      <TextWithHint
                        text={
                          !spe.address.complement
                            ? 'Nenhum'
                            : spe.address.complement
                        }
                        hint="Complemento"
                      />
                      <TextWithHint text={spe.address.state} hint="Estado" />
                    </Col>
                    <Col md={4}>
                      <TextWithHint
                        text={spe.address.address}
                        hint="Logradouro"
                      />
                      <TextWithHint text={spe.address.district} hint="Bairro" />
                      <TextWithHint text={spe.address.country} hint="País" />
                    </Col>
                    <Col md={5}>
                      <TextWithHint text={spe.address.number} hint="N.º" />

                      <TextWithHint text={spe.address.city} hint="Cidade" />
                    </Col>
                  </Row>
                }
              />
              <DetailsSection
                title="Registro"
                content={
                  <Row className="d-flex align-items-center">
                    <Col md={3}>
                      <TextWithHint text={spe.nire} hint="NIRE" />
                    </Col>
                    <Col md={3}>
                      <TextWithHint
                        text={spe.commercial_board_name}
                        hint="Junta Comercial"
                      />
                    </Col>
                    <Col md={3}>
                      <TextWithHint
                        text={spe.commercial_board_identifier}
                        hint="Número Identificador"
                      />
                    </Col>
                    <Col md={3}>
                      <TextWithHint
                        text={
                          <a
                            href={spe.commercial_board_document_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Abrir
                          </a>
                        }
                        hint="Documento da Junta Comercial"
                      />
                    </Col>
                  </Row>
                }
              />
              <DetailsSection
                title="Procurador(es)"
                content={
                  <>
                    {spe.proxies.length &&
                      spe.proxies.map((proxy: any) => (
                        <>
                          <Row className="d-flex align-items-center">
                            <Col md={3}>
                              <TextWithHint
                                text={proxy.name}
                                hint="Procurador(es)"
                              />
                            </Col>
                            <Col md={4}>
                              <TextWithHint
                                text={proxy.cpf}
                                hint="CPF(s) do(s) Procurador(es)"
                              />
                            </Col>
                            <Col md={5}>
                              <TextWithHint
                                text={proxy.email}
                                hint="E-mail(s) do(s) Procurador(es)"
                              />
                            </Col>
                          </Row>
                        </>
                      ))}
                  </>
                }
              />
              <DetailsSection
                title="Meios de Pagamento"
                content={
                  <Row className="d-flex align-items-center">
                    <Col md={6}>
                      <Row className="d-flex align-items-center">
                        <Col md={6}>
                          <TextWithHint
                            text="Transferência"
                            hint="Meio de Pagamento"
                          />
                        </Col>
                        <Col md={4}>
                          <TextWithHint
                            text={spe.bank_account.bank || ''}
                            hint="Banco"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={2}></Col>
                        <Col md={3}>
                          <TextWithHint
                            text={`${spe.bank_billet_preference.interest}`}
                            hint="Multa (%)"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={`${spe.bank_billet_preference.fine}`}
                            hint="Taxa de Juros (%)"
                          />
                        </Col>
                        <Col md={4}>
                          <TextWithHint
                            text={
                              INTEREST_TYPE[
                                spe.bank_billet_preference.interest_type
                              ]
                            }
                            hint="Periodicidade"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={2}></Col>
                        <Col md={3}>
                          <TextWithHint
                            text={`${spe.bank_account.branch_number} - ${spe.bank_account.branch_digit}`}
                            hint="Agência e Dígito"
                          />
                        </Col>
                        <Col md={3}>
                          <TextWithHint
                            text={`${spe.bank_account.account_number} - ${spe.bank_account.account_digit}`}
                            hint="Conta e Dígito"
                          />
                        </Col>
                        <Col md={4}>
                          <TextWithHint
                            text={BANK_ACCOUNT_TYPES[spe.bank_account.type]}
                            hint="Tipo de Conta"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              />

              <DetailButtonsRow
                buttons={
                  <>
                    <IconButton
                      onClick={handleOpenDeleteModal}
                      icon="icon-trash"
                      color="#E62355"
                    />
                    <IconButton
                      onClick={() =>
                        history.push('/spes/create', { speParams: spe })
                      }
                      icon="icon-edit"
                      color="#5E6EA7"
                    />
                  </>
                }
              />
            </>
          }
          content={<ProjectsTable projects={spe.projects} />}
        />
      )}
    </>
  )
}
