import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './app'
import * as serviceWorker from './serviceWorker'
import { store } from './store/store'
import config from './config'
import { ToastProvider } from 'react-toast-notifications'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-211671746-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <ToastProvider placement="top-center">
        <BrowserRouter basename={config.basename}>
          <App />
        </BrowserRouter>
      </ToastProvider>
    </Provider>
  </React.StrictMode>
)
ReactDOM.render(app, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
