import { EntityCardProps } from '../../components/organisms/EntityCard'
import { ProjectType } from '../models/ProjectModel'
import { BuildingModel } from '../models/BuildingModel'

export const convertProjectToEntity = (
  projects: ProjectType[] = [],
  deleteFn: (paramId: string) => void,
  openFn: (id: string) => void,
  addPhoto: (id: string) => void
): EntityCardProps[] => {
  return projects.map((project) => {
    const total_units = project.buildings.reduce((acc, curr: BuildingModel) => {
      return acc + curr.units.length
    }, 0)
    return {
      title: project.name.toUpperCase(),
      line1: project.ri,
      line2: project.spe.company.business_name,
      image: project.photo,
      logoOrPhoto: 'photo',
      leftBtnAction: () => deleteFn(project.id),
      rightBtnAction: () => openFn(project.id),
      onAddPhoto: () => addPhoto(project.id),
      icons: [{ icon: 'icon-home', text: `${total_units} Unidades` }]
    }
  })
}
