import { useLocation } from 'react-router'
import { RemoteCreateUnit } from '../../../../data/usecases/Unit/RemoteCreateUnit'
import { URL_API } from '../../../../services/api_url'
import CreateUnit from '../../../../pages/Units/CreateUnits'
import CreateUnitActionsContext from '../../../../pages/Units/CreateUnits/actions'
import { RemoteGetProject } from '../../../../data/usecases/Project/RemoteGetProject'
import { RemoteEditUnit } from '../../../../data/usecases/Unit/RemoteEditUnit'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { ProjectType } from '../../../../domain/models/ProjectModel'
import { UnitType } from '../../../../domain/models/UnitModel'

type ProjectParams = {
  projectParams: ProjectType
  unitParams: UnitType
}
export const CreateUnitFactory = () => {
  const URL = URL_API || 'without-url'

  const { state } = useLocation<ProjectParams>()
  const axiosHttpClient = useAxiosHttpClient()
  const remoteCreateUnit = new RemoteCreateUnit(URL, axiosHttpClient)
  const remoteGetProject = new RemoteGetProject(URL, axiosHttpClient)
  const remoteEditUnit = new RemoteEditUnit(URL, axiosHttpClient)

  return (
    <CreateUnitActionsContext
      remoteGetProject={remoteGetProject}
      remoteCreateUnit={remoteCreateUnit}
      hasProject={state?.projectParams}
      remoteEditUnit={remoteEditUnit}
      hasUnit={state?.unitParams}
    >
      <CreateUnit />
    </CreateUnitActionsContext>
  )
}
