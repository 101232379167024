import * as S from './styles'
import { Title } from '../Title'

export interface TextWithHintProps {
  text: string | React.ReactNode
  hint: string
  isTitle?: boolean
}

export const TextWithHint = (props: TextWithHintProps) => {
  return (
    <S.Wrapper isTitle={props.isTitle || false}>
      {props.isTitle ? <Title>{props.text}</Title> : <p>{props.text}</p>}
      <span>{props.hint}</span>
    </S.Wrapper>
  )
}
