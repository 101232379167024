import * as React from 'react'
import { Card, Table } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'

import { TableTitle } from '../../../components/atoms/TableTitle'
import { UsersTableRow } from '../../../components/organisms/UsersTableRow'
import { CompanyUser } from '../../../domain/models/CompanyUserModel'
import { deleteCompanyUser } from '../../../domain/usecases/CompanyUser'
import { ROLES } from 'constants/roles'

type TableProps = {
  users: CompanyUser[]
  handleDeleteUser?: deleteCompanyUser
}

export const UsersTableCard = ({ users, handleDeleteUser }: TableProps) => {
  const { addToast } = useToasts()
  const history = useHistory()

  // const handleDelete = (id: string) => {
  //   handleDeleteUser
  //     .deleteById({ id })
  //     .then((res) => {
  //       history.push('/companies')
  //       addToast('Usuário excluído com sucesso!', {
  //         appearance: 'success',
  //         autoDismiss: true
  //       })
  //     })
  //     .catch((error) =>
  //       addToast('Não foi possível excluir o Usuário.', {
  //         appearance: 'error',
  //         autoDismiss: true
  //       })
  //     )
  // }

  return (
    <>
      <Card className="table-card">
        <Card.Header>
          <TableTitle>
            <strong>Usuários ({users.length})</strong>
          </TableTitle>
        </Card.Header>
        <Card.Body className="pb-0">
          <Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>NOME</th>
                <th>E-MAIL</th>
                <th>STATUS</th>
                <th>PERFIL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!!users.length &&
                users.map((user) => (
                  <UsersTableRow
                    key={user.id}
                    // onClickDelete={() => handleDelete(user.id)}
                    // onClickEdit={() =>
                    //   history.push('/companies/create', { user })
                    // }
                    name={user.invite_name}
                    email={user.invite_email}
                    role={ROLES[user.role.toUpperCase()]}
                    statusText={
                      user.status === 'active' ? 'Cadastrado' : 'Não cadastrado'
                    }
                    statusType={user.status === 'active' ? 'success' : 'danger'}
                  />
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}
