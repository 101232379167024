import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .btn {
    margin-bottom: 10px;
    width: 100%;
  }

  @media only screen and (min-width: 991px) {
    justify-content: space-between;
    flex-direction: row;

    .btn {
      margin-bottom: 0;
      width: initial;
    }
  }
`

export const ErrorMessage = styled.p`
  color: red;
`
