import { HttpClient } from '../../protocols/http/httpClient'
import {
  deleteCompanyUser,
  CompanyUser
} from '../../../domain/usecases/CompanyUser'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export class RemoteDeleteCompanyUser implements deleteCompanyUser {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async deleteById(params: CompanyUser.DeleteParams): Promise<void> {
    return await this.httpClient.delete?.({
      url: `${this.url}/company-users/${params.id}`
    })
  }
}
