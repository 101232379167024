import { useContext } from 'react'
import { AppContext } from '../app'
import { useToasts } from 'react-toast-notifications'

export const useIsLogged = (history: any) => {
  const { user } = useContext(AppContext)
  const { addToast } = useToasts()

  if (!user) {
    history.push('/login')
    addToast('Você precisa estar logado para acessar esta página.', {
      appearance: 'error',
      autoDismiss: true
    })
  }
}
