import React, { useEffect, useState } from 'react'
import { TableCard } from '../../../../../components/organisms/TableCard'
import { useContextPage } from '../../contextPage'
import { convertNumberToCurrencyFormat } from '../../../../../utils/convertNumberToCurrencyFormat'

export default function UnitsTable() {
  const { state } = useContextPage()
  const parkingSlots = state.projectsSelected?.parking_slots.filter(
    (parking) => parking.unit_id === state.unitSelected?.id
  )

  const value = state.unitSelected?.value
    ? convertNumberToCurrencyFormat(Number(state.unitSelected?.value))
    : '--'

  return (
    <>
      <TableCard
        data={{
          titleHeader: 'Unidade(s) Selecionada(s)',
          thead: (
            <thead>
              <tr>
                <th>EMPREENDIMENTO</th>
                <th>UNIDADE</th>
                <th>ÁREA REAL TOTAL</th>
                <th>VAGAS</th>
                <th>VALOR (R$)</th>
              </tr>
            </thead>
          ),
          tbody: (
            <tbody>
              <tr>
                <td>{state.projectsSelected?.name || '--'}</td>
                <td>
                  {`${state.unitSelected?.building.name} - ${state.unitSelected?.name}` ||
                    '--'}
                </td>
                <td>{state.unitSelected?.real_total_area || '--'}</td>
                <td>{parkingSlots?.length || '--'}</td>
                <td>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <strong>{value}</strong>
                  </div>
                </td>
              </tr>
            </tbody>
          )
        }}
      />
    </>
  )
}
