import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import FormWizard, { Step } from '../../../components/organisms/FormWizard'
import Step1, { ICreateUnitFormStep1 } from './Step1'
import Step2, { ICreateUnitFormStep2 } from './Step2'
import Step3, { ICreateUnitFormStep3 } from './Step3'
import Step4, { ICreateUnitFormStep4 } from './Step4'
import Step5 from './Step5'
import { Title } from '../../../components/atoms/Title'
import { SetableContext } from '../../../interfaces/setable-context'
import { DispatchContext } from './actions'
import { UnitType } from '../../../domain/models/UnitModel'
import { useLocation } from 'react-router'
import Utils from './utils'
import { Unit } from '../../../domain/usecases/Unit'

export interface CompleteUnitsType {
  completeMultiple: boolean
  completeUnit?: UnitType
}

export type ICreateUnitForm = Partial<
  ICreateUnitFormStep1 &
    ICreateUnitFormStep2 &
    ICreateUnitFormStep3 &
    ICreateUnitFormStep4 &
    Pick<UnitType, 'id'>
>
export const CreateUnitContext = React.createContext<
  SetableContext<ICreateUnitForm> & Partial<CompleteUnitsType>
>({})

export default function CreateUnit() {
  const { remoteCreateUnit, state, remoteEditUnit } =
    React.useContext(DispatchContext)

  const location = useLocation<{
    complete_multiple: boolean
    complete_unit?: UnitType
  }>()

  const completeMultiple = location.state
    ? location.state?.complete_multiple
    : false
  const completeUnit = location.state?.complete_unit

  // React.useEffect(() => {
  //   isUpdatePage() && updateStateWithUnit()
  // }, [])

  // const updateStateWithUnit = () =>
  //   setData(Utils.convertUnitObjToExpectedFormatContext(state.unit))

  const isUpdatePage = () => haveUnit() && isNotCompletePage()

  const haveUnit = () => !!state.unit.id

  const isNotCompletePage = () => !completeUnit

  const steps: Step[] = [
    {
      id: 1,
      label: 'Seleção de Unidades',
      component: Step1,
      disableContinueLater: true
    },
    {
      id: 2,
      label: 'Características',
      component: Step2,
      disableContinueLater: true
    },
    {
      id: 3,
      label: 'Área da Unidade',
      component: Step3,
      disableContinueLater: true
    },
    {
      id: 4,
      label: 'Vagas',
      component: Step4,
      disableContinueLater: true
    },
    {
      id: 5,
      label: 'Final',
      component: Step5,
      disableContinueLater: true
    }
  ]

  const [data, setData] = useState<ICreateUnitForm>(
    isUpdatePage()
      ? Utils.convertUnitObjToExpectedFormatContext(state.unit)
      : {}
  )
  const onFinish = () => {
    if (isUpdatePage())
      return remoteEditUnit?.editById(convertDataToEditParams())
    return remoteCreateUnit?.createMany(data)
  }

  const convertDataToEditParams = (): Unit.UnitParams => ({
    ...data,
    characteristics: convertCharacteristicsToString(),
    parking_slots: convertParkingSlots()
  })

  const convertCharacteristicsToString = () => {
    return (data!.characteristics! as unknown as Array<string>).join(',')
  }

  const convertParkingSlots = () => {
    if (!isUpdatePage()) return
    return Object.values(data.parkingSlotByUnit!)[0]
  }

  const handleSave = () => {
    sessionStorage.setItem('CreateUnitForm', JSON.stringify(data))
  }

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Title>{isUpdatePage() ? 'Editar' : 'Cadastrar'} Unidade</Title>
          </Card.Header>
          <Card.Body>
            <CreateUnitContext.Provider
              value={{ data, setData, completeMultiple, completeUnit }}
            >
              <FormWizard
                onSaveDraft={handleSave}
                steps={steps}
                finishOnStep={5}
                onFinish={onFinish}
              />
            </CreateUnitContext.Provider>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
