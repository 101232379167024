import { useContext } from 'react'
import { AxiosHttpClient } from '../../../../infra/http/axiosHttpClient'
import { URL_API } from '../../../../services/api_url'
import { AppContext } from '../../../../app'
import ProjectDetails from '../../../../pages/Projects/ProjectDetails'
import { useParams } from 'react-router'

import { RemoteGetProject } from '../../../../data/usecases/Project/RemoteGetProject'
import { RemoteDeleteProject } from '../../../../data/usecases/Project/RemoteDeleteProject'

type ParamsState = {
  id: string
}

export const ProjectDetailsFactory = () => {
  const { user } = useContext(AppContext)
  const URL = URL_API || 'without-url'
  const TOKEN_USER = user?.token || 'without-token'
  const axiosHttpClient = new AxiosHttpClient(TOKEN_USER)
  const remoteGetProject = new RemoteGetProject(URL, axiosHttpClient)
  const remoteDeleteProject = new RemoteDeleteProject(URL, axiosHttpClient)

  const { id } = useParams<ParamsState>()

  return (
    <ProjectDetails
      getProject={remoteGetProject}
      id={id}
      handleDelete={remoteDeleteProject}
    />
  )
}
