import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Card, Table } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { TableTitle } from '../../../components/atoms/TableTitle'
import { SpeTableRow } from '../../../components/organisms/SpeTableRow'
import { SpeType } from '../../../domain/models/SpeModel'
import { IDeleteSpe } from '../../../domain/usecases/SPE'
import formatStringToCNPJ from '../../../utils/formatStringToCNPJ'
import Information from '../../../components/atoms/Information'

type SpesTableCardProps = {
  spes: SpeType[]
  handleDeleteSpe: IDeleteSpe
}

export const SpesTableCard = ({
  spes,
  handleDeleteSpe
}: SpesTableCardProps) => {
  const { addToast } = useToasts()

  const history = useHistory()

  const handleDelete = (id: string) => {
    handleDeleteSpe
      .deleteById({ id })
      .then((res) =>
        addToast('SPE excluída com sucesso!', {
          appearance: 'success',
          autoDismiss: true
        })
      )
      .catch((error) =>
        addToast('Não foi possível excluir a SPE.', {
          appearance: 'error',
          autoDismiss: true
        })
      )
  }
  return (
    <>
      <Card className="table-card">
        <Card.Header>
          <TableTitle>
            <strong>SPEs ({spes.length})</strong>
          </TableTitle>
        </Card.Header>

        <Card.Body className="pb-0">
          <Table responsive hover className="mb-0">
            <thead>
              <tr>
                <th>RAZÃO SOCIAL</th>
                <th>CNPJ</th>
                <th>ESTADO</th>
                <th>CIDADE</th>
                <th>EMPREENDIMENTOS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {spes.length ? (
                spes.map((spe) => (
                  <SpeTableRow
                    key={spe.id}
                    onClick={() => history.push(`/spes/${spe.id}`)}
                    onClickDelete={() => handleDelete(spe.id)}
                    onClickEdit={() => history.push('/spes/create', { spe })}
                    businessName={spe.business_name}
                    cnpj={formatStringToCNPJ(spe.cnpj)}
                    state={spe.address.state}
                    city={spe.address.city}
                    totalProjects={spe.projects.length}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <Information>Sem registros</Information>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}
