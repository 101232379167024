import { HttpClient } from '../../protocols/http/httpClient'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'
import { UserModel } from '../../../domain/models/UserModel'

export class RemoteGetUser {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async getMe(): Promise<UserModel> {
    return await this.httpClient.get?.({
      url: `${this.url}/users/me`
    })
  }
}
