/* eslint-disable prettier/prettier */
import { getPayments, IGetPayments } from '../../../domain/usecases/Payments'

import { HttpClient } from '../../protocols/http/httpClient'
import { Receivable } from '../../../domain/models/ReceivableModel'

export class RemoteGetPayments implements IGetPayments {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async getPayments(
    params?: getPayments.getPaymentsParams
  ): Promise<Receivable[]> {
    const httpResponse = await this.httpClient.get?.({
      url: `${this.url}/billings`,
      data: params
    })

    return httpResponse
  }
}
