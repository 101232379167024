import React, { useContext, useState } from 'react'

import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import { useToasts } from 'react-toast-notifications'
import { IHistoryPush } from '../../interfaces/router'
import { AppContext } from '../../app'
import { useIsGuest } from '../../hooks/useIsGuest'
import Logo from '../../components/atoms/Logo'
import { Card, Table } from 'react-bootstrap'
import { TextInput } from '../../components/atoms/TextInput'

const ExplorerDetail = ({ history }: { history: IHistoryPush }) => {
  document.title = 'Recebível Chipado - Explorer'
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const { setUser } = useContext(AppContext)

  const [accessDenied, setAccessDenied] = useState<boolean>(false)

  return (
    <div className="auth-wrapper">
      <div style={{ width: '1300px !important' }}>
        <Card>
          <div className="row align-items-center">
            <div className="col-md-12">
              <Card.Body>
                <div style={{ marginBottom: '20px' }}>
                  <Logo />
                </div>
                <h5 className="f-w-700">
                  Transação:
                  0x72672efd2dbaa8772d0d0f320c33cb049b22eb04b8b152c548974e383a252a09
                </h5>
                <Table responsive className="mb-0">
                  <tbody>
                    <tr>
                      <th>Data/Hora</th>
                      <td>10/10/2024 10:10</td>
                    </tr>
                    <tr>
                      <th>Bloco</th>
                      <td>520293</td>
                    </tr>
                    <tr>
                      <th>Tipo de Registro</th>
                      <td>Registro de Recebível</td>
                    </tr>
                    <tr>
                      <th>Objeto</th>
                      <td>Empreendimento Teste</td>
                    </tr>
                    <tr>
                      <th>Valor</th>
                      <td>R$ 55.320,00</td>
                    </tr>
                    <tr>
                      <th>Mais Info</th>
                      <td>...</td>
                    </tr>
                  </tbody>
                </Table>
                <button
                  className="btn btn-primary"
                  onClick={() => history.push('/explorer')}
                >
                  Voltar
                </button>
              </Card.Body>
            </div>
          </div>
        </Card>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default ExplorerDetail
