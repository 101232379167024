import { Button, Col, Row } from 'react-bootstrap'

import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import step0_schema from './step0.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateContractsContext } from '../index'
import { useContext } from 'react'
import {
  SelectField,
  SelectFieldOption
} from '../../../../components/atoms/SelectField'
import { useContextPage } from '../contextPage'
import { useToast } from '../../../../hooks/useToast'
import { Contract } from '../../../../domain/usecases/Contracts'

export type ICreateCompanyFormStep0 = Pick<
  Contract.CreateParams,
  'company_id' | 'is_tokenized'
>

export default function Step0({ nextHandler }: FormWizardHandlers) {
  const { data, setData } = useContext(CreateContractsContext)
  const { getCompanies, setState, state } = useContextPage()
  const { showToast } = useToast()
  const [companies, setCompanies] = useState<SelectFieldOption[]>([])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ICreateCompanyFormStep0>({
    resolver: yupResolver(step0_schema),
    defaultValues: {
      company_id: data?.company_id
    }
  })

  React.useEffect(() => {
    getCompaniesList()
  }, [])

  const onClickNext = (formData: ICreateCompanyFormStep0) => {
    setData?.({ ...data, ...formData })
    nextHandler()
  }

  const getCompaniesList = async () => {
    try {
      const companiesList = await getCompanies.getAll()
      setCompanies(
        companiesList.map((company) => ({
          value: company.id,
          label: company.business_name
        }))
      )
    } catch (error) {
      showToast('Não foi possível carregar as Incorporadoras.', 'error')
    }
  }

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="company_id"
            render={({ field: { onChange, value } }) => (
              <SelectField
                data-testid="select-company"
                value={value}
                label="Selecione a Incorporadora"
                emptyOptionText={'Selecione'}
                defaultValue={data?.company_id || ''}
                options={companies}
                onChange={(event) => {
                  onChange(event)
                  setState({ ...state, selectedCompanyId: event.target.value })
                }}
                errorMessage={errors.company_id?.message}
              />
            )}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col md={6}>
          <SwitchButton
            label="Quero Tokenizar este Contrato"
            onChange={(value) => setTokenized(value)}
          />
        </Col>
      </Row> */}
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperRight>
            <Button variant="primary" onClick={handleSubmit(onClickNext)}>
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperRight>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
