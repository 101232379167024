/* eslint-disable prettier/prettier */
import styled, { css, keyframes } from 'styled-components'


export type ContainerProps = {
  fullWidth?: boolean
  hasError?: boolean
  width?: number | string
}

const containerModifiers = {
  fullWidth: () => css`
    /* min-width: 100%; */
    max-width: 100%;
  `,
  width: (width: number | string) => css`
    max-width: ${typeof width === 'number' ? `${width}px` : width};
    
  `
}

export const Container = styled.div<ContainerProps>`
  ${({ fullWidth, hasError, width }) => css`
    position: relative;

    display: flex;
    align-items: center;

    background-color: #ecf0f5;

    overflow: auto;

    width: 100%;
    max-width: 512px;

    min-height: 40px;

    border-radius: 4px;

    padding: 8px;

    border: 1px solid ${hasError ? 'red' : '#ecf0f5'};

    transition: all 0.2s ease-in-out;

    /* cursor: text; */

    &:focus-within {
      border-color: rgba(0, 0, 0, 0.05);
      box-shadow: 0 2px 5px 0px rgb(69 90 100 / 10%);
    }

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #8b9ed150;
      border-radius: 4px;
    }

    ${!!fullWidth && containerModifiers.fullWidth()};
    ${!!width && containerModifiers.width(width)};
    
  `}
`
export const Outside = styled.div<ContainerProps>`
  ${({ fullWidth, width }) => css`
    position: relative;

    width: 100%;
    max-width: 512px;

    min-height: 40px;

    ${!!fullWidth && containerModifiers.fullWidth()};
    ${!!width && containerModifiers.width(width)};
  `}
`

export const Input = styled.input`
  flex: 1;
  border: 0;
  background: transparent;
  height: 100%;
`

export const Label = styled.label`
  color: #37474f;

  font-size: 14px;
  font-weight: 500;

  margin-bottom: 8px;
`

export const AdditionalInformation = styled.div``

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Tag = styled.button`
  display: flex;
  word-wrap: 0;
  align-items: center;
  white-space: nowrap;
  margin: 0;

  background: #8b9ed1;
  color: #ffffff;

  border: 0;

  padding: 4px 8px;

  
  border-radius: 4px;
  
  cursor: ne-resize;

  transition: filter 0.2s ease-in-out;

  &:not(:first-of-type) {
    margin-left: 8px;
  }

  &:disabled {
    background: #8b9ed180;

    &:hover {
      filter: brightness(1);
    }
  }

  &:hover {
    filter: brightness(0.8);
  }
`

export const CloseIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: #ffffff;
  

  border: 0;
  background: transparent;
  height: 100%;

  

  transition: color 0.2s ease-in-out;
  
  
  svg{
    font-size: 16px !important;

    cursor: pointer;
  }

  &:hover {
    color: #ff000075;
  }
`


export const ErrorMessage = styled.p`
  color: red;
  margin-top: 8px;
`

export const SuggestContainer = styled.div<{ show: boolean }>`
${({ show }) => css`
    position: absolute;
    top: 170px;

    box-shadow: 0 2px 5px 0px rgb(69 90 100 / 10%);

    display: flex;
    flex-direction: column;

    width: 100%;

    border-radius: 4px;

    background-color: #ffffff;


    pointer-events: ${show ? 'auto' : 'none'};
    opacity: ${show ? 1 : 0};

    transform: ${show ? 'translateY(0px)' : 'translateY(10px)'};

    transition: opacity, transform 0.2s ease-in-out;

    z-index: 10;
  
  `}
 `


export const SuggestItem = styled.li<{ activeSuggestion: boolean }>`
  width: 100%;

  list-style: none;


  padding: 4px 4px 4px 16px;
  border-radius: 4px;

  /* background: ${({ activeSuggestion }) =>
    activeSuggestion ? '#8b9ed130' : 'transparent'}; */

    border:3px dashed ${({ activeSuggestion }) =>
    activeSuggestion ? '#8b9ed130' : 'transparent'};

  cursor: pointer;

 

  &:hover {
    background: #8b9ed190;
  }

`
