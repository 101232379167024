import * as yup from 'yup'
import { checkIfDateIsValidBR } from 'utils/checkIfDateIsValidBR'
export default yup.object().shape({
  person: yup.object().shape({
    cpf: yup.string().required('O campo CPF é requerido.'),
    name: yup.string().required('O campo Nome é requerido.'),
    nationality: yup.string().required(' O campo Nacionalidade é requerido.'),
    birth_country: yup.string().required(' O campo Naturalidade é requerido.'),
    birth_date: yup
      .string()
      .transform((value) => (checkIfDateIsValidBR(value) ? value : '')),
    occupation: yup.string().required('O campo Profissão  é requerido.'),
    goods_regime: yup.string().when('marital_status', {
      is: (value: string) => value === 'Casado(a)',
      then: yup.string().required('O campo Comunhão de Bens é requerido.')
    }),
    marital_status: yup.string().required('O campo Estado Civil é requerido.'),
    identity: yup.string().required('O campo Identidade é requerido.'),
    identity_issuer: yup.string().required('O campo Emissor é requerido.'),
    identity_issuer_date: yup
      .string()
      .transform((value) => (checkIfDateIsValidBR(value) ? value : ''))
  })
})
