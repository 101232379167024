import { Button, Col, Row } from 'react-bootstrap'
import { TextInput } from '../../../../components/atoms/TextInput'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './step2.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { InputFile } from '../../../../components/molecules/InputFile'
import { useContext } from 'react'
import { CreateSpeContext } from '../index'
import { COMERCIAL_BOARD_NAME } from 'constants/comercialBoardName'
import { SelectField } from 'components/atoms/SelectField'

export interface ICreateSpeFormStep2 {
  nire: string
  commercial_board_name: string
  commercial_board_identifier: string
  commercial_board_document_url: File
  file_type: string
  file_size: number
}

export default function Step2({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateSpeContext)
  const [file, setFile] = React.useState<File | null>()

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<ICreateSpeFormStep2>({
    resolver: yupResolver(schema),
    defaultValues: {
      commercial_board_document_url: data?.commercial_board_document_url,
      commercial_board_identifier: data?.commercial_board_identifier || '',
      commercial_board_name: data?.commercial_board_name || '',
      file_size: data?.file_size,
      file_type: data?.file_type || '',
      nire: data?.nire || ''
    }
  })

  const commercial_board_document_url = watch('commercial_board_document_url')
  const file_type = watch('file_type')

  const onClickNext = (formData: ICreateSpeFormStep2) => {
    setData?.({
      ...data,
      ...formData,
      commercial_board_document_url: file as File
    })
    nextHandler()
  }

  const existsFileName = () => {
    if (file && !commercial_board_document_url) {
      return ''
    } else if (commercial_board_document_url) {
      if (commercial_board_document_url.name === 'null') {
        return ''
      }

      return commercial_board_document_url.name
    }

    return ''
  }
  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="nire"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="input-nire"
                label="NIRE"
                placeholder="Número de Identificação do Registro"
                errorMessage={errors.nire?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="commercial_board_name"
            render={({ field: { onChange, value } }) => (
              <SelectField
                data-testid="select-commercial-board-name"
                label="Junta Comercial"
                errorMessage={errors.commercial_board_name?.message}
                value={value}
                emptyOptionText={'Selecione'}
                defaultValue={data?.commercial_board_name || ''}
                onChange={onChange}
                options={COMERCIAL_BOARD_NAME.map((item) => ({
                  label: item,
                  value: item
                }))}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="commercial_board_identifier"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="input-commercial-board-identifier"
                type="text"
                label="N.º do Registro na Junta Comercial"
                errorMessage={errors.commercial_board_identifier?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <InputFile
            data-testid="input-file"
            originalFile={data?.id && existsFileName()}
            btnText={
              file
                ? file.name
                : file_type || commercial_board_document_url?.name
                ? 'Trocar Documento'
                : 'Buscar arquivo'
            }
            label="Documento da Junta Comercial (Formato PDF)"
            accept="application/pdf"
            onChange={(file) => {
              setValue('file_type', file.type, {
                shouldValidate: true
              })
              setValue('file_size', file.size, {
                shouldValidate: true
              })
              setFile(file)
            }}
            errorMessage={
              errors.file_type?.message || errors.file_size?.message
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperSpaced>
            <Button variant="link" onClick={backHandler}>
              Voltar
            </Button>
            <Button
              variant="primary"
              data-testid="button-submit"
              onClick={handleSubmit(onClickNext)}
            >
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperSpaced>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
