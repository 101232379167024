import * as yup from 'yup'

export default yup.object().shape({
  real_private_area: yup
    .number()
    .typeError('Digite um número válido.')
    .positive('Digite um número maior que zero.')
    .required('O campo Área Real Privativa é requerido.'),
  ideal_fraction: yup
    .number()
    .typeError('Digite um número válido.')
    .positive('Digite um número maior que zero.')
    .required('O campo Fração Ideal é requerido.'),
  value: yup
    .number()
    .positive('Digite um número maior que zero.')
    .required('O campo Valor da Unidade é requerido.')
})
