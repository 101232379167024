import { Card, Badge } from 'react-bootstrap'
import * as S from './styles'

import addLogoIcon from '../../../assets/images/add_logo_icon.svg'
import addPhotoIcon from '../../../assets/images/add_photo_icon.svg'
import { SeparateBetween } from '../../templates/SeparateBetween'
import { IconWithText, IconWithTextProps } from '../../molecules/IconWithText'
import Switch from '../../Switch'
import React from 'react'

interface BadgeProps {
  status: string
  type: string
}
export interface EntityCardProps {
  id?: string
  title?: string
  subtitle?: string
  badge?: BadgeProps
  line1?: string
  line2?: string
  image?: string
  icons?: IconWithTextProps[]
  withSwitch?: boolean
  leftBtnAction?: () => void
  leftBtnDisabled?: boolean
  rightBtnAction?: () => void
  logoOrPhoto?: 'logo' | 'photo'
  onAddPhoto?: () => void
  onChangeSwitch?: (value: boolean) => void
  disabledSwitch?: boolean
  rightSideContent?: React.ReactNode
  defaultChecked?: boolean
  photoEdition?: boolean
}

export const EntityCard = ({
  title,
  line1,
  line2,
  image,
  icons,
  subtitle,
  badge,
  withSwitch,
  disabledSwitch = false,
  onChangeSwitch,
  leftBtnAction,
  leftBtnDisabled,
  rightBtnAction,
  logoOrPhoto = 'photo',
  photoEdition = true,
  onAddPhoto,
  rightSideContent,
  defaultChecked
}: EntityCardProps) => {
  return (
    <Card>
      {!image && (
        <S.ImageWrapper>
          {photoEdition ? (
            <button type="button" onClick={onAddPhoto}>
              <img src={logoOrPhoto === 'logo' ? addLogoIcon : addPhotoIcon} />
            </button>
          ) : (
            <p>Sem imagem</p>
          )}
        </S.ImageWrapper>
      )}
      {image && (
        <S.Photo image={image}>
          {photoEdition && (
            <i className="feather icon-edit" onClick={onAddPhoto} />
          )}
        </S.Photo>
      )}
      {badge ? (
        <S.Badge>
          <Badge
            variant={badge.type === 'processing_info' ? 'info' : badge.type}
          >
            {badge.status}
          </Badge>
        </S.Badge>
      ) : (
        <div style={{ margin: '0.8rem', height: '21px' }} />
      )}

      <S.ContentWrapper>
        <SeparateBetween>
          <>
            <S.Title>{title}</S.Title>
            <S.Title>{subtitle}</S.Title>
          </>
          {rightSideContent && (
            <div style={{ marginTop: '-15px' }}>{rightSideContent}</div>
          )}
        </SeparateBetween>

        {line1 && <p>{line1}</p>}
        {line2 && <p>{line2}</p>}
        <S.IconsWrapper>
          <SeparateBetween>
            {icons?.map((iconProps) => (
              <IconWithText key={iconProps.icon} {...iconProps} />
            ))}
          </SeparateBetween>
        </S.IconsWrapper>
      </S.ContentWrapper>
      <Card.Footer>
        <SeparateBetween>
          {withSwitch ? (
            <Switch
              value={defaultChecked}
              disabled={disabledSwitch}
              name={'unit'}
              id={title}
              label="Selecionar"
              onChange={(wasSelected) => onChangeSwitch?.(wasSelected)}
            />
          ) : (
            <button
              className="btn  btn-sm "
              onClick={leftBtnAction}
              disabled={leftBtnDisabled}
            >
              Excluir
            </button>
          )}

          <S.SmallButton className="btn btn-sm" onClick={rightBtnAction}>
            Ver
          </S.SmallButton>
        </SeparateBetween>
      </Card.Footer>
    </Card>
  )
}
