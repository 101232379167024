import * as yup from 'yup'

export default yup.object().shape({
  contact_info: yup.object().shape({
    email: yup
      .string()
      .email('Insira um E-mail válido')
      .required('O campo E-mail é requerido.'),
    home_phone: yup.string(),
    mobile_phone: yup.string().required('O campo Celular é requerido.')
  }),
  secondary_contact_info: yup.object().shape({
    allowToRegisterSecondaryContact: yup.boolean(),
    email_second: yup
      .string()
      .email('Insira um E-mail válido')
      .when('allowToRegisterSecondaryContact', {
        is: true,
        then: yup.string().required('O campo E-mail Secundário é requerido.')
      }),
    homePhone_second: yup.string().when('allowToRegisterSecondaryContact', {
      is: true,
      then: yup.string()
    }),
    fone_second: yup.string().when('allowToRegisterSecondaryContact', {
      is: true,
      then: yup.string().required('O campo Celular Secundário é requerido.')
    })
  })
})
