import * as yup from 'yup'

export default yup.object().shape({
  file_size: yup
    .number()
    .required('O campo Arquivo do Contrato é requerido.')
    .max(
      10000000,
      'O arquivo é muito grande. Por favor, selecione um arquivo de até 10 MB.'
    ),
  file_type: yup
    .string()
    .required('O campo Arquivo do Contrato é requerido.')
    .test(
      'fileType',
      'Tipo de arquivo não suportado. Por favor, selecione um arquivo de extensão PDF.',
      (value) => value === 'application/pdf'
    ),

  id: yup.string().required('O campo ID do Contrato é requerido.')
})
