import { URL_API } from '../../../../services/api_url'
import CreateCompany from '../../../../pages/Companies/CreateCompany'
import { RemoteCreateCompany } from '../../../../data/usecases/Company/RemoteCreateCompany'
import { RemoteEditCompany } from '../../../../data/usecases/Company/RemoteEditCompany'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { RemoteCreateCompanyUser } from '../../../../data/usecases/CompanyUser/RemoteCreateCompanyUser'
import { RemoteUpdateCompanyUser } from '../../../../data/usecases/CompanyUser/RemoteUpdateCompanyUser'
import { RemoteDeleteCompanyUser } from '../../../../data/usecases/CompanyUser/RemoteDeleteCompanyUser'

export const CompanyCreateFactory = () => {
  const URL = URL_API || 'without-url'
  const axiosHttpClient = useAxiosHttpClient()
  const remoteCreateCompany = new RemoteCreateCompany(URL, axiosHttpClient)
  const remoteEditCompany = new RemoteEditCompany(URL, axiosHttpClient)
  const remoteCreateCompanyUser = new RemoteCreateCompanyUser(
    URL,
    axiosHttpClient
  )
  const remoteUpdateCompanyUser = new RemoteUpdateCompanyUser(
    URL,
    axiosHttpClient
  )
  const remoteDeleteCompanyUser = new RemoteDeleteCompanyUser(
    URL,
    axiosHttpClient
  )

  return (
    <CreateCompany
      handleCreateCompany={remoteCreateCompany}
      handleUpdateCompany={remoteEditCompany}
      handleCreateCompanyUser={remoteCreateCompanyUser}
      handleUpdateCompanyUser={remoteUpdateCompanyUser}
      handleDeleteCompanyUser={remoteDeleteCompanyUser}
    />
  )
}
