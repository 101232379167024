import styled from 'styled-components'

export const Wrapper = styled.div`
  th {
    font-weight: 400;
  }
  .badge {
    border-radius: 2rem;
    min-width: 5rem;
    padding: 0.4rem;
  }
  .badge-success {
    background-color: #95c567;
    color: #504f4a;
  }

  .badge-info {
    background: #8b9ed133;
    color: #5e6ea7;
  }
  .badge-warning {
    color: #e2b836;
    background: #faf2b4;
  }

  .badge-danger {
    color: #e62355;

    background: rgb(90%, 14%, 33%, 0.2);
  }
`

export const THead = styled.thead`
  th {
    text-align: center;
    vertical-align: middle;
  }
`
export const TBody = styled.tbody`
  td {
    text-align: center;
    vertical-align: middle;
  }
`
