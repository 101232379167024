import * as React from 'react'
import { useContext } from 'react'
import { Form } from 'react-bootstrap'
import { UseFormSetValue } from 'react-hook-form'
import { ICreateProjectFormStep3 } from './step3'
import { CreateProjectContext } from '../index'

interface UpdateProjectStep3Prop {
  onChange: (...args: any) => void
  setValue: UseFormSetValue<ICreateProjectFormStep3 & Record<string, any>>
}

const UpdateProjectStep3 = ({ setValue, onChange }: UpdateProjectStep3Prop) => {
  const { data } = useContext(CreateProjectContext)

  React.useEffect(() => {
    if (!data?.buildings?.length) return
    setValue('buildings', data.buildings)
  }, [data?.buildings])

  return (
    <>
      <Form.Label>
        <strong>Blocos:</strong>
      </Form.Label>
      <Form.Group onChange={onChange}>
        {data?.buildings?.map((building) => ` ${building.name}`).toString()}
      </Form.Group>
    </>
  )
}

export default UpdateProjectStep3
