import { HttpClient } from '../../protocols/http/httpClient'

import { validateHttpStatus } from '../../../validation/validateHttpResponse'
import { Contract, getContract } from '../../../domain/usecases/Contracts'
import { ContractWithPaymentPart } from '../../../domain/models/ContractsModel'

export class RemoteGetContract implements getContract {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpClient
  ) {}

  @validateHttpStatus
  async getOne({ id }: Contract.GetOne): Promise<ContractWithPaymentPart> {
    const httpResponse = await this.httpGetClient.get?.({
      url: `${this.url}/contracts/${id}`
    })

    return httpResponse
  }
}
