import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
`

export const BuyBtn = styled.button`
  color: darkgrey;
  font-weight: bold;
  background: none;
  padding: 0;
  border: none;
  margin: 0;

  :hover {
    cursor: default;
  }
`
