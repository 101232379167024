import api from './api'
import { IContractSignature } from '../pages/SignatureFlow'

export async function getSignature(id: string) {
  const response = await api.get<IContractSignature>(
    `/contract-signatures/${id}`
  )
  return response.data
}

export async function addPhone(id: string, mobile_phone: string) {
  const response = await api.patch(`/contract-signatures/${id}/add-phone`, {
    mobile_phone
  })
  return response.data
}

export async function shouldGenerateCodes(id: string) {
  const response = await api.get(
    `/contract-signatures/${id}/should-generate-codes`
  )
  return response.data
}

export async function generateCodes(id: string) {
  const response = await api.get(`/contract-signatures/${id}/generate-codes`)
  return response.data
}

export async function checkCodes(
  id: string,
  sms_code: number,
  email_code: number
) {
  const response = await api.post(`/contract-signatures/${id}/check-codes`, {
    sms_code,
    email_code
  })
  return response.data
}

export async function isSigned(id: string) {
  const response = await api.get(`/contract-signatures/${id}/is-signed`)
  return response.data
}
