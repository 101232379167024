import { useEffect, useState } from 'react'
import { URL_API } from 'services/api_url'
import { useAxiosHttpClient } from 'hooks/useAxiosHttpClient'

import { Tokens } from 'data/usecases/Tokens'
import { TokenList } from 'pages/Tokens/TokenList'
import { TokenModel } from 'domain/models/TokenModel'

export const TokenListFactory = () => {
  const [tokens, setTokens] = useState<TokenModel[]>([])
  const URL = URL_API || 'without-url'
  const axiosHttpClient = useAxiosHttpClient()
  const remoteToken = new Tokens(URL, axiosHttpClient)

  const getTokens = async () => {
    const response = await remoteToken.getAllByUserId()
    setTokens(response)
  }

  useEffect(() => {
    getTokens()

    return () => {
      setTokens([])
    }
  }, [])

  return <TokenList tokens={tokens} />
}
