import styled from 'styled-components'

export const Wrapper = styled.div`
  .info div {
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .info hr {
    margin-bottom: 2.2rem;
  }
  @media (max-width: 500px) {
    .info div {
      font-size: 14px;
      margin: 1rem;
    }
  }
`

export const Title = styled.h5`
  color: #5e6ea7 !important;
`
