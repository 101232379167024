import React, { Dispatch } from 'react'
import { IEditUnit, Unit } from '../../../domain/usecases/Unit'
import { getProject } from '../../../domain/usecases/Project'
import {
  MinimalProject,
  ProjectType
} from '../../../domain/models/ProjectModel'
import { UnitType } from '../../../domain/models/UnitModel'

export const DispatchContext = React.createContext<{
  state?: any
  dispatch?: any
  remoteCreateUnit?: Unit
  remoteEditUnit?: IEditUnit
  remoteGetProject?: getProject
}>({})

type CreateUnitProps = {
  children: React.ReactNode
  remoteCreateUnit: Unit
  remoteEditUnit?: IEditUnit
  remoteGetProject: getProject
  hasProject?: ProjectType
  hasUnit?: UnitType
}

type Action = {
  type: string
  payload?: any
}
type State = {
  loading: boolean
  minimalProjects: Array<MinimalProject>
  project: ProjectType
  unit: UnitType
  errorMessage: string
}

const Actions = function ({
  remoteCreateUnit,
  remoteGetProject,
  hasProject,
  hasUnit,
  remoteEditUnit,
  children
}: CreateUnitProps) {
  //TODO: create middleware to asycn method and refactoring to switch or enum format
  const [state, dispatch] = React.useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'GET_MINIMAL_PROJECTS':
          remoteGetProject
            .getMinimalProjects()
            .then((res) => dispatch({ type: 'PUT_PROJECT_LIST', payload: res }))
            .catch((error) =>
              dispatch({
                type: 'ERROR',
                payload: 'Não foi possível buscar a lista de Empreendimentos.'
              })
            )
          return { ...state, loading: true }

        case 'GET_PROJECTS_BY_ID':
          remoteGetProject
            .getProjectById({ id: action.payload })
            .then((res) => dispatch({ type: 'PUT_PROJECT', payload: res }))
            .catch((error) =>
              dispatch({
                type: 'ERROR',
                payload: 'Não foi possível buscar o Empreendimento selecionado.'
              })
            )
          return { ...state, loading: true }

        case 'PUT_PROJECT':
          return { ...state, project: action.payload, loading: false }

        case 'PUT_PROJECT_LIST':
          return { ...state, minimalProjects: action.payload, loading: false }

        case 'ERROR':
          return { ...state, loading: false, errorMessage: action.payload }

        default:
          return state
      }
    },
    {
      loading: false,
      minimalProjects: [],
      project: hasProject || {},
      unit: hasUnit || ({} as UnitType),
      errorMessage: ''
    }
  )
  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      remoteCreateUnit,
      remoteGetProject,
      remoteEditUnit
    }),
    [state]
  )
  return (
    <DispatchContext.Provider value={value}>
      {children}
    </DispatchContext.Provider>
  )
}

Actions.displayName = 'Actions'

export default Actions
