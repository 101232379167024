import * as S from '../styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

export type SignatureOkProps = {
  contractUrl: string | undefined
}

const SignatureOk = ({ contractUrl }: SignatureOkProps) => {
  return (
    <S.IconWrapper>
      <CheckCircleOutlineIcon
        style={{ color: '#95C567', fontSize: 96, marginBottom: 10 }}
      />
      <S.IconText>
        Assinatura realizada com sucesso!
        <br />
        <a href={contractUrl} target="_blank" rel="noreferrer">
          Clique aqui para visualizar o Contrato assinado.
        </a>
      </S.IconText>
    </S.IconWrapper>
  )
}
export default SignatureOk
