import { capitalizeFirstLetter } from './CapitalizeFirstLetter'

export const nationalitiesForSelectInput = () => {
  return nationalitiesOrdered.map((nationality) => {
    const nationalityCapitalized = capitalizeFirstLetter(
      nationality.toLowerCase()
    )
    return {
      label: nationalityCapitalized,
      value: nationalityCapitalized
    }
  })
}

const NATIONALITIES = [
  'ABISSÍNIA',
  'AÇORES',
  'AFAR FRANCES',
  'AFEGANISTÃO',
  'ALBÂNIA',
  'ALEMANHA',
  'ALTO VOLTA',
  'ANDORRA',
  'ANGOLA',
  'ANTÁRTICA FRANCESA',
  'ANTÁRTICO ARGENTINO',
  'ANTÁRTICO BRITÂNICO, TERRITÓRIO',
  'ANTÁRTICO CHILENO',
  'ANTÁRTICO NORUEGUES',
  'ANTÍGUA E. DEP. BARBUDA',
  'ANTILHAS HOLANDESAS',
  'APATRIDA',
  'ARÁBIA SAUDITA',
  'ARGÉLIA',
  'ARGENTINA',
  'ARMÊNIA',
  'ARQUIPÉLAGO DE BISMARK',
  'ARQUIPÉLAGO MANAHIKI',
  'ARQUIPÉLAGO MIDWAY',
  'ARUBA',
  'ASCENSÃO E TRISTÃO DA CUNHA,IS',
  'ASHMORE E CARTIER',
  'AUSTRÁLIA',
  'ÁUSTRIA',
  'AZERBAIJÃO',
  'BAHREIN',
  'BANGLADESH',
  'BASHKISTA',
  'BARBADOS',
  'BECHUANALÂNDIA',
  'BÉLGICA',
  'BELIZE',
  'BENIN',
  'BERMUDAS',
  'BHUTAN',
  'BIRMÂNIA',
  'BOLÍVIA',
  'BÓSNIA HERZEGOVINA',
  'BOTSUANA',
  'BRASIL',
  'BRUNEI',
  'BULGÁRIA',
  'BURKINA FASSO',
  'BURUNDI',
  'BURYAT',
  'CABO VERDE',
  'CAMARÕES',
  'CANADÁ',
  'CARELIA',
  'CATAR',
  'CAZAQUISTÃO',
  'CEILÃO',
  'CEUTA E MELILLA',
  'CHADE',
  'CHECHEN INGUSTH',
  'CHILE',
  'CHINA',
  'CHINA (TAIWAN)',
  'CHIPRE',
  'CHUVASH',
  'CINGAPURA',
  'COLÔMBIA',
  'COMUNIDADE DAS BAHAMAS',
  'COMUNIDADE DOMINICANA',
  'CONGO',
  'CORÉIA',
  'COSTA DO MARFIM',
  'COSTA RICA',
  'COVEITE',
  'CROÁCIA',
  'CUBA',
  'CURAÇAO',
  'DAGESTA',
  'DAOMÉ',
  'DEPENDÊNCIA DE ROSS',
  'DINAMARCA',
  'DJIBUTI',
  'EIRE',
  'EMIRADOS ÁRABES UNIDOS',
  'EQUADOR',
  'ESCÓCIA',
  'ESLOVÁQUIA',
  'ESLOVÊNIA',
  'ESPANHA',
  'ESTADO DA CIDADE DO VATICANO',
  'ESTADOS ASSOC. DAS ANTILHAS',
  'ESTADOS UNIDOS DA AMÉRICA (EUA)',
  'ESTÔNIA',
  'ETIÓPIA',
  'FILIPINAS',
  'FINLÂNDIA',
  'FRANÇA',
  'GÂMBIA',
  'GANA',
  'GAZA',
  'GEÓRGIA',
  'GIBRALTAR',
  'GORNO ALTAI',
  'GRÃ-BRETANHA',
  'GRANADA',
  'GRÉCIA',
  'GROENLÂNDIA',
  'GUAM',
  'GUATEMALA',
  'GUIANA FRANCESA',
  'GUINÉ',
  'GUINÉ BISSAU',
  'GUINÉ EQUATORIAL',
  'HOLANDA',
  'HONDURAS',
  'HONDURAS BRITÂNICAS',
  'HONG-KONG',
  'HUNGRIA',
  'IEMEN',
  'IEMEN DO SUL',
  'IFNI',
  'ILHA JOHNSTON E SAND',
  'ILHA MILHOS',
  'ILHAS BAKER',
  'ILHAS BALEARES',
  'ILHAS CANÁRIAS',
  'ILHAS CANTÃO E ENDERBURG',
  'ILHAS CAROLINAS',
  'ILHAS CHRISTMAS',
  'ILHAS COMORES',
  'ILHAS COOK',
  'ILHAS COSMOLEDO (LOMORES)',
  'ILHAS DE MAN',
  'ILHAS DO CANAL',
  'ILHAS DO PACÍFICO',
  'ILHAS FALKLANDS',
  'ILHAS FAROES',
  'ILHAS GILBERT',
  'ILHAS GUADALUPE',
  'ILHAS HOWLAND E JARVIS',
  'ILHAS KINGMAN REEF',
  'ILHAS LINHA305',
  'ILHAS MACDONAL E HEARD',
  'ILHAS MACQUAIRE',
  'ILHAS MALVINAS',
  'ILHAS MARIANAS',
  'ILHAS MARSHALL',
  'ILHAS NIUE',
  'ILHAS NORFOLK',
  'ILHAS NOVA CALEDÔNIA',
  'ILHAS NOVAS HEBRIDAS',
  'ILHAS PALAU',
  'ILHAS PÁSCOA',
  'ILHAS PITCAIRIN',
  'ILHAS SALOMmO',
  'ILHAS SANTA CRUZ',
  'ILHAS SERRANAS',
  'ILHAS TOKELAU',
  'ILHAS TURCA',
  'ILHAS TURKS E CAICOS',
  'ILHAS VIRGENS AMERICANAS',
  'ILHAS VIRGENS BRITÂNICAS',
  'ILHAS WAKE',
  'ILHAS WALLIS E FUTUNA',
  'ÍNDIA',
  'INDONÉSIA',
  'INGLATERRA',
  'IRÃ',
  'IRAQUE',
  'IRLANDA',
  'IRLANDA DO NORTE',
  'ISLÂNDIA',
  'ISRAEL',
  'ITÁLIA',
  'IUGOSLÁVIA',
  'JAMAICA',
  'JAPÃO',
  'JORDÂNIA',
  'KABARDINO BALKAR',
  'KALIMATAN',
  'KALMIR',
  'KARA KALPAK',
  'KARACHAEVOCHERKESS',
  'KHAKASS',
  'KMER/CAMBOJA',
  'KOMI',
  'KUWAIT',
  'LAOS',
  'LESOTO',
  'LETÔNIA',
  'LÍBANO',
  'LIBÉRIA',
  'LÍBIA',
  'LIECHTENSTEIN',
  'LITUÂNIA',
  'LUXEMBURGO',
  'MACAU',
  'MADAGASCAR',
  'MADEIRA',
  'MALÁSIA',
  'MALAWI',
  'MALDIVAS,IS',
  'MALI',
  'MARI',
  'MARROCOS',
  'MARTINICA',
  'MASCATE',
  'MAURÍCIO',
  'MAURITÂNIA',
  'MÉXICO',
  'MIANMA',
  'MOÇAMBIQUE',
  'MOLDÁVIA',
  'MÔNACO',
  'MONGÓLIA',
  'MONTE SERRAT',
  'MONTENEGRO',
  'NAMÍBIA',
  'NAURU',
  'NEPAL',
  'NGUANE',
  'NICARÁGUA',
  'NIGÉRIA',
  'NORUEGA',
  'NOVA GUINÉ',
  'NOVA ZELÂNDIA',
  'OMAN',
  'OSSETIA SETENTRIONAL',
  'PAÍS DE GALES',
  'PAÍSES BAIXOS',
  'PALESTINA',
  'PANAMÁ',
  'PANAMÁ – ZONA DO CANAL',
  'PAPUA NOVA GUINÉ',
  'PAQUISTÃO',
  'PARAGUAI',
  'PERU',
  'POLINÉSIA FRANCESA',
  'POLÔNIA',
  'PORTO RICO',
  'PORTUGAL',
  'PRAÇAS NORTE AFRICANAS',
  'PROTETOR DO SUDOESTE AFRICANO',
  'QUÊNIA',
  'QUIRGUISTÃO',
  'QUITASUENO',
  'REPÚBLICA ÁRABE DO EGITO',
  'REPÚBLICA CENTRO AFRICANA',
  'REPÚBLICA DA ÁFRICA DO SUL',
  'REPÚBLICA DA BIELORRÚSSIA',
  'REPÚBLICA DA MACEDÔNIA',
  'REPÚBLICA DE EL SALVADOR',
  'REPÚBLICA DE FIJI',
  'REPÚBLICA DE MALTA',
  'REPÚBLICA DO GABÃO',
  'REPÚBLICA DO HAITI',
  'REPÚBLICA DO NÍGER',
  'REPÚBLICA DOMINICANA',
  'REPÚBLICA GUIANA',
  'REPÚBLICA TCHECA',
  'REUNIÃO',
  'RODÉSIA (ZIMBÁBWE)',
  'ROMÊNIA',
  'RONCADOR',
  'RUANDA',
  'RUIQUIU,IS',
  'RÚSSIA',
  'SAARA ESPANHOL',
  'SABAH',
  'SAMOA AMERICANA',
  'SAMOA OCIDENTAL',
  'SAN MARINO',
  'SANTA HELENA',
  'SANTA LÚCIA',
  'SÃO CRISTÓVÃO',
  'SÃO TOMÉ E PRÍNCIPE',
  'SÃO VICENTE',
  'SARAWAK',
  'SENEGAL',
  'SEQUIN',
  'SERRA LEOA',
  'SÉRVIA',
  'SEYCHELLES',
  'SÍRIA',
  'SOMÁLIA, REPÚBLICA',
  'SRI-LANKA',
  'ST. PIERRE ET MIQUELON',
  'SUAZILÂNDIA',
  'SUDÃO',
  'SUÉCIA',
  'SUÍÇA',
  'SURINAME',
  'SVALBARD E JAN MAYER,IS',
  'TADJIQUISTÃO',
  'TAILÂNDIA',
  'TANGANICA',
  'TANZÂNIA',
  'TARTARIA',
  'TCHECOSLOVÁQUIA',
  'TERR. ANTÁRTICO DA AUSTRÁLIA',
  'TERRAS AUSTRAIS',
  'TERRIT. BRITÂNICO DO OCEANO ÍNDICO',
  'TERRITÓRIO DE COCOS',
  'TERRITÓRIO DE PAPUA',
  'TIMOR',
  'TOGO',
  'TONGA',
  'TRANSKEI',
  'TRÉGUA, ESTADO',
  'TRINIDAD E TOBAGO',
  'TUNÍSIA',
  'TURCOMENISTÃO',
  'TURQUIA',
  'TUVALU',
  'TUVIN',
  'UCRÂNIA',
  'UDMURT',
  'UGANDA',
  'UNIÃO SOVIÉTICA',
  'URUGUAI',
  'UZBEQUISTÃO',
  'VENEZUELA',
  'VIETNÃ DO NORTE',
  'VIETNÃ DO SUL',
  'YAKUT',
  'ZAIRE',
  'ZÂMBIA',
  'ZIMBÁBWE'
]

const nationalitiesOrdered = NATIONALITIES.sort((current, next) => {
  return current.localeCompare(next)
})
