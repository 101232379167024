import { Controller, useForm } from 'react-hook-form'
import { Button, Col, Row } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import { AppearFromDown } from '../../../../components/templates/AppearFromDown'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateProjectContext } from '../index'
import schema from './step5.schema'
import TagInput from '../../../../components/molecules/TagInput'
import SwitchButton from '../../../../components/molecules/SwitchButton'
import { AppearFromRight } from '../../../../components/templates/ApperFromLeft'
import * as React from 'react'
import { useCreationConfirmModal } from '../../../../hooks/useCreationConfirmModal'
import { Tag } from 'react-tag-autocomplete'
import { parkingSlotsGroup } from '../../../../domain/models/ProjectModel'
import Information from '../../../../components/atoms/Information'
import { NumberInput } from '../../../../components/atoms/NumberInput'
import { Input } from '@material-ui/core'

type ParkingSlots = {
  name: string
  id?: string
  group_id?: string
  group?: parkingSlotsGroup
}

type errorType = Record<string, boolean>

export type ParkingSlotsGrouped = {
  id?: string
  name: string
  parking_slots: ParkingSlots[]
}
export interface ICreateProjectFormStep5 {
  parking_slots: ParkingSlots[]
  grouped_parking_slots?: boolean
  parking_slots_groups: ParkingSlotsGrouped[]
  boxes?: { name?: string; id?: string }[]
}

export default function Step5({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  /* eslint-disable */
  const { data, setData } = useContext(CreateProjectContext)
  const [parkingSlotsGrouped, setParkingSlotsGrouped] = useState<
    ParkingSlotsGrouped[]
  >(data?.parking_slots_groups || [])
  const [
    allowRegisterGroupedParkingSlots,
    setAllowRegisterGroupedParkingSlots
  ] = useState(data?.grouped_parking_slots || false)
  const [error, setError] = useState<errorType>({})
  const [slotsQuantity, setSlotsQuantity] = useState<number>()

  const [prefix, setPrefix] = useState('')
  const [suffix, setSuffix] = useState('')

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<ICreateProjectFormStep5>({
    defaultValues: {
      ...data
    },
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (!allowRegisterGroupedParkingSlots) {
      setParkingSlotsGrouped([])
      setValue('grouped_parking_slots', false)
    }
  }, [allowRegisterGroupedParkingSlots])

  const validateParkingSlotsGrouped = (parkingSlots: ParkingSlotsGrouped[]) => {
    const hasError: errorType = {}
    parkingSlots.forEach(
      (item) => !item.parking_slots.length && (hasError[item.name] = true)
    )
    return hasError
  }

  const { setShowConfirmationModal, Modal } = useCreationConfirmModal({
    entityText: 'do Empreendimento',
    onFinish: nextHandler
  })

  const onClickNext = (formData: ICreateProjectFormStep5) => {
    if (allowRegisterGroupedParkingSlots) {
      const errors = validateParkingSlotsGrouped(parkingSlotsGrouped)
      if (!!Object.keys(errors).length) return setError(errors)
      setError({})

      formData = {
        ...getValues(),
        parking_slots: [],
        parking_slots_groups: parkingSlotsGrouped
      }
      setData?.({
        ...data,
        ...formData,
        building_permit: data?.building_permit,
        ri_document: data?.ri_document
      })
      setShowConfirmationModal(true)
      return
    }
    formData = { ...getValues(), parking_slots_groups: [] }
    setData?.({
      ...data,
      ...formData,
      building_permit: data?.building_permit,
      ri_document: data?.ri_document
    })
    setShowConfirmationModal(true)
  }

  const defaultTagSlots = () => {
    const isUpdatePageAndIsNotGroupedSlots = data?.grouped_parking_slots
    if (isUpdatePageAndIsNotGroupedSlots) return []
    if (!data?.parking_slots) return []
    return data?.parking_slots?.map((slot) => ({
      id: slot.id!,
      name: slot.name
    }))
  }

  return (
    <>
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={4}>
            <Controller
              control={control}
              name="grouped_parking_slots"
              render={({ field: { onChange } }) => (
                <SwitchButton
                  data-testid="grouped_parking_slots"
                  defaultSwitch={allowRegisterGroupedParkingSlots}
                  label="As vagas são divididas em boxes"
                  onChange={() => {
                    setAllowRegisterGroupedParkingSlots(
                      !allowRegisterGroupedParkingSlots
                    )
                    onChange(!allowRegisterGroupedParkingSlots)
                  }}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          {!allowRegisterGroupedParkingSlots && (
            <Col className="mt-5" md={12}>
              <AppearFromDown>
                <Controller
                  name="parking_slots"
                  control={control}
                  defaultValue={defaultTagSlots()}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        <TagInput
                          rerenderOnValuesChange={true}
                          onChange={onChange}
                          label="Vagas"
                          info={
                            <Information>
                              Digite o número de identificação de todas as vagas
                              e aperte a tecla <strong>ENTER</strong> após cada
                              número ou utilize os campos de geração automática.
                            </Information>
                          }
                          errorMessage={(errors.parking_slots as any)?.message}
                          defaultTags={defaultTagSlots()}
                          values={value.map((el) => ({
                            id: el.id as string,
                            name: el.name
                          }))}
                        />
                        <Row>
                          <Col md={12}>
                            <span
                              style={{
                                color: '#37474f',
                                fontSize: '14px',
                                fontWeight: 500
                              }}
                            >
                              Campos de geração automática
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={1}>
                            <Input
                              value={prefix}
                              onChange={(ev) => setPrefix(ev.target.value)}
                              placeholder="Prefixo"
                              style={{ marginTop: '10px' }}
                            />
                          </Col>
                          <Col md={3}>
                            <NumberInput
                              placeholder="Use esse campo para adicionar várias unidades automáticamente"
                              value={slotsQuantity}
                              onChange={(ev) =>
                                setSlotsQuantity(parseInt(ev.target.value, 10))
                              }
                            />
                          </Col>
                          <Col md={1}>
                            <Input
                              style={{ marginTop: '10px' }}
                              value={suffix}
                              onChange={(event) =>
                                setSuffix(event.target.value)
                              }
                              placeholder="Sufixo"
                            />
                          </Col>
                          <Col md={3}>
                            <Button
                              variant="info"
                              type="button"
                              onClick={() => {
                                const values = Array.from(
                                  { length: slotsQuantity || 0 },
                                  (_, i) => i + 1
                                )
                                setValue(
                                  'parking_slots',
                                  values.map((value) => ({
                                    id: value.toString(),
                                    name: `${prefix}${value}${suffix}`
                                  }))
                                )
                              }}
                            >
                              Gerar automaticamente
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )
                  }}
                />
              </AppearFromDown>
            </Col>
          )}
        </Row>
        <Row>
          {allowRegisterGroupedParkingSlots && (
            <Col className="mt-5" md={12} data-testid="input_box">
              <AppearFromDown>
                <Controller
                  name="parking_slots_groups"
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <TagInput
                        onChange={(tags) => {
                          setParkingSlotsGrouped(
                            tags.map((item) => ({
                              ...item,
                              parking_slots: item.parking_slots || []
                            }))
                          )
                          onChange(tags)
                        }}
                        label="Boxes"
                        info={
                          <Information>
                            Digite o número de identificação de todos os Boxes e
                            aperte a tecla <strong>ENTER</strong> após cada
                            número.
                          </Information>
                        }
                        defaultTags={
                          (data?.parking_slots_groups as Tag[]) || []
                        }
                        errorMessage={
                          (errors.parking_slots_groups as any)?.message
                        }
                      />
                    )
                  }}
                />
              </AppearFromDown>
            </Col>
          )}
        </Row>
        {!!parkingSlotsGrouped.length &&
          parkingSlotsGrouped.map((parking, index) => {
            const errorMsg = error[parking.name]
              ? `O campo ${parking.name} é requerido.`
              : ''
            return (
              <Row key={index}>
                <Col md={12} data-testid={`input-${parking.name}`}>
                  <AppearFromRight>
                    <TagInput
                      label={`Box ${parking.name}`}
                      info={
                        <Information>
                          Digite o número de identificação das vagas deste box e
                          tecle “<strong>Enter</strong>” após cada número.
                        </Information>
                      }
                      errorMessage={errorMsg}
                      defaultTags={
                        (data?.parking_slots?.filter(
                          (slot) => slot.group_id === parking.id
                        ) as Tag[]) || []
                      }
                      onChange={(tags) => {
                        const updatedItems = parkingSlotsGrouped.map((slot) =>
                          slot.name === parking.name
                            ? {
                                ...parking,
                                parking_slots: tags
                              }
                            : slot
                        )
                        setParkingSlotsGrouped(updatedItems)
                      }}
                    />
                  </AppearFromRight>
                </Col>
              </Row>
            )
          })}
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
      {Modal}
    </>
  )
}
