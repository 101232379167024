import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  border-top: 1px solid #ced4da;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ced4da;
  padding: 5px 0;
`

export const ItemHeader = styled.button`
  ${({ active }: { active: boolean }) => css`
    border-radius: 5px;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${active ? '10px 0' : '0'};
    border: none;
    background: ${active ? '#5E6EA7' : 'none'};
    text-align: left;

    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: ${active ? 'white' : '#5e6ea7'};
    }
  `}
`

export const ItemButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  i {
    margin-right: 15px;
    font-size: 24px;
    color: #ecf0f5;
  }
`

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`

export const SubItem = styled.button`
  width: 70%;
  display: flex;
  justify-content: space-between;
  border: none;
  background: none;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 500;
  color: #5e6ea7;

  :hover {
    opacity: 0.7;
  }

  i {
    color: #5e6ea7;
    font-size: 24px;
  }
`
