import styled, { css } from 'styled-components'

export const ImageWrapper = styled.div`
  ${({ image }: { image?: string }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${image ? `url(${image})` : '#ecf0f5'};
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 150px;

    img {
      width: 100%;
    }

    button {
      border: none;
      background: none;
    }
  `}
`

export const Photo = styled.div`
  ${({ image }: { image?: string }) => css`
    display: flex;
    background: ${image ? `url(${image})` : '#ecf0f5'};
    justify-content: flex-end;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 150px;
    padding: 8px;
    img {
      width: 100%;
    }

    button {
      border: none;
      background: none;
    }

    i {
      font-size: 24px;
      cursor: pointer;
    }
  `}
`

export const ContentWrapper = styled.div`
  padding: 15px;

  p {
    font-size: 14px;
    margin-bottom: 5px;
  }
`
export const Badge = styled.div`
  margin: 0.8rem;

  .badge-success {
    background-color: #95c567;
    color: #504f4a;
  }

  .badge {
    min-width: 5rem;
  }

  .badge-secondary {
    background: #8b9ed133;
    color: #5e6ea7;
  }

  .badge-danger {
    color: #e62355;

    background: rgb(90%, 14%, 33%, 0.1);
  }
`
export const Title = styled.div`
  color: #5e6ea7;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.9rem;
`

export const IconsWrapper = styled.div`
  margin-top: 15px;
`

export const SmallButton = styled.button`
  :hover {
    background-color: #4f5d90;
    color: #f4f4f4;
  }
  background-color: #5e6ea7;
  padding: 10px 1rem;
  color: #f4f4f4;
  font-weight: 600;
`
