import { Form, FormControl, InputGroup } from 'react-bootstrap'
import * as React from 'react'
import { ChangeEventHandler } from 'react'
import ErrorText from '../ErrorText'

interface PasswordFieldProps {
  onChange: ChangeEventHandler<HTMLInputElement>
  value: string
  label?: string
  placeholder?: string
  errorMessage?: string
  disabled?: boolean
  autoComplete?: string
}

export const PasswordField = ({
  onChange,
  value,
  label = 'Senha',
  placeholder = 'Sua Senha',
  errorMessage,
  disabled = false,
  autoComplete = 'off'
}: PasswordFieldProps) => {
  const [passwordInputType, setPasswordInputType] = React.useState('password')
  const togglePasswordView = () =>
    passwordInputType == 'password'
      ? setPasswordInputType('text')
      : setPasswordInputType('password')

  return (
    <Form.Group>
      <Form.Label>
        <strong>{label}</strong>
      </Form.Label>
      <InputGroup>
        <FormControl
          disabled={disabled}
          placeholder={placeholder}
          type={passwordInputType}
          aria-label="password"
          isInvalid={!!errorMessage}
          onChange={onChange}
          value={value}
          autoComplete={autoComplete}
        />
        <button
          disabled={disabled}
          type="button"
          tabIndex={-1}
          className="btn"
          onClick={togglePasswordView}
        >
          <i
            className={
              passwordInputType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'
            }
          />
        </button>
      </InputGroup>
      {errorMessage && <ErrorText text={errorMessage} />}
    </Form.Group>
  )
}
