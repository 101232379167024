import { HttpClient } from '../../protocols/http/httpClient'
import { Contract, ICreateContracts } from '../../../domain/usecases/Contracts'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'
import { Contract as ContracType } from '../../../domain/models/ContractsModel'
import moment from 'moment'

export class RemoteCreateContract implements ICreateContracts {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async createOne(params: Contract.CreateParams): Promise<ContracType> {
    const {
      buyers = [],
      payment_parts = [],
      custom_contract_file,
      deadline_allows_modify_unit,
      ...properties
    } = params
    const formData = new FormData()
    formData.append(`custom_contract_file`, custom_contract_file!)

    for (let i = 0; i < buyers.length; i++) {
      formData.append(`buyers[${i}]`, buyers[i])
    }

    formData.append(
      `deadline_allows_modify_unit`,
      moment(deadline_allows_modify_unit).toISOString()
    )

    for (let i = 0; i < payment_parts.length; i++) {
      formData.append(
        `payment_parts[${i}][first_due_date]`,
        moment(payment_parts[i].first_due_date).toISOString()
      )
      formData.append(
        `payment_parts[${i}][frequency]`,
        payment_parts[i].frequency
      )
      formData.append(
        `payment_parts[${i}][installment_value]`,
        payment_parts[i].installment_value.toString()
      )
      formData.append(
        `payment_parts[${i}][installments]`,
        payment_parts[i].installments.toString()
      )
      formData.append(`payment_parts[${i}][name]`, payment_parts[i].name)
      formData.append(
        `payment_parts[${i}][total_value]`,
        payment_parts[i].total_value.toString()
      )
    }

    console.log({
      properties
    })

    const props: Record<string, any> = properties
    for (const key in props) {
      if (typeof props[key] === 'object') {
        for (const subKey in props[key]) {
          formData.append(`${key}[${subKey}]`, props[key][subKey])
        }
      } else {
        formData.append(key, props[key])
      }
    }

    return await this.httpClient.post?.({
      url: `${this.url}/contracts/`,
      data: formData,
      headers: {
        Accept: '*/*'
      }
    })
  }
}
