import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import Payments from '../../../../pages/Payments'
import { RemoteGetPayments } from '../../../../data/usecases/Payments/RemoteGetPayments'

export const ListPaymentsFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetPayments = new RemoteGetPayments(URL, axiosHttpClient)

  return <Payments remoteGetPayments={remoteGetPayments} />
}
