import { Button, Card, Col, Row } from 'react-bootstrap'

import * as S from './style'
import iconToken from '../../../assets/images/icon-token.svg'
import moment from 'moment'
import { convertNumberToCurrencyFormat } from '../../../utils/convertNumberToCurrencyFormat'

interface TokenCardProps {
  symbol: string
  contract_final_date: Date
  unit_value: number
  total_supply: number
  available_supply: number
  expected_profit: number
}

export const TokenCard = ({
  symbol,
  available_supply,
  total_supply,
  contract_final_date,
  expected_profit,
  unit_value
}: TokenCardProps) => {
  return (
    <>
      <Card>
        <Card.Body>
          <div className="media align-items-center p-0">
            <div className="text-center">
              <img
                src={iconToken}
                style={{ width: '30px', marginRight: '15px' }}
              />
            </div>
            <S.HeaderWrapper>
              <div>
                <h3 className="m-0 text-bold">{symbol}</h3>
                <span>Token de antecipação de recebíveis</span>
                <small className="d-block">
                  Término do Contrato:{' '}
                  {moment(contract_final_date).format('DD/MM/YYYY')}
                </small>
              </div>
            </S.HeaderWrapper>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div>
              <p className="m-0 font-weight-600">
                {convertNumberToCurrencyFormat(unit_value / 100)} / 0.01 unidade
              </p>
              <p className="m-0">
                {available_supply} Unidades disponíveis ({total_supply} total)
              </p>
            </div>
            <div className="text-right">
              <p className="m-0 font-weight-600">
                <span style={{ color: '#00b100', fontWeight: 'bold' }}>
                  {expected_profit}% de lucro esperado
                </span>
              </p>
              <S.BuyBtn disabled>comprar</S.BuyBtn>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}
