import { HttpClient } from '../../protocols/http/httpClient'

import { validateHttpStatus } from '../../../validation/validateHttpResponse'
import { getContracts } from '../../../domain/usecases/Contracts'
import { Contract } from '../../../domain/models/ContractsModel'

export class RemoteGetContracts implements getContracts {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpClient
  ) {}

  @validateHttpStatus
  async getAll(): Promise<Contract[]> {
    const httpResponse = await this.httpGetClient.get?.({
      url: `${this.url}/contracts`
    })

    return httpResponse
  }
}
