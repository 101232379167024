import { Button, Row, Col } from 'react-bootstrap'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { Link } from 'react-router-dom'

import * as S from './styles'
import checkCircleIcon from '../../../../assets/images/check-circle.svg'

export default function Step3() {
  return (
    <FormWizardStyles.TabContent>
      <S.Wrapper>
        <img src={checkCircleIcon} />
        <p>
          <strong>Dados do Empreendimento cadastrados com sucesso!</strong>
        </p>
        <p>
          Você já pode cadastrar os detalhes das Unidades deste Empreendimento.
          Deseja fazer isso agora?
        </p>
        <Link to="/units/create">
          <Button variant="primary">Cadastrar Detalhes das Unidades</Button>
        </Link>
        <Link to="/projects">
          <a>Ver Empreendimento</a>
        </Link>
      </S.Wrapper>
    </FormWizardStyles.TabContent>
  )
}
