/* eslint-disable prettier/prettier */
import * as React from 'react'
import Chart from 'react-apexcharts'
import { convertNumberToCurrencyFormat } from '../../utils/convertNumberToCurrencyFormat'
export type ChartProps = React.ComponentProps<typeof Chart>

export const getChartStructure = (
  data: number[] | string[],
  name: string,
  dates: string[] = [
    '03/11/2021',
    '02/11/2021',
    '01/11/2021',
    '31/10/2021',
    '30/10/2021',
    '29/10/2021',
    '28/10/2021'
  ]
): ChartProps => {
  const dataWithDates = dates.length
    ? data.map((value, index) => ({
        x: dates[index],
        y: value
      }))
    : data

  return {
    type: 'line',
    height: 100,
    options: {
      chart: {
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#5E6EA7'],
      stroke: {
        curve: 'smooth',
        width: 2
      },
      yaxis: {
        labels: {
          formatter: (value) => convertNumberToCurrencyFormat(value)
        }
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: !!dates.length,
          format: 'dd MMM yyyy'
        },
        marker: {
          show: false
        }
      }
    },

    series: [
      {
        name,
        data: dataWithDates
      } as any
    ]
  }
}
