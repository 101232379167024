import { SpaceEvenly } from '../../templates/SpaceEvenly'
import { IconButton } from '../../atoms/IconButton'
import * as React from 'react'

interface SpeTableRowProps {
  businessName: string
  cnpj: string
  state: string
  city: string
  totalProjects: number
  onClickDelete?: () => void
  onClickEdit?: () => void
  onClick?: () => void
}

export const SpeTableRow = (props: SpeTableRowProps) => {
  return (
    <tr style={{ cursor: 'pointer' }} onClick={props.onClick}>
      <td>{props.businessName}</td>
      <td>{props.cnpj}</td>
      <td>{props.city}</td>
      <td>{props.state}</td>
      <td>{props.totalProjects}</td>
      <td>
        <SpaceEvenly>
          {/* <IconButton
            icon="icon-trash"
            color="#E62355"
            onClick={props.onClickDelete}
            disabled={props.totalProjects <= 0}
          /> */}
          <IconButton
            icon="icon-edit"
            color="#5E6EA7"
            onClick={props.onClickEdit}
          />
        </SpaceEvenly>
      </td>
    </tr>
  )
}
