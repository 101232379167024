import { Form } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import React, { InputHTMLAttributes } from 'react'
import ErrorText from '../ErrorText'

type TextFieldTypes = InputHTMLAttributes<HTMLInputElement>

export type TextInputWithMaskProps = {
  label?: string
  mask: string
  placeholder?: string
  errorMessage?: string
  disabled?: boolean
} & Omit<TextFieldTypes, 'size'>

export const TextInputWithMask = ({
  label,
  mask,
  placeholder,
  errorMessage,
  ...other
}: TextInputWithMaskProps) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          <strong>{label}</strong>
        </Form.Label>
      )}
      <InputMask
        className="form-control"
        mask={mask}
        autoComplete={label}
        placeholder={placeholder}
        {...other}
      />
      {errorMessage && <ErrorText text={errorMessage} />}
    </Form.Group>
  )
}
