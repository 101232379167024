/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { Controller, Control, UseFormSetValue } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'

import Utils from './utils'
import { CurrencyInput } from '../../../../../components/atoms/CurrencyInput'
import { SelectField } from '../../../../../components/atoms/SelectField'
import { FREQUENCY_PAYMENTS } from '../../../../../constants/frequency_payments'
import { convertNumberToCurrencyFormat } from '../../../../../utils/convertNumberToCurrencyFormat'
import {
  errorType,
  ICreateContractBillingPersonId,
  ICreateContractsFormStep5
} from '../step5'
import { ContractPaymentPart } from 'domain/models/PaymentPartsModel'


import * as S from './styles'
import { TextInput } from '../../../../../components/atoms/TextInput'

type PaymentDetailsFormProps = {
  setValue?: UseFormSetValue<
    ICreateContractsFormStep5 &
    ICreateContractBillingPersonId &
    Record<string, any>
  >
  control?: Control<
    ICreateContractsFormStep5 &
    ICreateContractBillingPersonId &
    Record<string, any>
  >
  index: number
  fieldName: string
  errors?: errorType
  paymentMethod?: string
  defaultValue?: ContractPaymentPart
}

export default function PaymentDetailsForm({
  control,
  errors,
  paymentMethod = '',
  setValue,
  fieldName,
  defaultValue
}: PaymentDetailsFormProps) {
  const {
    installmentDates,
    multiplyValueByInstallment,
    createInitialStateInstallments,
    createInitialStateInstallmentsValue,
    createInitialStateDate,
    createInitialStateFrequency,
    createInitialStateTotalValue
  } = Utils(defaultValue)

  const [installments, setInstallments] = React.useState(createInitialStateInstallments())
  const [installmentsValue, setInstallmentsValue] = React.useState(createInitialStateInstallmentsValue())
  const [dateSelected, setDateSelected] = React.useState<Date | null>(createInitialStateDate())
  const [frequency, SetFrequency] = React.useState(createInitialStateFrequency())
  const [datesTag, setDatesTag] = React.useState<string[]>([])
  const [totalValue, setTotalValue] = React.useState(createInitialStateTotalValue())
  const [showTotalDates, setShowTotalDates] = React.useState(false)

  useEffect(() => {
    calculatedTotalValue()
  }, [installments, installmentsValue])

  React.useEffect(() => {
    createdDatesList()
  }, [dateSelected, frequency, installments])

  useEffect(() => {
    setValue?.(`${fieldName}.name`, paymentMethod)
    setValue?.(`${fieldName}.first_due_date`, dateSelected)
    setValue?.(`${fieldName}.installments`, installments)
    setValue?.(`${fieldName}.installment_value`, installmentsValue)
  }, [paymentMethod, dateSelected, installments, installmentsValue])

  const createdDatesList = () => {
    const currentDate = new Date(String(dateSelected))
    const hasMoreThanOneInstallment = installments > 1 && frequency > 0
    if (hasMoreThanOneInstallment) {
      const datesResult = installmentDates({
        date: currentDate,
        amountOfDates: installments,
        frequency: Number(frequency)
      })

      setDatesTag(datesResult)
      return
    }
    setDatesTag([])
  }

  const handleShowTotalDates = () =>
    setShowTotalDates((prevState) => !prevState)

  const calculatedTotalValue = () => {
    const totalValueResult = multiplyValueByInstallment(
      installments,
      installmentsValue
    )
    setValue?.(`${fieldName}.total_value`, totalValueResult)
    setTotalValue(totalValueResult)
  }
  return (
    <>
      <fieldset name={fieldName} key={fieldName}>
        <Row className="mt-5 mb-3">
          <Col>
            <span className="lead">{paymentMethod}</span>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Controller
              control={control}
              name={`${fieldName}.frequency`}
              defaultValue={defaultValue?.frequency || ''}
              render={({ field: { onChange, value } }) => {
                const frequencyNumber = Number(FREQUENCY_PAYMENTS[value])
                SetFrequency(frequencyNumber)
                return (
                  <SelectField
                    data-testid={`${paymentMethod}_frequency`}
                    onChange={onChange}
                    value={value}
                    emptyOptionText={'Selecione'}
                    label="Periodicidade"
                    options={[
                      { label: 'Única', value: 'one' },
                      { label: 'Mensal', value: 'monthly' },
                      { label: 'Trimestral', value: 'quarterly' },
                      { label: 'Quinquemestral', value: 'quinterly' },
                      { label: 'Semestral', value: 'semester' },
                      { label: 'Anual', value: 'year' }
                    ]}
                  />
                )
              }}
            />
          </Col>

          <Col sm={12} md={2}>
            <Controller
              control={control}
              name={`${fieldName}.installments`}
              render={({ field: { onChange, value } }) => {
                const DEFAULT_INSTALLMENT = 1
                const SINGLE_PAYMENT = frequency === 0
                setInstallments(
                  SINGLE_PAYMENT ? DEFAULT_INSTALLMENT : installments
                )
                return (
                  <TextInput
                    data-testid={`${paymentMethod}_installments`}
                    type="number"
                    min="1"
                    label="Parcelas"
                    onChange={(event) =>
                      setInstallments(Number(event.target.value))
                    }
                    value={SINGLE_PAYMENT ? DEFAULT_INSTALLMENT : installments}
                    disabled={SINGLE_PAYMENT}
                  />
                )
              }}
            />
          </Col>
          <Col sm={12} md={3}>
            <Controller
              control={control}
              name={`${fieldName}.installment_value`}
              render={({ field: { onChange, value } }) => {
                return (
                  <CurrencyInput
                    defaultValue={String(defaultValue?.installment_value)}
                    data-testid={`${paymentMethod}_installment_value`}
                    disabled={!installments}
                    label="Valor da Parcela (R$)"
                    onChange={(value) => setInstallmentsValue(Number(value))}
                  />
                )
              }}
            />
          </Col>
          <Col sm={12} md={3}>
            <TextInput
              defaultValue={String(defaultValue?.total_value)}
              label="Valor Total (R$)"
              disabled={true}
              value={convertNumberToCurrencyFormat(totalValue)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <S.DateInput>
              <label>
                <strong>Primeiro Vencimento</strong>
              </label>
              <Controller
                control={control}
                name={`${fieldName}.first_due_date`}
                render={({ field: { onChange } }) => {
                  return (
                    <ReactDatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={dateSelected}
                      onChange={(date: Date) => {
                        setDateSelected(date)
                        return onChange(date)
                      }}
                    />
                  )
                }}
              />
            </S.DateInput>
          </Col>
          <Col>
            <label>
              <strong>Demais Vencimentos</strong>
            </label>
            <S.DateList showAll={showTotalDates}>
              <ul className="tags">
                {datesTag.length && !showTotalDates
                  ? datesTag.slice(0, 9).map((date) => {
                    return (
                      <li key={date} className="tag">
                        <span className="">{date}</span>
                      </li>
                    )
                  })
                  : datesTag.map((date) => {
                    return (
                      <li key={date} className="tag">
                        <span className="">{date}</span>
                      </li>
                    )
                  })}
                {!showTotalDates && datesTag.length >= 10 ? (
                  <li className="tag" onClick={handleShowTotalDates}>
                    <S.TotalDates>
                      {`+${datesTag.length - 9} restantes`}
                    </S.TotalDates>
                  </li>
                ) : null}
                {showTotalDates && datesTag.length ? (
                  <li className="tag" onClick={handleShowTotalDates}>
                    <S.TotalDates>
                      Mostrar menos
                    </S.TotalDates>
                  </li>
                ) : null}
              </ul>
            </S.DateList>
          </Col>
        </Row>
      </fieldset>
      <span style={{ color: 'red' }}>
        {!!errors?.[paymentMethod] && `É necessário preencher todos os campos`}
      </span>
    </>
  )
}
