import { OCCUPATIONS } from 'constants/occupations'
export const occupationsForSelectInput = () => {
  const occupations = orderedOccupations
    .map((occupation) => ({
      label: occupation,
      value: occupation
    }))
    .filter((occupation) => occupation.value !== 'Outra')
  occupations.push(outraItemShouldBeLast)
  return occupations
}

const orderedOccupations = OCCUPATIONS.sort((current, next) => {
  return current.localeCompare(next)
})

const outraItemShouldBeLast = {
  label: 'Outra',
  value: 'Outra'
}
