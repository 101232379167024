import React from 'react'
import { Alert, Card, Table } from 'react-bootstrap'
import * as S from './styles'

interface TableCardTemplateProps {
  columns: string[]
  rows: React.ReactNode
  withPagination?: boolean
  pagination?: number
}

export default function TableCardTemplate({
  columns,
  rows,
  withPagination = true,
  pagination
}: TableCardTemplateProps) {
  return (
    <S.Wrapper>
      <Card className="table-card">
        <Card.Body className="pb-0">
          <Table responsive hover className="mb-0">
            <S.THead>
              <tr>
                {columns.map((column) => (
                  <th key={column}>{column}</th>
                ))}
              </tr>
            </S.THead>
            <S.TBody>{rows}</S.TBody>
          </Table>
          {!(rows as any[]).length && (
            <Alert variant={'warning'} style={{ margin: '16px' }}>
              Sem Registros
            </Alert>
          )}
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
