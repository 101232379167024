import { RemoteCreateUnit } from '../../../../data/usecases/Unit/RemoteCreateUnit'
import { RemoteGetUnits } from '../../../../data/usecases/Unit/RemoteGetUnits'
import { RemoteDeleteUnit } from '../../../../data/usecases/Unit/RemoteDeleteUnit'
import { RemoteGetEntitiesCount } from '../../../../data/usecases/Reports/RemoteGetEntitiesCount'

import { URL_API } from '../../../../services/api_url'
import ListUnits from '../../../../pages/Units/ListUnits/listUnits'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'

export const ListUnitsFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetUnits = new RemoteGetUnits(URL, axiosHttpClient)
  const remoteDeleteUnit = new RemoteDeleteUnit(URL, axiosHttpClient)
  const remoteGetEntitiesCount = new RemoteGetEntitiesCount(
    URL,
    axiosHttpClient
  )
  const remoteCreateUnit = new RemoteCreateUnit(URL, axiosHttpClient)

  return (
    <ListUnits
      remoteGetEntitiesCount={remoteGetEntitiesCount}
      remoteGetUnits={remoteGetUnits}
      remoteDeleteUnit={remoteDeleteUnit}
      remoteCreateUnit={remoteCreateUnit}
    />
  )
}
