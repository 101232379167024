import { EntitiesCountModel } from '../../domain/models/ReportsModel'

export const filtersFactory = (count: EntitiesCountModel, history: any) => {
  return [
    {
      title: 'Incorporadora',
      badge: true,
      badgeValue: count.companies,
      onClick: () => history.push('/companies')
    },
    {
      title: 'SPEs',
      badge: true,
      badgeValue: count.spes,
      onClick: () => history.push('/spes')
    },
    {
      title: 'Empreendimentos',
      badge: true,
      badgeValue: count.projects,
      onClick: () => history.push('/projects')
    },
    {
      title: 'Unidades',
      badge: true,
      badgeValue: count.units,
      onClick: () => history.push('/units')
    },
    {
      title: 'Contratos',
      badge: true,
      badgeValue: count.contracts,
      onClick: () => history.push('/contracts?layout=grid')
    }
  ]
}
