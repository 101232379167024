import { Form } from 'react-bootstrap'
import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import ErrorText from '../ErrorText'

type TextFieldTypes = InputHTMLAttributes<HTMLInputElement>

type CheckboxProps = {
  label: any
  errorMessage?: string
} & TextFieldTypes

export const Checkbox = ({
  label,
  onChange,
  defaultChecked,
  errorMessage
}: CheckboxProps) => {
  return (
    <Form.Group>
      <div className="switch d-inline m-r-10">
        <Form.Control
          type="checkbox"
          id="checked-default"
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <Form.Label htmlFor="checked-default" className="cr" />
      </div>
      <Form.Label>{label}</Form.Label>
      {errorMessage && (
        <div>
          <ErrorText text={errorMessage} />
        </div>
      )}
    </Form.Group>
  )
}
