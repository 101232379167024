import { HttpClient } from '../../protocols/http/httpClient'
import { getDashboard } from '../../../domain/usecases/Dashboard'
import {
  DashboardCharts,
  EthChartData
} from '../../../domain/models/DashboardModel'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'
import { TokenOffer } from '../../../domain/models/DashboardInvestorModels'

export class RemoteGetDashboard implements getDashboard {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async getEthData(): Promise<EthChartData> {
    const httpResponse = await this.httpClient.get?.({
      url: `${this.url}/dashboard/eth-data`
    })

    if (httpResponse.error)
      throw new Error(`Error on get dashboard information`)
    return httpResponse
  }

  @validateHttpStatus
  async getIndex(): Promise<DashboardCharts> {
    return this.httpClient.get?.({
      url: `${this.url}/dashboard`
    })
  }

  async getAntecipationTokensOffering(): Promise<TokenOffer[]> {
    const httpResponse = await this.httpClient.get?.({
      url: `${this.url}/tokens/antecipation/offers`
    })

    if (httpResponse.error)
      throw new Error(`Error on get token offers information`)
    return httpResponse
  }
}
