import { useEffect, useState } from 'react'
import moment from 'moment'
import { SubmitHandler } from 'react-hook-form'
import {
  EntityWithSearch,
  EntityWithSearchFields
} from '../../components/organisms/EntityFilterWithSearch'
import { EntityList } from '../../components/organisms/EntityList'
import { PaymentTableCard } from '../../components/organisms/PaymentsTableCard'
import { IGetPayments } from '../../domain/usecases/Payments'
import { useDispatch } from 'react-redux'
import { Receivable } from '../../domain/models/ReceivableModel'

export type PaymentsProps = {
  remoteGetPayments: IGetPayments
}

export type Filters = {
  company_name?: string
  spe_name?: string
  project_name?: string
  start_date?: string
  end_date?: string
  status?: string[]
}

const Payments = ({ remoteGetPayments }: PaymentsProps) => {
  const [payments, setPayments] = useState<Receivable[]>([])
  const [filters, setFilters] = useState<Filters>()

  const dispatch = useDispatch()

  useEffect(() => {
    const getPayments = async () => {
      try {
        dispatch({ type: 'SET_LOADING', loading: true })
        const payments = await remoteGetPayments.getPayments(filters)
        console.log('payments', payments)
        setPayments(payments)
      } finally {
        dispatch({ type: 'SET_LOADING', loading: false })
      }
    }

    getPayments()
  }, [filters])

  const handleSendSubmitFilter: SubmitHandler<EntityWithSearchFields> = (
    values
  ) => {
    const { companyName, speName, projectName, endDate, startDate, status } =
      values

    setFilters({
      company_name: companyName,
      project_name: projectName,
      start_date:
        startDate && moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end_date: endDate && moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      spe_name: speName,
      status
    })
  }

  return (
    <EntityList
      mainTitle="Recebíveis"
      title="Contratos de Compra e Venda"
      entityTable={<PaymentTableCard values={payments} />}
      filterWithSearch={<EntityWithSearch onSubmit={handleSendSubmitFilter} />}
      activeFilter={4}
    />
  )
}

export default Payments
