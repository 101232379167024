import { useContext } from 'react'
import { RemoteGetCompanies } from '../../../../data/usecases/Company/RemoteGetCompanies'
import { RemoteDeleteCompany } from '../../../../data/usecases/Company/RemoteDeleteCompany'
import { RemoteDeleteCompanyUser } from '../../../../data/usecases/CompanyUser/RemoteDeleteCompanyUser'
import { RemoteDeleteSpe } from '../../../../data/usecases/SPE/RemoteDeleteSPE'
import { URL_API } from '../../../../services/api_url'
import { AppContext } from '../../../../app'
import { CompanyDetails } from '../../../../pages/Companies/CompanyDetails'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'

export const CompanyDetailsFactory = () => {
  const { user } = useContext(AppContext)
  const URL = URL_API || 'without-url'
  const TOKEN_USER = user?.token || 'without-token'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetCompanies = new RemoteGetCompanies(URL, axiosHttpClient)
  const remoteDeleteCompany = new RemoteDeleteCompany(URL, axiosHttpClient)
  const remoteDeleteCompanyUser = new RemoteDeleteCompanyUser(
    URL,
    axiosHttpClient
  )
  const remoteDeleteSpe = new RemoteDeleteSpe(URL, axiosHttpClient)

  return (
    <CompanyDetails
      handleDeleteSpe={remoteDeleteSpe}
      remoteDeleteCompany={remoteDeleteCompany}
      remoteGetCompanies={remoteGetCompanies}
      remoteDeleteCompanyUser={remoteDeleteCompanyUser}
    />
  )
}
