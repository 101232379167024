import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import logo from '../../assets/images/logo.svg'
import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './forgotPassword.schema'
import { TextInputWithMask } from '../../components/atoms/TextInputWithMask'
import { forgotPassword } from '../../services/auth'
import { useToasts } from 'react-toast-notifications'
import { IForgotPasswordForm } from './forgotPassword-form.interface'
import * as S from '../SignUp/styles'
import { useState } from 'react'
import { LoadingIndicator } from '../../components/atoms/LoadingIndicator'
import { IHistoryPush } from '../../interfaces/router'

const ForgotPassword = ({ history }: { history: IHistoryPush }) => {
  document.title = 'Recebível Chipado - Recuperar Senha'
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IForgotPasswordForm>({
    resolver: yupResolver(schema)
  })

  const onSend: SubmitHandler<IForgotPasswordForm> = async (data) => {
    try {
      setLoading(true)
      await forgotPassword(data)
      history.push('/login')
      setLoading(false)
      addToast(
        'Te enviamos um E-mail para auxiliar na alteração da Senha. Pode conferir, por favor? 😉',
        {
          appearance: 'success',
          autoDismiss: true
        }
      )
    } catch (e) {
      setLoading(false)
      if (e.response.status === 400) {
        addToast('CPF não encontrado.', {
          appearance: 'error',
          autoDismiss: true
        })
      } else {
        addToast('Ocorreu um erro desconhecido.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(onSend)}>
          <Card>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Card.Body>
                  <img src={logo} alt="" className="logo" />
                  <h5 className="f-w-700">
                    Preencha o campo abaixo com seu CPF para recuperar sua
                    Senha.
                  </h5>
                  <TextInputWithMask
                    disabled={loading}
                    mask="999.999.999-99"
                    label="Esqueci minha Senha"
                    placeholder="Seu CPF"
                    errorMessage={errors?.cpf?.message}
                    {...register('cpf')}
                  />
                  <S.ButtonsWrapper>
                    <div className="">
                      {!loading && (
                        <NavLink to="/login" className="f-w-400">
                          <a className="btn">Voltar</a>
                        </NavLink>
                      )}
                    </div>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {!loading ? 'Recuperar Senha' : <LoadingIndicator />}
                      </button>
                    </div>
                  </S.ButtonsWrapper>
                </Card.Body>
              </div>
            </div>
          </Card>
        </form>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default ForgotPassword
