import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import CreateContracts from '../../../../pages/Contracts/CreateContracts'
import { RemoteGetSPE } from '../../../../data/usecases/SPE/RemoteGetSPE'
import { RemoteGetProject } from '../../../../data/usecases/Project/RemoteGetProject'
import { RemoteCreateContract } from '../../../../data/usecases/Contracts/RemoteCreateContract'

import { RemoteGetCompanies } from '../../../../data/usecases/Company/RemoteGetCompanies'

import { ContextPageProvider } from '../../../../pages/Contracts/CreateContracts/contextPage'
import { RemoteGetPersons } from '../../../../data/usecases/Persons/RemoteGetPersons'

export const CreateContractFactory = () => {
  const URL = URL_API || 'without-url'
  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetSpes = new RemoteGetSPE(URL, axiosHttpClient)
  const remoteGetProjects = new RemoteGetProject(URL, axiosHttpClient)
  const remoteGetPersons = new RemoteGetPersons(URL, axiosHttpClient)
  const remoteCreateContract = new RemoteCreateContract(URL, axiosHttpClient)
  const remoteGetCompanies = new RemoteGetCompanies(URL, axiosHttpClient)

  return (
    <ContextPageProvider
      getSpes={remoteGetSpes}
      getProjects={remoteGetProjects}
      getPersons={remoteGetPersons}
      createContract={remoteCreateContract}
      getCompanies={remoteGetCompanies}
    >
      <CreateContracts />
    </ContextPageProvider>
  )
}
