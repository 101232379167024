import * as React from 'react'
import { useContext } from 'react'
import { DispatchContext } from '../actions'
import { Form } from 'react-bootstrap'
import { UseFormSetValue } from 'react-hook-form'
import { ICreateUnitFormStep1 } from './index'

interface UpdateStep1Prop {
  onChange: (...args: any) => void
  setValue: UseFormSetValue<ICreateUnitFormStep1 & Record<string, any>>
}

const UpdateUnitStep1 = ({ setValue, onChange }: UpdateStep1Prop) => {
  const { state } = useContext(DispatchContext)

  React.useEffect(() => {
    setValue('unitsName', [state.unit.name])
  }, [state.unit])

  return (
    <>
      <Form.Label>
        <strong>Unidade</strong>
      </Form.Label>
      <Form.Group onChange={onChange}>{state.unit.name}</Form.Group>
    </>
  )
}

export default UpdateUnitStep1
