import * as React from 'react'

export const CreditCardPending = () => {
  return (
    <>
      <h5>Pagamento em análise</h5>
      <br />
      <p>
        Só falta um passo para você registrar o seu Contrato na Rede Ethereum.
        <br />
        <br />
        Após o pagamento ser confirmado pela operadora do cartão o seu Contrato
        será registrado automaticamente e você receberá um E-mail de confirmação
        com os dados da transação.
      </p>
    </>
  )
}
