/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Button, Col, Row } from 'react-bootstrap'

import { TextWithHint } from '../../../components/atoms/TextWithHint'
import { EntityDetails } from '../../../components/organisms/EntityDetails'
import { DetailsSection } from '../../../components/templates/DetailsSection'
import { convertNumberToCurrencyFormat } from '../../../utils/convertNumberToCurrencyFormat'
import { getBilling } from '../../../domain/usecases/Billing'
import * as S from './styles'
import { BillingCompositionTable } from './BillingCompositionTable'
import { BillingEventsTable } from './BillingsEventsTable'
import { BillingDetail } from '../../../domain/models/BillingModel'
import { formatCPFWithDots } from '../../../utils/formatCPFWithDots'

export type IParams = {
  id: string
}

export type StatusBadge = 'success' | 'info' | 'warning' | 'error'

export type BillingDetailsProps = {
  remote: getBilling
}

export const BillingDetails = ({
  remote
}: BillingDetailsProps) => {
  const [values, setValues] = useState<BillingDetail>()

  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { id } = params as IParams

  useEffect(() => {
    const getOneBilling = async () => {
      try {
        dispatch({ type: 'SET_LOADING', loading: true })

        const billing = await remote.getOne({ id })
        setValues(billing)
      } catch (error) {
        addToast('Recebível não encontrado.', {
          appearance: 'error',
          autoDismiss: true
        })
        history.push('/billings?layout=grid')
      } finally {
        dispatch({ type: 'SET_LOADING', loading: false })
      }
    }

    getOneBilling()
  }, [])

  console.log({
    values
  })

  return (
    <EntityDetails
      title="Recebível Chipado"
      sections={
        <>
          <Row>
            <Col xs={2}></Col>
            <Col className="pl-0 mb-2">
              <S.ValueContainer>
                <h5>Valor do recebível</h5>
                <span>{convertNumberToCurrencyFormat(Number(values?.billing?.value))}</span>
              </S.ValueContainer>
            </Col>
          </Row>
          <DetailsSection
            title='Originação do crédito'
            content={
              <>
                <Row className="pt-2">
                  <Col xs={2}>
                    <TextWithHint
                      text={values?.billing?.contract_payment_part?.contract?.spe.business_name}
                      hint="Razão Social"
                    />
                  </Col>
                  <Col xs={2}>
                    <TextWithHint
                      text={values?.billing?.contract_payment_part?.contract?.spe.cnpj}
                      hint="CNPJ"
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xs={2}>
                    <TextWithHint
                      text={moment(values?.billing?.contract_payment_part?.contract?.created_at).format(
                        'DD/MM/YYYY HH:mm'
                      )}
                      hint="Data e horário da originação"
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xs={6}>
                    <TextWithHint
                      text={<a target="_blank" href={`https://polygonscan.com/tx/${values?.billing.transaction_hash}`} rel="noreferrer">{values?.billing.transaction_hash}</a>}
                      hint="Hash de Originação (blockchain)"
                    />
                  </Col>
                  <Col xs={2}>
                    <TextWithHint
                      text={
                        <div>
                          <a target={'_blank'} href={values?.billing.contract_payment_part.contract.url} rel="noreferrer">Abrir</a>
                        </div>
                      }
                      hint="Contrato"
                    />
                  </Col>
                </Row>
              </>
            }
          />
          <DetailsSection
            title="Devedor do crédito"
            content={
              <Row className="pt-2">
                <Col xs={2}>
                  <TextWithHint text={values?.customer?.cpf ? formatCPFWithDots(values?.customer?.cpf) : ''} hint="CPF/CNPJ" />
                </Col>
                <Col>
                  <TextWithHint
                    text={values?.customer.name}
                    hint="Nome/Razão Social"
                  />
                </Col>
              </Row>
            }
          />
          <DetailsSection
            title="Negócio"
            content={
              <>
                <Row className="pt-2">
                  <Col xs={2}>
                    <TextWithHint
                      text={values?.billing.contract_payment_part.contract.project.name}
                      hint="Nome do empreendimento"
                    />
                  </Col>
                  <Col>
                    <TextWithHint
                      text={values?.billing.contract_payment_part.contract.unit.name}
                      hint="Unidade"
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xs={2}>
                    <TextWithHint
                      text={values?.billing.contract_payment_part.contract.project.ri}
                      hint="Registro da Incorporação"
                    />
                  </Col>
                  <Col>
                    <TextWithHint
                      text={values?.billing.contract_payment_part.contract.project.matriculation_number}
                      hint="Matrícula"
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col>
                    <TextWithHint
                      text={values?.billing.contract_payment_part.contract.project.notary}
                      hint="Cartório"
                    />
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xs={2}>
                    <TextWithHint
                      text={moment(values?.last_signature_date).format(
                        'DD/MM/YYYY HH:mm'
                      )}
                      hint="Data/hora assinatura do contrato"
                    />
                  </Col>
                </Row>
              </>
            }
          />
        </>
      }
      content={
        <>
          {values && values.bank_billet_transaction && <BillingCompositionTable values={values} />}
          {!values?.bank_billet_transaction && <div className="alert alert-warning">Nenhum boleto encontrado para este recebível. O sistema gera e envia os boletos 7 dias antes da data de vencimento. Após essa data, você poderá ver mais informações aqui.</div>}
          <BillingEventsTable  />
        </>
      }
    />
  )
}
