import * as S from '../styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const PaymentError = () => {
  return (
    <S.IconWrapper>
      <ErrorOutlineIcon
        style={{ color: '#CC0000', fontSize: 96, marginBottom: 10 }}
      />
      <S.IconText>
        <b>Não foi possível concluir o pagamento.</b>
        <br />
        Verifique as informações do cartão e tente novamente mais tarde.
      </S.IconText>
    </S.IconWrapper>
  )
}
export default PaymentError
