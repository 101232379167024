import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import Blockchain from '../../../../pages/Blockchain'
import RemoteGetBlockchainTransactions from '../../../../data/usecases/Blockchain_Transaction/RemoteGetBlockchainTransactions'

export const ListBlockchainFactory = () => {
  const URL = URL_API || 'without-url'
  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetBlockchainTransactions = new RemoteGetBlockchainTransactions(
    URL,
    axiosHttpClient
  )
  return (
    <Blockchain
      remoteGetBlockchainTransactions={remoteGetBlockchainTransactions}
    />
  )
}
