import React from 'react'

import * as S from './styles'

interface BorderedContentProps {
  children: React.ReactNode
}

export const BorderedContent = ({ children }: BorderedContentProps) => {
  return <S.Wrapper>{children}</S.Wrapper>
}
