import * as yup from 'yup'

export default yup.object().shape({
  value: yup
    .number()
    .min(
      5,
      'O Valor Negociado deve ser maior ou igual a R$ 5,00 e menor ou igual ao Valor do Imóvel.'
    )
})
