import styled from 'styled-components'

export const Wrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #5e6ea7;
  width: 100%;
  position: relative;
  .icon {
    position: absolute;
    right: 0;
  }
`
