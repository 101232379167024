import { Col, Row } from 'react-bootstrap'
import {
  Controller,
  Control,
  FieldErrors,
  UseFormWatch,
  UseFormSetValue,
  useWatch
} from 'react-hook-form'
import { TextInputWithMask } from '../../../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../../../components/atoms/TextInput'
import * as React from 'react'
import { ICreatePromissoryBuyerFormStep1, SelectedPerson } from './step1'
import { SelectField } from '../../../../../components/atoms/SelectField'
import { useContextPage } from '../../contextPage'
import { nationalitiesForSelectInput } from '../../../../../utils/Nationalities'
import { occupationsForSelectInput } from '../../../../../utils/Occupations'

interface IndividualStep1 {
  control: Control<Partial<ICreatePromissoryBuyerFormStep1>>
  individual?: Partial<SelectedPerson>
  errors: FieldErrors<Partial<ICreatePromissoryBuyerFormStep1>>
  watch?: UseFormWatch<Partial<ICreatePromissoryBuyerFormStep1>>
  setValue: UseFormSetValue<Partial<ICreatePromissoryBuyerFormStep1>>
}

export default function IndividualStep1({
  control,
  errors,
  setValue
}: IndividualStep1) {
  const { state } = useContextPage()
  const watchMarital = useWatch({ control, name: 'person.marital_status' })
  const MARITAL_STATUS = [
    {
      label: 'Solteiro(a)',
      value: 'Solteiro(a)'
    },
    {
      label: 'Casado(a)',
      value: 'Casado(a)'
    },
    {
      label: 'Divorciado(a)',
      value: 'Divorciado(a)'
    },
    {
      label: 'Viúvo(a)',
      value: 'Viúvo(a)'
    },
    {
      label: 'Separado(a)',
      value: 'Separado(a)'
    }
  ]
  const COMMUNION_GOODS_STATUS = [
    {
      label: 'Comunhão Parcial de Bens',
      value: 'Comunhão Parcial de Bens'
    },
    {
      label: 'Comunhão Universal de Bens',
      value: 'Comunhão Universal de Bens'
    },
    {
      label: 'Separação com Pacto Antenupcial',
      value: 'Separação com Pacto Antenupcial'
    },
    {
      label: 'Separação Legal de Bens',
      value: 'Separação Legal de Bens'
    },
    {
      label: 'Participação Final nos Aquestos',
      value: 'Participação Final nos Aquestos'
    },
    {
      label: 'Misto',
      value: 'Misto'
    }
  ]

  React.useEffect(() => {
    if (isNotMarried()) resetPersonGoodsRegimeState()
  }, [watchMarital])

  const isNotMarried = () => {
    return watchMarital !== MARITAL_STATUS[1].value
  }

  const resetPersonGoodsRegimeState = () => {
    // @ts-ignore
    return setValue('person.goods_regime', '')
  }
  return (
    <>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="person.cpf"
            render={({ field: { onChange, value } }) => (
              <TextInputWithMask
                mask="999.999.999-99"
                label="CPF"
                readOnly
                placeholder="CPF do Promissário"
                errorMessage={errors?.person?.cpf?.message}
                defaultValue={state?.selectedPerson?.info?.cpf}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="person.name"
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="text"
                label="Nome"
                readOnly
                placeholder="Campo preenchido automaticamente"
                value={value}
                defaultValue={state?.selectedPerson?.info?.name}
                errorMessage={errors.person?.name?.message}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="person.nationality"
            render={({ field: { onChange, value } }) => (
              <SelectField
                data-testid="nationality"
                type="text"
                emptyOptionText={'Selecione'}
                label="Nacionalidade"
                errorMessage={errors.person?.nationality?.message}
                onChange={onChange}
                value={value}
                options={nationalitiesForSelectInput()}
                defaultValue="Brasil"
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="person.birth_country"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="birth_country"
                type="text"
                label="Naturalidade"
                errorMessage={errors.person?.birth_country?.message}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="person.birth_date"
            render={({ field: { onChange, value } }) => (
              <TextInputWithMask
                data-testid="birth_date"
                mask="99/99/9999"
                label="Data de Nascimento"
                errorMessage={errors.person?.birth_date?.message}
                value={value}
                onChange={onChange}
                defaultValue={''}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="person.occupation"
            render={({ field: { onChange, value } }) => (
              <SelectField
                data-testid="occupation"
                type="text"
                label="Profissão"
                errorMessage={errors.person?.occupation?.message}
                onChange={onChange}
                value={value}
                options={occupationsForSelectInput()}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="person.marital_status"
            render={({ field: { onChange, value } }) => (
              <SelectField
                data-testid="marital_status"
                errorMessage={errors.person?.marital_status?.message}
                emptyOptionText={'Selecione'}
                value={value}
                defaultValue={''}
                onChange={onChange}
                label="Estado Civil"
                options={MARITAL_STATUS}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="person.goods_regime"
            render={({ field: { onChange, value } }) => (
              <SelectField
                errorMessage={errors.person?.goods_regime?.message}
                emptyOptionText={'Selecione'}
                onChange={onChange}
                label="Comunhão de bens"
                value={value}
                defaultValue={''}
                disabled={isNotMarried()}
                options={COMMUNION_GOODS_STATUS}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="person.identity"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="identity"
                label="Identidade"
                errorMessage={errors.person?.identity?.message}
                value={value}
                onChange={onChange}
                defaultValue={''}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="person.identity_issuer"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="identity_issuer"
                type="text"
                label="Emissor"
                errorMessage={errors.person?.identity_issuer?.message}
                onChange={onChange}
                defaultValue={''}
                value={value}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="person.identity_issuer_date"
            render={({ field: { onChange, value } }) => (
              <TextInputWithMask
                data-testid="identity_issuer_date"
                mask="99/99/9999"
                label="Data de Emissão"
                errorMessage={errors.person?.identity_issuer_date?.message}
                value={value}
                defaultValue={''}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
    </>
  )
}
