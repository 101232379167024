import { Button, Card, Col, Row } from 'react-bootstrap'
import { InputFile } from '../../../../components/molecules/InputFile'
import { Controller, useForm } from 'react-hook-form'
import { SelectField } from '../../../../components/atoms/SelectField'
import { TextInputWithMask } from '../../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../../components/atoms/TextInput'
import { Checkbox } from '../../../../components/atoms/Checkbox'
import * as React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from '../create-simplified-smart-contract.schema'
import { CreateSimplifiedSmartContract } from '../index'

interface FormProps {
  onContinue: (data: CreateSimplifiedSmartContract) => Promise<void>
  file: any
  setFile: any
}

export const Form = ({ onContinue, file, setFile }: FormProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<CreateSimplifiedSmartContract>({
    resolver: yupResolver(schema),
    defaultValues: {
      document_type: '1',
      payment_type: 'bank_billet'
    }
  })

  const document_type = watch('document_type')

  return (
    <>
      <form onSubmit={handleSubmit(onContinue)}>
        <Card.Body className="card-small-padding">
          <Row>
            <Col md={12}>
              <p>
                Por favor, preencha os campos abaixo para registrar um Contrato
                na Blockchain Ethereum.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <InputFile
                label="Arquivo do Contrato (Formato PDF)"
                btnText={file?.name || 'Buscar arquivo'}
                onChange={(file) => {
                  setValue('file_type', file.type, {
                    shouldValidate: true
                  })
                  setValue('file_size', file.size, {
                    shouldValidate: true
                  })
                  setFile(file)
                }}
                errorMessage={
                  errors.file_type?.message || errors.file_size?.message
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Controller
                control={control}
                name="object"
                render={({ field: { onChange, value } }) => (
                  <SelectField
                    emptyOptionText={'-- Selecione --'}
                    options={[
                      {
                        label: 'Troca ou Permuta',
                        value: 'Troca ou Permuta'
                      },
                      {
                        label: 'Compra e Venda',
                        value: 'Compra e Venda'
                      },
                      {
                        label: 'Prestação de Serviços',
                        value: 'Prestação de Serviços'
                      },
                      {
                        label: 'Procuração particular',
                        value: 'Procuração particular'
                      },
                      { label: 'Locação', value: 'Locação' },
                      { label: 'Doação', value: 'Doação' },
                      { label: 'Comodato', value: 'Comodato' },
                      { label: 'Comissão', value: 'Comissão' },
                      { label: 'Corretagem', value: 'Corretagem' },
                      { label: 'Fiança', value: 'Fiança' },
                      { label: 'Outros', value: 'Outros' }
                    ]}
                    onChange={onChange}
                    value={value}
                    label="Tipo do Contrato"
                    errorMessage={errors.object?.message}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Controller
                control={control}
                name="value"
                render={({ field: { onChange, value } }) => (
                  <SelectField
                    emptyOptionText={'-- Selecione --'}
                    options={[
                      {
                        label: 'Até R$ 500,00',
                        value: 'Até R$ 500,00'
                      },
                      {
                        label: 'De R$ 501,00 até R$ 1.000,00',
                        value: 'De R$ 501,00 até R$ 1.000,00'
                      },
                      {
                        label: 'De R$ 1.000,01 até R$ 10.000,00',
                        value: 'De R$ 1.000,01 até R$ 10.000,00'
                      },
                      {
                        label: 'De R$ 10.000,01 até R$ 50.000,00',
                        value: 'De R$ 10.000,01 até R$ 50.000,00'
                      },
                      {
                        label: 'De R$ 50.000,01 até R$ 100.000,00',
                        value: 'De R$ 50.000,01 até R$ 100.000,00'
                      },
                      {
                        label: 'De R$ 100.000,01 até R$ 250.000,00',
                        value: 'De R$ 100.000,01 até R$ 250.000,00'
                      },
                      {
                        label: 'De R$ 250.000,01 até R$ 500.000,00',
                        value: 'De R$ 250.000,01 até R$ 500.000,00'
                      },
                      {
                        label: 'De R$ 500.000,01 até R$ 1.000.000,00',
                        value: 'De R$ 500.000,01 até R$ 1.000.000,00'
                      },
                      {
                        label: 'De R$ 1.000.000,01 até R$ 2.500.000,00',
                        value: 'De R$ 1.000.000,01 até R$ 2.500.000,00'
                      },
                      {
                        label: 'De R$ 2.500.000,01 até R$ 5.000.000,00',
                        value: 'De R$ 2.500.000,01 até R$ 5.000.000,00'
                      },
                      {
                        label: 'De R$ 5.000.000,01 até R$ 10.000.000,00',
                        value: 'De R$ 5.000.000,01 até R$ 10.000.000,00'
                      },
                      {
                        label: 'Acima de R$ 10.000.000,00',
                        value: 'Acima de R$ 10.000.000,00'
                      }
                    ]}
                    onChange={onChange}
                    value={value}
                    label="Valor do Objeto (R$)"
                    errorMessage={errors.object?.message}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3}>
              <Controller
                name="document_type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectField
                    label="Tipo do Responsável"
                    onChange={onChange}
                    value={value}
                    options={[
                      { label: 'CPF', value: '1' },
                      { label: 'CNPJ', value: '0' }
                    ]}
                  />
                )}
              />
            </Col>
            <Col sm={12} md={4}>
              <Controller
                name="document"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInputWithMask
                    label="Documento do Responsável"
                    errorMessage={errors?.document?.message}
                    mask={
                      document_type === '1'
                        ? '999.999.999-99'
                        : '99.999.999/9999-99'
                    }
                    placeholder={
                      document_type === '1'
                        ? 'ex: 999.999.999-99'
                        : 'ex: 99.999.999/9999-99'
                    }
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Col>
            <Col sm={12} md={5}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    label="Nome do Responsável"
                    errorMessage={errors?.document?.message}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                name="payment_type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectField
                    emptyOptionText={'-- Selecione --'}
                    options={[
                      {
                        label: 'Boleto (R$ 19,00)',
                        value: 'bank_billet'
                      },
                      {
                        label: 'Cartão de Crédito (R$ 19,00)',
                        value: 'credit_card'
                      }
                    ]}
                    onChange={onChange}
                    value={value}
                    label="Selecione a Forma de Pagamento"
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                name="terms_accepted"
                control={control}
                render={({ field: { onChange } }) => (
                  <div>
                    <Checkbox
                      label="Eu li e aceito os Termos de Uso."
                      defaultChecked={false}
                      errorMessage={errors.terms_accepted?.message}
                      onChange={onChange}
                    />

                    <a href="#" target="_blank">
                      Ver Termos de Uso
                    </a>
                  </div>
                )}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button variant="primary" type="submit" className="btn btn-block">
            Continuar para o pagamento
          </Button>
        </Card.Footer>
      </form>
    </>
  )
}
