type StepsValuesProps = {
  id: 'companyName' | 'speName' | 'projectName'
  title: string
  label: string
  placeholder: string
}

export const statusValues = [
  {
    id: 'pending',
    label: 'Pendente',
    value: 'pending'
  },
  {
    id: 'paid',
    label: 'Pago',
    value: 'paid'
  },
  {
    id: 'late',
    label: 'Atrasado',
    value: 'late'
  }
]

export const stepsValues: StepsValuesProps[] = [
  {
    id: 'companyName',
    title: 'Incorporadora',
    label: 'Filtrar por nome',
    placeholder: 'Nome da Incorporadora'
  },
  {
    id: 'speName',
    title: 'SPE',
    label: 'Filtrar por nome',
    placeholder: 'Nome da SPE'
  },
  {
    id: 'projectName',
    title: 'Empreendimento',
    label: 'Filtrar por nome',
    placeholder: 'Nome do Empreendimento'
  }
]
