import { Card, Form } from 'react-bootstrap'

import { TextInput } from '../../atoms/TextInput'
import { TextInputWithMask } from '../../atoms/TextInputWithMask'

import { statusValues, stepsValues } from './formValues'

import { useForm, SubmitHandler, Controller } from 'react-hook-form'

import * as S from './styles'

const { Check } = Form

export type EntityWithSearchFields = {
  status: string[]
  companyName: string
  speName: string
  projectName: string
  startDate: string
  endDate: string
}
export type EntityWithSearchProps = {
  onSubmit?: SubmitHandler<EntityWithSearchFields>
}

export const EntityWithSearch = ({ onSubmit }: EntityWithSearchProps) => {
  const { control, handleSubmit, setValue, watch, register } =
    useForm<EntityWithSearchFields>({
      defaultValues: {
        status: []
      }
    })

  const status = watch('status')

  const handleAddNewOrRemoveStatus = (id: string) => {
    const existsStatus = status.find((status) => status === id)

    if (existsStatus) {
      const values = status.filter((status) => status !== id)

      setValue('status', values)
      return
    }

    setValue('status', [...status, id])
  }

  return (
    <div>
      <Card.Header>
        <h6>Filtros</h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={onSubmit ? handleSubmit(onSubmit) : console.log}>
          <S.Title>Status</S.Title>
          <div>
            {statusValues.map((item) => (
              <S.CustomFromGroup key={item.id}>
                <S.CustomLabel htmlFor={item.id}>{item.label}</S.CustomLabel>
                <Check
                  custom
                  id={item.id}
                  name={item.id}
                  onClick={() => handleAddNewOrRemoveStatus(item.id)}
                />
              </S.CustomFromGroup>
            ))}
          </div>
          {stepsValues.map((item) => (
            <div key={item.id}>
              <S.Title>{item.title}</S.Title>
              <div>
                <TextInput
                  type="text"
                  placeholder={item.placeholder}
                  label={item.label}
                  id={item.id}
                  {...register(item.id)}
                />
              </div>
            </div>
          ))}
          <div>
            <S.Title>Período</S.Title>
            <div>
              <Controller
                control={control}
                name="startDate"
                render={({ field }) => (
                  <TextInputWithMask
                    mask="99/99/9999"
                    type="text"
                    placeholder="01/01/2021"
                    label="Data Inicial"
                    id="start-date"
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <TextInputWithMask
                    mask="99/99/9999"
                    type="text"
                    placeholder="31/12/2021"
                    label="Data Final"
                    id="finish-date"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <S.CustomButton type="submit">Filtrar</S.CustomButton>
        </Form>
      </Card.Body>
    </div>
  )
}
