import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { RemoteGetDashboard } from '../../../../data/usecases/Dashboard/RemoteGetDashboard'
import { DashboardInvestor } from '../../../../pages/DashboardInvestor'

export const DashboardInvestorFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetDashboard = new RemoteGetDashboard(URL, axiosHttpClient)
  return <DashboardInvestor remoteGetDashboard={remoteGetDashboard} />
}
