import { Card, Table } from 'react-bootstrap'
import * as React from 'react'
import * as S from './styles'

type DataParams = {
  titleHeader: string
  thead?: React.HTMLProps<HTMLTableElement>
  tbody?: React.HTMLProps<HTMLTableElement>
  infoMsg?: string
}

interface IDataTableProps {
  data?: DataParams
}

export const TableCard = ({ data }: IDataTableProps) => {
  return (
    <S.Wrapper>
      <Card className="table-card">
        <Card.Header>
          <S.Title>{data?.titleHeader}</S.Title>
        </Card.Header>
        <Card.Body className="pb-0">
          <Table responsive hover className="mb-0">
            {data?.thead}
            {data?.tbody}
          </Table>
          {!data?.tbody && (
            <div className="info">
              <div>
                <span>{data?.infoMsg}</span>
              </div>
              <hr />
            </div>
          )}
        </Card.Body>
      </Card>
    </S.Wrapper>
  )
}
