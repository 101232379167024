import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateCompanyContext } from '../index'
import { useForm } from 'react-hook-form'
import { Button, Col, Row } from 'react-bootstrap'
import step3_schema from './step3.schema'
import ProxiesSelector from '../../../../components/organisms/ProxiesSelector'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { Proxy } from 'domain/models/ProxyModel'
import { validateEmail } from '../../../../utils/validateEmail'
import { useToasts } from 'react-toast-notifications'
import { useCreationConfirmModal } from '../../../../hooks/useCreationConfirmModal'

export interface ICreateCompanyFormStep3 {
  proxies: Proxy[]
}

export default function Step3({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateCompanyContext)
  const { addToast } = useToasts()
  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ICreateCompanyFormStep3>({
    resolver: yupResolver(step3_schema),
    defaultValues: {
      proxies: data?.proxies
    }
  })
  const location = useLocation()

  const { setShowConfirmationModal, Modal } = useCreationConfirmModal({
    entityText: 'da Incorporadora',
    onFinish: nextHandler
  })

  const onClickNext = (formData: ICreateCompanyFormStep3) => {
    setData?.({ ...data, ...formData })
    setShowConfirmationModal(true)
  }

  const proxies = watch('proxies')

  const onFinish = () => {
    const hasErrors = !!proxies?.filter(
      (proxy) => !proxy?.cpf || !proxy?.name || !validateEmail(proxy?.email)
    ).length
    console.log({
      hasErrors
    })
    if (!hasErrors) {
      handleSubmit(onClickNext)()
    } else {
      return addToast(
        'Por favor, verifique se todos os campos foram preenchidos corretamente.',
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    }
  }

  return (
    <>
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={12}>
            <ProxiesSelector
              errors={errors}
              onChange={(new_proxies) => {
                console.log('changed', {
                  new_proxies
                })
                setValue('proxies', new_proxies)
              }}
              defaultProxies={proxies}
              hintText="Digite o(s) CPF(s) do(s) Procurador(es) da Incorporadora."
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={onFinish}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
      {Modal}
    </>
  )
}
