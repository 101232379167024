import { Col, Row } from 'react-bootstrap'
import * as React from 'react'
import WelcomeCardInvestor from './WelcomeCardInvestor'
import { DepositCard } from '../../components/organisms/DepositCard'
import { WithdrawCard } from '../../components/organisms/WithdrawCard'
import { WalletCard } from '../../components/organisms/WalletCard'
import { TokenCard } from '../../components/organisms/TokenCard'
import moment from 'moment'
import { TransactionsCard } from '../../components/organisms/TransactionsCard'
import { getDashboard } from '../../domain/usecases/Dashboard'
import { TokenOffer } from '../../domain/models/DashboardInvestorModels'
import { useEffect, useState } from 'react'

interface DashboardProps {
  remoteGetDashboard: getDashboard
}

export const DashboardInvestor = ({ remoteGetDashboard }: DashboardProps) => {
  const [tokenOffers, setTokenOffers] = useState<TokenOffer[]>([])

  useEffect(() => {
    remoteGetDashboard.getAntecipationTokensOffering().then((offers) => {
      setTokenOffers(offers)
    })
  }, [])

  return (
    <>
      <Row>
        <Col md={12}>
          <WelcomeCardInvestor />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h5>Tokens em oferta</h5>
        </Col>
      </Row>
      <Row>
        {tokenOffers.map((offer) => (
          <Col key={offer.id} md={4}>
            <TokenCard
              symbol={offer.symbol}
              available_supply={offer.available_supply}
              total_supply={offer.total_supply}
              contract_final_date={moment(offer.contract_ending_date).toDate()}
              expected_profit={offer.expected_profit}
              unit_value={offer.unit_value}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col md={12}>
          <WalletCard />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DepositCard />
        </Col>
        <Col md={6}>
          <WithdrawCard />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TransactionsCard />
        </Col>
      </Row>
    </>
  )
}
