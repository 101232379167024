import * as React from 'react'
import { Form } from 'react-bootstrap'
import { Tag } from 'react-tag-autocomplete'
import {
  Control,
  DeepMap,
  FieldError,
  UseFormSetValue,
  useWatch
} from 'react-hook-form'
import { ICreateUnitFormStep1 } from './index'
import Information from '../../../../components/atoms/Information'

interface CompleteUnitStep1Props {
  onChange: (...event: any[]) => void
  units: Tag[]
  errors: DeepMap<ICreateUnitFormStep1, FieldError>
  control: Control<ICreateUnitFormStep1>
  setValue: UseFormSetValue<ICreateUnitFormStep1 & Record<string, any>>
}

const CompleteUnitStep1 = ({
  control,
  units,
  setValue
}: CompleteUnitStep1Props) => {
  const unitsName = useWatch({ control, name: 'unitsName' })

  return (
    <>
      <Form.Label>
        <strong>Unidade(s)</strong>
      </Form.Label>
      <Information>
        {units.length
          ? 'Selecione abaixo o(s) número(s) das Unidades que você irá cadastrar. Obs.: Lembre-se de que estas Unidades devem ter as mesmas características.'
          : 'Por favor, selecione um Empreendimento e um Bloco.'}
      </Information>
      <Form.Group>
        {units.map((unit, index) => (
          <Form.Check
            key={`unit-${index}`}
            custom
            type="checkbox"
            defaultChecked={unitsName?.includes(unit.name) || false}
            label={unit.name}
            value={unit.name}
            id={`unit-${index}`}
            name="selected-units"
            onChange={(ev) => {
              const currentUnitsName = unitsName || []
              const newUnitsName = !ev.target.checked
                ? currentUnitsName.filter((i: string) => i !== ev.target.value)
                : [...currentUnitsName, ev.target.value]
              setValue('unitsName', newUnitsName)
            }}
          />
        ))}
      </Form.Group>
    </>
  )
}

export default CompleteUnitStep1
