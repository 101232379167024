import React from 'react'
import { Card, Button, Col, Row, ProgressBar } from 'react-bootstrap'

import * as S from './styles'
import { Title } from '../../atoms/Title'
import { useHistory } from 'react-router-dom'
import { IDashboardFirstSteps } from 'pages/Dashboard/dashboard'

export interface WelcomeCardToCreateCompanyProps {
  data: IDashboardFirstSteps
}

export default function WelcomeCardToCreateCompany({
  data
}: WelcomeCardToCreateCompanyProps) {
  const history = useHistory()

  const [customButton, setCustomButton] = React.useState({
    url: '/companies/create',
    text: 'Incorporadora'
  })

  const [progress, setProgress] = React.useState<number>(0.09)

  const companyText = `1️⃣ Incorporadora e Usuários da Plataforma;`
  const speText = `2️⃣ SPE (Sociedade de Propósito Específico);`
  const projectText = `3️⃣ Empreendimento;`
  const unitText = `4️⃣ Unidades do Empreendimento;`

  React.useEffect(() => {
    const currentStep = Object.values(data).reduce((acc, value) => {
      return acc + value
    }, 0)

    switch (currentStep) {
      case 1:
        setCustomButton({
          url: '/spes/create',
          text: 'SPE'
        })
        setProgress(0.25)
        break

      case 2:
        setCustomButton({
          url: '/projects/create',
          text: 'Empreendimento'
        })
        setProgress(0.5)
        break

      case 3:
        setCustomButton({
          url: '/units/create',
          text: 'Unidades'
        })
        setProgress(0.75)
        break

      default:
        setCustomButton({
          url: '/companies/create',
          text: 'Incorporadora'
        })
        setProgress(0.09)
        break
    }
  }, [data])

  return (
    <S.Container>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Title>Primeiros Passos</Title>
            </Card.Header>

            <ProgressBar variant="success" now={progress * 100} />

            <Card.Body>
              Olá! Seja bem-vindo(a)!
              <br />
              <br />
              Existimos para propiciar Negócios Imobiliários Inteligentes! 😉
              <br />
              Nesta segura plataforma, você poderá criar Contratos Digitais 100%
              automatizados, além de registrá-los em Blockchain e originar
              Tokens.
              <br />
              <br />
              Antes disso, é necessário cadastrar os itens abaixo:
              <br />
              <br />
              {data.companies ? (
                <S.StepOk>{companyText}</S.StepOk>
              ) : (
                <S.StepPending>{companyText}</S.StepPending>
              )}
              <br />
              <br />
              {data.spes ? (
                <S.StepOk>{speText}</S.StepOk>
              ) : (
                <S.StepPending>{speText}</S.StepPending>
              )}
              <br />
              <br />
              {data.projects ? (
                <S.StepOk>{projectText}</S.StepOk>
              ) : (
                <S.StepPending>{projectText}</S.StepPending>
              )}
              <br />
              <br />
              {data.units ? (
                <S.StepOk>{unitText}</S.StepOk>
              ) : (
                <S.StepPending>{unitText}</S.StepPending>
              )}
            </Card.Body>
            <Button
              className="button-create"
              variant="primary"
              onClick={() => history.push(`${customButton.url}`)}
            >
              Cadastrar {customButton.text}
            </Button>
          </Card>
        </Col>
      </Row>
    </S.Container>
  )
}
