import { Form } from 'react-bootstrap'
import * as React from 'react'
import { InputHTMLAttributes } from 'react'

import ErrorText from '../ErrorText'

type TextFieldTypes = InputHTMLAttributes<HTMLInputElement>

export type TextInputProps = {
  label?: string
  type?: 'email' | 'text' | 'password' | 'number'
  value?: string | number
  rows?: number
  as?: React.ElementType
  errorMessage?: string
} & Omit<TextFieldTypes, 'size' | 'value'>

const TextInputComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextInputProps
> = ({ label, type = 'text', placeholder, errorMessage, ...other }, ref) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          <strong>{label}</strong>
        </Form.Label>
      )}
      <Form.Control
        isInvalid={!!errorMessage}
        autoComplete={label}
        type={type}
        placeholder={placeholder}
        {...other}
        ref={ref}
      />
      {errorMessage && <ErrorText text={errorMessage} />}
    </Form.Group>
  )
}

export const TextInput = React.forwardRef(TextInputComponent)
