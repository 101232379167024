import { Card, Table } from 'react-bootstrap'

export const WalletCard = () => {
  return (
    <Card>
      <Card.Header>
        <h5>Saldo em Carteira</h5>
      </Card.Header>
      <Card.Body>
        <Table striped hover responsive bordered id="data-table-zero">
          <thead>
            <tr>
              <th>Moeda/Token</th>
              <th>Saldo</th>
              <th>Valor em R$ (hoje)</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle">
                <div className="d-flex align-items-center">
                  {' '}
                  <i className="f-24 m-r-10 text-c-yellow" /> Reais{' '}
                </div>
              </td>
              <td className="align-middle">
                <div>0</div>
              </td>
              <td className="align-middle">
                <div>0</div>
              </td>
              <td className="align-middle">
                <div className="transact form-inline">
                  <div className="mr-xl-2 mr-1">
                    <a href={''} className="btn btn-success btn-sm disabled">
                      <i className="far fa-arrow-alt-circle-up" /> Depositar
                    </a>
                  </div>
                  <div>
                    <a href={''} className="btn btn-danger btn-sm disabled">
                      <i className="far fa-arrow-alt-circle-down" /> Sacar
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <td className="align-middle">*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*      {' '}*/}
            {/*      <i className="f-24 m-r-10 text-c-yellow" /> PRP-ATP-1005*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*  <td className="align-middle">*/}
            {/*    <div>0.50</div>*/}
            {/*  </td>*/}
            {/*  <td className="align-middle">*/}
            {/*    <div>R$ 3.420,00</div>*/}
            {/*  </td>*/}
            {/*  <td className="align-middle">*/}
            {/*    <div className="transact form-inline">*/}
            {/*      <div className="mr-xl-2 mr-1">*/}
            {/*        <a href={''} className="btn btn-info btn-sm disabled">*/}
            {/*          <i className="feather icon-info" /> Ver detalhes*/}
            {/*        </a>*/}
            {/*      </div>*/}
            {/*      <div className="mr-xl-2 mr-1">*/}
            {/*        <a href={''} className="btn btn-success btn-sm disabled">*/}
            {/*          <i className="far fa-arrow-alt-circle-down" /> Comprar*/}
            {/*        </a>*/}
            {/*      </div>*/}
            {/*      <div>*/}
            {/*        <a href={''} className="btn btn-danger btn-sm disabled">*/}
            {/*          <i className="far fa-arrow-alt-circle-up " /> Vender*/}
            {/*        </a>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
