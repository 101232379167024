import * as React from 'react'
import { Suspense, useEffect, useState } from 'react'
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  RouteComponentProps,
  Redirect
} from 'react-router-dom'
import 'font-awesome/scss/font-awesome.scss'
import Loader from './components/theme-originals/layout/Loader'
import ScrollToTop from './components/theme-originals/layout/ScrollToTop'
import guestRoutes, { RouteObject } from './routes/guest.routes'
import './assets/scss/style.scss'
import { RoutesFactory } from './routes/logged.routes'
import { LoadingOverlay } from './components/atoms/LoadingOverlay'
import { URL_API } from './services/api_url'
import { RemoteGetUser } from './data/usecases/User/RemoteGetUser'
import { AxiosHttpClient } from './infra/http/axiosHttpClient'
import { LoggedRoutesContainer } from './components/atoms/LoggedRoutesContainer'
import { UserType } from './domain/models/UserModel'

export interface User {
  token: string
  name: string
  fullName?: string
  type?: UserType
}

interface IAppContext {
  user?: User | null
  setUser?: (user: User) => void
  clearUser?: () => void
  menuVisible?: boolean
  setMenuVisible: (visible: boolean) => void
}

export const AppContext = React.createContext<IAppContext>({
  setMenuVisible: () => console.log('set menu visible')
})
const App = () => {
  const location = useLocation()
  const history = useHistory()

  const storageUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : undefined
  const [user, setUserState] = useState<User | null>(storageUser)
  const [menuVisible, setMenuVisible] = useState<boolean>(true)
  const [routes, setRoutes] = useState<RouteObject[]>([])

  const setUser = (user: User) => {
    const firstAndLastName = (nameArray: string[]) =>
      `${nameArray[0]} ${nameArray[nameArray.length - 1]}`
    localStorage.setItem('user', JSON.stringify(user))
    const axiosHttpClient = new AxiosHttpClient(user.token)
    const URL = URL_API || 'without-url'
    const remoteGetUser = new RemoteGetUser(URL, axiosHttpClient)
    remoteGetUser
      .getMe()
      .then((data) => {
        setUserState({
          ...user,
          name: firstAndLastName(data.name.split(' ')),
          fullName: data.name,
          type: data.type
        })
        setRoutes(RoutesFactory.createRoutes(data as User))
      })
      .catch(() => {
        setUserState(null)
        history.push('/login')
      })
  }

  useEffect(() => {
    if (storageUser) {
      setUser(storageUser)
    }
  }, [])

  const clearUser = () => {
    localStorage.removeItem('user')
    setUserState(null)
  }

  return (
    <>
      <AppContext.Provider
        value={{
          user,
          setUser,
          clearUser,
          setMenuVisible,
          menuVisible
        }}
      >
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Route path={guestRoutes.map((x) => x.path)}>
              <Switch location={location} key={location.pathname}>
                {guestRoutes.map((route, index) => {
                  return route.component ? (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      render={(props: RouteComponentProps) => (
                        <route.component {...props} />
                      )}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                })}
                {!user && <Redirect to={'/'} />}
              </Switch>
            </Route>
            <Route path={routes.map((x) => x.path)}>
              <LoggedRoutesContainer />
            </Route>
          </Suspense>
        </ScrollToTop>
      </AppContext.Provider>
      <div className="backdrop" />
      <LoadingOverlay />
    </>
  )
}

export default App
