/* eslint-disable prettier/prettier */
import {
  useState,
  HTMLAttributes,
  KeyboardEvent,
  useMemo,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect
} from 'react'
import { useEventListener } from '../../../hooks/useEventListener'
import { serializedTagsWithSubstring, validateBeforeSaveNewTag } from './funcs'

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'

import * as S from './styles'

export type Tag = {
  id: string | number
  name: string
  disabled?: boolean
}
export type Suggestion = {
  id: string | number
  name: string
}

export interface InputTagProps
  extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  values?: Tag[]
  suggestions?: Suggestion[]
  additionalInformation?: React.ReactElement
  label?: string
  fullWidth?: boolean
  limit?: number
  errorMessage?: string
  width?: number | string
  interceptorBeforeAddTagFunc?: (value: string) => string | void
  onChange?: (values: Tag[], type: 'add' | 'remove') => void
  onValidate?: (currentTag: Tag, tags: Tag[]) => boolean
  onlySuggestions?: boolean
  rerenderOnValuesChange?: boolean
}

const InputTagComponent: ForwardRefRenderFunction<
  HTMLInputElement,
  InputTagProps
> = (
  {
    values = [],
    suggestions = [],
    label = '',
    fullWidth = true,
    limit = 0,
    errorMessage,
    width,
    interceptorBeforeAddTagFunc,
    onlySuggestions = false,
    additionalInformation,
    onChange,
    onValidate,
    rerenderOnValuesChange = false,
    ...rest
  },
  ref
) => {
  const [inputValue, setInputValue] = useState('')
  const [activeSuggestion, setActiveSuggestion] = useState<number>(0)
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [tags, setTags] = useState<Tag[]>(values)

  useEffect(() => {
    if (rerenderOnValuesChange) {
      setTags(values)
    }
  }, [values])

  // Events listeners for hide suggestions when click outside or press ESC
  useEventListener('click', () => setShowSuggestions(false))
  useEventListener('keydown', (event) => {
    const newEvent = event as unknown as KeyboardEvent<HTMLInputElement>

    if (newEvent.key === 'Escape') {
      setShowSuggestions(false)
    }
  })

  // Filter that returns only the suggestions that contain the not added value
  const filteredSuggestions = useMemo(() => {
    return suggestions
      ? suggestions?.filter((suggestion) => {
          // If the value is already in the list, it will not be added
          const isAlreadyAdded = tags.find(
            (tag) => tag.name === suggestion.name
          )

          if (isAlreadyAdded) {
            return false
          }

          return suggestion.name
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        })
      : []
  }, [suggestions, tags, inputValue])

  const handleAddOrDeleteTag = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event

    const checkInputValueContainsInSuggest = suggestions.find((suggestion) => {
      return suggestion.name.toLowerCase().includes(inputValue.toLowerCase())
    })
    if (key === 'Enter') {
      if (onlySuggestions && !checkInputValueContainsInSuggest) {
        setInputValue('')
        return
      }
      handleAddTagWithEnterKey()
      return
    }
    if (key === 'Backspace') {
      if (!inputValue.length && !tags[tags.length - 1]?.disabled) {
        handleRemoveTagWithBackspaceKey()
      }
    }

    if (key === 'ArrowUp') {
      if (!activeSuggestion) {
        setActiveSuggestion(filteredSuggestions.length - 1)

        return
      }

      setActiveSuggestion(activeSuggestion - 1)
    }
    if (key === 'ArrowDown') {
      if (activeSuggestion === filteredSuggestions.length - 1) {
        setActiveSuggestion(0)
        return
      }

      setActiveSuggestion(activeSuggestion + 1)
    }

    setInputValue(event.currentTarget.value)
    !showSuggestions && setShowSuggestions(true)
  }

  const handleAddTagWithEnterKey = () => {
    const value = filteredSuggestions[activeSuggestion]?.name || inputValue

    addTag(value)
  }

  const handleAddTagPerSuggestList = (id: string | number) => {
    const value = suggestions.find((suggestion) => suggestion.id === id)?.name
    if (!value) return
    addTag(value)
  }

  const addTag = (value: string) => {
    // Validate before add tag to list and return if it is valid
    const newTag = validateBeforeSaveNewTag({
      value,
      tags,
      interceptorBeforeAddTagFunc,
      limit
    })
    if (newTag) {
      const validate = onValidate?.(newTag, tags) ?? true
      if (validate) {
        onChange?.([...tags, newTag], 'add')
        setTags([...tags, newTag])
      }
      setInputValue('')
      setActiveSuggestion(0)
    }
  }

  const handleRemoveTagWithBackspaceKey = () => {
    const tagsWithoutLast = tags.slice(0, tags.length - 1)
    onChange?.(tagsWithoutLast, 'remove')
    setTags(tagsWithoutLast)
  }

  const handleRemoveTagById = (id: string | number) => {
    const newTags = tags.filter((tag) => tag.id !== id)
    onChange?.(newTags, 'remove')
    setTags(newTags)
  }

  const serializedTags = serializedTagsWithSubstring(tags)

  return (
    <S.Outside fullWidth={fullWidth} width={width}>
      {!!label && <S.Label htmlFor={rest.id}>{label}</S.Label>}
      {!!additionalInformation && (
        <S.AdditionalInformation>
          {additionalInformation}
        </S.AdditionalInformation>
      )}
      <S.Container
        fullWidth={fullWidth}
        width={width}
        hasError={!!errorMessage}
      >
        <S.TagsContainer>
          {serializedTags.map(({ disabled = false, ...tag }) => (
            <S.Tag key={tag.id} disabled={!!disabled}>
              {tag.name}
              {!disabled && (
                <S.CloseIconButton onClick={() => handleRemoveTagById(tag.id)}>
                  <CloseOutlinedIcon />
                </S.CloseIconButton>
              )}
            </S.Tag>
          ))}
        </S.TagsContainer>

        <S.Input
          {...rest}
          type="text"
          onKeyDown={handleAddOrDeleteTag}
          onChange={(event) => setInputValue(event.target.value)}
          value={inputValue}
          ref={ref}
          placeholder={tags.length ? '' : rest.placeholder}
        />
      </S.Container>
      {!!errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
      <S.SuggestContainer
        show={
          !!(
            showSuggestions &&
            filteredSuggestions &&
            filteredSuggestions.length
          )
        }
      >
        {filteredSuggestions.map((suggest, index) => (
          <S.SuggestItem
            key={suggest.id}
            activeSuggestion={activeSuggestion === index}
            onClick={() => handleAddTagPerSuggestList(suggest.id)}
          >
            {suggest.name}
          </S.SuggestItem>
        ))}
      </S.SuggestContainer>
    </S.Outside>
  )
}

export const InputTag = forwardRef(InputTagComponent)
