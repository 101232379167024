import { useContext, useState, useEffect } from 'react'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateProjectContext } from '../index'
import { useForm } from 'react-hook-form'
import { Button, Col, Row } from 'react-bootstrap'
import TagInput from '../../../../components/molecules/TagInput'
import { BuildingModel } from '../../../../domain/models/BuildingModel'
import { Tag } from '../../../../components/molecules/InputTag'
import UpdateProjectStep4 from './updateProjectStep4'
import * as React from 'react'
import Information from '../../../../components/atoms/Information'

type unitsType = { [key: string]: Array<Record<string, string>> }
type errorType = Record<string, boolean>
export interface ICreateProjectFormStep4 {
  units: unitsType
}

export default function Step4({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateProjectContext)
  const [units, setUnits] = useState<unitsType>(data?.units || {})
  const [error, setError] = useState<errorType>({})
  const buildings = data?.buildings
  const isUpdatePage = () => data?.id

  const { handleSubmit } = useForm<ICreateProjectFormStep4>({})

  useEffect(() => {
    if (isUpdatePage()) return createInitialBuildingsAndUnits()
    if (!data?.units) return createInitialUnitListByBuildings()
  }, [buildings])

  const createInitialBuildingsAndUnits = () => {
    const units: unitsType = {}
    buildings &&
      (buildings as BuildingModel[]).forEach(
        (building) => (units[building.name] = createInitialUnits(building))
      )
    setUnits(units)
  }

  const createInitialUnits = (building: BuildingModel): Record<any, any>[] => {
    if (building.units)
      return building.units.filter((unit) => unit.building_id === building.id)

    return []
  }

  const createInitialUnitListByBuildings = () => {
    const initObject: unitsType = {}
    buildings && buildings.forEach((unit) => (initObject[unit.name] = []))
    setUnits(initObject)
  }

  const validateUnitsFields = (units: unitsType) => {
    const hasError: errorType = {}
    Object.entries(units).forEach(
      ([key, value]) => !value.length && (hasError[key] = true)
    )
    return hasError
  }

  const onClickNext = (formData: ICreateProjectFormStep4) => {
    const errors = isUpdatePage() ? {} : validateUnitsFields(units)
    if (Object.keys(errors).length) return setError(errors)
    setData?.({ ...data, units })
    setError({})
    nextHandler()
  }

  return (
    <FormWizardStyles.TabContent>
      <Row>
        {isUpdatePage() && (
          <Col md={12}>
            <strong>Bloco(s):</strong>
          </Col>
        )}
        {buildings?.map((building, index) => {
          const errorMsg = error[building.name]
            ? `O campo ${building.name} é requerido.`
            : ''

          return (
            <Col
              data-testid={`input-${building}`}
              className="mt-3"
              md={12}
              key={index}
            >
              {isUpdatePage() ? (
                <UpdateProjectStep4 building={building as BuildingModel} />
              ) : (
                <TagInput
                  data-testid={`input-${building.name}`}
                  label={building.name}
                  info={
                    <Information>
                      Digite o número de identificação de todas as Unidades
                      deste Bloco e aperte a tecla <strong>ENTER</strong> após
                      cada número.
                    </Information>
                  }
                  errorMessage={errorMsg}
                  onChange={(tags) =>
                    setUnits({ ...units, [building.name]: tags })
                  }
                  defaultTags={
                    data?.units?.[building.name] as unknown[] as Tag[]
                  }
                />
              )}
            </Col>
          )
        })}
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperSpaced>
            <Button variant="link" onClick={backHandler}>
              Voltar
            </Button>
            <Button variant="primary" onClick={handleSubmit(onClickNext)}>
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperSpaced>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
