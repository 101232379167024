import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { Button, Col, Row, Alert } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { SelectField } from '../../../../components/atoms/SelectField'
import step2_schema from './step2.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { InputFile } from '../../../../components/molecules/InputFile'
import { TextInput } from '../../../../components/atoms/TextInput'
import { CreateUnitContext } from '../index'
import { DispatchContext } from '../actions'
import * as S from './styles'
import { MultiSelectField } from 'components/atoms/MultiSelectField'
import Utils from '../utils'
import { unitCharacteristics } from 'constants/unitCharacteristics'

export interface ICreateUnitFormStep2 {
  characteristics: string
  total_bedrooms: number
  description: string
  file?: File
  file_type?: string
  file_size?: number
}

export default function Step2({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateUnitContext)
  const { state } = useContext(DispatchContext)
  const [file, setFile] = useState<File>()
  const [buildingName, setBuildingName] = useState('')

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ICreateUnitFormStep2>({
    resolver: yupResolver(step2_schema),
    defaultValues: {
      characteristics: data?.characteristics,
      total_bedrooms: data?.total_bedrooms || 1,
      description: data?.description,
      file: data?.file,
      file_type: data?.file_type,
      file_size: data?.file_size
    }
  })

  useEffect(() => {
    if (data?.file) {
      setFile(data?.file)
    }
  }, [data?.file])

  useEffect(() => {
    const { buildings } = state.project

    const [building] =
      buildings &&
      buildings.filter((item: any) => item.id === data?.building_id)
    setBuildingName(building.name)
  }, [data, state])

  const onClickNext = (formData: ICreateUnitFormStep2) => {
    setData!({ ...data, ...formData, file })
    nextHandler()
  }

  const isUpdatePage = state.unit.characteristics

  useEffect(() => {
    if (!state.unit.characteristics) return
    setValue('characteristics', state.unit.characteristics.split(','))
  }, [state.unit.characteristics])

  const createCharacteristicsSelectOptions = () => {
    const characteristics = state.unit.characteristics.split(',')
    return Utils.convertArrayInSelectOptions(characteristics)
  }

  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={3}>
            <S.LabelUnits>
              <span className="title">{buildingName}</span>
              <span className="label">Bloco</span>
            </S.LabelUnits>
          </Col>
          <Col md={4}>
            <S.LabelUnits>
              <span className="title">{data?.unitsName?.toString()}</span>
              <span className="label">Unidade(s)</span>
            </S.LabelUnits>
          </Col>
        </Row>
        <Row className="mt-5 mb-3">
          <Col>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="description"
                  placeholder="Conte-nos um pouco sobre essas Unidades"
                  type="text"
                  label="Descrição"
                  errorMessage={errors.description?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={6}>
            <InputFile
              btnText={file?.name || 'Buscar arquivo'}
              label="Planta da Unidade (Formato PDF)"
              accept="application/pdf"
              onChange={(file) => {
                setValue('file_type', file.type, {
                  shouldValidate: true
                })
                setValue('file_size', file.size, {
                  shouldValidate: true
                })
                setFile(file)
              }}
              errorMessage={
                errors.file_type?.message || errors.file_size?.message
              }
            />
          </Col>
          <Col className="ml-5" md={3}>
            <Controller
              control={control}
              name="total_bedrooms"
              render={({ field: { onChange, value } }) => (
                <SelectField
                  errorMessage={errors.total_bedrooms?.message}
                  onChange={(event) => onChange(Number(event.target.value))}
                  value={value}
                  emptyOptionText={'Selecione'}
                  label="N.º de Dormitórios"
                  options={[
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                    { label: '5', value: '5' },
                    { label: '6', value: '6' },
                    { label: '7', value: '7' },
                    { label: '8', value: '8' },
                    { label: '9', value: '9' },
                    { label: '10', value: '10' }
                  ]}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Controller
              name="characteristics"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <MultiSelectField
                    label="Características da Unidade"
                    options={unitCharacteristics}
                    onChange={(options) => onChange(options as string[])}
                    defaultValue={
                      isUpdatePage && createCharacteristicsSelectOptions()
                    }
                    errorMessage={errors.characteristics?.message}
                    emptyOptionText="Selecione as características da Unidade"
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
