import { Button, Card } from 'react-bootstrap'
import * as S from './styles'
interface CallToActionCardProps {
  title: string
  description: string
  buttonText: string
  onClick?: () => void
  disabled?: boolean
}

export const CallToActionCard = ({
  title,
  description,
  buttonText,
  disabled = false,
  onClick
}: CallToActionCardProps) => {
  return (
    <S.Container disabled={!!disabled}>
      <Card className="card-smaller-radius">
        <Card.Header>
          <h5>{title}</h5>
        </Card.Header>
        <Card.Body>
          <p>{description}</p>
          <Button className="button" disabled={disabled} onClick={onClick}>
            {buttonText}
          </Button>
        </Card.Body>
      </Card>
    </S.Container>
  )
}
