import { combineReducers } from 'redux'
import { createSelectorHook } from 'react-redux'
import ableReducer, { initialState as AbleState } from './ableReducer'
import loadingReducer from './loadingReducer'

const reducer = combineReducers({
  able: ableReducer,
  loading: loadingReducer
})

export const useSelector = createSelectorHook<{
  able: typeof AbleState
  loading: boolean
}>()

export const useLoadingSelector = createSelectorHook<{
  loading: boolean
}>()

export default reducer
