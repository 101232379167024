import { HttpClient } from '../../protocols/http/httpClient'
import { editCompany, Company } from '../../../domain/usecases/Company'
import { CompanyReturnedFromCreation } from '../../../domain/models/CompanyModel'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export class RemoteEditCompany implements editCompany {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async editById(
    params: Company.EditParams
  ): Promise<CompanyReturnedFromCreation> {
    return await this.httpClient.patch?.({
      url: `${this.url}/companies/${params.id}`,
      data: {
        business_name: params.business_name,
        cnpj: params.cnpj,
        proxies: params.proxies
      }
    })
  }
}
