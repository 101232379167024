import * as React from 'react'
import { useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import step2_schema from './step2.schema'
import * as FormWizardStyles from '../../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../../components/organisms/FormWizard'
import { CreatePromissoryBuyerContext } from '../index'
import { TextInputWithMask } from '../../../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../../../components/atoms/TextInput'
import SwitchButton from '../../../../../components/molecules/SwitchButton'
import { useGetCep } from '../../../../../hooks/useGetCep'
import { CepDto } from '../../../../../domain/usecases/Cep'
import { AddressPerson } from '../../../../../domain/models/PersonsModel'

export interface ICreatePromissoryBuyerFormStep2 {
  address: Partial<AddressPerson>
  secondary_address?: Partial<AddressPerson>
}

export default function Step2({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreatePromissoryBuyerContext)
  const hasSecondaryAddress = !!data?.secondary_address?.zip_code_business
  const [businessAddress, setBusinessAddress] =
    React.useState(hasSecondaryAddress)
  const { getCep } = useGetCep()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<ICreatePromissoryBuyerFormStep2>({
    resolver: yupResolver(step2_schema),
    defaultValues: {
      address: data?.address,
      secondary_address: data?.secondary_address
    }
  })

  const watchAddress = watch('address')

  const onClickNext = (formData: ICreatePromissoryBuyerFormStep2) => {
    setData?.({ ...data, ...formData })
    nextHandler()
  }

  const checkCep = React.useCallback(async (cep: string) => {
    const cepDto = await getCep(cep)
    if (!cepDto) return
    updateAddressState(cepDto)
  }, [])

  const updateAddressState = (cepDto: CepDto) => {
    setValue('address', {
      ...watchAddress,
      zip_code: cepDto.cep,
      address: cepDto.logradouro,
      state: cepDto.uf,
      city: cepDto.localidade,
      district: cepDto.bairro,
      country: 'Brasil'
    })
  }
  const checkCepBusiness = React.useCallback(async (cep: string) => {
    const cepDto = await getCep(cep)
    if (!cepDto) return
    updateBusinessAddressState(cepDto)
  }, [])

  const updateBusinessAddressState = (cepDto: CepDto) => {
    setValue('secondary_address', {
      zip_code_business: cepDto.cep,
      address_business: cepDto.logradouro,
      state_business: cepDto.uf,
      city_business: cepDto.localidade,
      district_business: cepDto.bairro,
      country_business: 'Brasil'
    })
  }

  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={4}>
            <Controller
              control={control}
              name="address.zip_code"
              render={({ field: { onChange, value } }) => (
                <TextInputWithMask
                  data-testid="zip_code"
                  mask="99999-999"
                  label="CEP"
                  errorMessage={errors.address?.zip_code?.message}
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value)
                    return checkCep(event.target.value)
                  }}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="address.address"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="text"
                  label="Logradouro"
                  errorMessage={errors.address?.address?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="address.number"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="number"
                  type="text"
                  label="Número"
                  errorMessage={errors.address?.number?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Controller
              control={control}
              name="address.complement"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="text"
                  label="Complemento"
                  errorMessage={errors.address?.complement?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="address.district"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="text"
                  label="Bairro"
                  errorMessage={errors.address?.district?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="address.city"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="text"
                  label="Cidade"
                  errorMessage={errors.address?.city?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Controller
              control={control}
              name="address.state"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="text"
                  label="Estado"
                  errorMessage={errors.address?.state?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="address.country"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="text"
                  label="País"
                  errorMessage={errors.address?.country?.message}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Controller
              control={control}
              name="secondary_address.allowToRegisterBusinessAddress"
              render={({ field: { onChange } }) => (
                <SwitchButton
                  defaultSwitch={businessAddress}
                  label="Cadastrar um endereço comercial"
                  onChange={() => {
                    setBusinessAddress(!businessAddress)
                    return onChange(!businessAddress)
                  }}
                />
              )}
            />
          </Col>
        </Row>

        {businessAddress && (
          <>
            <Row>
              <Col md={4}>
                <Controller
                  control={control}
                  name="secondary_address.zip_code_business"
                  render={({ field: { onChange, value } }) => (
                    <TextInputWithMask
                      mask="99999-999"
                      label="CEP"
                      errorMessage={
                        errors.secondary_address?.zip_code_business?.message
                      }
                      value={value}
                      onChange={({ target: { value } }) => {
                        onChange(value)
                        return checkCepBusiness(value)
                      }}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="secondary_address.address_business"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="text"
                      label="Logradouro"
                      errorMessage={
                        errors.secondary_address?.address_business?.message
                      }
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="secondary_address.number_business"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="text"
                      label="Número"
                      errorMessage={
                        errors.secondary_address?.number_business?.message
                      }
                      defaultValue={''}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  control={control}
                  name="secondary_address.complement_business"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="text"
                      label="Complemento"
                      errorMessage={
                        errors.secondary_address?.complement_business?.message
                      }
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="secondary_address.district_business"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="text"
                      label="Bairro"
                      errorMessage={
                        errors.secondary_address?.district_business?.message
                      }
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="secondary_address.city_business"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="text"
                      label="Cidade"
                      errorMessage={
                        errors.secondary_address?.city_business?.message
                      }
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  control={control}
                  name="secondary_address.state_business"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="text"
                      label="Estado"
                      errorMessage={
                        errors.secondary_address?.state_business?.message
                      }
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="secondary_address.country_business"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      type="text"
                      label="País"
                      errorMessage={
                        errors.secondary_address?.country_business?.message
                      }
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button
                variant="primary"
                data-testid="button_next"
                onClick={handleSubmit(onClickNext)}
              >
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
