import { HttpClient } from '../../protocols/http/httpClient'
import {
  CompanyUser,
  IUpdatedCompanyUser
} from '../../../domain/usecases/CompanyUser'
import { CompanyUser as CompanyUserModel } from '../../../domain/models/CompanyUserModel'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export class RemoteUpdateCompanyUser implements IUpdatedCompanyUser {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async update(params: CompanyUser.UpdateParams): Promise<CompanyUserModel> {
    const { id, ...properties } = params
    return await this.httpClient.patch?.({
      url: `${this.url}/company-users/${id}`,
      data: properties
    })
  }
}
