import { Form } from 'react-bootstrap'
import * as React from 'react'
import ErrorText from '../../atoms/ErrorText'

export type SwitchButtonProps = {
  label?: string
  errorMessage?: string
  defaultSwitch?: boolean
  onChange(): void
}

export default function SwitchButton({
  label,
  errorMessage,
  defaultSwitch = false,
  onChange,
  ...other
}: SwitchButtonProps) {
  return (
    <Form.Group>
      <div className="switch d-inline m-r-10">
        <Form.Control
          type="checkbox"
          id="checked-default"
          isInvalid={!!errorMessage}
          defaultChecked={defaultSwitch}
          onChange={onChange}
          {...other}
        />
        <Form.Label htmlFor="checked-default" className="cr" />
      </div>
      <Form.Label>{label && label}</Form.Label>
      {errorMessage && <ErrorText text={errorMessage} />}
    </Form.Group>
  )
}
