import React from 'react'

interface SeparateBetweenProps {
  children: React.ReactNode
}

import * as S from './styles'

export const SeparateBetween = ({ children }: SeparateBetweenProps) => {
  return <S.Wrapper>{children}</S.Wrapper>
}
