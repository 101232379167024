import { RemoteCreateCompany } from '../../../../data/usecases/Company/RemoteCreateCompany'
import { RemoteGetCompanies } from '../../../../data/usecases/Company/RemoteGetCompanies'
import { RemoteDeleteCompany } from '../../../../data/usecases/Company/RemoteDeleteCompany'
import { RemoteGetEntitiesCount } from '../../../../data/usecases/Reports/RemoteGetEntitiesCount'

import { URL_API } from '../../../../services/api_url'
import { ListCompanies } from '../../../../pages/Companies/ListCompanies'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'

export const ListCompaniesFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetCompanies = new RemoteGetCompanies(URL, axiosHttpClient)
  const remoteDeleteCompany = new RemoteDeleteCompany(URL, axiosHttpClient)
  const remoteGetEntitiesCount = new RemoteGetEntitiesCount(
    URL,
    axiosHttpClient
  )
  const remoteCreateCompany = new RemoteCreateCompany(URL, axiosHttpClient)

  return (
    <ListCompanies
      remoteGetCompanies={remoteGetCompanies}
      remoteDeleteCompany={remoteDeleteCompany}
      remoteGetEntitiesCount={remoteGetEntitiesCount}
      remoteCreateCompany={remoteCreateCompany}
    />
  )
}
