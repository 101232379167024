import * as yup from 'yup'

export default yup.object().shape({
  password: yup
    .string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'A Senha deve conter pelo menos 8 caracteres, uma letra maiúscula, um número e um caractere especial.'
    )
    .required('O campo Senha é requerido.'),
  password_confirmation: yup
    .string()
    .test(
      'is-equal-password',
      'A Confirmação de Senha deve ser igual a Senha.',
      (value, context) => context.parent.password === value
    )
    .required('O campo Confirmação de Senha é requerido.')
})
