import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ isValid }: { isValid: boolean }) => css`
    padding: 0 10px 10px 10px;
    i {
      margin-right: 5px;
      font-size: 20px;
      color: ${isValid ? '#00c547' : 'grey'};
    }
    span {
      font-size: 13px;
      color: ${isValid ? '#004218' : 'grey'};
    }
  `}
`
