import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

interface PendencyRowProps {
  redirectTo: string
  state?: Record<string, any>
  title: string
  message: string
}

const PendencyRow = ({
  redirectTo,
  title,
  message,
  state
}: PendencyRowProps) => {
  const history = useHistory()
  return (
    <Row className="p-t-30 p-b-10">
      <Col sm="auto" className="text-right update-meta">
        <i className="feather icon-alert-triangle bg-danger update-icon" />
      </Col>
      <Col>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(redirectTo, state)}
        >
          <h6>{title}</h6>
        </div>
        <p className="text-muted m-b-0">{message}</p>
      </Col>
    </Row>
  )
}

export default PendencyRow
