import { Card } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import * as React from 'react'

import { TendencyTypes, TendencyViewer } from '../../molecules/TendencyViewer'
import { IconTendencyUp } from '../../atoms/iconTendencyUp'

interface ChartCardProps {
  value: string | React.ReactNode
  name: string
  data: React.ComponentProps<typeof Chart>
  tendency?: TendencyTypes
  bgClass?: string
  children?: React.ReactElement
}

export const ChartCard = ({
  value,
  name,
  data,
  tendency,
  bgClass,
  children
}: ChartCardProps) => {
  return (
    <Card>
      <Card.Body className="pb-0">
        <div className="card-container">
          <div>
            <h2 className="m-0">{value}</h2>
            <span className="text-secondary">
              <strong>{name}</strong>
            </span>
          </div>
          <div>
            <IconTendencyUp />
          </div>
        </div>
      </Card.Body>
      <Card.Body className="p-0">
        <Chart {...data} />
        {children}
      </Card.Body>
    </Card>
  )
}
