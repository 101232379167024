import React, { useState } from 'react'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import * as S from '../styles'
import { LoadingIndicator } from '../../../components/atoms/LoadingIndicator'
import { IPaymentStep0Form } from './payment-step0-form.interface'
import schema from './payment-step0.schema'
import { Form } from 'react-bootstrap'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CheckoutDetails } from '../../../domain/models/BillingModel'
import { convertNumberToCurrencyFormat } from '../../../utils/convertNumberToCurrencyFormat'
import moment from 'moment'
import { paymentSteps } from '../index'
import { PaymentMainInfo } from '../payment-main-info'

export type PaymentStep0Props = {
  checkoutDetails: CheckoutDetails
  handleStepChange: (step: paymentSteps) => void
}

export type PaymentMethods = 'credit-card' | 'bank_billet'

const PaymentStep0 = ({
  checkoutDetails,
  handleStepChange
}: PaymentStep0Props) => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState<boolean>(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IPaymentStep0Form>({
    resolver: yupResolver(schema)
  })

  const onSubmit: SubmitHandler<IPaymentStep0Form> = async (data) => {
    try {
      handleStepChange('credit_card_form')
    } catch (e) {
      setLoading(false)
      addToast('Algo deu errado, tente novamente.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <S.ContentWrapper>
      <S.p>
        <b>Olá</b>!
        <br />
        Texto introdução, aqui você pode realizar o pagamento de forma segura,
        etc. Praesent aliquet consectetur faucibus aliquet purus ut et,
        malesuada. Maecenas tincidunt sed facilisis integer egestas quam
        sagittis. Ornare quam eu tellus.
      </S.p>
      <PaymentMainInfo checkoutDetails={checkoutDetails} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="payment_option"
          render={({ field: { onChange, value } }) => (
            <>
              <Form.Check
                type="radio"
                label="Boleto"
                name={value}
                id="boleto"
                disabled={loading}
                onChange={({ target: { id } }) => {
                  onChange(id)
                  setPaymentMethod('bank_billet')
                }}
                style={{ marginBottom: '10px' }}
              />
              {paymentMethod === 'bank_billet' && (
                <>
                  <S.BoletoInfo>
                    <ContentCopyIcon />
                    <CopyToClipboard
                      onCopy={() => {
                        addToast('Código de Barras copiado.', {
                          appearance: 'success',
                          autoDismiss: true
                        })
                      }}
                      text={
                        checkoutDetails.bank_billet_transaction.info.barcode
                      }
                    >
                      <p>Copiar Código de Barras</p>
                    </CopyToClipboard>
                  </S.BoletoInfo>

                  <S.BoletoInfo>
                    <DownloadForOfflineIcon />
                    <a
                      href={checkoutDetails.bank_billet_transaction.info.url}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      Baixar Boleto
                    </a>
                  </S.BoletoInfo>
                </>
              )}
              <Form.Check
                type="radio"
                label="Cartão de Crédito"
                name={value}
                id="credit-card"
                disabled={loading}
                onChange={({ target: { id } }) => {
                  onChange(id)
                  setPaymentMethod('credit-card')
                }}
              />
            </>
          )}
        />
        {paymentMethod && paymentMethod === 'credit-card' && (
          <button
            className="btn btn-primary pull-left mt-4 mb-4"
            style={{ width: '100%' }}
            type="submit"
            disabled={loading}
          >
            {!loading ? 'Pagar' : <LoadingIndicator />}
          </button>
        )}
      </form>
      <S.Tip>Selecione a Forma de Pagamento.</S.Tip>
    </S.ContentWrapper>
  )
}
export default PaymentStep0
