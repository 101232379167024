import { Button, Col, Row } from 'react-bootstrap'
import { TextInput } from '../../../../components/atoms/TextInput'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './step2.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { useContext } from 'react'
import { CreateProjectContext } from '../index'
import { TextInputWithMask } from '../../../../components/atoms/TextInputWithMask'
import * as React from 'react'
import { useGetCep } from '../../../../hooks/useGetCep'

export interface IAddressSchema {
  address: string
  zip_code: string
  number: string
  complement: string
  district: string
  city: string
  state: string
  country: string
}
export interface ICreateProjectFormStep2 {
  address: IAddressSchema
}

export default function Step2({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateProjectContext)
  const { getCep } = useGetCep()
  const address = data?.address
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<IAddressSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...address
    }
  })

  const onClickNext = (formData: IAddressSchema) => {
    setData?.({ ...data, address: { ...formData } })
    nextHandler()
  }

  const checkCep = React.useCallback(async (cep: string) => {
    const cepDto = await getCep(cep)
    setValue('country', 'Brasil')
    if (!cepDto) return
    setValue('address', cepDto.logradouro)
    setValue('state', cepDto.uf)
    setValue('city', cepDto.localidade)
    setValue('district', cepDto.bairro)
  }, [])

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="zip_code"
            render={({ field: { onChange, value } }) => (
              <TextInputWithMask
                data-testid="zip_code"
                mask="99999-999"
                label="CEP"
                errorMessage={errors.zip_code?.message}
                value={value}
                onChange={({ target: { value } }) => {
                  onChange(value)
                  checkCep(value)
                }}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="address"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Logradouro"
                errorMessage={errors.address?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="number"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="number"
                label="Número"
                errorMessage={errors.number?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="district"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Bairro"
                errorMessage={errors.district?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Cidade"
                errorMessage={errors.city?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="state"
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Estado"
                errorMessage={errors.state?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperSpaced>
            <Button variant="link" onClick={backHandler}>
              Voltar
            </Button>
            <Button variant="primary" onClick={handleSubmit(onClickNext)}>
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperSpaced>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
