import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormWizardHandlers } from 'components/organisms/FormWizard'
import { CreatePromissoryBuyerContext } from '../index'
import { useForm } from 'react-hook-form'
import { Button, Col, Row } from 'react-bootstrap'
import PNotify from 'pnotify/dist/es/PNotify'
import step0_schema from './step0.schema'
import ProxiesSelector from 'components/organisms/ProxiesSelector'
import * as FormWizardStyles from 'components/organisms/FormWizard/styles'
import { useContextPage } from '../../contextPage'
import { Proxy } from 'domain/models/ProxyModel'
import { useToast } from 'hooks/useToast'
import { validateEmail } from 'utils/validateEmail'

export interface ICreatePromissoryBuyerFormStep0 {
  proxies: Proxy[]
}

export default function Step0({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { showToast } = useToast()
  const { data, setData } = useContext(CreatePromissoryBuyerContext)
  const { state } = useContextPage()
  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ICreatePromissoryBuyerFormStep0>({
    resolver: yupResolver(step0_schema),
    defaultValues: {
      proxies: state.selectedPerson?.proxies
    }
  })
  const proxies = watch('proxies')
  const location = useLocation()

  const onClickNext = (formData: ICreatePromissoryBuyerFormStep0) => {
    const proxiesWithCompanyID = formData.proxies.map((proxy) => ({
      ...proxy,
      company_id: state.selectedCompanyId
    }))

    setData?.({ ...data, proxies: proxiesWithCompanyID as Proxy[] })
    nextHandler()
  }

  const validateProxies = () => {
    const hasErrors = !!proxies?.filter(
      (proxy) => !proxy?.cpf || !proxy?.name || !validateEmail(proxy?.email)
    ).length
    if (!hasErrors) {
      handleSubmit(onClickNext)()
    } else {
      showToast(
        'Por favor, verifique se todos os campos foram preenchidos corretamente.',
        'error'
      )
    }
  }

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col data-testid="proxies">
          <ProxiesSelector
            errors={errors}
            onChange={(new_proxies) => setValue('proxies', new_proxies)}
            defaultProxies={proxies}
            hintText="Insira o CPF de todos os procuradores."
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperSpaced>
            <Button variant="link" onClick={backHandler}>
              Voltar
            </Button>
            <Button
              variant="primary"
              data-testid="button_next"
              onClick={validateProxies}
            >
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperSpaced>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
