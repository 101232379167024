import { EntitiesCountModel } from '../../../domain/models/ReportsModel'
import { HttpClient } from '../../protocols/http/httpClient'
import { getEntitiesCount } from '../../../domain/usecases/Reports'

export class RemoteGetEntitiesCount implements getEntitiesCount {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpClient
  ) {}

  async getEntitiesCount(): Promise<EntitiesCountModel> {
    const httpResponse = await this.httpGetClient.get?.({
      url: `${this.url}/reports/entities-count`
    })

    if (httpResponse.error) throw new Error('Error on get entities count')
    return httpResponse
  }
}
