import { Button, Col, Row } from 'react-bootstrap'
import { TextInputWithMask } from '../../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../../components/atoms/TextInput'
import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import step1_schema from './step1.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateCompanyContext } from '../index'
import { useContext } from 'react'
import { useGetCnpj } from '../../../../hooks/useGetCnpj'

export interface ICreateCompanyFormStep1 {
  cnpj: string
  business_name: string
}

export default function Step1({ nextHandler }: FormWizardHandlers) {
  const { data, setData } = useContext(CreateCompanyContext)
  const { getCnpj } = useGetCnpj()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<ICreateCompanyFormStep1>({
    resolver: yupResolver(step1_schema),
    defaultValues: {
      ...data
    }
  })

  const onClickNext = (formData: ICreateCompanyFormStep1) => {
    setData?.({ ...data, ...formData })
    nextHandler()
  }

  const checkCnpj = React.useCallback(async (cnpj: string) => {
    try {
      const cnpjDto = await getCnpj(cnpj)
      if (!cnpjDto) return
      setValue('business_name', cnpjDto.nomeEmpresarial)
    } catch {
      reset({ cnpj: '', business_name: '' })
    }
  }, [])

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="cnpj"
            render={({ field: { onChange, value } }) => (
              <TextInputWithMask
                disabled={!!data?.cnpj}
                data-testid="cnpj"
                mask="99.999.999/9999-99"
                label="CNPJ"
                placeholder="CNPJ da Incorporadora"
                errorMessage={errors.cnpj?.message}
                value={value}
                defaultValue={data?.cnpj}
                onChange={({ target: { value } }) => {
                  onChange(value)
                  checkCnpj(value)
                }}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="business_name"
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="text"
                label="Razão Social"
                readOnly
                placeholder=""
                errorMessage={errors.business_name?.message}
                onChange={onChange}
                value={value}
                defaultValue={`${data?.business_name}` && ''}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperRight>
            <Button
              variant="primary"
              data-testid="button-submit"
              onClick={handleSubmit(onClickNext)}
            >
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperRight>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
