import { Form } from 'react-bootstrap'
import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import ErrorText from '../ErrorText'
import IntlCurrencyInput from 'react-intl-currency-input'
import config from './config'

type TextFieldTypes = InputHTMLAttributes<HTMLInputElement>

export type CurrencyInputProps = {
  label?: string
  disabled?: boolean
  value?: string
  defaultValue?: string
  as?: HTMLElement
  errorMessage?: string
  onChange?: (value: number, maskedValue: string) => void
  onBlur?: (value: number, maskedValue: string) => void
} & Omit<TextFieldTypes, 'size' | 'value' | 'defaultValue'>

export const CurrencyInput = ({
  label,
  placeholder,
  errorMessage,
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  defaultValue,
  ...other
}: CurrencyInputProps) => {
  const formatValueToCurrencyLocale = (value: string) => {
    return new Intl.NumberFormat('pt-Br', {
      style: 'currency',
      currency: 'BRL'
    }).format(Number(value))
  }

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          <strong>{label}</strong>
        </Form.Label>
      )}

      <IntlCurrencyInput
        className="form-control"
        currency="BRL"
        config={config}
        max={999999999999}
        onChange={(_: unknown, value: number, maskedValue: string) =>
          onChange(value, maskedValue)
        }
        onBlur={(_: unknown, value: number, maskedValue: string) =>
          onBlur(value, maskedValue)
        }
        defaultValue={defaultValue && formatValueToCurrencyLocale(defaultValue)}
        {...other}
      />

      {errorMessage && <ErrorText text={errorMessage} />}
    </Form.Group>
  )
}
