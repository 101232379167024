import { UnitType } from '../../../domain/models/UnitModel'
import { ICreateUnitForm } from './index'

export default class Utils {
  static convertUnitObjToExpectedFormatContext(
    unit: UnitType
  ): ICreateUnitForm {
    return {
      id: unit.id,
      building_id: unit.building_id,
      unitsName: [unit.name],
      characteristics: unit.characteristics,
      total_bedrooms: unit.total_bedrooms,
      description: unit.description,
      real_private_area: unit.real_private_area,
      real_private_area_accessory: unit.real_private_area_accessory,
      common_real_area: unit.common_real_area,
      real_total_area: unit.real_total_area,
      ideal_fraction: unit.ideal_fraction,
      value: String(unit.value),
      parkingSlotByUnit: {
        slots: [...unit.parking_slots?.map((item) => item.name)] || []
      }
    }
  }

  static convertArrayInSelectOptions(
    array: string[]
  ): { label: string; value: string }[] {
    return array.map((item) => ({ label: item, value: item }))
  }
}
