import { HttpClient } from '../../protocols/http/httpClient'
import { createCompany, Company } from '../../../domain/usecases/Company'
import { CompanyReturnedFromCreation } from '../../../domain/models/CompanyModel'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export class RemoteCreateCompany implements createCompany {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async createOne(
    params: Company.ParamsToCreate
  ): Promise<CompanyReturnedFromCreation> {
    return await this.httpClient.post?.({
      url: `${this.url}/companies/`,
      data: params
    })
  }

  async uploadCompanyPhoto(params: Company.UploadCompanyPhoto): Promise<any> {
    const httpResponse = await this.httpClient.post?.({
      url: `${this.url}/companies/${params.id}/upload-photo`,
      data: { base64: params.base64 }
    })

    if (httpResponse?.statusCode && httpResponse?.statusCode !== 201)
      throw new Error('error on upload company photo')
    return httpResponse
  }
}
