import { useState, KeyboardEvent } from 'react'
import { useEventListener } from '../../../hooks/useEventListener'
import { TextInput, TextInputProps } from '../TextInput'

import * as S from './styles'

export type Option = {
  label: string
  value: string
}

export type TextInputSelectProps = {
  options: Option[]
  onChange?: (option: Option) => void
  defaultValue?: Option
  input?: TextInputProps
}

export const TextInputSelect = ({
  input,
  onChange,
  options,
  defaultValue
}: TextInputSelectProps) => {
  const [inputValue, setInputValue] = useState(
    defaultValue ? defaultValue.label : ''
  )
  const [isOpen, setIsOpen] = useState(false)

  useEventListener('click', (event) => {
    //@ts-ignore
    const classValue: string = event.target.className

    classValue.startsWith('search-input-value')
      ? setIsOpen(true)
      : setIsOpen(false)
  })
  useEventListener('keydown', (event) => {
    const newEvent = event as unknown as KeyboardEvent<HTMLInputElement>

    if (newEvent.key === 'Escape') {
      setIsOpen(false)
    }
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    setInputValue(searchValue)

    const existsOption = options.find((option) => option.label === searchValue)

    if (existsOption) {
      onChange && onChange(existsOption)
    }
  }

  const handleFocus = () => setIsOpen(true)

  const handleClickOption = (option: Option) => {
    setInputValue(option.label)
    setIsOpen(false)
    onChange && onChange(option)
  }

  const filterOptions = options.filter(({ label }) =>
    label.toLowerCase().includes(inputValue.toLowerCase())
  )

  return (
    <S.SelectSearch>
      <TextInput
        onChange={handleChange}
        onFocus={handleFocus}
        className="search-input-value"
        value={inputValue}
        {...input}
      />
      <S.OptionsList>
        {isOpen &&
          filterOptions.map((option) => (
            <S.Option
              key={option.value}
              value={option.value}
              onClick={() => handleClickOption(option)}
            >
              {option.label}
            </S.Option>
          ))}
      </S.OptionsList>
    </S.SelectSearch>
  )
}
