import { Button, Col, Row } from 'react-bootstrap'
import { TextInput } from '../../../../components/atoms/TextInput'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import step1_schema from './step1.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { useCallback, useContext, useEffect, useState } from 'react'
import { CreateProjectContext } from '../index'
import { InputFile } from '../../../../components/molecules/InputFile'
import { SelectField } from '../../../../components/atoms/SelectField'
import { DispatchContext } from '../actions'
import { SpeType } from '../../../../domain/models/SpeModel'
import { DateInput } from 'components/atoms/DateInput'

export interface ICreateProjectFormStep1 {
  ri: string
  name: string
  ri_document?: File
  building_permit?: File
  notary: string
  spe_id: string
  id: string
  conclusion_date: Date
  matriculation_number: string
}
type Files = {
  file_type_ri_document?: string
  file_type_building_permit?: string
  file_size?: number
}

export default function Step1({ nextHandler }: FormWizardHandlers) {
  const [spes, setSpes] = useState<SpeType[]>([])
  const { remoteGetSpes } = useContext(DispatchContext)

  const fetchSpes = useCallback(async () => {
    const res = await remoteGetSpes?.getAll()
    setSpes(res!)
  }, [])

  useEffect(() => {
    fetchSpes()
  }, [fetchSpes])
  const { data, setData } = useContext(CreateProjectContext)

  const [ri_document, setRi_document] = useState<File | null>()
  const [building_permit, setBuildingPermit] = useState<File | null>()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ICreateProjectFormStep1 & Files>({
    resolver: yupResolver(step1_schema),
    defaultValues: {
      ...data
    }
  })

  const onClickNext = (formData: ICreateProjectFormStep1) => {
    setData?.({
      ...data,
      ...formData,
      building_permit: building_permit as File,
      ri_document: ri_document as File
    })
    nextHandler()
  }

  return (
    <FormWizardStyles.TabContent>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={6}>
              <Controller
                control={control}
                name="spe_id"
                render={({ field: { onChange, value } }) => (
                  <SelectField
                    data-testid="select-spe"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    label="SPE"
                    defaultValue={data?.spe_id || ''}
                    options={[
                      { label: 'Selecione', value: '' },
                      ...spes.map((spe) => {
                        const obj: any = {}
                        obj['label'] = spe.business_name
                        obj['value'] = spe.id

                        return obj
                      })
                    ]}
                    errorMessage={errors.spe_id?.message}
                  />
                )}
              />
            </Col>
            <Col md={6}>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    data-testid="name"
                    label="Nome do Empreendimento"
                    errorMessage={errors.name?.message}
                    value={value}
                    defaultValue={data?.name}
                    onChange={onChange}
                  />
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="ri"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="ri"
                label="Registro da Incorporação"
                placeholder="N.º do Registro"
                defaultValue={data?.ri}
                errorMessage={errors.ri?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <InputFile
            data-testid="file_type_ri_document"
            btnText={ri_document?.name || 'Buscar arquivo'}
            label="Documento de Registro da Incorporação (Formato PDF)"
            accept="application/pdf"
            originalFile={data?.ri_document?.name}
            onChange={(file) => {
              setValue('file_type_ri_document', file?.type, {
                shouldValidate: true
              })
              setValue('file_size', file?.size, {
                shouldValidate: true
              })
              setRi_document(file)
            }}
            errorMessage={
              errors.file_type_ri_document?.message || errors.file_size?.message
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="notary"
            render={({ field: { onChange, value } }) => (
              <TextInput
                data-testid="notary"
                type="text"
                label="Cartório de Registro de Imóveis"
                errorMessage={errors.notary?.message}
                value={value}
                defaultValue={data?.notary}
                onChange={onChange}
              />
            )}
          />
        </Col>
        <Col md={6}>
          <InputFile
            btnText={building_permit?.name || 'Buscar arquivo'}
            label="Alvará de Construção (Formato PDF)"
            accept="application/pdf"
            originalFile={data?.building_permit?.name}
            onChange={(file) => {
              setValue('file_type_building_permit', file?.type, {
                shouldValidate: true
              })
              setValue('file_size', file?.size, {
                shouldValidate: true
              })
              setBuildingPermit(file)
            }}
            errorMessage={
              errors.file_type_building_permit?.message ||
              errors.file_size?.message
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Controller
            control={control}
            name="conclusion_date"
            render={({ field: { onChange } }) => {
              return (
                <DateInput
                  defaultValue={data?.conclusion_date}
                  errorMessage={errors.conclusion_date?.message}
                  label="Data prevista para conclusão da obra"
                  onChange={onChange}
                />
              )
            }}
          />
        </Col>
        <Col md={6}>
          <Controller
            control={control}
            name="matriculation_number"
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  label="Número de Matrícula"
                  defaultValue={data?.matriculation_number}
                  errorMessage={errors.matriculation_number?.message}
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWizardStyles.ButtonsWrapperRight>
            <Button variant="primary" onClick={handleSubmit(onClickNext)}>
              Próximo
            </Button>
          </FormWizardStyles.ButtonsWrapperRight>
        </Col>
      </Row>
    </FormWizardStyles.TabContent>
  )
}
