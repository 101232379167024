import React from 'react'
import { Form } from 'react-bootstrap'
import { InputTag, Tag } from '../InputTag'

export type tag = Tag
export interface TagInputProps {
  errorMessage?: string
  placeholder?: string
  label?: string
  info?: React.ReactElement
  allowNew?: boolean
  fullWidth?: boolean
  suggestions?: Tag[]
  onChange: (data: any[], type?: 'add' | 'remove') => void
  onValidate?(tag: Tag, data: Tag[]): any
  defaultTags?: Tag[]
  values?: Tag[]
  interceptorBeforeAddTag?: (value: string) => string | void
  rerenderOnValuesChange?: boolean
}

const TagInput = ({
  placeholder = '',
  label,
  info,
  defaultTags = [],
  values = [],
  onChange,
  allowNew = false,
  fullWidth = true,
  suggestions,
  errorMessage,
  onValidate,
  interceptorBeforeAddTag,
  rerenderOnValuesChange = false
}: TagInputProps) => {
  return (
    <Form.Group>
      <InputTag
        label={label}
        additionalInformation={info}
        placeholder={placeholder}
        values={defaultTags.length ? defaultTags : values}
        suggestions={suggestions}
        onlySuggestions={allowNew}
        onChange={onChange}
        errorMessage={errorMessage}
        onValidate={onValidate}
        fullWidth={fullWidth}
        rerenderOnValuesChange={rerenderOnValuesChange}
        interceptorBeforeAddTagFunc={interceptorBeforeAddTag}
      />
    </Form.Group>
  )
}

export default TagInput
