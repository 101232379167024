import * as yup from 'yup'

export default yup.object().shape({
  total_bedrooms: yup
    .number()
    .required('O campo N.º de Dormitórios é requerido.'),
  description: yup.string().required('O campo Descrição é requerido.'),
  characteristics: yup
    .array()
    .required('O campo Características da Unidade é requerido.'),
  file_size: yup.number(),
  file_type: yup.string()
})
