import * as React from 'react'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateSpeContext } from '../index'
import { Controller, useForm } from 'react-hook-form'
import { Button, Col, Row, Form } from 'react-bootstrap'
import { useContext } from 'react'
import { TextInput } from '../../../../components/atoms/TextInput'
import { NumberInput } from '../../../../components/atoms/NumberInput'
import { SelectField } from '../../../../components/atoms/SelectField'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './step4.schema'
import { BANK_AND_CODE_LIST } from '../../../../constants/banksAndCodesList'
import { useCreationConfirmModal } from '../../../../hooks/useCreationConfirmModal'
import { TextInputSelect } from '../../../../components/atoms/TextInputSelect'
import { formatValueToNumberInput } from '../../../../utils/formatValueToNumberInput'
import Information from '../../../../components/atoms/Information'

export interface ICreateSpeFormStep4 {
  fine: string
  interest: string
  interest_type: 'monthly' | 'daily'
  bank_account_type: 'savings' | 'checkings'
  bank: string
  branch: string
  account: string
  branch_digit: string
  account_digit: string
}

export default function Step4({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateSpeContext)
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ICreateSpeFormStep4>({
    resolver: yupResolver(schema),
    defaultValues: {
      fine: formatValueToNumberInput(data?.fine),
      interest: formatValueToNumberInput(data?.interest),
      interest_type: data?.interest_type,
      bank: data?.bank,
      branch: data?.branch,
      account: data?.account,
      branch_digit: data?.branch_digit,
      account_digit: data?.account_digit,
      bank_account_type: data?.bank_account_type || 'checkings'
    }
  })

  const { setShowConfirmationModal, Modal } = useCreationConfirmModal({
    entityText: 'da SPE',
    onFinish: nextHandler
  })

  const onClickNext = (formData: ICreateSpeFormStep4) => {
    formData.fine = formatValueToNumberInput(formData.fine, 'reverse')
    formData.interest = formatValueToNumberInput(formData.interest, 'reverse')

    setData?.({ ...data, ...formData })
    setShowConfirmationModal(true)
  }

  return (
    <>
      <FormWizardStyles.TabContent>
        <Information>
          Essas informações serão utilizadas para a gestão do Contrato que será
          automatizado.
        </Information>

        <Row>
          <Col md={4}>
            <Controller
              control={control}
              name="interest"
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  label="Multa (%)"
                  placeholder="Valor % da Multa"
                  errorMessage={errors.interest?.message}
                  value={value}
                  defaultValue={data?.interest || ''}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="fine"
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  label="Taxa de Juros (%)"
                  placeholder="Valor % da Taxa de Juros"
                  errorMessage={errors.fine?.message}
                  value={value}
                  defaultValue={data?.fine}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="interest_type"
              render={({ field: { onChange, value } }) => {
                return (
                  <SelectField
                    data-testid="interest_type"
                    errorMessage={errors.interest_type?.message}
                    emptyOptionText={'Selecione'}
                    onChange={onChange}
                    label="Periodicidade"
                    defaultValue={data?.interest_type || ''}
                    options={[
                      { label: 'Mensal', value: 'monthly' },
                      { label: 'Diário', value: 'daily' }
                    ]}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12}>
            <Form.Label className="mb-4">
              <b>Dados Bancários</b>
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Controller
              control={control}
              name="bank"
              render={({ field: { onChange, value } }) => {
                const defaultValueBank = BANK_AND_CODE_LIST.find(
                  (bank) => Number(bank.value) === Number(data?.bank)
                )
                return (
                  <TextInputSelect
                    data-testid="bank"
                    input={{
                      errorMessage: errors.bank?.message,
                      label: 'Banco'
                    }}
                    onChange={(option) => {
                      onChange(option?.value)
                    }}
                    options={BANK_AND_CODE_LIST.map((option) => ({
                      label: `${option.value} - ${option.label}`,
                      value: option.value
                    }))}
                    defaultValue={defaultValueBank}
                  />
                )
              }}
            />
          </Col>
          <Col md={2}>
            <Controller
              control={control}
              name="branch"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="branch"
                  type="text"
                  label="Agência"
                  errorMessage={errors.branch?.message}
                  value={value}
                  defaultValue={data?.branch}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={1}>
            <Controller
              control={control}
              name="branch_digit"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="branch_digit"
                  type="text"
                  label="Dígito"
                  errorMessage={errors.branch_digit?.message}
                  defaultValue={data?.branch_digit}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={2}>
            <Controller
              control={control}
              name="account"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="account"
                  type="text"
                  label="Conta"
                  errorMessage={errors.account?.message}
                  defaultValue={data?.account}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={1}>
            <Controller
              control={control}
              name="account_digit"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  data-testid="account_digit"
                  type="text"
                  label="Dígito"
                  errorMessage={errors.account_digit?.message}
                  value={value}
                  defaultValue={data?.account_digit}
                  onChange={onChange}
                />
              )}
            />
          </Col>
          <Col md={3}>
            <Controller
              control={control}
              name="bank_account_type"
              render={({ field: { onChange, value } }) => (
                <SelectField
                  data-testid="bank_account_type"
                  errorMessage={errors.bank_account_type?.message}
                  emptyOptionText={'Selecione'}
                  defaultValue={data?.bank_account_type}
                  onChange={onChange}
                  label="Tipo de Conta"
                  value={value}
                  options={[
                    { label: 'Conta Corrente', value: 'checkings' },
                    { label: 'Conta Poupança', value: 'savings' }
                  ]}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
      {Modal}
    </>
  )
}
