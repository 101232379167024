import { Button, Card, Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './verify-simplified-smart-contract.schema'
import * as React from 'react'
import { TextInput } from '../../../components/atoms/TextInput'
import logo from '../../../assets/images/logo.svg'
import { Centered } from '../../../components/templates/Centered'
import { useContext, useState } from 'react'
import loadingBlockchainImg from '../../../assets/loading-blockchain.gif'
import { InputFile } from '../../../components/molecules/InputFile'
import { verifySimplifiedContract } from '../../../services/simplified-smart-contracts'
import { useToasts } from 'react-toast-notifications'
import { useIsLogged } from '../../../hooks/useIsLogged'
import { AppContext } from '../../../app'
import { IHistoryPush } from '../../../interfaces/router'
import { HeaderCard } from '../HeaderCard'

export interface VerifySimplifiedSmartContract {
  id: string
  file_type: string
  file_size: number
  file?: File
}

export const VerifySimplifiedSmartContract = ({
  history
}: {
  history: IHistoryPush
}) => {
  document.title = 'Recebível Chipado'

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successData, setSuccessData] = useState<boolean>()
  const { addToast } = useToasts()
  const [file, setFile] = useState<File>()

  useIsLogged(history)

  const { user } = useContext(AppContext)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<VerifySimplifiedSmartContract>({
    resolver: yupResolver(schema)
  })

  const send = async (data: VerifySimplifiedSmartContract) => {
    try {
      setLoading(true)
      //eslint-disable-next-line
      const res = await verifySimplifiedContract(user!.token, {
        ...data,
        file
      })
      setSuccessData(res.data.match)
      setLoading(false)
      setSuccess(true)
    } catch (e) {
      setLoading(false)
      addToast('Ocorreu um erro desconhecido.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(send)}>
          <Row>
            <Col md={12}>
              <Card>
                <HeaderCard title="Validação de registro na rede Ethereum" />

                {success && (
                  <Card.Body>
                    <Centered>
                      {successData && (
                        <>
                          <i
                            style={{ fontSize: '100px', color: 'green' }}
                            className="feather icon-check"
                          />
                          <h5>
                            A validação foi efetuada com sucesso!
                            <br /> O documento está registrado na Blockchain.
                          </h5>
                        </>
                      )}
                      {!successData && (
                        <>
                          <i
                            style={{ fontSize: '100px', color: 'darkred' }}
                            className="feather icon-x"
                          />
                          <h5>
                            Validação falhou. O documento escolhido não é o
                            mesmo registrado na Blockchain.
                          </h5>
                        </>
                      )}
                      <br />
                      <a href="/verify-smart-contract">Voltar</a>
                    </Centered>
                  </Card.Body>
                )}
                {loading && (
                  <Card.Body>
                    <Centered>
                      <h5>
                        Por favor, aguarde enquanto consultamos a Blockchain.
                      </h5>
                      <img
                        style={{ width: '400px', maxWidth: '90%' }}
                        src={loadingBlockchainImg}
                      />
                    </Centered>
                  </Card.Body>
                )}
                {!loading && !success && (
                  <>
                    <Card.Body className="card-small-padding">
                      <Row>
                        <Col md={12}>
                          <p>
                            Por favor, preencha os campos abaixo para consultar
                            um Contrato na Blockchain Ethereum.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <InputFile
                            label="Arquivo do Contrato (Formato PDF)"
                            btnText={file?.name || 'Buscar arquivo'}
                            onChange={(file) => {
                              setValue('file_type', file.type, {
                                shouldValidate: true
                              })
                              setValue('file_size', file.size, {
                                shouldValidate: true
                              })
                              setFile(file)
                            }}
                            errorMessage={
                              errors.file_type?.message ||
                              errors.file_size?.message
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Controller
                            control={control}
                            name="id"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                label="ID do Contrato"
                                placeholder="Digite o ID do Contrato"
                                errorMessage={errors.id?.message}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn btn-block"
                      >
                        Consultar
                      </Button>
                    </Card.Footer>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}
