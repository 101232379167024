import React, { useContext, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import step4_schema from './step4.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateContractsContext } from '../index'
import { TextInput } from '../../../../components/atoms/TextInput'
import { CurrencyInput } from '../../../../components/atoms/CurrencyInput'
import { Contract } from '../../../../domain/usecases/Contracts'
import { convertNumberToCurrencyFormat } from '../../../../utils/convertNumberToCurrencyFormat'

import { useContextPage } from '../contextPage'
import { useToast } from '../../../../hooks/useToast'

export type ICreateContractsFormStep4 = Pick<Contract.CreateParams, 'value'>

export default function Step4({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateContractsContext)
  const { state, setState } = useContextPage()
  const { showToast } = useToast()
  const [valueUnit, setValueUnit] = useState('')
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ICreateContractsFormStep4>({
    resolver: yupResolver(step4_schema),
    defaultValues: {
      value: data?.value || 0
    }
  })

  React.useEffect(() => {
    setValueUnit(
      convertNumberToCurrencyFormat(Number(state.unitSelected?.value))
    )
  }, [])

  const onClickNext = (formData: ICreateContractsFormStep4) => {
    if (formData.value > state.unitSelected!.value)
      return showToast(
        'O Valor Negociado deve ser menor ou igual ao Valor do Imóvel.',
        'error'
      )
    setData!({
      ...data,
      value: Number(formData.value || state.unitSelected?.value)
    })
    setState({ ...state, valueTraded: formData.value })
    nextHandler()
  }

  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        <Row className="mt-4">
          <Col md={6}>
            <TextInput
              type="text"
              label="Valor do Imóvel (R$)"
              disabled={true}
              value={valueUnit}
            />
          </Col>
          <Col md={6}>
            <Controller
              control={control}
              name="value"
              render={({ field: { onChange, value } }) => {
                return (
                  <CurrencyInput
                    defaultValue={String(data?.value)}
                    data-testid="value"
                    label="Valor Negociado (R$)"
                    onChange={(value) => onChange(Number(value))}
                    errorMessage={errors.value?.message}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
