import React, { useContext, useState } from 'react'
import { UnitType } from '../../../domain/models/UnitModel'
import { ProjectType } from '../../../domain/models/ProjectModel'
import { IGetSpes } from '../../../domain/usecases/SPE'
import { getProject } from '../../../domain/usecases/Project'
import { Person } from '../../../domain/models/PersonsModel'
import { IGetPersons } from '../../../domain/usecases/Persons'
import { ICreateContracts } from '../../../domain/usecases/Contracts'
import { getCompany } from '../../../domain/usecases/Company'

/*eslint-disable */

type ContextPageProviderProps = {
    children: React.ReactNode
    getSpes: IGetSpes
    getProjects: getProject
    getPersons: IGetPersons
    createContract: ICreateContracts
    getCompanies: getCompany
}

export type State = {
    projectsSelected?: ProjectType
    units?: UnitType[]
    unit_id?: string
    persons?: Person[]
    selectedPerson?: Partial<Person>
    cpfOrCnpjList: string[]
    selectedCompanyId?: string
    unitSelected?: UnitType
    valueTraded?: number
    paymentMethods?: string[]
}

export type ContextTypes = {
    state: State
    setState: React.Dispatch<React.SetStateAction<State>>
    getSpes: IGetSpes
    getProjects: getProject
    getPersons: IGetPersons
    createContract:ICreateContracts
    getCompanies: getCompany
}

const Context = React.createContext<ContextTypes>({} as ContextTypes)
export const useContextPage = () => useContext(Context)

export const ContextPageProvider = ({ children, getSpes, getProjects, getPersons, createContract, getCompanies }: ContextPageProviderProps) => {
    const [state, setState] = useState<State>({} as State)

    const value = React.useMemo(() => ({ state, setState, getSpes, getProjects, createContract, getPersons, getCompanies }), [state])

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}