import * as S from './styles'

export interface IconButtonProps {
  icon: string
  disabled?: boolean
  color?: string
  onClick?: () => void
}

export const IconButton = ({
  icon,
  disabled = false,
  color = 'black',
  onClick
}: IconButtonProps) => {
  return (
    <S.Wrapper
      type="button"
      disabled={disabled}
      color={color}
      onClick={!disabled ? onClick : undefined}
    >
      <i className={`feather ${icon}`} />
    </S.Wrapper>
  )
}
