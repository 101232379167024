import { BillingModel, BillingStatus } from './BillingModel'
import { BuyerModel } from './BuyersModel'
import { CompanyType } from './CompanyModel'
import { ProjectType } from './ProjectModel'
import { SignatureModel } from './SignatureModel'
import { SpeType } from './SpeModel'
import { UnitType } from './UnitModel'
import { Blockchain_Transaction } from './Blockchain_Transaction'

export enum ContractTypes {
  SALE = 'sale',
  PRE_SALE = 'pre_sale',
  exchange = 'exchange'
}

export enum ContractStatuses {
  PASSWORD_PENDING = 'password_pending',
  PROCESSING = 'processing',
  REGISTERED = 'registered',
  ERROR = 'error',
  SIGNATURES_PENDING = 'signatures_pending'
}

export type Contract = {
  id: string
  company_id: string
  company: Partial<CompanyType>
  spe_id: string
  spe: Partial<SpeType>
  project_id: string
  unit_id: string
  value: string
  creator_id: string
  type: ContractTypes
  is_tokenized: false
  status: ContractStatuses
  created_at: Date
  unit: UnitType
  project: ProjectType
  blockchain_transaction?: Blockchain_Transaction
  url: string

  buyers: BuyerModel[]
}

export type ContractWithPaymentPart = {
  contract: Omit<Contract, 'buyers'> & {
    url: string
    signatures: SignatureModel[]
  }
  payments: {
    contract_id: string
    created_at: Date
    first_due_date: Date
    frequency: string
    id: string
    installment_value: string
    installments: string
    name: string
    total_value: number
    billings: {
      status: BillingStatus
      data: BillingModel[]
    }
  }[]
}

export type LastTenContracts = {
  id: string
  value: string
  type: ContractTypes
  status: ContractStatuses
  buyers_name: string
  created_at: string
}
