import styled from 'styled-components'

export const SelectSearch = styled.div`
  max-width: 100%;
  position: relative;
  z-index: 2;
`

export const OptionsList = styled.div`
  width: 100%;
  position: absolute;

  max-height: 400px;

  overflow-y: scroll;

  background: #fff;

  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`

export const Option = styled.button`
  text-align: left;

  background: transparent;
  border: 0;

  cursor: pointer;

  padding: 4px 8px;

  &:hover {
    background: #f5f5f5;
  }
`
