import * as React from 'react'

interface LinksSeparatorProps {
  left: React.ReactElement
  right: React.ReactElement
}

export const ContentSeparator = ({ left, right }: LinksSeparatorProps) => {
  return (
    <>
      {left}
      <span
        style={{
          marginLeft: '10px',
          marginRight: '10px'
        }}
      >
        |
      </span>
      {right}
    </>
  )
}
