import api from './api'
import { CreateSimplifiedSmartContract } from '../pages/SimplifiedSmartContracts/CreateSimplifiedSmartContract'
import { VerifySimplifiedSmartContract } from '../pages/SimplifiedSmartContracts/VerifySimplifiedSmartContract'

interface Response {
  id: string
  bank_billet_url?: string
}

export const sendSimplifiedContract = async (
  token: string,
  data: CreateSimplifiedSmartContract
) => {
  const formData = new FormData()

  formData.append('object', data.object)
  formData.append('value', data.value)
  formData.append('document_type', data.document_type)
  formData.append('document', data.document)
  formData.append('name', data.name)
  formData.append('payment_type', data.payment_type)
  formData.append('credit_card_info', JSON.stringify(data.credit_card_info))
  //eslint-disable-next-line
  formData.append('file', data.file!)

  return api.post<Response>('/simplified-smart-contracts', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${token}`
    }
  })
}

interface VerifyResponse {
  match: boolean
}

export const verifySimplifiedContract = async (
  token: string,
  data: VerifySimplifiedSmartContract
) => {
  const formData = new FormData()

  formData.append('id', data.id)
  //eslint-disable-next-line
  formData.append('file', data.file!)

  return api.post<VerifyResponse>(
    '/simplified-smart-contracts/verify',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${token}`
      }
    }
  )
}

export async function getMyContractHistory(token: string) {
  const response = await api.get('/simplified-smart-contracts', {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  return response.data
}

export async function getContractById(id: string, token: string) {
  const response = await api.get(`/simplified-smart-contracts/${id}`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  return response.data
}
