import { Col, Row, Button } from 'react-bootstrap'
import { TextInput } from '../../atoms/TextInput'
import * as React from 'react'

import * as S from './styles'
import { SelectField } from '../../atoms/SelectField'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppContext, User } from 'app'

import schema from './user-inviter.schema'
import { deleteCompanyUser } from 'domain/usecases/CompanyUser'
import { useEffect, useState, useContext } from 'react'

interface InviteUserForm {
  invite_name: string
  invite_email: string
}

export interface InvitedUser {
  id?: string
  invite_name: string
  invite_email: string
  role?: string
}

interface UserInviterProps {
  onChange: (users: InvitedUser[]) => void
  defaultValue?: InvitedUser[]
  deleteUserCompany?: deleteCompanyUser
}

export default function UserInviter({
  onChange,
  defaultValue,
  deleteUserCompany
}: UserInviterProps) {
  const { user: userLogged } = useContext(AppContext)
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = useForm<InviteUserForm>({
    resolver: yupResolver(schema)
  })

  const [users, setUsers] = useState<InvitedUser[]>(defaultValue || [])

  const isEmailAdded = (email: string) => {
    return users.find((user) => user.invite_email === email)
  }

  const onSend = (data: InviteUserForm) => {
    if (isEmailAdded(data.invite_email)) {
      return setError('invite_email', {
        type: 'manual',
        message: 'Este E-mail já foi adicionado'
      })
    }

    reset(
      { invite_name: '', invite_email: '' },
      {
        keepValues: false
      }
    )
    setUsers([
      ...users,
      {
        invite_name: data.invite_name,
        invite_email: data.invite_email,
        role: 'manager'
      }
    ])
  }

  useEffect(() => {
    onChange(users)
  }, [users, onChange])

  const setProfileForUser = (email: string, role: string) => {
    const newUsers = users.map((user) =>
      user.invite_email === email ? { ...user, role } : user
    )
    setUsers(newUsers)
  }

  const removeUser = async (user: InvitedUser) => {
    if (user.id) await deleteUserCompany?.deleteById({ id: user.id })
    setUsers(users.filter((u) => u.invite_email !== user.invite_email))
  }

  return (
    <>
      <Row>
        <Col>
          <p>
            Adicione Usuários que terão acesso aos negócios desta Incorporadora.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            control={control}
            name="invite_name"
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="text"
                label="Nome"
                placeholder="Nome do Usuário"
                value={value}
                onChange={onChange}
                errorMessage={errors.invite_name?.message}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            control={control}
            name="invite_email"
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="text"
                label="E-mail"
                value={value}
                onChange={onChange}
                placeholder="E-mail do Usuário"
                errorMessage={errors.invite_email?.message}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <div style={{ marginTop: '30px' }}>
            <Button variant="primary" onClick={handleSubmit(onSend)}>
              Adicionar
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <S.TableWrapper>
            {users.map((user) => {
              const currentUser = userLogged?.fullName === user.invite_name
              return (
                <Row key={user.invite_email}>
                  <Col md={3}>
                    <TextInput
                      type="text"
                      label="Nome"
                      placeholder="Nome do Usuário"
                      value={user.invite_name}
                      disabled
                    />
                  </Col>
                  <Col md={3}>
                    <TextInput
                      type="text"
                      label="E-mail"
                      placeholder="E-mail do Usuário"
                      value={user.invite_email}
                      disabled
                    />
                  </Col>
                  <Col md={3}>
                    <SelectField
                      disabled={currentUser}
                      value={user.role}
                      onChange={(ev) =>
                        setProfileForUser(user.invite_email, ev.target.value)
                      }
                      label="Perfil"
                      options={[
                        {
                          label: 'Administrador',
                          value: 'admin'
                        },
                        { label: 'Gerente', value: 'manager' },
                        { label: 'Vendedor', value: 'seller' }
                      ]}
                    />
                  </Col>

                  <Col md={3}>
                    {!currentUser && (
                      <Button
                        variant="outline-danger"
                        onClick={async () => await removeUser(user)}
                      >
                        <i className="feather icon-x" />
                      </Button>
                    )}
                  </Col>
                </Row>
              )
            })}
          </S.TableWrapper>
        </Col>
      </Row>
    </>
  )
}
