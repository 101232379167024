import { URL_API } from '../../../../services/api_url'
import { AppContext } from '../../../../app'
import DetailsSpe from '../../../../pages/Spes/DetailSpe'
import { useParams } from 'react-router'

import { RemoteGetSPE } from '../../../../data/usecases/SPE/RemoteGetSPE'
import { RemoteDeleteSpe } from '../../../../data/usecases/SPE/RemoteDeleteSPE'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { useContext } from 'react'

type ParamsState = {
  id: string
}

export const DetailsSpeFactory = () => {
  const { user } = useContext(AppContext)
  const URL = URL_API || 'without-url'
  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetSpe = new RemoteGetSPE(URL, axiosHttpClient)
  const remoteDeleteSpe = new RemoteDeleteSpe(URL, axiosHttpClient)

  const { id } = useParams<ParamsState>()

  return (
    <DetailsSpe getSpes={remoteGetSpe} id={id} handleDelete={remoteDeleteSpe} />
  )
}
