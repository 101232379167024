import React from 'react'
import { createProject } from '../../../domain/usecases/Project'
import { ProjectType } from '../../../domain/models/ProjectModel'
import { IGetSpes } from '../../../domain/usecases/SPE'

export const DispatchContext = React.createContext<{
  state?: any
  dispatch?: any
  remoteProject?: createProject
  remoteGetSpes?: IGetSpes
}>({})

type CreateProjectProps = {
  remoteProject: createProject
  remoteGetSpes: IGetSpes
  children: React.ReactNode
}

type Action = {
  type: string
  payload?: any
}

type State = {
  loading: boolean
  project: ProjectType
  errorMessage: string
}

const Actions = function ({
  remoteProject,
  remoteGetSpes,
  children
}: CreateProjectProps) {
  const [state, dispatch] = React.useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'CREATE_PROJECT':
          remoteProject
            .createProject(action.payload)
            .then((res) => {
              return { ...state, loading: false, payload: res }
            })
            .catch((error) =>
              dispatch({
                type: 'ERROR',
                payload: 'Não foi possível buscar a lista de Empreendimentos.'
              })
            )
          return { ...state, loading: true, project: action.payload }

        case 'GET_SPES':
          remoteGetSpes
            .getAll()
            .then((res) => {
              return { ...state, loading: false, payload: res }
            })
            .catch((error) =>
              dispatch({
                type: 'ERROR',
                payload: 'Não foi possível buscar a lista de Empreendimentos.'
              })
            )
          return { ...state, loading: true, spes: action.payload }
        case 'ERROR':
          return { ...state, loading: false, errorMessage: action.payload }

        default:
          return state
      }
    },
    {
      loading: false,
      project: {},
      errorMessage: ''
    }
  )

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      remoteProject,
      remoteGetSpes
    }),
    [state]
  )
  return (
    <DispatchContext.Provider value={value}>
      {children}
    </DispatchContext.Provider>
  )
}

Actions.displayName = 'Actions'

export default Actions
