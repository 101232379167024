import { CustomModal } from '../CustomModal'

export interface ConfirmCreationModalProps {
  show: boolean
  handleClose?: () => void
  handleOk?: () => void
  entityName: string
}

export const ConfirmCreationModal = ({
  show,
  handleClose,
  handleOk,
  entityName
}: ConfirmCreationModalProps) => {
  return (
    <>
      <CustomModal
        show={show}
        title="Confirmar cadastro?"
        body={
          <span>
            Você está prestes a concluir o cadastro {entityName}.<br />
            Podemos prosseguir?
          </span>
        }
        closeButtonText="Cancelar"
        handleClose={handleClose}
        okButtonText="Sim, cadastrar"
        handleOk={handleOk}
      />
    </>
  )
}
