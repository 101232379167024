import React, { useContext } from 'react'
import { Modal, Row } from 'react-bootstrap'
import FormWizard, { Step } from '../../../../components/organisms/FormWizard'
import { SetableContext } from '../../../../interfaces/setable-context'
import Step0, { ICreatePromissoryBuyerFormStep0 } from './StepModal0/step0'
import Step1, { ICreatePromissoryBuyerFormStep1 } from './StepModal1/step1'
import Step2, { ICreatePromissoryBuyerFormStep2 } from './StepModal2/step2'
import Step3, { ICreatePromissoryBuyerFormStep3 } from './StepModal3/step3'
import Step4 from './StepModal4'
import * as S from './styles'
import PersonService from '../../../../domain/usecases/PersonService'
import { AppContext } from '../../../../app'
import { useContextPage } from '../contextPage'
import {
  AddressPerson,
  ContactPerson
} from '../../../../domain/models/PersonsModel'
import { formatCPFWithDots } from 'utils/formatCPFWithDots'
import formatStringToCNPJ from 'utils/formatStringToCNPJ'

export type ICreatePromissoryBuyerForm = Partial<
  ICreatePromissoryBuyerFormStep0 &
    ICreatePromissoryBuyerFormStep1 &
    ICreatePromissoryBuyerFormStep2 &
    ICreatePromissoryBuyerFormStep3
>

export const CreatePromissoryBuyerContext = React.createContext<
  SetableContext<ICreatePromissoryBuyerForm>
>({})

export default function ModalFormToCreatePromissoryUser({
  showModal,
  closeModal
}: {
  showModal: boolean
  closeModal: (e: boolean) => void
}) {
  const [data, setData] = React.useState<ICreatePromissoryBuyerForm>({
    proxies: []
  })
  const { user } = useContext(AppContext)
  const { state, getPersons, setState } = useContextPage()
  const proxiesStep = {
    id: 2,
    label: 'Procuradores',
    component: Step0,
    disableContinueLater: true
  }

  const personIsPj = !!state?.selectedPerson?.info?.cnpj
  const steps: Step[] = [
    {
      id: 1,
      label: 'Dados Pessoais',
      component: Step1,
      disableContinueLater: true
    },
    ...(personIsPj ? [proxiesStep] : []),
    {
      id: 3,
      label: 'Endereço',
      component: Step2,
      disableContinueLater: true
    },
    {
      id: 4,
      label: 'Dados de Contato',
      component: Step3,
      disableContinueLater: true
    },
    {
      id: 5,
      label: 'Final',
      component: () => Step4({ closeModal }),
      disableContinueLater: true
    }
  ]

  const onFinish = async () => {
    const dto = createPromissoryDto()
    if (dto.person.id) {
      await PersonService.update(dto, user?.token)
    } else {
      await PersonService.create(dto, user?.token)
    }
    const persons = await getPersons.getAll({ cpfOrCnpj: state.cpfOrCnpjList })
    setState({ ...state, persons })
  }

  const createPromissoryDto = () => {
    return {
      ...data,
      person: {
        ...data.person,
        cnpj: data.person?.cnpj && formatStringToCNPJ(data.person.cnpj),
        cpf: data.person?.cpf && formatCPFWithDots(data.person.cpf!),
        birth_date: convertDateStringToYYYYMMDD(data.person!.birth_date!),
        identity_issuer_date: convertDateStringToYYYYMMDD(
          data.person!.identity_issuer_date!
        )
      },
      secondary_address: convertSecondAddressPromissory(data.secondary_address),
      secondary_contact_info: convertSecondContactInfoPromissory(
        data.secondary_contact_info
      ),
      company_id: state.selectedCompanyId
    }
  }

  const convertDateStringToYYYYMMDD = (date: string) => {
    if (!date) return ''
    const [day, month, year] = date.split('/')
    return `${year}-${month}-${day}`
  }

  const convertSecondAddressPromissory = (dto?: Partial<AddressPerson>) => {
    return {
      zip_code: dto?.zip_code_business,
      complement: dto?.complement_business,
      address: dto?.address_business,
      number: dto?.number_business,
      district: dto?.district_business,
      city: dto?.city_business,
      state: dto?.state_business,
      country: dto?.country_business
    }
  }

  const convertSecondContactInfoPromissory = (dto?: Partial<ContactPerson>) => {
    return {
      email: dto?.email_second,
      home_phone: dto?.homePhone_second,
      mobile_phone: dto?.fone_second
    }
  }

  const handleSave = () => {
    sessionStorage.setItem(
      '@proptoken:create-promissory-buyer',
      JSON.stringify(data)
    )
  }

  const isEditMode = () => {
    return !!state.selectedPerson?.id
  }
  return (
    <S.Container>
      <Modal size="xl" show={showModal} onHide={() => closeModal(!showModal)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            {isEditMode() ? 'Editar' : 'Cadastrar'} Promissário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatePromissoryBuyerContext.Provider value={{ data, setData }}>
            <FormWizard
              steps={steps}
              finishOnStep={steps.length}
              onFinish={onFinish}
              onSaveDraft={handleSave}
            />
          </CreatePromissoryBuyerContext.Provider>
        </Modal.Body>
      </Modal>
    </S.Container>
  )
}
