import * as S from '../styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const SignatureError = () => {
  return (
    <S.IconWrapper>
      <ErrorOutlineIcon
        style={{ color: '#CC0000', fontSize: 96, marginBottom: 10 }}
      />
      <S.IconText>
        Não foi possível realizar a assinatura.
        <br />
        Por favor, tente novamente mais tarde.
      </S.IconText>
    </S.IconWrapper>
  )
}
export default SignatureError
