import styled, { css } from 'styled-components'
import { RoundImageProps } from './index'

export const Wrapper = styled.img`
  ${({ image }: RoundImageProps) => css`
    background: ${image ? `url(${image})` : '#ecf0f5'};
    height: 115px;
    width: 115px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
  `}
`
