import { Col, Row } from 'react-bootstrap'
import * as React from 'react'

import * as S from './styles'

interface Info {
  name: string
  value: string
}

interface ChartAdditionalInfoProps {
  info: Info
  info2?: Info
  info3?: Info
}

export const ChartAdditionalInfo = ({
  info,
  info2,
  info3
}: ChartAdditionalInfoProps) => {
  return (
    <>
      <S.Wrapper>
        <Row>
          {info && (
            <Col>
              <p>{info.name}</p>
              <span>{info.value}</span>
            </Col>
          )}
          {info2 && (
            <Col>
              <p>{info2.name}</p>
              <span>{info2.value}</span>
            </Col>
          )}
          {info3 && (
            <Col>
              <p>{info3.name}</p>
              <span>{info3.value}</span>
            </Col>
          )}
        </Row>
      </S.Wrapper>
    </>
  )
}
