import * as React from 'react'
import { useContext } from 'react'
import { Col, Form } from 'react-bootstrap'
import { CreateProjectContext } from '../index'
import { BuildingModel } from '../../../../domain/models/BuildingModel'

interface UpdateProjectStep4Prop {
  building: BuildingModel
}

const UpdateProjectStep4 = ({ building }: UpdateProjectStep4Prop) => {
  function getUnitsNamesBuilding(building: BuildingModel) {
    const getUnitsBuilding = (building: BuildingModel) => {
      if (building.units)
        return building.units.filter((unit) => unit.building_id === building.id)
      return []
    }

    return getUnitsBuilding(building).map((unit) => ` ${unit.name}`)
  }

  const units = getUnitsNamesBuilding(building)
  return (
    <>
      <Form.Group>
        <Col md={4} key={building.id}>
          <strong>{building.name}</strong>

          {units.length ? (
            <p>{units}</p>
          ) : (
            <p>Este bloco não possui unidades.</p>
          )}
        </Col>
      </Form.Group>
    </>
  )
}

export default UpdateProjectStep4
