import * as S from './styles'
import { Col, Row } from 'react-bootstrap'
import { RoundImage } from '../../molecules/RoundImage'
import React from 'react'

interface DetailsHeaderProps {
  image?: string
  fields: React.ReactNode
  rightButtons?: React.ReactNode
  buttonsRow?: React.ReactNode
}

export const DetailsHeader = ({
  image = 'https://service.proptoken.com.br/image-thumb.png',
  fields,
  rightButtons,
  buttonsRow
}: DetailsHeaderProps) => {
  return (
    <S.Wrapper>
      <S.FirstRow>
        <Row>
          {image && (
            <Col md={2}>
              <S.ImageWrapper>
                <RoundImage image={image} />
              </S.ImageWrapper>
            </Col>
          )}
          <Col md={5}>
            <Row>{fields}</Row>
          </Col>
          <Col md={5}>{rightButtons}</Col>
        </Row>
      </S.FirstRow>

      <Row>
        <Col md={12}>{buttonsRow}</Col>
      </Row>
    </S.Wrapper>
  )
}
