import styled, { css } from 'styled-components'

export const Title = styled.h4`
  ${({ color }) => css`
    color: ${color};
  `}
`

export const Subtitle = styled.h6`
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`

export const Icon = styled.i`
  ${({ color }) => css`
    color: ${color};
  `}
`
