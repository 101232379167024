import { Centered } from '../../../../components/templates/Centered'
import loadingBlockchainImg from '../../../../assets/loading-blockchain.gif'
import { Card } from 'react-bootstrap'
import * as React from 'react'

export const LoadingCard = () => {
  return (
    <Card.Body>
      <Centered>
        <h5>Por favor, aguarde enquanto registramos as informações.</h5>
        <img
          style={{ width: '400px', maxWidth: '90%' }}
          src={loadingBlockchainImg}
        />
      </Centered>
    </Card.Body>
  )
}
