import * as yup from 'yup'

export default yup.object().shape({
  zipcode: yup.string().required('O campo CEP é requerido.'),
  street: yup.string().required('O campo Endereço é requerido.'),
  number: yup.string().required('O campo Número é requerido.'),
  neighborhood: yup.string().required('O campo Bairro é requerido.'),
  city: yup.string().required('O campo Cidade é requerido.'),
  state: yup.string().required('O campo Estado é requerido.')
})
