import styled from 'styled-components'
import { Alert } from 'react-bootstrap'

export const Span = styled.span`
  display: block;
  font-size: 14px;

  strong {
    font-size: 15px;
  }
`

export const WarningInformation = styled(Alert).attrs({
  variant: 'warning'
})`
  margin-top: 10px;
`
