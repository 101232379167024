import * as yup from 'yup'
import { isValid } from 'cpf'

export default yup.object().shape({
  name: yup.string().required('O campo Nome é requerido.'),
  phone: yup.string().required('O campo Telefone é requerido.'),
  email: yup.string().required('O campo E-mail é requerido.'),
  cpf: yup
    .string()
    .test('is-cpf', 'Digite um CPF válido', (value) => isValid(value as string))
    .required('O campo CPF é requerido.'),
  password: yup
    .string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'A Senha deve conter pelo menos 8 caracteres, uma letra maiúscula, um número e um caractere especial.'
    )
    .required('O campo Senha é requerido.'),
  password_confirmation: yup
    .string()
    .test(
      'is-equal-password',
      'A Confirmação de Senha deve ser igual a Senha.',
      (value, context) => context.parent.password === value
    )
    .required('O campo Confirmação de Senha é requerido.')
})
