import * as React from 'react'
import { Link } from 'react-router-dom'
import TableCardTemplate from '../../templates/TableCardTemplate'

export type Values = {
  id: string
  created_at: string
  transaction_hash: string
  contract_id: string
  etherscan: string
}

export type BlockchainTableCardProps = {
  values: Values[]
}

export const BlockchainTableCard = ({ values }: BlockchainTableCardProps) => {
  return (
    <TableCardTemplate
      withPagination={false}
      columns={['DATA', 'TIPO', 'HASH', 'CONTRATO']}
      rows={values.map((item) => (
        <tr key={item.id}>
          <td>{item.created_at}</td>
          <td>Registro de Contrato</td>
          <td>{item.transaction_hash}</td>
          <td>
            <a
              className="btn btn-outline-primary btn-sm"
              target="_blank"
              rel="noreferrer"
              href={`/contracts/${item.contract_id}`}
            >
              Abrir
            </a>
          </td>
          {/*<td>*/}
          {/*  <a*/}
          {/*    className="btn btn-outline-primary btn-sm"*/}
          {/*    href={`${process.env.REACT_APP_BLOCKCHAIN_EXPLORER_URL}/tx/${item.transaction_hash}`}*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*  >*/}
          {/*    Abrir*/}
          {/*  </a>*/}
          {/*</td>*/}
        </tr>
      ))}
    />
  )
}
