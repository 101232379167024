import { Button, Card, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import FormWizard, { Step } from '../../../components/organisms/FormWizard'
import Step1, { ICreateProjectFormStep1 } from './Step1/step1'
import Step2, { ICreateProjectFormStep2 } from './Step2/step2'
import Step3, { ICreateProjectFormStep3 } from './Step3/step3'
import Step4, { ICreateProjectFormStep4 } from './Step4'
import Step5, { ICreateProjectFormStep5 } from './Step5'
import Step6 from './Step6'
import { Title } from '../../../components/atoms/Title'
import React, { useEffect, useState } from 'react'
import { SetableContext } from '../../../interfaces/setable-context'
import { ProjectType } from '../../../domain/models/ProjectModel'
import Utils from './utils'
import { DispatchContext } from './actions'

export type ICreateProjectForm = Partial<
  ICreateProjectFormStep1 &
    ICreateProjectFormStep2 &
    ICreateProjectFormStep3 &
    ICreateProjectFormStep4 &
    ICreateProjectFormStep5
>
export const CreateProjectContext = React.createContext<
  SetableContext<ICreateProjectForm>
>({})

interface Props {
  hasProject: ProjectType
  hasSpeId: string
}

export default function CreateProject({ hasProject, hasSpeId }: Props) {
  const { convertProjectObj } = Utils()
  const { remoteProject } = React.useContext(DispatchContext)
  const [data, setData] = useState<ICreateProjectForm>(
    hasProject ? convertProjectObj(hasProject) : { spe_id: hasSpeId }
  )
  const history = useHistory()
  const { addToast } = useToasts()

  const steps: Step[] = [
    {
      id: 1,
      label: 'Dados do Empreendimento',
      component: Step1
    },
    {
      id: 2,
      label: 'Endereço',
      component: Step2
    },
    {
      id: 3,
      label: 'Blocos',
      component: Step3
    },
    {
      id: 4,
      label: 'Unidades',
      component: Step4
    },
    {
      id: 5,
      label: 'Vagas',
      component: Step5
    },
    { id: 6, label: 'Final', component: Step6, disableContinueLater: true }
  ]

  const onFinish = async () => {
    try {
      await remoteProject!.createProject(data as Required<ICreateProjectForm>)
    } catch (error) {
      history.push('/projects')
      addToast('Não foi possivel salvar o Empreendimento.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const handleSave = () => {
    sessionStorage.setItem('CreateProjectForm', JSON.stringify(data))
  }
  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Title>{hasProject ? 'Editar' : 'Cadastrar'} Empreendimento</Title>
          </Card.Header>
          <Card.Body>
            <CreateProjectContext.Provider value={{ data, setData }}>
              <FormWizard
                steps={steps}
                finishOnStep={6}
                onFinish={onFinish}
                onSaveDraft={handleSave}
                canClickOnSteps={!!hasProject}
              />
            </CreateProjectContext.Provider>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
