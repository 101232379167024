import { HttpClient } from '../../protocols/http/httpClient'
import { IGetSpes, SPE } from '../../../domain/usecases/SPE'
import { SpeType } from '../../../domain/models/SpeModel'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export class RemoteGetSPE implements IGetSpes {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async getOne(params: SPE.GetOneParams): Promise<SpeType> {
    return await this.httpClient.get?.({
      url: `${this.url}/spes/${params.id}`
    })
  }

  @validateHttpStatus
  async getAll(): Promise<SpeType[]> {
    return await this.httpClient.get?.({
      url: `${this.url}/spes/`
    })
  }
}
