import * as yup from 'yup'

export default yup.object().shape({
  ri: yup.string().required('O campo Registro da Incorporação é requerido.'),
  name: yup.string().required('O campo Nome do Empreendimento é requerido.'),
  notary: yup
    .string()
    .required('O campo Cartório de Registro de Imóveis é requerido.'),
  spe_id: yup.string().required('O campo SPE é requerido.'),
  file_size: yup
    .number()
    .max(
      10000000,
      'O arquivo é muito grande. Por favor, selecione um arquivo de até 10 MB.'
    ),
  file_type_building_permit: yup.string(),
  file_type_ri_document: yup.string(),
  conclusion_date: yup.date().required('O campo Data Prevista é requerido.'),
  matriculation_number: yup
    .string()
    .required('O campo Número de Matrícula é requerido.')
})
