import React, { useContext, useState } from 'react'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './payment-step1.schema'
import { IPaymentStep1Form } from './payment-step1-form.interface'
import * as S from '../styles'
import { LoadingIndicator } from '../../../components/atoms/LoadingIndicator'
import { TextInputWithMask } from '../../../components/atoms/TextInputWithMask'
import { TextInput } from '../../../components/atoms/TextInput'
import CreditCard from '../../../components/molecules/CreditCard'
import { PaymentContext, paymentSteps } from '../index'
import { Col, Row } from 'react-bootstrap'
import getCreditCardBrandFromNumber from '../../../utils/getCreditCardBrandFromNumber'

export type SignatureStep1Props = {
  paymentData?: any
  handleStepChange: (step: paymentSteps) => void
}

const PaymentStepCreditCardForm = ({
  handleStepChange
}: SignatureStep1Props) => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState<boolean>(false)
  const [focused, setFocused] = useState<
    'number' | 'name' | 'expiry' | 'cvc' | undefined
  >('number')

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<IPaymentStep1Form>({
    resolver: yupResolver(schema)
  })

  const cardNumber = watch('number') || ''
  const cardName = watch('name')
  const cardExpiry = watch('expiry')
  const cardCvc = watch('cvc')

  const { setPaymentData, paymentData } = useContext(PaymentContext)

  const getPaymentTokenCallback = async (
    error: any,
    response: any,
    data: IPaymentStep1Form
  ) => {
    if (error) {
      setLoading(false)
      // Trata o erro ocorrido
      console.error(error)

      const { error: errorMessage } = error

      if (errorMessage == 'invalid_card_number') {
        addToast('Dados incorretos, por favor, verifique e tente novamente.', {
          appearance: 'error'
        })
      }
    } else {
      const {
        data: { payment_token }
      } = response

      setPaymentData!({
        credit_card: {
          number: data.number,
          name: data.name,
          expiry: data.expiry,
          cvc: data.cvc,
          birth: data.birth,
          payment_token
        }
      })

      setLoading(false)
      handleStepChange('billing_form')
    }
  }

  const onSubmit: SubmitHandler<IPaymentStep1Form> = async (data) => {
    try {
      try {
        setLoading(true)

        const brand = getCreditCardBrandFromNumber(
          data.number.replace(/ /g, '')
        )

        // @ts-ignore
        $gn.checkout.getPaymentToken(
          {
            brand, // bandeira do cartão
            number: data.number, // número do cartão
            cvv: data.cvc, // código de segurança
            expiration_month: data.expiry.split('/')[0], // mês de vencimento
            expiration_year: data.expiry.split('/')[1] // ano de vencimento
          },
          (error: any, response: any) =>
            getPaymentTokenCallback(error, response, data)
        )
      } catch (e) {
        console.log('e', e)
        setLoading(false)
        addToast('Dados incorretos, verifique e tente novamente.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    } catch (e) {
      setLoading(false)
      addToast('Algo deu errado, tente novamente.', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <S.ContentWrapper>
      <CreditCard
        number={cardNumber}
        name={cardName}
        expiry={cardExpiry}
        cvc={cardCvc}
        focus={focused}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="number"
          render={({ field: { onChange, value } }) => (
            <TextInput
              value={value}
              disabled={loading}
              label="Número do Cartão"
              placeholder="Número do Cartão"
              onChange={({ target: { value } }) => {
                onChange(value)
              }}
              onFocus={() => {
                setFocused('number')
              }}
              errorMessage={errors.number?.message}
            />
          )}
        />
        <Row>
          <Col md={8}>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={value}
                  disabled={loading}
                  label="Nome no Cartão"
                  placeholder="Nome no Cartão"
                  onChange={({ target: { value } }) => {
                    onChange(value)
                  }}
                  onFocus={() => {
                    setFocused('name')
                  }}
                  errorMessage={errors.name?.message}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <Controller
              control={control}
              name="birth"
              render={({ field: { onChange, value } }) => (
                <TextInputWithMask
                  mask="99/99/9999"
                  value={value}
                  disabled={loading}
                  label="Data de Nascimento"
                  placeholder="Data de Nascimento"
                  onChange={({ target: { value } }) => {
                    onChange(value)
                  }}
                  onFocus={() => {
                    setFocused('name')
                  }}
                  errorMessage={errors.birth?.message}
                />
              )}
            />
          </Col>
        </Row>

        <S.CardInputsWrapper>
          <S.CardItem>
            <Controller
              control={control}
              name="expiry"
              render={({ field: { onChange, value } }) => (
                <TextInputWithMask
                  value={value}
                  disabled={loading}
                  label="Data de Expiração (MM/AA)"
                  placeholder="MM/AA"
                  mask="99/99"
                  onChange={({ target: { value } }) => {
                    onChange(value)
                  }}
                  onFocus={() => {
                    setFocused('expiry')
                  }}
                  errorMessage={errors.expiry?.message}
                />
              )}
            />
          </S.CardItem>

          <Controller
            control={control}
            name="cvc"
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                disabled={loading}
                label="Código de Segurança"
                placeholder="Código de Segurança"
                onChange={({ target: { value } }) => {
                  onChange(value)
                }}
                onFocus={() => {
                  setFocused('cvc')
                }}
                errorMessage={errors.cvc?.message}
              />
            )}
          />
        </S.CardInputsWrapper>
        <button
          className="btn btn-primary pull-left mt-4 mb-4"
          style={{ width: '100%' }}
          type="submit"
          disabled={loading}
        >
          {!loading ? 'Continuar' : <LoadingIndicator />}
        </button>
      </form>
      <S.Tip>
        Você poderá revisar as informações antes de finalizar o pagamento.
      </S.Tip>
    </S.ContentWrapper>
  )
}
export default PaymentStepCreditCardForm
