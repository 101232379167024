import { HttpClient } from '../../protocols/http/httpClient'
import { deleteUnit, Unit } from '../../../domain/usecases/Unit'

export class RemoteDeleteUnit implements deleteUnit {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async deleteById(params: Unit.DeleteParams): Promise<void> {
    const httpResponse = await this.httpClient.delete?.({
      url: `${this.url}/units/${params.id}`
    })

    if (httpResponse?.statusCode === 400) {
      throw new Error(httpResponse.message)
    } else if (httpResponse?.statusCode && httpResponse?.statusCode != 200) {
      throw new Error('Não foi possível excluir a Unidade.')
    }
  }
}
