import { useEffect, useCallback, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { TextWithHint } from '../../../components/atoms/TextWithHint'
import { EntityDetails } from '../../../components/organisms/EntityDetails'
import { DetailsHeader } from '../../../components/templates/DetailsHeader'
import { DetailButtonsRow } from '../../../components/molecules/DetailButtonsRow'
import { IconButton } from '../../../components/atoms/IconButton'
import { DetailsSection } from '../../../components/templates/DetailsSection/index'
import { IconWithText } from '../../../components/molecules/IconWithText'
import { TableCard } from 'components/organisms/TableCard'
import { DeleteModal } from 'components/molecules/DeleteModal'
import { useToast } from 'hooks/useToast'
import {
  deleteProject as IDeleteProject,
  getProject as IGetProject
} from '../../../domain/usecases/Project'
import { ProjectType } from '../../../domain/models/ProjectModel'
import { UnitType } from '../../../domain/models/UnitModel'
import { convertNumberToCurrencyFormat } from 'utils/convertNumberToCurrencyFormat'
type Props = {
  getProject: IGetProject
  id: string
  handleDelete: IDeleteProject
}
const ProjectDetails = ({ getProject, id, handleDelete }: Props) => {
  const history = useHistory()
  const { showToast } = useToast()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [selectedId, setSelectedId] = useState('')
  const [units, setUnits] = useState<UnitType[]>([])

  const [loading, setLoading] = useState<boolean>(false)
  const [project, setProject] = useState<ProjectType>()

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const projectResponse = await getProject.getProjectById({ id })

      setProject({
        ...projectResponse,
        address: {
          ...projectResponse?.address,
          complement:
            projectResponse?.address?.complement === 'undefined'
              ? ''
              : projectResponse?.address?.complement
        }
      })
    } catch (error) {
      history.push('/spes')
      showToast('Não foi possível carregar o Empreendimento.', 'error')
    } finally {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    loadData()
  }, [])

  const handleDeleteProject = () => {
    handleDelete
      .deleteById({ id: selectedId })
      .then((res) => {
        loadData()
        handleClose()
      })
      .catch((error) => {
        handleClose()
        showToast(error.message, 'error')
      })
  }

  const openModalToConfirmDeleteProject = (idProject: string) => {
    handleShow()
    setSelectedId(idProject)
  }
  return (
    <>
      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleOk={handleDeleteProject}
      />
      <EntityDetails
        title="Empreendimento"
        sections={
          <>
            <DetailsHeader
              image={project?.photo || ''}
              fields={
                <>
                  <Col md={12}>
                    <Row>
                      <Col md={4}>
                        <TextWithHint
                          text={project?.name}
                          hint="Razão Social"
                          isTitle={true}
                        />
                      </Col>
                      <Col md={4}>
                        <TextWithHint
                          text={project?.notary}
                          hint="Cartório do Registro"
                        />
                      </Col>
                      <Col md={4}>
                        <TextWithHint
                          text={project?.ri}
                          hint="Registro da Incorporação"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <IconWithText
                          icon="icon-file"
                          text={
                            <TextWithHint
                              text={
                                <a
                                  href={project?.build_permit_document_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Abrir
                                </a>
                              }
                              hint="Alvará da Construção"
                            />
                          }
                        />
                      </Col>
                      <Col md={6}>
                        <IconWithText
                          icon="icon-file"
                          text={
                            <TextWithHint
                              text={
                                <a
                                  href={project?.ri_document_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Abrir
                                </a>
                              }
                              hint="Documento de Registro da Incorporação"
                            />
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              }
              rightButtons={
                <Row>
                  <Col md={2}></Col>
                  <Col>
                    <Button
                      onClick={() =>
                        history.push('/units/create', {
                          projectParams: project
                        })
                      }
                      className="py-3 mb-2"
                      variant="primary"
                    >
                      Adicionar Unidade
                    </Button>
                  </Col>
                </Row>
              }
            />

            <DetailsSection
              title="Endereço"
              content={
                <Row>
                  <Col md={3}>
                    <TextWithHint
                      text={project?.address?.zip_code}
                      hint="CEP"
                    />
                    <TextWithHint
                      text={project?.address?.state}
                      hint="Estado"
                    />
                  </Col>
                  <Col md={4}>
                    <TextWithHint
                      text={project?.address?.address}
                      hint="Logradouro"
                    />
                    <TextWithHint
                      text={project?.address?.district}
                      hint="Bairro"
                    />
                  </Col>
                  <Col md={5}>
                    <TextWithHint text={project?.address?.number} hint="N.º" />

                    <TextWithHint text={project?.address?.city} hint="Cidade" />
                  </Col>
                </Row>
              }
            />
            <DetailsSection
              title="Características do Empreendimento"
              content={
                <Row className="d-flex align-items-center">
                  <Col md={3}>
                    <TextWithHint
                      text={project?.buildings?.length}
                      hint="Blocos"
                    />
                  </Col>
                  <Col md={4}>
                    <TextWithHint
                      text={project?.buildings
                        .map((building) => building.units.length)
                        .reduce((amount1, amount2) => amount1 + amount2, 0)}
                      hint="Unidades"
                    />
                  </Col>
                  <Col md={5}>
                    <TextWithHint
                      text={project?.parking_slots?.length}
                      hint="Vagas"
                    />
                  </Col>
                </Row>
              }
            />

            <DetailButtonsRow
              buttons={
                <>
                  <IconButton
                    onClick={() => openModalToConfirmDeleteProject(id)}
                    icon="icon-trash"
                    color="#E62355"
                  />
                  <IconButton
                    icon="icon-edit"
                    onClick={() =>
                      history.push('/projects/create', {
                        projectParams: project
                      })
                    }
                    color="#5E6EA7"
                  />
                </>
              }
            />
          </>
        }
        content={
          <TableCard
            data={{
              titleHeader: 'Unidade(s)',
              thead: (
                <thead>
                  <tr>
                    <th>UNIDADE</th>
                    <th>ÁREA REAL TOTAL</th>
                    <th>VAGAS</th>
                    <th>VALOR (R$)</th>
                    <th></th>
                  </tr>
                </thead>
              ),
              tbody: (
                <tbody>
                  {project?.buildings.map((building) =>
                    building.units.map((unit) => {
                      const parkingSlotAmount = project?.parking_slots.filter(
                        (parking) => parking.unit_id === unit.id
                      ).length
                      const value = convertNumberToCurrencyFormat(
                        Number(unit.value)
                      )
                      return (
                        <>
                          <tr
                            style={{ cursor: 'pointer' }}
                            onClick={() => history.push(`/units/${unit.id}`)}
                          >
                            <td>{`${building.name} - ${unit.name}` || '--'}</td>
                            <td>{unit.real_private_area || '--'}</td>
                            <td>{parkingSlotAmount}</td>
                            <td>{value}</td>
                            <td>
                              {unit.is_complete ? (
                                <label className="badge badge-light-primary">
                                  Cadastrado
                                </label>
                              ) : (
                                <label className="badge badge-light-danger">
                                  Não Cadastrado
                                </label>
                              )}
                            </td>
                          </tr>
                        </>
                      )
                    })
                  )}
                </tbody>
              )
            }}
          />
        }
      />
    </>
  )
}

export default ProjectDetails
