import { Form, FormGroup, InputGroup } from 'react-bootstrap'
import * as React from 'react'
import ErrorText from '../../atoms/ErrorText'

interface InputFileProps {
  label: string
  btnText?: string
  onChange: (files: File) => void
  accept?: string
  errorMessage?: string
  originalFile?: string
}

export const InputFile = ({
  label,
  btnText = 'Buscar',
  accept = '',
  errorMessage,
  originalFile = '',
  onChange
}: InputFileProps) => {
  return (
    <FormGroup>
      <Form.Label>
        <strong>{label}</strong>
        {originalFile && (
          <>
            {' - '}
            <a href={originalFile} target="_blank" rel="noreferrer">
              Ver documento salvo
            </a>
          </>
        )}
      </Form.Label>
      <InputGroup>
        <div className="custom-file">
          <Form.Control
            aria-describedby="custom-addons5"
            type="file"
            accept={accept}
            className="custom-file-input"
            id="validatedCustomFile1"
            onChange={(ev: any) => onChange(ev.target.files[0])}
          />
          <Form.Label
            className="custom-file-label"
            htmlFor="validatedCustomFile1"
          >
            {btnText}
          </Form.Label>
        </div>
      </InputGroup>
      {errorMessage && <ErrorText text={errorMessage} />}
    </FormGroup>
  )
}
