import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { isValid } from 'cpf'
import { useToasts } from 'react-toast-notifications'
import CPFTagInputLabel from '../../atoms/CPFTagInputLabel/'
import TagInput from 'components/molecules/TagInput'
import { Tag } from 'components/molecules/InputTag'
import { convertCPFStringToDots } from 'utils/convertCPFStringToDots'
import { convertCPForCNPJStringToNumbers } from 'utils/convertCPFStringToNumbers'

export interface TagInputProps {
  onChange: (cpfs: string[]) => Promise<Tag[]>
  defaultValue?: Tag[]
  cpfHintText?: string
}

export const CPFTagInput = ({
  onChange,
  defaultValue = [],
  cpfHintText
}: TagInputProps) => {
  const [data, setData] = useState<Tag[]>(defaultValue)
  const { addToast } = useToasts()

  const notifyChange = (data: Tag[]) =>
    onChange(data.map((tag) => tag.name)).then((newTags) => setData(newTags))

  const cpfValidate = (cpf: string) => {
    if (!isValid(cpf)) {
      addToast(
        `CPF ${convertCPFStringToDots(
          cpf
        )} inválido. Por favor, digite um CPF válido.`,
        {
          autoDismiss: true,
          appearance: 'error'
        }
      )
      return false
    }
    return true
  }

  const handleAddition = (tags: Tag[]) => {
    tags.forEach((tag) => (tag.name = cleanAndFormatCpf(tag.name)))
    notifyChange(tags)
  }

  const handleDelete = (tags: Tag[]) => notifyChange(tags)

  const cleanAndFormatCpf = (cpf: string) => {
    const cpfNumbers = convertCPForCNPJStringToNumbers(cpf)
    return convertCPFStringToDots(cpfNumbers)
  }

  const allowToAddIfNotDuplicated = (tag: Pick<Tag, 'name'>, value: Tag[]) => {
    return !value.find(
      (item) =>
        convertCPForCNPJStringToNumbers(item.name) ===
        convertCPForCNPJStringToNumbers(tag.name)
    )
  }

  return (
    <Form.Group>
      <Form.Label>
        <CPFTagInputLabel hintText={cpfHintText} />
      </Form.Label>
      <TagInput
        placeholder="Digite e aperte ENTER"
        defaultTags={data}
        onChange={(tags, type) => {
          type === 'add' ? handleAddition(tags) : handleDelete(tags)
        }}
        onValidate={(tag) => {
          return allowToAddIfNotDuplicated(tag, data) && cpfValidate(tag.name)
        }}
      />
    </Form.Group>
  )
}
