import * as React from 'react'
import { useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import step1_schema from './step1.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateContractsContext } from '../index'
import { CPFAndCNPJTagInput } from '../../../../components/molecules/CPFAndCNPJTagInput'
import { useContextPage } from '../contextPage'
import { Contract } from '../../../../domain/usecases/Contracts'
import { useToast } from '../../../../hooks/useToast'

export type ICreateContractsFormStep1 = Pick<Contract.CreateParams, 'buyers'>

export default function Step1({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateContractsContext)
  const { showToast } = useToast()
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ICreateContractsFormStep1>({
    resolver: yupResolver(step1_schema),
    defaultValues: {
      ...data
    }
  })
  const { state, setState, getPersons } = useContextPage()
  const [promissoryBuyerCPForCNPJ, setPromissoryBuyerCPForCNPJ] =
    React.useState<string[]>([])

  const onClickNext = (formData: ICreateContractsFormStep1) => {
    if (isInvalidToNextStep())
      return showToast(
        'Todos os Promissários precisam estar cadastrados.',
        'error'
      )
    const buyersIds = state.persons?.map((person) => person.id)
    setData?.({ ...data, buyers: buyersIds })
    nextHandler()
  }

  const tryGetPersons = async () => {
    try {
      const persons = await getPersons.getAll({
        cpfOrCnpj: promissoryBuyerCPForCNPJ
      })
      setState({ ...state, persons, cpfOrCnpjList: promissoryBuyerCPForCNPJ })
    } catch (error) {
      showToast(
        'Nao foi possivel buscar informações sobre os CPFs/CNPJs informados.',
        'error'
      )
    }
  }

  const isInvalidToNextStep = () => {
    return !state?.persons?.every((person) => person.id)
  }

  React.useEffect(() => {
    tryGetPersons()
  }, [promissoryBuyerCPForCNPJ])

  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        <Row className="mt-4">
          <Col>
            <Controller
              control={control}
              name="buyers"
              render={({ field: { onChange, value } }) => {
                const defaultValues = state?.cpfOrCnpjList?.map(
                  (cpfOrCnpj) => ({
                    id: '',
                    name: cpfOrCnpj
                  })
                )
                return (
                  <CPFAndCNPJTagInput
                    defaultValue={defaultValues}
                    label="Digite o(s) CPF(s)/CNPJ(s) do(s) Promissários(s)"
                    onChange={(event) => {
                      setPromissoryBuyerCPForCNPJ(event)
                      onChange(event)
                    }}
                    errorMessage={(errors.buyers as any)?.message}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
