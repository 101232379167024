import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { BillingDetails } from '../../../../pages/Billings/BillingDetails'
import { RemoteBillings } from '../../../../data/usecases/Billing/RemoteBillings'

export const BillingDetailsFactory = () => {
  const URL = URL_API || 'without-url'

  const axiosHttpClient = useAxiosHttpClient()
  const remote = new RemoteBillings(URL, axiosHttpClient)

  document.title = 'Recebível Chipado - Detalhes do Recebível'

  return <BillingDetails remote={remote} />
}
