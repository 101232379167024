import { lazy, useEffect } from 'react'
import { URL_API } from '../../../../services/api_url'
import { useAxiosHttpClient } from '../../../../hooks/useAxiosHttpClient'
import { RemoteGetContracts } from '../../../../data/usecases/Contracts/RemoteGetContracts'
import { ContractListContextProvider } from '../../../../pages/Contracts/actions'
import { useLocation, useHistory } from 'react-router-dom'
import { RemoteGetEntitiesCount } from '../../../../data/usecases/Reports/RemoteGetEntitiesCount'

export const ListContractsFactory = () => {
  const URL = URL_API || 'without-url'
  const history = useHistory()

  const ListContractsGrid = lazy(
    () => import('../../../../pages/Contracts/ListContractsGrid')
  )
  const ContractList = lazy(
    () => import('../../../../pages/Contracts/ContractList')
  )

  const { search } = useLocation()
  const typeQueryParam = new URLSearchParams(search).get('layout')

  const axiosHttpClient = useAxiosHttpClient()
  const remoteGetContracts = new RemoteGetContracts(URL, axiosHttpClient)

  const remoteGetEntitiesCount = new RemoteGetEntitiesCount(
    URL,
    axiosHttpClient
  )

  useEffect(() => {
    if (!typeQueryParam) {
      history.push('/contracts?layout=grid')
    }
  }, [typeQueryParam])

  document.title = 'Recebível Chipado - Lista de Contratos'

  return (
    <ContractListContextProvider
      remoteGetContracts={remoteGetContracts}
      remoteGetEntitiesCount={remoteGetEntitiesCount}
    >
      {typeQueryParam === 'grid' ? <ListContractsGrid /> : <ContractList />}
    </ContractListContextProvider>
  )
}
