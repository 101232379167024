import { ReactNode } from 'react'
import * as S from './styles'

export interface IconWithTextProps {
  disabled?: boolean
  icon: string
  text: ReactNode
}

export const IconWithText = ({ disabled, icon, text }: IconWithTextProps) => {
  return (
    <S.Wrapper disabled={disabled}>
      <i className={`feather ${icon}`} />
      <p>{text}</p>
    </S.Wrapper>
  )
}
