import styled from 'styled-components'

export const DateInput = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      background: #ecf0f5;
      display: flex;
      padding: 10px;
      border-radius: 0.3rem;
      input {
        height: 30px;
        width: 100%;
        background: #ecf0f5;
        border: none;
        font-size: 1.1rem;
        border-radius: 0.3rem;
      }
    }
  }
`
