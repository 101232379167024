import styled from 'styled-components'

export const Wrapper = styled.div``

export const Plus = styled.i`
  margin-left: 1rem;
  font-size: 1rem;
`

export const IconsWrapper = styled.span`
  display: grid;
  grid-template-columns: repeat(2, 50%);
`
export const Button = styled.div`
  text-align: right;
`
