import { HttpClient } from '../../protocols/http/httpClient'
import { IDeleteSpe, SPE } from '../../../domain/usecases/SPE'
import { validateHttpStatus } from '../../../validation/validateHttpResponse'

export class RemoteDeleteSpe implements IDeleteSpe {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async deleteById(params: SPE.DeleteParams): Promise<void> {
    const httpResponse = await this.httpClient.delete?.({
      url: `${this.url}/spes/${params.id}`
    })

    if (httpResponse?.statusCode === 400) {
      throw new Error(httpResponse.message)
    } else if (httpResponse?.statusCode && httpResponse?.statusCode != 200) {
      throw new Error('Não foi possível excluir a SPE.')
    }
  }
}
