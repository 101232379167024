import React, { useContext, useState } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateContractsContext } from '../index'
import SwitchButton from '../../../../components/Switch'

import { Contract } from '../../../../domain/usecases/Contracts'
import { useCreationConfirmModal } from '../../../../hooks/useCreationConfirmModal'
import { InputFile } from '../../../../components/molecules/InputFile'
import { yupResolver } from '@hookform/resolvers/yup'
import step7b_schema from './step7b.schema'

export type ICreateContractsFormStep7b = Pick<
  Contract.CreateParams,
  'custom_contract'
> & {
  custom_contract_file?: File
}

type Files = {
  file_type_custom_contract?: string
  file_size?: number
}

export default function Step7b({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateContractsContext)
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm<ICreateContractsFormStep7b & Files>({
    resolver: yupResolver(step7b_schema)
  })

  const [customContractFile, setCustomContractFile] = useState<File | null>()

  const { setShowConfirmationModal, Modal } = useCreationConfirmModal({
    entityText: 'do Contrato',
    onFinish: nextHandler
  })

  const custom_contract = watch('custom_contract')

  const onClickNext = (formData: ICreateContractsFormStep7b) => {
    setData?.({ ...data, custom_contract_file: customContractFile as File })
    setShowConfirmationModal(true)
  }

  return (
    <>
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={12}>
            <Alert variant="warning">
              Escolha entre utilizar a nossa minuta padrão de compra e venda ou
              enviar a sua própria minuta.
              <br /> Se optar pela sua minuta, um quadro resumo com os dados
              fornecidos será gerado e anexado ao documento para assinaturas.
            </Alert>
            <Controller
              control={control}
              name="custom_contract"
              render={({ field: { onChange, value } }) => (
                <SwitchButton
                  label="Optar por enviar minha minuta personalizada"
                  value={data?.custom_contract}
                  onChange={(event) => onChange(event)}
                />
              )}
            />
          </Col>
        </Row>
        {custom_contract && (
          <>
            <Row>
              <Col md={4}>
                <InputFile
                  btnText={customContractFile?.name || 'Buscar arquivo'}
                  label="Minuta personalizada"
                  accept="application/pdf"
                  onChange={(file) => {
                    setValue('file_type_custom_contract', file?.type, {
                      shouldValidate: true
                    })
                    setValue('file_size', file?.size, {
                      shouldValidate: true
                    })
                    setCustomContractFile(file)
                  }}
                  errorMessage={
                    errors.custom_contract?.message || errors.file_size?.message
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Alert variant="warning">
                  Por favor, garanta que todas as lacunas da sua minuta foram
                  devidamente preenchidas
                </Alert>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
      {Modal}
    </>
  )
}
