import React, { useContext, useState } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateContractsContext } from '../index'
import SwitchButton from '../../../../components/Switch'

import { Contract } from '../../../../domain/usecases/Contracts'
import { useCreationConfirmModal } from '../../../../hooks/useCreationConfirmModal'

export type ICreateContractsFormStep7 = Pick<
  Contract.CreateParams,
  'company_is_signing'
>
export default function Step7({
  nextHandler,
  backHandler
}: FormWizardHandlers) {
  const { data, setData } = useContext(CreateContractsContext)
  const [checkoutCompany, setCheckoutCompany] = useState(
    !!data?.company_is_signing
  )
  const { handleSubmit } = useForm<ICreateContractsFormStep7>({})

  const onClickNext = (formData: ICreateContractsFormStep7) => {
    setData?.({ ...data, company_is_signing: checkoutCompany })
    nextHandler()
  }

  return (
    <>
      <FormWizardStyles.TabContent>
        <Row>
          <Col md={12}>
            <Alert variant="warning">
              Após concluir o cadastro, os Procuradores da Promitente e
              Promissária deverão assinar este Contrato.
              <br />
              Se julgar necessário, marque a opção abaixo para que a assinatura
              da Incorporadora também seja solicitada.
            </Alert>
            <SwitchButton
              value={checkoutCompany}
              label="Desejo que a Incorporadora assine este Contrato."
              onChange={(value) => setCheckoutCompany(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperSpaced>
              <Button variant="link" onClick={backHandler}>
                Voltar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onClickNext)}>
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperSpaced>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </>
  )
}
