import React, { useContext, useState } from 'react'

import { NavLink } from 'react-router-dom'
import { Alert, Card } from 'react-bootstrap'
import { ContentSeparator } from '../../components/atoms/ContentSeparator'

import logo from '../../assets/images/logo.svg'
import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import { PasswordField } from '../../components/atoms/PasswordField'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './signin.schema'
import { ISignInForm } from './signin-form.interface'
import { TextInputWithMask } from '../../components/atoms/TextInputWithMask'
import { signIn } from '../../services/auth'
import { useToasts } from 'react-toast-notifications'
import { LoadingIndicator } from '../../components/atoms/LoadingIndicator'
import { IHistoryPush } from '../../interfaces/router'
import { AppContext } from '../../app'
import { useIsGuest } from '../../hooks/useIsGuest'
import Logo from '../../components/atoms/Logo'

const SignIn = ({ history }: { history: IHistoryPush }) => {
  document.title = 'Recebível Chipado - Login'
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  useIsGuest(history)

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ISignInForm>({
    resolver: yupResolver(schema)
  })

  const { setUser } = useContext(AppContext)

  const [accessDenied, setAccessDenied] = useState<boolean>(false)

  const onSend: SubmitHandler<ISignInForm> = async (data) => {
    try {
      setLoading(true)
      const response = await signIn(data)
      setUser?.({
        token: response.access_token,
        name: response.name
      })
      // TODO: Remove this setTimeout later (added to avoid redirecting to dashboard before the user has set to the context)
      setTimeout(() => {
        setAccessDenied(false)
        history.push('/dashboard')
        setLoading(false)
        addToast('Login efetuado com sucesso!', {
          appearance: 'success',
          autoDismiss: true
        })
      }, 500)
    } catch (e) {
      setLoading(false)
      if (e.response.status === 401) {
        setAccessDenied(true)
      } else if (e.response.status === 403) {
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true
        })
      } else {
        setAccessDenied(false)
        addToast('Ocorreu um erro desconhecido.', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(onSend)}>
          <Card>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Card.Body>
                  <div style={{ marginBottom: '20px' }}>
                    <Logo />
                  </div>
                  <h4 className="f-w-700">
                    Olá! Que bom ter você aqui, fazendo negócios e Originando
                    créditos! :)
                  </h4>
                  <Controller
                    control={control}
                    name="cpf"
                    render={({ field }) => (
                      <TextInputWithMask
                        disabled={loading}
                        mask="999.999.999-99"
                        label="Login"
                        placeholder="Seu CPF"
                        errorMessage={errors.cpf?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="password"
                    render={({ field: { onChange, value } }) => (
                      <PasswordField
                        disabled={loading}
                        onChange={onChange}
                        value={value}
                        errorMessage={errors.password?.message}
                      />
                    )}
                  />

                  {accessDenied && (
                    <Alert variant="danger">
                      Usuário e/ou Senha incorretos.
                    </Alert>
                  )}
                  <div className="d-flex mb-4 mt-4">
                    <button
                      className="btn btn-primary pull-left"
                      type="submit"
                      disabled={loading}
                    >
                      {!loading ? 'Entrar' : <LoadingIndicator />}
                    </button>
                  </div>
                  <div className="d-block mb-4">
                    {!loading && (
                      <p className="mb-2 text-muted pull-left">
                        <ContentSeparator
                          left={
                            <NavLink to="/abra-sua-conta" className="f-w-400">
                              Criar Conta
                            </NavLink>
                          }
                          right={
                            <NavLink
                              to="/esqueci-minha-senha"
                              className="f-w-400"
                            >
                              Esqueci minha Senha
                            </NavLink>
                          }
                        />
                      </p>
                    )}
                  </div>
                </Card.Body>
              </div>
            </div>
          </Card>
        </form>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default SignIn
