import * as yup from 'yup'
import { isValid as validateCPF } from 'cpf'
import { validate as validateCNPJ } from 'cnpj'

export default yup.object().shape({
  file_size: yup
    .number()
    .required('O campo Arquivo do Contrato é requerido.')
    .max(
      10000000,
      'O arquivo é muito grande. Por favor, selecione um arquivo de até 10 MB.'
    ),
  file_type: yup
    .string()
    .required('O campo Arquivo do Contrato é requerido.')
    .test(
      'fileType',
      'Tipo de arquivo não suportado. Por favor, selecione um arquivo de extensão PDF.',
      (value) => value === 'application/pdf'
    ),

  object: yup.string().required('O campo Objeto do Contrato é requerido.'),
  value: yup.string().required('O campo Valor do objeto é requerido.'),
  parties: yup.array().of(
    yup.object().shape({
      document: yup
        .string()
        .required('O campo Número do Documento é requerido.')
        .test('is-cpf', 'Digite um documento válido', (value, context) => {
          const document_type = context.parent.document_type
          return document_type === '1'
            ? validateCPF(value as string)
            : validateCNPJ(value as string)
        }),
      name: yup.string().required('O campo Nome é requerido.')
    })
  ),
  document: yup
    .string()
    .required('O campo Documento do Responsável é requerido.'),
  document_type: yup
    .string()
    .required('O campo Tipo do Documento do Responsável é requerido.'),
  name: yup.string().required('O campo Nome do Responsável é requerido.'),
  terms_accepted: yup
    .boolean()
    .test(
      'is-accepted',
      'Para continuar, por favor, aceite os Termos de Uso.',
      (value) => {
        return value === true
      }
    )
})
