import { Col, Row } from 'react-bootstrap'
import React from 'react'

interface DetailsSectionProps {
  title: string
  content: React.ReactNode
}

import * as S from './styles'

export const DetailsSection = ({ title, content }: DetailsSectionProps) => {
  return (
    <S.Wrapper>
      <Row className="d-flex align-items-center">
        <Col md={12}>
          <Row className="mb-2">
            <Col md={2}></Col>
            <Col className="px-0">
              <h6>{title}</h6>
              {content}
            </Col>
          </Row>
        </Col>
      </Row>
    </S.Wrapper>
  )
}
