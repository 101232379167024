import { ProjectType } from '../../../domain/models/ProjectModel'
import { ParkingSlotsGrouped } from './Step5'
export default function Utils() {
  const convertProjectObj = (project: ProjectType) => {
    return {
      id: project.id,
      name: project.name,
      address: project.address,
      ri: project.ri,
      notary: project.notary,
      spe_id: project.spe_id,
      buildings: project.buildings,
      parking_slots: project.parking_slots,
      grouped_parking_slots: project.grouped_parking_slots,
      parking_slots_groups: project.grouped_parking_slots
        ? convertGroupedParkingSlots(project)
        : [],
      boxes: convertSlotsToBoxes(project),
      ri_document: new File(
        ['fijRKjhudDjiokDhg1524164151'],
        project.ri_document_url,
        { type: 'application/pdf' }
      ),
      building_permit: new File(
        ['fijRKjhudDjiokDhg1524164151'],
        project.build_permit_document_url,
        { type: 'application/pdf' }
      ),
      conclusion_date: new Date(project.conclusion_date),
      matriculation_number: project.matriculation_number
    }
  }

  const convertGroupedParkingSlots = (
    project: ProjectType
  ): ParkingSlotsGrouped[] => {
    const uniqueGroupedParkingSlots = createUniqueGroupedParkingSlots(project)
    const parkingSlotsGrouped: ParkingSlotsGrouped[] = []
    for (const key in uniqueGroupedParkingSlots) {
      parkingSlotsGrouped.push(uniqueGroupedParkingSlots[key])
    }
    return parkingSlotsGrouped
  }

  const createUniqueGroupedParkingSlots = (project: ProjectType) => {
    const uniqueGroupedParkingSlots: Record<string, ParkingSlotsGrouped> = {}
    project.parking_slots.forEach((slot) => {
      uniqueGroupedParkingSlots[slot.group!.id] = {
        id: slot.group!.id,
        name: slot.group!.name,
        parking_slots: project.parking_slots.filter(
          (parking) => slot.group_id === parking.group_id
        )
      }
    })
    return uniqueGroupedParkingSlots
  }

  const convertSlotsToBoxes = (project: ProjectType) =>
    project.parking_slots.map((slot) => ({
      name: slot.group?.name,
      id: slot.group?.id
    }))

  return {
    convertProjectObj
  }
}
