import * as React from 'react'
import TagInput from '../../../../components/molecules/TagInput'
import { useContext } from 'react'
import { DispatchContext } from '../actions'
import { Tag } from 'react-tag-autocomplete'
import { DeepMap, FieldError } from 'react-hook-form'
import { ICreateUnitFormStep1 } from './index'
import Information from '../../../../components/atoms/Information'

interface CreateOrUpdateStep1Props {
  onChange: (...args: any) => void
  units: Tag[]
  errors: DeepMap<ICreateUnitFormStep1, FieldError>
}

const CreateUnitStep1 = ({
  onChange,
  units,
  errors
}: CreateOrUpdateStep1Props) => {
  const { state } = useContext(DispatchContext)
  const allowToAddMultiTags = () => !state.unit.is_complete
  return (
    <TagInput
      onChange={onChange}
      label="Unidade(s)"
      suggestions={units}
      onValidate={() => allowToAddMultiTags()}
      info={
        <Information>
          Digite o(s) número(s) das Unidades que você irá cadastrar, aperte a
          tecla <strong>ENTER</strong> após cada uma. <br />
          Obs.: Lembre-se de que todas estas Unidades devem ter as mesmas
          características.
        </Information>
      }
      errorMessage={(errors.unitsName as any)?.message}
    />
  )
}

export default CreateUnitStep1
