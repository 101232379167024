import * as yup from 'yup'

export default yup.object().shape({
  grouped_parking_slots: yup.boolean(),
  parking_slots: yup.array().when('grouped_parking_slots', {
    is: (grouped_parking_slots: boolean) => grouped_parking_slots === false,
    then: yup.array().min(1, 'O campo Vagas é requerido.')
  }),
  parking_slots_groups: yup.array().when('grouped_parking_slots', {
    is: (grouped_parking_slots: boolean) => grouped_parking_slots === true,
    then: yup.array().min(1, 'O campo Boxes é requerido.')
  })
})
