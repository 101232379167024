import * as yup from 'yup'

export default yup.object().shape({
  address: yup.string().required('O campo Logradouro é requerido.'),
  zip_code: yup.string().required('O campo CEP é requerido.'),
  number: yup.string().required('O campo  Número é requerido.'),
  district: yup.string().required('O campo Bairro é requerido.'),
  city: yup.string().required('O campo Cidade é requerido.'),
  state: yup.string().required('O campo Estado é requerido.')
})
