import axios, { AxiosResponse } from 'axios'
import { HttpClient } from '../../data/protocols/http/httpClient'

export class AxiosHttpClient implements HttpClient {
  private setLoading: ((loading: boolean) => void) | undefined
  constructor(
    private readonly token: string,
    setLoading?: (loading: boolean) => void
  ) {
    this.setLoading = setLoading

    // TODO: Fix rerender issue to uncomment the code bellow
    axios.interceptors.request.use(
      function (config) {
        if (setLoading) {
          setLoading(true)
        }
        return config
      },
      function (error) {
        if (setLoading) {
          setLoading(false)
        }
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      function (response) {
        if (setLoading) {
          setLoading(false)
        }
        return response
      },
      function (error) {
        if (setLoading) {
          setLoading(false)
        }
        return Promise.reject(error)
      }
    )
  }
  //TODO: refactor to convert these methods into dynamic methods

  async post(params: HttpClient.HttpPostClientParams): Promise<any> {
    let axiosResponse: AxiosResponse

    try {
      axiosResponse = await axios.post(params.url, params.data, {
        headers: { ...params.headers, Authorization: `bearer ${this.token}` }
      })

      return axiosResponse?.data
    } catch (error) {
      axiosResponse = error.response?.data
      return axiosResponse
    }
  }
  async get(params: HttpClient.HttpGetClientParams): Promise<any> {
    let axiosResponse: AxiosResponse

    try {
      axiosResponse = await axios.get(params.url, {
        headers: { ...params.headers, Authorization: `bearer ${this.token}` },
        params: params.data
      })

      return axiosResponse?.data
    } catch (error) {
      axiosResponse = error.response?.data
      return axiosResponse
    }
  }
  async delete(params: HttpClient.HttpDeleteClientParams): Promise<any> {
    let axiosResponse: AxiosResponse

    try {
      axiosResponse = await axios.delete(params.url, {
        headers: { ...params.headers, Authorization: `bearer ${this.token}` }
      })

      return axiosResponse?.data
    } catch (error) {
      axiosResponse = error.response?.data
      return axiosResponse
    }
  }
  async patch(params: HttpClient.HttpPostClientParams): Promise<any> {
    let axiosResponse: AxiosResponse

    try {
      axiosResponse = await axios.patch(params.url, params.data, {
        headers: { ...params.headers, Authorization: `bearer ${this.token}` }
      })

      return axiosResponse?.data
    } catch (error) {
      axiosResponse = error.response?.data
      return axiosResponse
    }
  }
}
