import React, { useEffect, useState, useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useForm, Controller, DeepMap, FieldError } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToasts } from 'react-toast-notifications'
import { Tag } from 'react-tag-autocomplete'
import {
  SelectField,
  SelectFieldOption
} from '../../../../components/atoms/SelectField'
import step1_schema from './step1.schema'
import * as FormWizardStyles from '../../../../components/organisms/FormWizard/styles'
import { FormWizardHandlers } from '../../../../components/organisms/FormWizard'
import { CreateUnitContext } from '../index'
import { DispatchContext } from '../actions'
import { BuildingModel } from '../../../../domain/models/BuildingModel'
import CreateUnitStep1 from './createUnitStep1'
import CompleteUnitStep1 from './completeUnitStep1'
import UpdateUnitStep1 from './updateUnitStep1'

export interface ICreateUnitFormStep1 {
  unitsName: string[]
  building_id: string
}
type projectMinimal = {
  id: string
  name: string
}

export default function Step1({ nextHandler }: FormWizardHandlers) {
  const { data, setData, completeMultiple, completeUnit } =
    useContext(CreateUnitContext)
  const { state, dispatch } = useContext(DispatchContext)
  const { addToast } = useToasts()
  const [projectId, setProjectId] = useState<string>()
  const [projects, setProjects] = useState([])
  const [buildings, setBuildings] = useState<SelectFieldOption[]>([])
  const [units, setUnits] = useState<Tag[]>([])
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<ICreateUnitFormStep1>({
    resolver: yupResolver(step1_schema),
    defaultValues: {
      unitsName: data?.unitsName?.length
        ? data?.unitsName
        : completeUnit
        ? [completeUnit.name!]
        : [],
      building_id: data?.building_id
    }
  })
  const building_id = watch('building_id')

  useEffect(() => {
    if (isUpdatePage()) {
      setValue('unitsName', [state.unit.name])
      handleGetProjectInformationsById(state.unit.building.project.id)
    }
  }, [state.unit])

  const isUpdatePage = () => state.unit.name

  useEffect(() => {
    if (completeUnit) {
      dispatch({
        type: 'GET_PROJECTS_BY_ID',
        payload: completeUnit.building.project.id
      })
      setProjectId(completeUnit.building.project.id)
      setValue('building_id', completeUnit.building_id)
    }
  }, [completeUnit])

  const onClickNext = (formData: ICreateUnitFormStep1) => {
    if (isCreatePage()) formData.unitsName = convertTagsToUnitNames(formData)
    if (isUpdatePage()) formData.unitsName = [state.unit.name]
    setData!((prevState) => ({
      ...prevState,
      ...formData
    }))
    nextHandler()
  }

  const isCompletePage = () => completeMultiple

  const isCreatePage = () => !completeMultiple

  const convertTagsToUnitNames = (formData: ICreateUnitFormStep1) =>
    // @ts-ignore
    formData.unitsName.map((u: Tag) => u.name)

  const handleGetProjectInformationsById = async (id: string) => {
    setProjectId(id)
    await dispatch({ type: 'GET_PROJECTS_BY_ID', payload: id })
  }

  const getMinimalProjectsListByUser = async () => {
    await dispatch({ type: 'GET_MINIMAL_PROJECTS' })
  }

  useEffect(() => {
    getMinimalProjectsListByUser()
  }, [])

  useEffect(() => {
    const minimalProjects = state.minimalProjects.length
      ? state.minimalProjects.map((item: Partial<projectMinimal>) => ({
          label: item.name,
          value: item.id
        }))
      : []

    setProjects(minimalProjects)
  }, [state.minimalProjects])

  useEffect(() => {
    if (state.errorMessage) {
      addToast(state.errorMessage, {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }, [state.errorMessage])

  useEffect(() => {
    setProjectId(state.project?.id)
    const { buildings }: { buildings: BuildingModel[] } = state.project
    setBuildings(convertBuildingsToSelectOptions(buildings))

    if (
      buildings &&
      !building_id &&
      !buildings.find((building) => building.id === building_id)
    ) {
      setValue('building_id', buildings[0].id)
    }
    const units = buildings?.map(convertBuildingUnitsToTags)
    units && setUnits(createUniqueUnitsTags(units))
  }, [state.project])

  useEffect(() => {
    if (state.project?.buildings?.length) {
      const building = state.project.buildings.filter(
        (b: BuildingModel) => b.id === building_id
      )
      const units = building.map(convertBuildingUnitsToTags)
      setUnits(createUniqueUnitsTags(units))
    }
  }, [building_id])

  const convertBuildingsToSelectOptions = (buildings?: BuildingModel[]) =>
    buildings?.map((item) => ({
      value: item.id,
      label: item.name
    })) || []

  const convertBuildingUnitsToTags = (building: BuildingModel) =>
    building.units.map((unit) => ({ id: unit.id, name: unit.name }))

  const createUniqueUnitsTags = (units: { id: string; name: string }[][]) => [
    ...new Set<Tag>(...units)
  ]
  const unitsName = watch('unitsName')
  return (
    <div className="m-3">
      <FormWizardStyles.TabContent>
        <Row className="mb-3">
          <Col md={4}>
            <SelectField
              data-testid="select-project"
              emptyOptionText={'Selecione'}
              onChange={(event) => {
                handleGetProjectInformationsById(event.target.value)
              }}
              value={projectId}
              defaultValue={data?.building_id || state.project.id || ''}
              disabled={isUpdatePage()}
              label="Selecione o Empreendimento"
              options={projects}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Controller
              control={control}
              name="building_id"
              render={({ field: { onChange, value } }) => {
                return (
                  <SelectField
                    data-testid="select-building"
                    errorMessage={errors.building_id?.message}
                    emptyOptionText={
                      !buildings.length
                        ? 'Selecione o Bloco da(s) Unidade(s)'
                        : 'Selecione'
                    }
                    onChange={onChange}
                    value={value}
                    label="Bloco"
                    disabled={isUpdatePage()}
                    options={buildings || []}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <Controller
              name="unitsName"
              control={control}
              render={({ field: { onChange } }) => {
                if (isCompletePage())
                  return (
                    <CompleteUnitStep1
                      onChange={onChange}
                      units={units}
                      errors={
                        errors as DeepMap<ICreateUnitFormStep1, FieldError>
                      }
                      setValue={setValue}
                      control={control}
                    />
                  )
                if (isUpdatePage())
                  return (
                    <UpdateUnitStep1 onChange={onChange} setValue={setValue} />
                  )
                return (
                  <CreateUnitStep1
                    onChange={onChange}
                    units={units}
                    errors={errors as DeepMap<ICreateUnitFormStep1, FieldError>}
                  />
                )
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormWizardStyles.ButtonsWrapperRight>
              <Button
                variant="primary"
                data-testid="button-submit"
                onClick={handleSubmit(onClickNext)}
              >
                Próximo
              </Button>
            </FormWizardStyles.ButtonsWrapperRight>
          </Col>
        </Row>
      </FormWizardStyles.TabContent>
    </div>
  )
}
