import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 40px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
`

export const h4 = styled.h4`
  font-weight: 700;
  max-width: 100% !important;
  margin-bottom: 0px !important;
`

export const h5 = styled.h5`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px !important;
`

export const Img = styled.img`
  margin-bottom: 0px !important;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const p = styled.p`
  font-size: 14px;
  margin-bottom: 20px !important;
`

export const Tip = styled.p`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px !important;
`

export const CodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CodeItem = styled.div`
  margin-bottom: 40px;
`

export const CodeLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px !important;
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconText = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 0 !important;
`

export const ErrorLabel = styled.p`
  color: red;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 0px !important;
  margin-top: 10px !important;
`

export const MainInfo = styled.div`
  display: flex;
  flex-direction: row;

  p {
    margin-right: 40px;
  }

  p:last-child {
    margin-right: 0px;
  }
`

export const BoletoInfo = styled.div`
  align-items: center;
  color: #5e6ea7;
  display: flex;
  flex-direction: row;
  margin: 20px 0;

  a,
  p {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-left: 10px;
    text-decoration: underline;
  }
`

export const CardInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const CardItem = styled.div`
  margin-right: 40px;
`
