import { useContext } from 'react'
import { AppContext } from '../app'
import { AxiosHttpClient } from '../infra/http/axiosHttpClient'
import { useSelector } from '../store/reducers/reducer'
import { useDispatch } from 'react-redux'

export const useAxiosHttpClient = () => {
  const { user } = useContext(AppContext)
  const dispatch = useDispatch()
  const TOKEN_USER = user?.token || 'without-token'

  const setLoading = (loading: boolean) => {
    dispatch({ type: 'SET_LOADING', loading })
  }

  return new AxiosHttpClient(TOKEN_USER, setLoading)
}
