import styled from 'styled-components'

import { Card, Form, Button } from 'react-bootstrap'

const { Group, Label } = Form

export const Title = styled.p`
  width: 100%;
  background: #5e6ea7;
  font-size: 14px;

  padding: 7px 9px;
  border-radius: 2px;
  font-weight: 500;
  color: #ffffff;
`

export const CustomFromGroup = styled(Group)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CustomLabel = styled(Label)`
  color: #5e6ea7;
  font-weight: 500;
`

export const CustomButton = styled(Button)`
  width: 100%;
  height: 43px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
