import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import * as S from './styles'
import TableCardTemplate from '../../templates/TableCardTemplate'
import { Receivable } from '../../../domain/models/ReceivableModel'
import moment from 'moment'
import { convertNumberToCurrencyFormat } from '../../../utils/convertNumberToCurrencyFormat'
import { HooverableTooltip } from '../../molecules/HooverableTooltip'
import { Link } from 'react-router-dom'

export type PaymentsTableCardProps = {
  values: Receivable[]
}

export const PaymentTableCard = ({ values }: PaymentsTableCardProps) => {
  return (
    <TableCardTemplate
      columns={[
        'CONTRATO',
        'PROMISSÁRIOS',
        'N.º PARCELA',
        'VALOR PARCELA (R$)',
        'DATA DE VENCIMENTO',
        'ETAPA',
        'STATUS'
      ]}
      rows={values.map((item) => (
        <tr key={item.id}>
          <td>
            <Link to={`/billings/${item.id}`}>
              <a>{item.contract_object}</a>
            </Link>
          </td>
          <S.WrapperProxyBox>
            <div>
              {item.buyers_initials.map((buyer) => (
                <HooverableTooltip key={buyer.name} text={buyer.name}>
                  <S.ProxyBox>
                    <strong>{buyer.initials}</strong>
                  </S.ProxyBox>
                </HooverableTooltip>
              ))}
              {item.buyers_initials.length > 2 && (
                <S.ProxyBox>
                  <strong>+{item.buyers_initials.length - 2}</strong>
                </S.ProxyBox>
              )}
            </div>
          </S.WrapperProxyBox>
          <td>{item.installment_number}</td>
          <td>{convertNumberToCurrencyFormat(item.value)}</td>
          <td>{moment(item.due_date).format('DD/MM/YYYY')}</td>
          <td>{item.payment_part_name}</td>
          <td>
            {item.status === 'pending' && (
              <Badge variant="info">Pendente</Badge>
            )}
            {item.status === 'paid' && <Badge variant="success">Pago</Badge>}
            {item.status === 'late' && (
              <Badge variant="warning">Atrasado</Badge>
            )}
          </td>
        </tr>
      ))}
    />
  )
}
