import React from 'react'
import { TableCard } from '../../../../../components/organisms/TableCard'
import ModalFormToCreatePromissoryUser from '../../ModalFormToCreatePromissoryUser'
import { CreateContractsContext, ICreateContractsForm } from '../../index'
import { Person } from '../../../../../domain/models/PersonsModel'
import { State, useContextPage } from '../../contextPage'

export default function PromissoryBuyerTable() {
  const [showModal, setShowModal] = React.useState(false)
  const table = Table(setShowModal)

  return (
    <>
      <TableCard data={table} />
      <ModalFormToCreatePromissoryUser
        showModal={showModal}
        closeModal={(closeModal: boolean) => setShowModal(closeModal)}
      />
    </>
  )
}

const formatCpf = (cpf: string) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

const formatCnpj = (cnpj: string) => {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

const Table = (setShowModal: React.Dispatch<React.SetStateAction<boolean>>) => {
  const { state, setState } = useContextPage()

  const updateSelectedPerson = (person: Partial<Person>) => {
    setState({ ...state, selectedPerson: person })
  }

  return {
    titleHeader: 'Promissário(s)',
    thead: (
      <thead>
        <tr>
          <th>CPF/CNPJ</th>
          <th>NOME</th>
          <th>STATUS</th>
          <th></th>
        </tr>
      </thead>
    ),
    tbody: (
      <tbody>
        {state?.persons?.map((person: any) => (
          <tr key={person.info?.cpf || person.info?.cnpj}>
            <td>
              <strong>
                {person.info?.cpf
                  ? formatCpf(person.info?.cpf)
                  : formatCnpj(person.info?.cnpj)}
              </strong>
            </td>
            <td>
              <strong>{person.info?.name || person.info?.business_name}</strong>
            </td>
            <td>
              {person.info.id ? (
                <label className="badge badge-light-primary">Cadastrado</label>
              ) : (
                <label className="badge badge-light-danger">
                  Não Cadastrado
                </label>
              )}
            </td>
            <td>
              {person.info.id ? (
                <div style={{ width: '50%' }}>
                  <button
                    style={{
                      color: '#5E6EA7',
                      background: 'none',
                      border: 'none',
                      display: 'flex',
                      marginLeft: 'auto'
                    }}
                    onClick={() => {
                      updateSelectedPerson(person)
                      setShowModal(true)
                    }}
                  >
                    <i className="feather icon-edit" style={{ fontSize: 24 }} />
                  </button>
                </div>
              ) : (
                <button
                  style={{
                    color: '#5E6EA7',
                    background: 'none',
                    fontWeight: 600,
                    border: 'none',
                    display: 'flex',
                    marginLeft: 'auto'
                  }}
                  onClick={() => {
                    updateSelectedPerson(person)
                    setShowModal(true)
                  }}
                >
                  Cadastrar
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    )
  }
}
