import { Unit } from '../../../domain/usecases/Unit'
import { UnitType } from '../../../domain/models/UnitModel'
import { HttpClient } from '../../protocols/http/httpClient'

export class RemoteCreateUnit implements Unit {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient
  ) {}

  async create(params: Unit.UnitParams): Promise<UnitType> {
    const { file, parking_slots = [], ...properties } = params

    const formData = new FormData()

    for (let i = 0; i < parking_slots.length; i++) {
      formData.append(`parking_slots[${i}]`, parking_slots[i])
    }
    Object.entries(properties).map((item: any) => {
      if (item[1]) {
        formData.append(item[0], item[1])
      }
    })
    formData.append('plan', params.file!)

    const response = await this.httpClient.post?.({
      url: `${this.url}/units`,
      data: formData,
      headers: {
        Accept: '*/*'
      }
    })
    return response
  }

  async createMany(params: Unit.UnitParamsToCreateMany): Promise<void> {
    const { unitsName, parkingSlotByUnit, ...properties } = params

    // the backend doesn't support creating multiples yet.

    unitsName?.forEach(async (name) => {
      return await this.create({
        name,
        parking_slots: parkingSlotByUnit?.[name],
        ...properties
      })
    })
  }

  async uploadUnitPhoto(params: Unit.UploadUnitPhoto): Promise<any> {
    const httpResponse = await this.httpClient.post?.({
      url: `${this.url}/units/${params.id}/upload-photo`,
      data: { base64: params.base64 }
    })

    if (httpResponse?.statusCode && httpResponse?.statusCode !== 201)
      throw new Error('error on upload unit photo')
    return httpResponse
  }
}
