import React, { useContext, useState } from 'react'

import { DevelopedByGT } from '../../components/atoms/DevelopedByGT'
import { useToasts } from 'react-toast-notifications'
import { IHistoryPush } from '../../interfaces/router'
import { AppContext } from '../../app'
import Logo from '../../components/atoms/Logo'
import { Card, Table } from 'react-bootstrap'
import { TextInput } from '../../components/atoms/TextInput'

const Explorer = ({ history }: { history: IHistoryPush }) => {
  document.title = 'Recebível Chipado - Explorer'
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const { setUser } = useContext(AppContext)

  const [accessDenied, setAccessDenied] = useState<boolean>(false)

  return (
    <div className="auth-wrapper">
      <div style={{ width: '1300px !important' }}>
        <Card>
          <div className="row align-items-center">
            <div className="col-md-12">
              <Card.Body>
                <div style={{ marginBottom: '20px' }}>
                  <Logo />
                </div>
                <h4 className="f-w-700">Explorador da Blockchain</h4>
                <TextInput
                  label={'Buscar'}
                  placeholder="Digite aqui a sua busca"
                />

                <h5>Últimas transações</h5>
                <Table responsive hover className="mb-0">
                  <thead>
                    <tr>
                      <th>Hash da Transação</th>
                      <th>Bloco</th>
                      <th>Tipo de Registro</th>
                      <th>Objeto</th>
                      <th>Valor</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        0x034bbfd3653148f11642ac7f93802c25fef0295efd398dc43157f5e9204ece70
                        <br />
                        <span style={{ fontSize: '12px' }}>
                          9 minutos atrás
                        </span>
                      </td>
                      <td>520293</td>
                      <td>Registro de Recebível</td>
                      <td>Empreendimento Teste</td>
                      <td>R$ 55.320,00</td>
                      <td>
                        <button
                          onClick={() =>
                            history.push(
                              `/explorer/0x72672efd2dbaa8772d0d0f320c33cb049b22eb04b8b152c548974e383a252a09`
                            )
                          }
                          className="btn btn-primary"
                        >
                          Ver detalhes
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        0x034bbfd3653148f11642ac7f93802c25fef0295efd398dc43157f5e9204ece70
                        <br />
                        <span style={{ fontSize: '12px' }}>
                          9 minutos atrás
                        </span>
                      </td>
                      <td>520293</td>
                      <td>Registro de Contrato</td>
                      <td>Empreendimento Teste 2</td>
                      <td>R$ 820.000,00</td>
                      <td>
                        <button
                          onClick={() =>
                            history.push(
                              `/explorer/0x72672efd2dbaa8772d0d0f320c33cb049b22eb04b8b152c548974e383a252a09`
                            )
                          }
                          className="btn btn-primary"
                        >
                          Ver detalhes
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </div>
          </div>
        </Card>
        <DevelopedByGT />
      </div>
    </div>
  )
}
export default Explorer
