import * as S from '../../../pages/PaymentFlow/styles'
import logo from '../../../assets/images/logo.svg'
import React, { useEffect, useState } from 'react'
import { CheckoutDetails } from '../../../domain/models/BillingModel'
import { paymentSteps } from '../../../pages/PaymentFlow'

interface PaymentsHeaderProps {
  step: paymentSteps
  checkoutDetails: CheckoutDetails
}

export const PaymentsHeader = ({
  checkoutDetails,
  step
}: PaymentsHeaderProps) => {
  const [headerTitle, setHeaderTitle] = useState<string>('')

  document.title = `Recebível Chipado - ${headerTitle}`

  useEffect(() => {
    const checkHeaderTitle = () => {
      switch (step) {
        case 'initial':
          setHeaderTitle('Pagamento de Fatura')
          break

        case 'credit_card_form':
          setHeaderTitle('Efetuar Pagamento')
          break

        case 'billing_form':
          setHeaderTitle('Informações de Cobrança')
          break

        case 'review':
          setHeaderTitle('Revisar Pagamento')
          break

        case 'analysis':
          setHeaderTitle('Em Análise')
          break

        case 'error':
          setHeaderTitle('Erro no Pagamento')
          break

        case 'already-payed':
          setHeaderTitle('Fatura Paga')
          break

        default:
          setHeaderTitle('Pagamento')
      }
    }
    checkHeaderTitle()
  }, [step])

  return (
    <S.HeaderWrapper>
      <div>
        <S.h4>{headerTitle}</S.h4>
        <S.h5>
          {checkoutDetails.billing.contract_payment_part.contract.project.name}
          <br />
          Unidade{' '}
          {
            checkoutDetails.billing.contract_payment_part.contract.unit.name
          }{' '}
          Bloco{' '}
          {
            checkoutDetails.billing.contract_payment_part.contract.unit.building
              .name
          }
          <br />
          {checkoutDetails.billing.contract_payment_part.contract.id}
        </S.h5>
      </div>

      <div>
        <S.Img src={logo} alt="Recebível Chipado" className="logo" />
      </div>
    </S.HeaderWrapper>
  )
}
