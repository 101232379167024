import { useState } from 'react'
import { ConfirmCreationModal } from '../components/molecules/ConfirmCreationModal'
import * as React from 'react'

interface useCreationConfirmModalProps {
  onFinish: () => void
  entityText: string
}

export const useCreationConfirmModal = ({
  entityText,
  onFinish
}: useCreationConfirmModalProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  return {
    setShowConfirmationModal,
    Modal: (
      <ConfirmCreationModal
        show={showConfirmationModal}
        entityName={entityText}
        handleClose={() => setShowConfirmationModal(false)}
        handleOk={onFinish}
      />
    )
  }
}
